import React, { useEffect, useState,useRef } from "react";
import {
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Table,
    ExpandableRowContent,
} from "@patternfly/react-table";
import {
    EmptyState,
    EmptyStateBody,
    EmptyStateHeader,
    EmptyStateIcon,
    Pagination,
    Select,
    SelectOption,
    SelectList,
    SelectGroup,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    MenuToggle,
    SearchInput,
    Flex,
    FlexItem,
    Tooltip,
    Icon,
    Title,
    List,
    ListItem,
    ListVariant,
    Card,
    CardBody,
    Grid,
    GridItem,
    CardTitle,

} from "@patternfly/react-core";
import { connect, useDispatch } from "react-redux";
import { EditAltIcon, SearchIcon, SortAmountDownIcon, TrashAltIcon } from "@patternfly/react-icons"
import { Loader } from "src/util/Loadingstate";
import { formatTableTimestamp } from "src/util/Timeutils";
import dbApi from '../../services/DbApi'
import { alertBoxData } from "src/Redux/Action";
import ConfirmBox from "../ConfirmBox/ConfirmBox";

const AlertingruleListTable = (props) => {
    const defaultColumns = {
        unique_id: 'Unique ID',
        title: "Rule Name",
        priority: "Priority",
        hit_count: "Hit Count",
        last_datetime: "Last Date Time",
        action: 'Action'
    };
    const childUpdateruleFormRef = useRef(null);
    const dispatch=useDispatch()
    const [columns, setColumns] = useState(defaultColumns);
    const [rows, setRows] = useState([]);
    const initialExpandedRowNames = [];
    const [expandedRowNames, setExpandedRowNames] = useState(initialExpandedRowNames);
    const [expandedRowData, setExpandedRowData] = useState({});
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRules, setTotalRules] = useState(0);
    const [activeSortIndex, setActiveSortIndex] = useState(4);
    const [activeSortDirection, setActiveSortDirection] = useState('desc');
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
    const [selectedRowName, setSelectedRowName] = useState('');
    const [loadRules, setLoadrules] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [searchValue, setSearchValue] = useState('');
    const [loadRulesdetail, setLoadrulesdetail] = useState(false)
    const [ruleDetails, setRuleDetails] = useState('')
    const excludeOrder = ['Action',]
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })
    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage, newPage) => {
        setPage(newPage);
        setPerPage(newPerPage);
    };

    const getRulesList = async (orgId) => {
        try {
            let params = {
                limitStart: (perPage * page) - perPage,
                limitLength: perPage,
                search: searchValue,
                sortBy: Object.keys(columns)[activeSortIndex],
                sortOrder: activeSortDirection,
                organization_unique_id: orgId,
            }
            setLoadrules(true)
            let res = await dbApi.postApi("Opensearch/ruleListAlerting", params)
            console.log(res)
            let total = 0
            let totaldata = []
            if (!res.error) {
                totaldata = res.data.data
                total = res.data.totalRecord
                setRows(totaldata)
                setTotalRules(total)
            }
            else {
                setRows(totaldata)
                setTotalRules(total)
            }
            setLoadrules(false)
            // props.isload(false)
        } catch (error) {
            console.log(error)
            setRows([])
            setTotalRules(0)
            setLoadrules(false)
            // props.isload(false)
        }
    }
    useEffect(() => {
        if (props.OrganizationID !== '') { getRulesList(props.OrganizationID) }
    }, [page,
        perPage,
        activeSortIndex,
        activeSortDirection,
        searchValue,
        props.OrganizationID,
        props.Refresh
    ])

    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };

    const getRuleDetails = async (id) => {
        try {
            let param = {
                rule_unique_id: id
            }
            setLoadrulesdetail(true)
            let res = await dbApi.postApi("Opensearch/expandRuleListAlerting", param)
            console.log(res)
            if (!res.error) {
                setRuleDetails(res.expandRuleListAlerting)
            }
            else {
                setRuleDetails('')
            }
            setLoadrulesdetail(false)
        } catch (error) {
            console.log(error)
            setLoadrulesdetail(false)
        }
    }

    //code for row  expansion 
    const setRowExpanded = (row, isExpanding) => {
        const otherExpandedRowNames = expandedRowNames.filter((r) => r !== row.unique_id);
        setExpandedRowNames(
            isExpanding ? [row.unique_id] : []
        );
        if (!expandedRowData[row.unique_id]) {
            getRuleDetails(row.unique_id)
            setExpandedRowData([row.unique_id]);
        }
        const updatedExpandedRowNames = isRowExpanded(row)
            ? otherExpandedRowNames
            : [row.unique_id];
        setExpandedRowNames(updatedExpandedRowNames);

    };
    const isRowExpanded = (row) => {
        return expandedRowNames.includes(row.unique_id);
    };


    const renderPagination = () =>
        <Pagination
            itemCount={totalRules}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
        />;

    const onSearch = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        setSearchValue(inputValue)
        setPage(1)
    }

    const finalDelete = async (data) => {
        try {
            let param = {
                unique_id: data
            }
            let res = await dbApi.deleteApi("Opensearch/deleteAlertsRules", param)
            console.log('delete', res)
            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                getRulesList(props.OrganizationID)
            }
            else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]))
            }
        } catch (error) {
            console.log(error)
        }

    }


    const deleteRules = (rulesId) => {
        setConfirmBox({
            show: true,
            msg: 'This rule will be deleted from your list',
            funCall: finalDelete,
            funParam: rulesId,
            title: `Permanently delete rule?`,
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        })
    }

    const tableToolbar = <Toolbar id="sortable-toolbar">
        <ToolbarContent>
            <ToolbarItem visibility={{ md: 'hidden' }}>
                <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
                    if (value === 'asc' || value === 'desc') {
                        onSort(event, activeSortIndex, value);
                    } else {
                        onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'desc');
                    }
                }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
                    <SortAmountDownIcon />
                </MenuToggle>}>
                    <SelectGroup label="Sort column">
                        <SelectList>
                            {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                                {column}
                            </SelectOption>)}
                        </SelectList>
                    </SelectGroup>
                    <SelectGroup label="Sort direction">
                        <SelectList>
                            <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                                Ascending
                            </SelectOption>
                            <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                                Descending
                            </SelectOption>
                        </SelectList>
                    </SelectGroup>
                </Select>
            </ToolbarItem>
            <ToolbarItem key={'search-input'} variant="search-filter" style={{ width: '45%' }}>
                <SearchInput placeholder="Search..."
                    value={inputValue}
                    onChange={(_event, value) => setInputValue(value)}
                    onSearch={onSearch}
                    onClear={() => { setSearchValue(''); setInputValue('') }} />
            </ToolbarItem>
            <ToolbarItem align={{ default: 'alignRight' }} >{renderPagination()}</ToolbarItem>
        </ToolbarContent>
    </Toolbar>;
    return (
        <React.Fragment>
            <ConfirmBox confirmModal={confirmModalData} />
            {loadRules ?
                <Loader /> :
                <>
                    {tableToolbar}
                    <Table variant="compact" aria-label="indextable" id="indextable-table" isStickyHeader gridBreakPoint="">
                        <Thead >
                            <Tr key={'cahrt-table-header'}>
                                <Th key={'expand'} aria-label='expandall' screenReaderText="expandtable" />
                                {Object.values(columns).map((column, columnIndex) => {
                                    const sortParams = {
                                        sort: {
                                            sortBy: {
                                                index: activeSortIndex,
                                                direction: activeSortDirection
                                            },
                                            onSort,
                                            columnIndex
                                        }
                                    };
                                    // return <Th key={column} {...sortParams} isStickyColumn modifier="wrap">{column}</Th>;
                                    return (
                                        <>
                                            {!excludeOrder.includes(column) ? <Th key={columnIndex + column} {...sortParams} isStickyColumn>{column}</Th>
                                                :
                                                <Th key={columnIndex + column} isStickyColumn>{column}</Th>}
                                        </>)
                                })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {rows && rows.length > 0 ?
                                rows.map((row, rowIndex) => {
                                    const { formattedTimeStamp } = formatTableTimestamp(row?.last_datetime);
                                    return (
                                        <>
                                            <Tr key={rowIndex + 'row'}
                                                onRowClick={() => setSelectedRowName(rowIndex)}
                                                isSelectable
                                                isClickable
                                                isRowSelected={selectedRowName === rowIndex} >
                                                <Td
                                                    expand={{
                                                        rowIndex: rowIndex,
                                                        isExpanded: isRowExpanded(row),
                                                        onToggle: () => setRowExpanded(row, !isRowExpanded(row)),

                                                    }}
                                                    key={"exp-" + rowIndex}
                                                />
                                                <Td width={20} dataLabel="Unique ID" key={rowIndex + 'uniqueid'}>{row?.unique_id}</Td>
                                                <Td width={20} dataLabel="Rule Name" key={rowIndex + 'rulename'}>{row?.title}</Td>
                                                <Td width={10} dataLabel="Priority" key={rowIndex + 'prio'}>{row?.priority}</Td>
                                                <Td width={20} key={rowIndex + 'hitcount'} dataLabel="Hit Count">{row?.hit_count}</Td>
                                                <Td width={20} key={rowIndex + 'time'} dataLabel="Last Date Time">{formattedTimeStamp}</Td>
                                                <Td width={10} dataLabel="Action" key={rowIndex + 'action'}>
                                                    <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                                                        <FlexItem key={'editorg'}>
                                                            {/* childUpdateruleFormRef.current.setUpdateruleDetailForm(row?.unique_id) */}
                                                            <Tooltip content='Edit rule'><Icon onClick={()=> props.onUpdateruleformRef(row?.unique_id)}><EditAltIcon /></Icon></Tooltip>
                                                        </FlexItem>
                                                        {/* deleteRules */}
                                                        <FlexItem key={'remove org'}>
                                                            <Tooltip content='delete rule'><Icon status='danger' onClick={() => { deleteRules(row.unique_id) }} ><TrashAltIcon /></Icon></Tooltip>
                                                        </FlexItem>
                                                    </Flex>
                                                </Td>
                                            </Tr>
                                            <Tr isExpanded={isRowExpanded(row)}>
                                                <Td colSpan={Object.keys(columns).length + 1}>
                                                    <ExpandableRowContent>
                                                        {isRowExpanded(row) && (
                                                            loadRulesdetail ? <Loader /> :
                                                                ruleDetails !== '' &&
                                                                <>
                                                                    <Grid hasGutter>
                                                                        <GridItem span={6}>
                                                                            <Card isFullHeight>
                                                                                <CardTitle>Agents</CardTitle>
                                                                                <CardBody>
                                                                                    <List >
                                                                                        {ruleDetails?.ruleAgent?.map((item, index) =>
                                                                                        (<ListItem key={index}>{item.agent_name}</ListItem>
                                                                                        ))} </List>

                                                                                </CardBody>
                                                                            </Card>
                                                                        </GridItem>
                                                                        <GridItem span={6}>
                                                                            <Card isFullHeight>
                                                                            <CardTitle>Members Email</CardTitle>
                                                                                <CardBody>
                                                                                    <List >
                                                                                        {ruleDetails?.alertsRulesMember?.map((item, index) =>
                                                                                        (<ListItem key={index}>{item.email}</ListItem>
                                                                                        ))} </List>

                                                                                </CardBody>
                                                                            </Card>
                                                                        </GridItem>
                                                                    </Grid>
                                                                    <Card className="pf-v5-u-mt-sm">
                                                                        <CardBody>

                                                                            <Title headingLevel="h4">Filter Criteria</Title>
                                                                            <Table variant="compact" aria-label="detail" id="detail-table" isStickyHeader gridBreakPoint="">
                                                                                <Thead >
                                                                                    <Tr>
                                                                                        <Th>rule</Th>
                                                                                        <Th>condition</Th>
                                                                                        <Th>value</Th>
                                                                                    </Tr>
                                                                                </Thead>
                                                                                <Tbody>
                                                                                    {ruleDetails?.rulesConditions?.map((row, rowIndex) => {
                                                                                        return (
                                                                                            <>
                                                                                                <Tr key={rowIndex + 'row'}>
                                                                                                    <Td key={rowIndex + 'column'} >{row?.columns}</Td>
                                                                                                    <Td key={rowIndex + 'condition'} >{row?.conditions}</Td>
                                                                                                    <Td key={rowIndex + 'value'} >{JSON.parse(row?.value).join(', ')}</Td>
                                                                                                </Tr>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </Tbody>
                                                                            </Table >

                                                                        </CardBody>
                                                                    </Card>
                                                                </>
                                                        )}
                                                    </ExpandableRowContent>
                                                </Td>
                                            </Tr>
                                        </>
                                    )
                                })
                                :
                                <Tr>
                                    <Td colSpan={10} key='empty-data'>
                                        <EmptyState>
                                            <EmptyStateHeader titleText="No results found" headingLevel="h4" icon={<EmptyStateIcon icon={SearchIcon} />} />
                                            <EmptyStateBody>No results match the filter criteria. Clear all filters and try again.</EmptyStateBody>

                                        </EmptyState>
                                    </Td>
                                </Tr>
                            }
                        </Tbody>
                    </Table >
                    <div className="pf-v5-u-my-sm">
                        {renderPagination()}
                    </div>
                </>
            }

        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(AlertingruleListTable)
