import {
    Button,
    Card,
    Flex,
    FlexItem,
    MenuToggle,
    Modal, ModalVariant,
    Select,
    SelectList,
    SelectOption,
    Switch,
    Tab,
    TabContentBody,
    TabTitleText,
    Tabs,

} from '@patternfly/react-core';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import OnBoardingForm from './OnBoardingForm';
import { connect } from 'react-redux';
import { Loader } from 'src/util/Loadingstate';
import dbApi from 'src/services/DbApi';
import AddMembers from './AddMembers';
import { TimesIcon } from '@patternfly/react-icons';
import AlertingRule from './AlertingRule';
import TapRegistrationForm from './TapRegForm';

const UpdateOrganisation = forwardRef((props, ref) => {
    // const childUpdateOnBoardFormRef = useRef(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [orgUniqueId, setOrgUniqueId] = useState('')
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [activeTabKey2, setActiveTabKey2] = useState(10);
    const [openorganization, setOpenOrganization] = useState('')
    const [isSwitchemailalert, setIsSwitchEmailalert] = useState(false);
    const [rulelevelopen, setRulelevelOpen] = useState(false)
    const [selectedrule, setSelectedRule] = useState('select level')
    const [isLoadGetMembers, setIsLoadGetMembers] = useState(false)
    const [memberData, setMemberData] = useState([])

    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };
    const handleTabClickSecond = (event, tabIndex) => {
        setActiveTabKey2(tabIndex);
    };

    useImperativeHandle(ref, () => ({
        setUpdateOnBoardForm(rowId = '', Orgname = '') {
            setOrgUniqueId(rowId)
            setOpenOrganization(Orgname)
            handleModalToggle()
        }
    }));
    const handleModalToggle = () => {
        setModalOpen(!isModalOpen);
        setActiveTabKey(0)
        setActiveTabKey2(10);
    };
    const modalclose = (value) => {
        setModalOpen(value);
    }

    const handleSwitchChangeEA = (_event, checked) => {
        setIsSwitchEmailalert(checked);
    };

    const RuleLevelOption = [
        { id: '1', value: '3', children: ' 3' },
        { id: '2', value: '4', children: ' 4' },
        { id: '3', value: '5', children: ' 5' },
        { id: '4', value: '6', children: ' 6' },
        { id: '5', value: '7', children: ' 7' },
        { id: '6', value: '8', children: ' 8' },
        { id: '7', value: '9', children: ' 9' },
        { id: '8', value: '10', children: ' 10' },
        { id: '9', value: '11', children: ' 11' },
        { id: '10', value: '12', children: ' 12' },
        { id: '11', value: '13', children: ' 13' },

    ]
    const renderRuleLevelBox = useMemo(() => {
        const onToggleClick = () => {
            setRulelevelOpen(!rulelevelopen);
        };
        const onSelect = (_event, value) => {
            console.log(value)
            setSelectedRule(value);
            setRulelevelOpen(false);
        };
        const toggle = toggleRef => <MenuToggle ref={toggleRef} onClick={onToggleClick} isExpanded={rulelevelopen}>
            {selectedrule}  {selectedrule !== 'select level' && <Button variant="plain" style={{ margin: '-16px' }}
                onClick={() => {
                    setIsSwitchEmailalert(false)
                    setSelectedRule('select level');
                }} aria-label="Clear input value">
                <TimesIcon aria-hidden />
            </Button>}
        </MenuToggle>
        return (<React.Fragment>
            <Select id="single-select" isScrollable maxMenuHeight='150px' isOpen={rulelevelopen}
                selected={selectedrule} onSelect={onSelect} onOpenChange={isOpen => setRulelevelOpen(isOpen)}
                toggle={toggle} shouldFocusToggleOnSelect >
                <SelectList>
                    {RuleLevelOption.map((item, index) => <SelectOption key={index} value={item.value} onClick={() => setSelectedRule(item.children)}>{item.children}</SelectOption>)}
                </SelectList>
            </Select>
        </React.Fragment>);

    }, [rulelevelopen, setRulelevelOpen, selectedrule, setSelectedRule, RuleLevelOption])


    const getMemberRows = async () => {
        let data = {
            org_unique_id: orgUniqueId
        }
        try {
            setIsLoadGetMembers(true)
            let res = await dbApi.postApi("Opensearch/getEmailMemberALerting", data)
            console.log("alerting member", res)
            if (res.getEmailAlerts.length > 0) {
                let rowDetail = res.Member
                let emailstatus = res.getEmailAlerts[0].email_status == '1' ? true : false
                let rulelevel = res.getEmailAlerts[0].rule_level
                setIsSwitchEmailalert(emailstatus)
                setSelectedRule(rulelevel)
                setMemberData(rowDetail)
            }
            else {
                setIsSwitchEmailalert(false)
                setSelectedRule('select level')
                setMemberData([])
            }
            setIsLoadGetMembers(false)

        } catch (error) {
            console.log(error)
            setIsLoadGetMembers(false)
        }
    }

    useEffect(() => {
        if (activeTabKey == 1 && orgUniqueId !== '') { getMemberRows(orgUniqueId) }
    }, [activeTabKey])

    return (
        <div>
            <Modal variant={activeTabKey == 1 ? ModalVariant.large : ModalVariant.medium} title={`Edit Organization Details (${openorganization})`}
                isOpen={isModalOpen}
                style={{ height: activeTabKey == 1 && '600px' }}
                onClose={() => { handleModalToggle(); setOrgUniqueId(''); setActiveTabKey(0); setSelectedRule('select level'); setIsSwitchEmailalert(false) }}>
                <Card style={{ padding: '10px' }}>
                    <Tabs
                        activeKey={activeTabKey}
                        onSelect={handleTabClick}
                        aria-label="Tabs for update detail"
                        role="region"
                    >
                        <Tab eventKey={0} title={<TabTitleText>Details</TabTitleText>} aria-label="Default content - users">
                            {activeTabKey == 0 &&
                                <TabContentBody>
                                    <OnBoardingForm
                                        OrgUniqueID={orgUniqueId}
                                        OrgName={openorganization}
                                        actiontype={'Update'}
                                        openModal={true}
                                        updatemodalstate={modalclose}
                                        callUpdateList={props.callUpdateList}
                                        UpdateStatus={() => { }}
                                    />
                                </TabContentBody>
                            }
                        </Tab>
                        <Tab eventKey={1} title={<TabTitleText>Alerting</TabTitleText>}>
                            {activeTabKey == 1 &&
                                <TabContentBody>
                                    {isLoadGetMembers ? <Loader /> :
                                        <>
                                            <div className='pf-v5-u-mt-md'>
                                                <Flex display={{ default: 'inlineFlex' }} spaceItems={{ default: 'spaceItemsLg' }} >
                                                    <FlexItem>Filter by rule &nbsp; {renderRuleLevelBox}</FlexItem>
                                                    {selectedrule !== 'select level' && <FlexItem align={{ default: 'alignRight' }} >Enable Email Alerts &nbsp;<span style={{ marginTop: '6.5px' }}>
                                                        <Switch id="emailalert" aria-label="emailalert" isChecked={isSwitchemailalert} onChange={handleSwitchChangeEA} />
                                                    </span></FlexItem>}
                                                </Flex>
                                            </div>
                                            {isSwitchemailalert && <Tabs
                                                aria-label="secondary tabs for users"
                                                role="region"
                                                activeKey={activeTabKey2}
                                                isSecondary
                                                onSelect={handleTabClickSecond}
                                            >

                                                <Tab eventKey={10} title={<TabTitleText>Members</TabTitleText>}>
                                                    <AddMembers emailStatus={isSwitchemailalert} OrgUniqueID={orgUniqueId} ruleLevel={selectedrule} MemberData={memberData} getMemberCall={() => getMemberRows()} />
                                                </Tab>
                                                {memberData.length > 0 &&
                                                    <Tab eventKey={11} title={<TabTitleText>Alerting by rule</TabTitleText>}>
                                                        {activeTabKey2 == 11 &&
                                                            <AlertingRule MemberData={memberData} OrgUniqueID={orgUniqueId} ruleLevel={selectedrule} />
                                                        }
                                                    </Tab>
                                                }
                                                {/* </TabContentBody> */}
                                            </Tabs>}
                                        </>
                                    }
                                </TabContentBody>
                            }
                        </Tab>
                        <Tab eventKey={2} title={<TabTitleText>TAP Configuration</TabTitleText>}>
                            {activeTabKey == 2 &&
                                <TabContentBody>
                                    <TapRegistrationForm
                                        OrgUniqueID={orgUniqueId}
                                        OrgName={openorganization}
                                        updatemodalstate={modalclose} /></TabContentBody>
                            }
                        </Tab>

                        {/* {switchstate && <Tab eventKey={1} title={<TabTitleText>Members</TabTitleText>}>
                        <TabContentBody>
                            <AddMembers />
                        </TabContentBody>
                    </Tab>} */}
                    </Tabs>
                </Card>
            </Modal>
        </div>
    )
})

const mapStateToProps = (state) => ({})

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(UpdateOrganisation)