import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { calculateTimeDifference, formatTime, formatTimestamp } from '../../util/Timeutils';
import { formatValue } from '../../util/ChartHelper';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { UpdateFilterData } from '../../util/FilterHelper';
import { FilterData } from '../../Redux/Action';
import { connect, useDispatch } from 'react-redux';
const ELineStackChart = (props) => {
  const id = generateUniqueID()
  const dispatch = useDispatch();
  const handleFilter = (fields, value) => {

    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    console.log(updatedFields)
    dispatch(FilterData(updatedFields));
  }
  useEffect(() => {
    if (props.lineseries && props.lineseries.length > 0) {
      const chartDom = document.getElementById(id + 'elsc');
      const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
      const linecolor = props.theme == 'dark-theme' ? '#595757' : '#d9d9d9'
      const myChart = echarts.init(chartDom);
      const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(props.StartDateTime, props.EndDateTime);
      const xAxisData = []
      const Label = props.label;
      const uniqueDatesSet = new Set();
      for (let i = 0; i < props.lineseries.length; i++) {
        for (let j = 0; j < props.lineseries[i].date_histogram_agg.buckets.length; j++) {
          const xvalue = props.lineseries[i].date_histogram_agg.buckets[j].key_as_string;
          uniqueDatesSet.add(xvalue);
        }
      }
      const uniqueDatesArray = [...uniqueDatesSet];
      for (let i = 0; i < uniqueDatesArray.length; i++) {
        const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(uniqueDatesArray[i]);
        const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
        xAxisData.push({ x: formattedTime });
      }
      const seriesData = props.lineseries.map((item) => ({
        name: item.key,
        type: 'line',
        stack: 'total',
        emphasis: {
          focus: 'series',
        },
        data: item.date_histogram_agg.buckets.map((dataItem) => dataItem.doc_count),
      }));
      const legendData = props.lineseries.map((item) => item.key.toString());

      let interval = ''
      if (Label === '30 seconds') {
        interval = 4
      }
      else if (Label === 'hour') {
        interval = 3
      }
      else {
        interval = 3
      }
      const option = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: "#000"
          },
        },
        title: {
          text: `Timestamp per ${Label}`,
          top: 'bottom',
          left: 'center',
          textStyle: {
            fontWeight: 450,
            fontSize: 14,
            color: textcolor,
            fontfamily: 'RedHatText, helvetica, arial, sans-serif',
          },
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 'right',
          top: 5,
          // left:170,
          itemWidth: 9.5,
          itemHeight: 10,
          itemGap: 6.5,
          tooltip: {
            trigger: 'item',
            show: true,
            position: [50, 50],
            textStyle: {
              color: "#000"
            }
          },
          icon: "rect",
          pageIconSize: 12,
          textStyle: {
            width: props.legendwidth ? props.legendwidth : 200,
            overflow: 'truncate',
            fontSize: 12,
            color: textcolor
          }
        },
        grid: {
          left: '1%',
          right: '16%',
          bottom: '10%',
          top: '15%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            axisLabel: { interval: interval, rotate: -50, color: textcolor },
            data: xAxisData.map(item => item.x),
            axisTick: {
              alignWithLabel: true
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: formatValue,
              color: textcolor
            },
            splitLine: {
              lineStyle: {
                color: linecolor
              }
            },
            axisLine: {
              show: true
            },
          }
        ],
        color: props.color,
        series: seriesData
      };
      option && myChart.setOption(option);
      myChart.on('legendselectchanged', function (param) {

        handleFilter(props.filterFields, param.name)
      });
      const resizeChart = () => {
        if (myChart) { // Check if chart exists before resizing
          myChart.resize();
        }
      };

    window.addEventListener('resize', resizeChart);
    return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
    };
    }

  }, [props.lineseries, props.label, props.StartDateTime, props.legendwidth,props.height,
    props.EndDateTime, props.filterFields, props.theme,props.sidebarState])


  return <> <div className="container">
    {props.lineseries.length == 0 ?
      (<>
        <div id={id + 'elsc'} style={{ width: '20px', height: '20px' }}> </div>
        < Emptystatefornodata />
      </>)
      : (
        <div id={id + 'elsc'} style={{ width: '100%', height:props.height?props.height: '290px' }} > </div>
      )
    }
  </div>
  </>
}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState:state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(ELineStackChart)