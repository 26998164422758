import React, { useState, useRef, useEffect, forwardRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Bullseye,
  Grid,
  GridItem,
  MenuToggle,
  Page,
  Sidebar,
  Select,
  SelectOption,
  SelectList,
  SidebarContent,
  Text,

} from "@patternfly/react-core";
import EventChart from "../CommonEventPages/EventChart";
import dbApi from 'src/services/DbApi';
import EventLogTable from "../CommonEventPages/EventLogTable";
import Eventsidebar from "../CommonEventPages/EventSidebar";
import { formatTableTimestamp } from "src/util/Timeutils";
import { Loader } from "src/util/Loadingstate";
import { Emptystatefornodata } from "src/util/EmptyStateCard";

const SCAEvents = forwardRef(function SCAEvents(props, ref) {
  const dispatch = useDispatch();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("select");
  const [SAscrollId, setSAscrollId] = useState('');
  const [LogTableData, setLogTableData] = useState([]);
  const [TotalCount, setTotalCount] = useState("0");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [chartlabel, setChartLabel] = useState('')
  const [chartseries, setChartSeries] = useState([])
  const [fieldKeys, setFieldKeys] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const childSecurityEventRef = useRef(null);
  const time1options = [
    <SelectOption key={0} value="seconds"> seconds</SelectOption>,
    <SelectOption key={1} value="minutes">minutes </SelectOption>,
    <SelectOption key={2} value="hours">hours</SelectOption>,
    <SelectOption key={3} value="days">days</SelectOption>,
    <SelectOption key={4} value="weeks">weeks</SelectOption>,
    <SelectOption key={5} value="months">months</SelectOption>,
    <SelectOption key={6} value="years">years</SelectOption>,
  ];
  const SCAColumn = ["Time",
    "Agent Name",
    "data.sca.check.title",
    "data.sca.check.file",
    "data.sca.check.result",
    "data.sca.policy"]

  const clearselection = () => {
    setSelected(null)
    setIsOpen(false)
  }
  const onToggle = (isOpen) => {
    setIsOpen(isOpen)
  }
  const onSelect = (event, selection, isplaceholder) => {
    if (isplaceholder) {
      clearselection()
    } else {
      setSelected(selection)
      setIsOpen(false)
    }
  }

  // ... rest of your existing code

  const getEventlog = async (data) => {
    try {
      setIsLoading(true)
      let all_eventlog = await dbApi.postApi("Opensearch/Security_Configuration_Assement_Event", data);
      console.log(all_eventlog);
      let ScrollID = ''
      let logtableData = []
      let ChartLabel = ''
      let ChartSeries = []
      let totalCount = '0'
      if (Object.keys(all_eventlog.Security_Configuration_Assement_Event).length !== 0) {
        let events = all_eventlog.Security_Configuration_Assement_Event.Security_Configuration_Assement.body.hits
        let label = all_eventlog.Security_Configuration_Assement_Event.interval
        let ChartData = all_eventlog.Security_Configuration_Assement_Event.Security_Configuration_Assement.body.aggregations[2]
        let scrollID = all_eventlog.Security_Configuration_Assement_Event.Security_Configuration_Assement.body._scroll_id
        let fieldkeys = all_eventlog.Security_Configuration_Assement_Event.keys

        ChartSeries = ChartData.buckets;
        ChartLabel = label
        ScrollID = scrollID
        logtableData = events.hits;
        totalCount = events.total.value;

        setLogTableData(logtableData)
        setSAscrollId(ScrollID)
        setFieldKeys(fieldkeys)
        setTotalCount(totalCount)
        setChartLabel(ChartLabel)
        setChartSeries(ChartSeries)
      }
      else {
        setLogTableData(logtableData)
        setSAscrollId(ScrollID)
        setFieldKeys([])
        setTotalCount(totalCount)
        setChartLabel(ChartLabel)
        setChartSeries(ChartSeries)
      }
      setIsLoading(false)
      // }
    }
    catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (childSecurityEventRef.current) {
      childSecurityEventRef.current.setSecurityEventTable(LogTableData, SAscrollId, SCAColumn);
    }
  }, [LogTableData, SAscrollId, SCAColumn]);

  const eventsApi = async (startDate, endDate, allFields,) => {
    let staticfield = []
    if (props?.ScaFieldsgroup == 'cis') {
      staticfield = [{
        "fields": 'rule.rbi',
        'condition': 'does_not_exist',
      }]
    }
    else if (props?.ScaFieldsgroup == 'rbi') {
      staticfield = [{
        "fields": 'rule.rbi',
        'condition': 'exists',
      }]
    }
    else {
      staticfield = staticfield
    }
    const Fields = [...allFields, ...staticfield]
    const data = {
      startDate: startDate,
      endDate: endDate,
      filter: JSON.stringify(Fields),
    };
    try {
      setIsLoading(true)
      setStartDate(startDate)
      setEndDate(endDate)
      await getEventlog(data)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (props.dates.startDate != "") {
      let Dates = props.dates
      eventsApi(Dates.startDate, Dates.endDate, props.filterformdata)
    }
  }, [props?.dates.startDate, props?.dates.endDate, props.filterformdata, props?.ScaFieldsgroup]);


  const toggle = toggleRef => <MenuToggle
    ref={toggleRef}
    onClick={onToggle}
    isExpanded={isOpen}
    style={{ width: '150px' }}>
    {selected}
  </MenuToggle>
  const { formattedTimeStamp: StartDate } = formatTableTimestamp(startDate);
  const { formattedTimeStamp: EndDate } = formatTableTimestamp(endDate);
  return (
    <div>
      <Grid hasGutter>
        <GridItem>
          <Page isManagedSidebar>
            <Sidebar style={{ overflow: "auto" }} >
              <Eventsidebar Count={TotalCount}
                FieldKeys={fieldKeys}
                Loading={isLoading}
                filterfield={[{
                  'fields': 'rule.groups',
                  'condition': 'is',
                  'value': 'sca'
                }]}
              />
              <div style={{ width: "100%" }}>
                <SidebarContent className="Sidebar-content">
                  <Text align="center"><b>{`${TotalCount.toLocaleString('hi-IN')} hits`}</b></Text>
                  <Text align='center'><small><b>{StartDate} - {EndDate}</b></small></Text>
                  {/* <div>
                    <Bullseye>
                      <div>
                        <Select
                          id="single-select"
                          isOpen={isOpen}
                          selected={selected}
                          onSelect={onSelect}
                          // onOpenChange={isOpen}
                          toggle={toggle}
                          shouldFocusToggleOnSelect
                        >
                          <SelectList>
                            {time1options}
                          </SelectList>
                        </Select>
                      </div>
                    </Bullseye>
                  </div> */}
                  {isLoading ? <Loader /> :
                    chartseries.length == 0 ? <Emptystatefornodata /> :
                      <>
                        <EventChart EventSeries={chartseries} EventLabel={chartlabel} StartDateTime={startDate} EndDateTime={endDate} />
                        <EventLogTable ref={childSecurityEventRef} />
                      </>}
                </SidebarContent>
              </div>
            </Sidebar>
          </Page>
        </GridItem>
      </Grid>
    </div>
  );
})

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  selectedGroup: state.SelectedGroup,
  ScaFieldsgroup: state.ScaFields
  // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(SCAEvents)
