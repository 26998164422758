import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Divider,
    Flex,
    Grid,
    GridItem,
    Icon,
    Modal,
    Progress,
    Text,
    TextContent,
    TextVariants,
    ModalVariant,
    Tabs,
    Tab,
    TabTitleText,
    Bullseye,
    TabContentBody,
} from '@patternfly/react-core'
import dbApi from 'src/services/DbApi'
import OsqueryTop5Rules from './Top5Rules'
import EBarStackChart from '../AllCharts/EBarStackChart'

import { connect, useDispatch } from 'react-redux'
import { Emptystatefornodata } from 'src/util/EmptyStateCard'
import TableViewForChart from '../AllCharts/TableViewForChart'
import { Loader,TextSkeleton } from 'src/util/Loadingstate'
import EPieChart from '../AllCharts/EPieChart'
const OsqueryDashboard = forwardRef(function OsqueryDashboard(props, ref) {
    const dispatch = useDispatch()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [agentreporting, setAgentreporting] = useState(0)
    const [agents, setAgents] = useState([])
    const [addedseries, setAddedSeries] = useState([]);
    const [removedseries, setRemovedSeries] = useState([]);
    const [evolution, setEvolution] = useState([]);
    const [evolutionlabel, setOsEvolutionLabel] = useState()
    const [commonpackseries, setCommonPackSeries] = useState([]);
    const [ruleTable, setRuleTable] = useState([]);
    //Loading states
    const [isloadAgent, setIsLoadAgent] = useState(false)
    const [isloadaddEvent, setIsLoadaddEvent] = useState(false)
    const [isloadrmvEvent, setIsLoadrmvEvent] = useState(false)
    const [isloadEvolution, setIsLoadEvolution] = useState(false)
    const [isloadCommonpack, setIsLoadCommonpack] = useState(false)
    const [isloadRules, setIsLoadRules] = useState(false)
    //modal states
    const [modalStates, setModalStates] = useState({
        modalAddedEvents: false,
        modalRemovedEvents: false,
        modalCommonPack: false,
        modalAgents: false,
    });
    const handleModalToggle = (modalName) => {
        setModalStates((prevStates) => ({
            ...prevStates,
            [modalName]: !prevStates[modalName],
        }));
    };

    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex)
    }

    const getAgentReporting = async (data) => {
        try {
            setIsLoadAgent(true)
            let Totalagents = await dbApi.postApi("Opensearch/Osquery_Dashboard_Agents_Reporting", data)
            console.log(Totalagents)
            let AgentReporting = "0"
            let Agents = []
            let agents_reporting = Totalagents.Osquery_Dashboard_Agents_Reporting
            if (Object.keys(agents_reporting).length !== 0) {
                AgentReporting = agents_reporting.body.aggregations.Agents_reporting.value
                Agents = agents_reporting.body.aggregations.Agent_Name.buckets
                setAgentreporting(AgentReporting)
                setAgents(Agents)
            }
            else {
                setAgentreporting(AgentReporting)
                setAgents(Agents)
            }
            setIsLoadAgent(false)
        } catch (error) {
            console.log(error)
            setAgentreporting(0)
            setAgents([])
            setIsLoadAgent(false)
        }
    }
    const getOsqueryeventsAdded = async (data) => {
        try {
            setIsLoadaddEvent(true)
            let added_events = await dbApi.postApi("Opensearch/Osquery_Dashboard_Top_5_Osquery_Events_Added", data);
            console.log(added_events)
            let Addedseries = [];
            let addedEvents = added_events.Osquery_Dashboard_Top_5_Osquery_Events_Added;
            if (Object.keys(addedEvents).length !== 0) {
                Addedseries = addedEvents.body.aggregations.Top_Osquery_events_added.buckets
                setAddedSeries(Addedseries)
            }
            else {
                setAddedSeries(Addedseries)
            }
            setIsLoadaddEvent(false)
        } catch (error) {
            console.log(error)
            setAddedSeries([])
            setIsLoadaddEvent(false)
        }
    }
    const getOsqueryeventsRemoved = async (data) => {
        try {
            setIsLoadrmvEvent(true)
            let removed_events = await dbApi.postApi("Opensearch/Osquery_Dashboard_Top_5_Osquery_Events_Removed", data);
            console.log(removed_events)
            let RemovedSeries = []
            let removedEvents = removed_events.Osquery_Dashboard_Top_5_Osquery_Events_Removed;
            if (Object.keys(removedEvents).length !== 0) {
                RemovedSeries = removedEvents.body.aggregations.Top_Osquery_events_removed.buckets
                setRemovedSeries(RemovedSeries)
            }
            else {
                setRemovedSeries(RemovedSeries)
            }
            setIsLoadrmvEvent(false)
        } catch (error) {
            console.log(error)
            setRemovedSeries([])
            setIsLoadrmvEvent(false)
        }
    }

    const getOsqueryEvolution = async (data) => {
        try {
            setIsLoadEvolution(true)
            let osquery_evolution = await dbApi.postApi("Opensearch/Osquery_Dashboard_Evolution_Of_Osquery_Events_Per_Pack_Over_Time", data);
            console.log(osquery_evolution)
            let Label = ""
            let Evolution = [];
            let resdata = osquery_evolution.Osquery_Dashboard_Evolution_Of_Osquery_Events_Per_Pack_Over_Time
            if (Object.keys(resdata).length !== 0) {
                Evolution = resdata.data.body.aggregations["Evolution_of_osquery_over time"].buckets.slice(0, 5);
                Label = resdata.interval

                setEvolution(Evolution)
                setOsEvolutionLabel(Label)
            }
            else {
                setEvolution(Evolution)
                setOsEvolutionLabel(Label)
            }
            setIsLoadEvolution(false)
        }
        catch (error) {
            console.log(error)
            setEvolution([])
            setOsEvolutionLabel('')
            setIsLoadEvolution(false)
        }
    }
    const getOsqueryCommonPacks = async (data) => {
        try {
            setIsLoadCommonpack(true)
            let commonPacks = await dbApi.postApi("Opensearch/Osquery_Dashboard_Most_Common_Packs", data);
            console.log(commonPacks)
            let CommonPackSeries = [];
            let OsqueryCommonpacks = commonPacks.Osquery_Dashboard_Most_Common_Packs;
            if (Object.keys(OsqueryCommonpacks).length !== 0) {
                CommonPackSeries = OsqueryCommonpacks.body.aggregations.most_common_packs.buckets
                setCommonPackSeries(CommonPackSeries)
            }
            else { setCommonPackSeries(CommonPackSeries) }
            setIsLoadCommonpack(false)
        } catch (error) {
            console.log(error)
            setCommonPackSeries([])
            setIsLoadCommonpack(false)
        }
    }
    const getOsqueryTop5Rules = async (data) => {
        try {
            setIsLoadRules(true)
            let allrules = await dbApi.postApi("Opensearch/Osquery_Dashboard_Most_Top_5_Rules", data);
            console.log(allrules)
            let RuleTable = []
            let OsqueryRules = allrules.Osquery_Dashboard_Most_Top_5_Rules
            if (Object.keys(OsqueryRules).length !== 0) {
                RuleTable = OsqueryRules.sort((a, b) => b.count - a.count)
                setRuleTable(RuleTable)
            }
            else { setRuleTable(RuleTable) }
            setIsLoadRules(false)
        } catch (error) {
            console.log(error)
            setRuleTable([])
            setIsLoadRules(false)
        }
    }
    const OsqueryDashboardApi = async (startDate, endDate, allFields) => {
        const Fields = allFields
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
        };
        try {
            setStartDate(startDate);
            setEndDate(endDate);
            console.log(data)
            getAgentReporting(data);
            getOsqueryeventsAdded(data);
            getOsqueryeventsRemoved(data);
            getOsqueryEvolution(data);
            getOsqueryCommonPacks(data);
            getOsqueryTop5Rules(data);

        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {

        if (props.dates.startDate != "") {
            let Dates = props.dates
            OsqueryDashboardApi(Dates.startDate, Dates.endDate, props.filterformdata)
        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata,]);
    
    const colorforchart = ['#8BC1F7', '#06C', '#002F5D', '#519DE9', '#004B95']
    const AddedSeries = useMemo(() => {
        return (
            isloadaddEvent ? <Loader /> : addedseries.length > 0 ?
                (<EPieChart color={colorforchart} seriesData={addedseries.slice(0, 5)} height="290px" filterFields={'data.osquery.name'} />) : (<Emptystatefornodata />)
        )
    }, [isloadaddEvent, addedseries])
    const RemoveSeries = useMemo(() => {
        return (
            isloadrmvEvent ? <Loader /> : removedseries.length > 0 ?
                (<EPieChart color={colorforchart} seriesData={removedseries.slice(0, 5)} height="290px" filterFields={'data.osquery.name'} />)
                : (<Emptystatefornodata />)
        )
    }, [isloadrmvEvent, removedseries])
    const CommonPack = useMemo(() => {
        return (
            isloadCommonpack ? <Loader /> : commonpackseries.length > 0 ?
                (<EPieChart color={colorforchart} seriesData={commonpackseries.slice(0, 5)} height="290px" filterFields={'data.osquery.pack'} />) : (<Emptystatefornodata />)
        )
    }, [isloadCommonpack, commonpackseries])

    return (<>
        {props?.hideCard ? '' :
            <Bullseye>
                <div>
                    <Card isPlain>
                        <CardTitle align="center">Agents Reporting</CardTitle>
                        <CardBody>
                            <TextContent>
                                {isloadAgent ? <TextSkeleton /> :
                                    <>
                                        <a onClick={() => { handleModalToggle('modalAgents') }}><Text component={TextVariants.h1} className='pf-v5-u-info-color-100 pf-v5-u-text-align-center'>{agentreporting ?? 0} </Text></a>
                                        <Modal
                                            variant={ModalVariant.large}
                                            title={`All Agents (${agentreporting})`}
                                            isOpen={modalStates.modalAgents}
                                            onClose={() => handleModalToggle('modalAgents')}
                                        >
                                            <Divider className='modal-Divider' />
                                            <Card>
                                                <TableViewForChart
                                                    Tabledata={agents}
                                                    columnData={["Agent Name", "Count"]} filterFields={'agent.name'} />
                                            </Card>
                                        </Modal>
                                    </>
                                }
                            </TextContent>
                        </CardBody>
                    </Card>
                </div>
            </Bullseye>
        }
        <Grid hasGutter>
            <GridItem md={6} sm={12} lg={3}>
                <Card isFullHeight>
                    <CardTitle >Top 5 OsQuery Events Added</CardTitle>
                    <Divider />
                    <CardBody > {AddedSeries} </CardBody>
                    {addedseries.length > 0 && <CardFooter>
                        <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                            <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalAddedEvents'); }}>
                                More
                            </Button>
                            <Modal
                                variant={ModalVariant.large}
                                title={`Osquery Events Added (${addedseries.length})`}
                                isOpen={modalStates.modalAddedEvents}
                                onClose={() => handleModalToggle('modalAddedEvents')}
                            >
                                <Divider className='modal-Divider' />
                                <Card>
                                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                            {isloadaddEvent ? <Loader /> : <TableViewForChart Tabledata={addedseries} columnData={["Added Event", "Count"]} filterFields={'data.osquery.name'} />}
                                        </Tab>
                                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                            {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'> {isloadaddEvent ? <Loader /> :
                                                addedseries.length > 0 ?
                                                    (<EPieChart  color={colorforchart} seriesData={addedseries} height="250px" filterFields={'data.osquery.name'} />) : (<Emptystatefornodata />)
                                            }
                                            </TabContentBody>
                                            }
                                        </Tab>
                                    </Tabs>
                                </Card>
                            </Modal>
                        </Flex>
                    </CardFooter>}
                </Card>
            </GridItem>
            <GridItem md={6} sm={12} lg={3}>
                <Card isFullHeight >
                    <CardTitle >Top 5 OsQuery Events Removed</CardTitle>
                    <Divider />
                    <CardBody >{RemoveSeries}</CardBody>
                    {removedseries.length > 0 && <CardFooter>
                        <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                            <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalRemovedEvents'); }}>
                                More
                            </Button>
                            <Modal
                                variant={ModalVariant.large}
                                title={`Osquery Events Removed (${removedseries.length})`}
                                isOpen={modalStates.modalRemovedEvents}
                                onClose={() => handleModalToggle('modalRemovedEvents')}
                            >
                                <Divider className='modal-Divider' />
                                <Card>
                                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">

                                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                            {isloadrmvEvent ? <Loader /> : <TableViewForChart Tabledata={removedseries} columnData={["Removed Event", "Count"]} filterFields={'data.osquery.name'} />}
                                        </Tab>
                                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                            {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>    {isloadrmvEvent ? <Loader /> :
                                                removedseries.length > 0 ?
                                                    (<EPieChart  color={colorforchart}seriesData={removedseries} height="250px" filterFields={'data.osquery.name'} />) : (<Emptystatefornodata />)
                                            }</TabContentBody>}
                                        </Tab>
                                    </Tabs>
                                </Card>
                            </Modal>
                        </Flex>
                    </CardFooter>}

                </Card>
            </GridItem>
            <GridItem md={12} sm={12} lg={6}>
                <Card isFullHeight >
                    <CardTitle >Evolution Of OsQuery Events Per Pack Over Time</CardTitle>
                    <Divider />
                    <CardBody>
                        {isloadEvolution ? <Loader /> :
                            <EBarStackChart
                                label={evolutionlabel}
                                Stackseries={evolution}
                                StartDateTime={startDate}
                                EndDateTime={endDate}
                                filterFields={'data.osquery.name'}
                            />
                        }
                    </CardBody>
                </Card>
            </GridItem>
        </Grid>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={6} sm={12} lg={4}>
                    <Card isFullHeight>
                        <CardTitle > Most Common Packs</CardTitle>
                        <Divider />
                        <CardBody >{CommonPack}</CardBody>
                        {commonpackseries.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalCommonPack'); }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`Most Common Packs (${commonpackseries.length})`}
                                    isOpen={modalStates.modalCommonPack}
                                    onClose={() => handleModalToggle('modalCommonPack')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>
                                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">

                                            <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                {isloadCommonpack ? <Loader /> : <TableViewForChart Tabledata={commonpackseries} columnData={["Common Packs", "Count"]} filterFields={'data.osquery.pack'} />}
                                            </Tab>
                                            <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>  {isloadCommonpack ? <Loader /> : commonpackseries.length > 0 ?
                                                    <EPieChart color={colorforchart} seriesData={commonpackseries} height="250px" filterFields={'data.osquery.pack'} /> : <Emptystatefornodata />
                                                }</TabContentBody>}
                                            </Tab>
                                        </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
                <GridItem md={6} sm={12} lg={8}>
                    <Card isFullHeight>
                        <CardTitle > Top 5 Rules</CardTitle>
                        <Divider />
                        <CardBody >
                            {isloadRules ? <Loader /> :
                                <OsqueryTop5Rules RuleTableData={ruleTable.slice(0, 5)} IsModal={false} />
                            }
                        </CardBody>
                        {ruleTable.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { handleModalToggle('modalTable'); }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title='Top Rules'
                                    isOpen={modalStates.modalTable}
                                    onClose={() => handleModalToggle('modalTable')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>
                                        <OsqueryTop5Rules RuleTableData={ruleTable} IsModal={true} />
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
            </Grid>
        </div>
    </>
    )
}
)
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} }
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(OsqueryDashboard)

