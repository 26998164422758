import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';
const VulnerableStatus = ({ Critical,High,Medium,Low, theme,sidebarState }) => {
    const id = generateUniqueID()
    useEffect(() => {
        const chartDom = document.getElementById(id + 'vss');
        const myChart = echarts.init(chartDom);
        const textcolor = theme == 'dark-theme' ? '#fff' : '#000'
        // const legendData = severityData.map((item) => item.Status.toString());

        const option = {
            tooltip: {
                trigger: 'item',
                textStyle: {
                    color: "#000"
                },
                position: [50, 50]
            },
            legend: {
                top: 'bottom',
                // right: 'right',
                itemWidth: 8.5,
                itemHeight: 8,
                itemGap: 6.5,
                icon: "rect",
                tooltip: {
                    trigger: 'item',
                    show: true,
                    position: [50, 30],
                    textStyle: {
                      color: "#000"
                    }
                  },
                textStyle: {
                    width:85,
                    fontSize: 12,
                    color: textcolor,
                    overflow: 'truncate',
                },
                data: ['Critical','High','Medium','Low']
            },
            color: ["#F0AB00", "#C9190B", "#7D1007"],
            series: [
                {
                    type: 'pie',
                    left: 0,
                    right: 40,
                    top: 5,
                    bottom: 43,
                    radius: '100%',
                    center: ['50%', '50%'],
                    label: {
                        show:false,
                        lineHeight: 6,
                        fontSize: 10
                      },
                    
                    data: [
                        { value: Critical, name: 'Critical', itemStyle: {color: '#A30000'}, },
                        { value: High, name: 'High',itemStyle: {color: '#C9190B'},},
                        { value: Medium, name: 'Medium',itemStyle: {color: '#f0ab00'}, },
                        { value: Low, name: 'Low',itemStyle: {color: '#2B9AF3'}, },
                        
                    ]
                }
            ]
        };

        option && myChart.setOption(option);
        const resizeChart = () => {
            if (myChart) { // Check if chart exists before resizing
              myChart.resize();
            }
          };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };

    }, [Critical,High,Medium,Low, theme,sidebarState])

    return <div className="container">
        <div id={id + 'vss'} style={{ width: '100%', height: '120px' }} ></div>
    </div>;
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState:state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(VulnerableStatus)


