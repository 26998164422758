//2nd which is perfectly run and usable
import React, { useEffect, useState, } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  ExpandableRowContent,
  Table,
  InnerScrollContainer,
} from "@patternfly/react-table";
import {
  Badge,
  Button,
  DescriptionList,
  DescriptionListDescription,
  DescriptionListGroup,
  DescriptionListTerm,
  Flex,
  FlexItem,
  Pagination,
  Tab,
  Tabs,
  TabTitleText,
  TabContentBody,
  Tooltip,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  ToolbarGroup,
  List,
  ListItem,
  ListVariant,

} from "@patternfly/react-core";
import dbApi from '../../services/DbApi'
import { formatTableTimestamp } from "../../util/Timeutils";
import { ArrowLeftIcon, ArrowRightIcon } from "@patternfly/react-icons";
import { Link } from "react-router-dom";
import { UpdateFilterData } from "src/util/FilterHelper";
import { FilterData } from "src/Redux/Action";
import { connect, useDispatch } from "react-redux";
const { forwardRef, useImperativeHandle } = React;
const EventLogTable = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [eventLogData, seteventLogData] = useState([])
  const [ScrollID, setScrollID] = React.useState('')
  const [columns, setColumns] = useState();
  useImperativeHandle(ref, () => ({
    setSecurityEventTable(data = [], scrollId = '', column = []) {
      setBeforescrolldata([])
      seteventLogData(data)
      setScrollID(scrollId)
      setColumns(column)
    }
  }));

  const [page, setPage] = React.useState(1);
  const [PerPage, setPerPage] = React.useState(20);
  const [itemcount, setItemcount] = useState(0)
  const [defaultRows, setDefaultRows] = useState([]);
  const [scrolldata, setScrollData] = useState([]);
  const [beforescrolldata, setBeforescrolldata] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [activeTabKey, setActiveTabKey] = React.useState(0);
  // const initialExpandedRowNames = [];
  // const [areAllExpanded, setAreAllExpanded] = React.useState(false);
  // const [collapseAllAriaLabel, setCollapseAllAriaLabel] = React.useState("Expand all");
  const [expandedRowNames, setExpandedRowNames] = useState([]);
  const [expandedRowData, setExpandedRowData] = useState({});
  const [selectedRowName, setSelectedRowName] = useState('');
  const [isloadnext, setIsloadNext] = useState(false)
  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex);
  };
  //Scroll Api paginationAPi
  const SEPaginationApi = async (ScrollID) => {
    const data = {
      scroll_Id: ScrollID
    }
    try {
      setIsloadNext(true)
      let Scrolldata = await dbApi.postApi("Opensearch/Latest_scroll", data)
      console.log(Scrolldata)
      const nextdata = Scrolldata.Latest_scroll
      let scrolldata = []
      if (nextdata.length > 0) {
        scrolldata = nextdata
      }
      setScrollData(scrolldata);
      console.log(data)
      setIsloadNext(false)
    }
    catch (error) {
      console.log(error)
      setIsloadNext(false)
    }
  }


  const onSetPage = (_event, newPage) => {
    setPage(newPage);
  };
  const onPerPageSelect = (_event, newPerPage, newPage) => {
    setPerPage(newPerPage);
    if (newPage !== page) { setPage(newPage); }
  };

  const resetPagination = () => {
    setPage(page);
    setPerPage(PerPage); // Set to your desired default perPage value
  };
  useEffect(() => {
    let afterScrolldata = []
    if (beforescrolldata.length === 0) {
      afterScrolldata = eventLogData
    } else {
      afterScrolldata = [...beforescrolldata, ...scrolldata]
    }

    setBeforescrolldata(afterScrolldata)
    resetPagination() // Reset pagination when new data is set

    const newdefaultRows = afterScrolldata.map((log) => {
      const { formattedTimeStamp } = formatTableTimestamp(log._source.timestamp);//localStorage.getItem("timezone")
      return {
        id: log._id,
        timestamp: formattedTimeStamp,
        agentID: log._source.agent.id,
        agentName: log._source.agent.name,
        ruleDescription: log._source.rule.description,
        ruleLevel: log._source.rule.level,
        ruleId: log._source.rule.id,
        //Integretiy Column
        sysevent: log._source.syscheck?.event,
        syscheckpath: log._source.syscheck?.path,
        //System Auditing column
        datatitle: log._source.data?.title,
        dataauditcommand: log._source.data?.audit?.command,
        dataauditpid: log._source.data?.audit?.pid,
        //Vulnerability Column
        vulpackagename: log._source.data?.vulnerability?.package?.name,
        vulseverity: log._source.data?.vulnerability?.severity,
        vulcve: log._source.data?.vulnerability?.cve,
        vulstatus: log._source.data?.vulnerability?.status,
        //SCA Column
        scatitle: log._source.data?.sca?.check?.title,
        scafile: log._source.data?.sca?.check?.file,
        scaresult: log._source.data?.sca?.check?.result,
        scapolicy: log._source.data?.sca?.policy,
        //OS-Query Column
        osqueryname: log._source.data?.osquery?.name,
        osquerypack: log._source.data?.osquery?.pack,
        osqueryaction: log._source.data?.osquery?.action,
        osquerysubquery: log._source.data?.osquery?.subquery,
        //Mitre attack Column
        mitreid: log._source.rule?.mitre?.id,
        mitretactic: log._source.rule?.mitre?.tactic,
        //Network Insight Column
        NIseverity: log._source?.data?.alert?.severity,
        signature: log._source.data?.alert?.signature,
        srcip: log._source?.data?.srcip,
        srcport: log._source?.data?.srcport,
        dstip: log._source?.data?.dstip,
        dstport: log._source?.data?.dstport,
        vlan: log._source?.data?.vlan,
        //pcidss column
        rulepci: log._source.rule?.pci_dss,
        rulerbi: log._source.rule?.rbi,
        //gdprcolumn
        rulegdpr: log._source.rule?.gdpr,
        //hipaa
        rulehipaa: log._source.rule?.hipaa,
        //nist
        rulenist: log._source.rule?.nist_800_53,
        // tsc
        ruletsc: log._source.rule?.tsc,
        // gpg
        rulegpg13: log._source.rule?.gpg13,
        rulegpg_13: log._source.rule?.gpg_13,       //For expansion take whole log
        details: log,
      };

    });

    setDefaultRows(newdefaultRows)
    setItemcount(newdefaultRows.length)
    const startIndex = (page - 1) * PerPage;
    const endIndex = startIndex + PerPage;
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);

  }, [eventLogData, scrolldata]);

  //useEffect for pagination 
  useEffect(() => {
    const startIndex = (page - 1) * PerPage;
    const endIndex = startIndex + PerPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);
  }, [defaultRows, page, PerPage]);


  const renderPagination = (variant) => <Pagination
    itemCount={itemcount}
    perPage={PerPage}
    page={page}
    variant={variant}
    onSetPage={onSetPage}
    onPerPageSelect={onPerPageSelect} />


  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }

  //Expansion code 
  const setRowExpanded = (row, isExpanding) => {
    const otherExpandedRowNames = expandedRowNames.filter((r) => r !== row.id);
    setExpandedRowNames(
      // isExpanding ? [...otherExpandedRowNames, row.id] : otherExpandedRowNames
      isExpanding ? [row.id] : []
    );
    if (!expandedRowData[row.id]) {
      let agentName = typeof row?.details?._source?.agent?.name != "undefined" ? row?.details?._source?.agent?.name : "";
      let agentID = typeof row?.details?._source?.agent?.id != "undefined" ? row?.details?._source?.agent?.id : "";
      const keyifiedData = keyify(row?.details, "", agentName, agentID);
      setExpandedRowData({ [row.id]: keyifiedData, });
    }
    const updatedExpandedRowNames = isRowExpanded(row)
      ? otherExpandedRowNames : [row.id];
    setExpandedRowNames(updatedExpandedRowNames);

  };
  const isRowExpanded = (row) => {
    return expandedRowNames.includes(row.id);
  };


  const renderRuleLevelcolumn = (ruleLevel) => {
    let tooltipContent = "";
    let badgeStyle = {};
    if (ruleLevel >= 6) {
      tooltipContent = "Medium";
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
      if (ruleLevel >= 12) {
        tooltipContent = "Critical";
        badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
      }
      return (
        <Tooltip content={tooltipContent}>
          <div><Badge style={badgeStyle}> <a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('rule.level', `${ruleLevel}`) }}>{ruleLevel}</a></Badge></div>
        </Tooltip>
      );
    }
    else {
      return ruleLevel;
    }
  };

  const hyperlinkConditions = {
    'agent.id': (value) => value !== '000',
    'agent.name': (value) => value !== '000',
  };
  const keyify = (obj, prefix = "", agentName = '', agentID = '') => {
    const skipKeys = ["_score", "_version", "_index", "sort", "@timestamp"];
    const result = [];
    Object.keys(obj).forEach((el) => {
      const fullkey = prefix + el;
      if (skipKeys.includes(el)) {
        return;
      }
      else if (Array.isArray(obj[el])) {
        const value = obj[el].join(", ");
        result.push({ key: fullkey, value });
      } else if (typeof obj[el] === "object" && obj[el] !== null) {
        if (el === "_source") {
          result.push(...keyify(obj[el], prefix, agentName, agentID));
        }
        else {
          result.push(...keyify(obj[el], fullkey + ".", agentName, agentID));
        }
      }
      else {
        if (hyperlinkConditions[fullkey] && hyperlinkConditions[fullkey](obj[el])) {
          // result.push({ key: fullkey, value: <a href="${obj[el]}">{obj[el]}</a> });
          result.push({ key: fullkey, value: <Link to={`/individual-agent-dashboard/${agentName}/${agentID}`}>{obj[el]}</Link> });
        }
        else if (typeof obj[el] === 'boolean') {
          result.push({ key: fullkey, value: obj[el] ? 'true' : 'false' });
        }
        else { result.push({ key: fullkey, value: obj[el] }); }
      }
    });
    const sortedResult = result.sort((a, b) => {
      if (a.key < b.key) return -1;
      if (a.key > b.key) return 1;
      return 0
    });

    return sortedResult;
  };

  //Css according to the need 
  const renderSeveritycolumn = (vulseverity) => {
    let badgeStyle = {};
    if (vulseverity === "High") {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" };
    }
    else if (vulseverity === "Critical") {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
    }
    else {
      return vulseverity;
    }
    return (
      <div><Badge style={badgeStyle}>{vulseverity}</Badge></div>
    );
  };
  //NI SEVERITY
  const renderNISeveritycolumn = (NIseverity) => {
    let badgeStyle = {};
    if (NIseverity === '1') {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000" };
      return (<div><Badge style={badgeStyle} >High</Badge></div>)
    }
    else if (NIseverity === '2') {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00 " };
      return (<div><Badge style={badgeStyle} >Medium</Badge></div>)
    }
    else {
      return ' ';
    }
  };

  const renderStatuscolumn = (vulstatus) => {
    let badgeStyle = {};
    if (vulstatus === "Solved") {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" };
    }
    else {
      return vulstatus;
    }
    return (
      <div><Badge style={badgeStyle}>{vulstatus}</Badge></div>
    );
  };

  const renderResult = (scaresult) => {
    let badgeStyle = {};
    if (scaresult === 'passed') {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" };
      return (<div><Badge style={badgeStyle} >{scaresult}</Badge></div>)
    }
    else if (scaresult === "failed") {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
      return (<div><Badge style={badgeStyle} >{scaresult}</Badge></div>)
    }
    else if (scaresult === "not applicable") {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#2B9AF3  " };
      return (<div><Badge style={badgeStyle} >{scaresult}</Badge></div>)
    }
    else {
      return ' ';
    }
  };

  return (
    <React.Fragment>
      <>
        <Toolbar>
          <ToolbarContent>
            <ToolbarGroup align={{ default: 'alignRight' }}>
              <ToolbarItem><Button onClick={() => SEPaginationApi(ScrollID)} size="sm" isLoading={isloadnext}>Next</Button></ToolbarItem>
              <ToolbarItem id="pagination" align={{ default: 'alignRight' }}>{renderPagination('top')} </ToolbarItem>
            </ToolbarGroup>
          </ToolbarContent>
        </Toolbar>
        <InnerScrollContainer>
          <Table variant="compact"
            aria-label="EventsLogTable"
            id="events-log-table"
            isStickyHeader
            gridBreakPoint="grid-md"
          >
            <Thead>
              <Tr>
                <Th key={'expand'} aria-label="expand-event" screenReaderText="expand" />
                {columns && columns.map((column) => (<Th key={column}>{column}</Th>))}
              </Tr>
            </Thead>
            {rows.map((row, rowIndex) => (
              <Tbody key={row.id} isExpanded={isRowExpanded(row)}>
                <Tr key={row.id}
                  onRowClick={() => setSelectedRowName(row.id)}
                  isSelectable
                  isClickable
                  isRowSelected={selectedRowName === row.id}
                  className={row?.ruleLevel >= 8 ? "pf-v5-u-background-color-danger" : ""}  >
                  <Td
                    key={'expand-td' + rowIndex}
                    expand={
                      row.details
                        ? {
                          rowIndex: rowIndex,
                          isExpanded: isRowExpanded(row),
                          onToggle: () => { setRowExpanded(row, !isRowExpanded(row)); setActiveTabKey(0) }
                        }
                        : undefined
                    }
                  />

                  <Td width={20} dataLabel={'Time'} key={'Time' + rowIndex}>{row?.timestamp}</Td>
                  {/* Integrity Column */}
                  {columns.includes('Agent Name') && <Td width={15} dataLabel={'Agent Name'} key={'AgNa' + rowIndex}><Link to={"/individual-agent-dashboard/" + row?.agentName + '/' + row?.agentID}>{row?.agentID}: {row?.agentName}</Link></Td>}
                  {columns.includes('Syscheck Path') && <Td width={20} dataLabel={'Syscheck Path'} key={'syspath' + rowIndex} modifier="breakWord">{row?.syscheckpath}</Td>}
                  {columns.includes('Syscheck event') && <Td dataLabel={'Syscheck event'} key={'syseve' + rowIndex}>{row?.sysevent}</Td>}
                  {columns.includes('Data Title') && <Td dataLabel={'Data Title'} width={20} key={'datatit' + rowIndex}>{row?.datatitle}</Td>}
                  {columns.includes('Data audit command') && <Td dataLabel={'Data audit command'} key={'dac' + rowIndex} width={10}>{row?.dataauditcommand}</Td>}
                  {columns.includes('Data audit pid') && <Td dataLabel={'Data audit pid'} key={'dap' + rowIndex} width={10}>{row?.dataauditpid}</Td>}
                  {/* vulnerabilty column */}
                  {columns.includes('data.vulnerability.package.name') && <Td key={'dvpn' + rowIndex} dataLabel={'data.vulnerability.package.name'} width={20}><a className='pf-v5-u-color-100' onClick={() => { handleFilter('data.vulnerability.package.name', row?.vulpackagename) }}>{row?.vulpackagename}</a></Td>}
                  {columns.includes('data.vulnerability.cve') && <Td dataLabel={'data.vulnerability.cve'} key={'dvc' + rowIndex} width={20}> <a className='pf-v5-u-color-100' onClick={() => { handleFilter('data.vulnerability.cve', row?.vulcve) }}>{row?.vulcve}</a></Td>}
                  {columns.includes('data.vulnerability.severity') && <Td dataLabel={'data.vulnerability.severity'} key={'dvs' + rowIndex} width={20}>{renderSeveritycolumn(row?.vulseverity)}</Td>}
                  {columns.includes('data.vulnerability.status') && <Td dataLabel={'data.vulnerability.status'} key={'dvstatus' + rowIndex} width={20}>{renderStatuscolumn(row?.vulstatus)}</Td>}
                  {/* sca column */}
                  {columns.includes('data.sca.check.title') && <Td dataLabel={'data.sca.check.title'} key={'dsct' + rowIndex} width={20}>{row?.scatitle}</Td>}
                  {columns.includes('data.sca.check.file') && <Td dataLabel={'data.sca.check.file'} width={20} key={'dscf' + rowIndex}>{row?.scafile}</Td>}
                  {columns.includes('data.sca.check.result') && <Td dataLabel={'data.sca.check.result'} width={20} key={'dscr' + rowIndex}>{renderResult(row?.scaresult)}</Td>}
                  {columns.includes('data.sca.policy') && <Td dataLabel={'data.sca.policy'} width={20} key={'dsp' + rowIndex}>{row?.scapolicy}</Td>}
                  {/* osquery column */}
                  {columns.includes('data.osquery.name') && <Td dataLabel={'data.osquery.name'} modifier='truncate' key={'don' + rowIndex} width={20}>{row?.osqueryname}</Td>}
                  {columns.includes('data.osquery.pack') && <Td dataLabel={'data.osquery.pack'} key={'dop' + rowIndex}>{row?.osquerypack}</Td>}
                  {columns.includes('data.osquery.action') && <Td dataLabel={'data.osquery.action'} key={'doaction' + rowIndex}>{row?.osqueryaction}</Td>}
                  {columns.includes('data.osquery.subquery') && <Td dataLabel={'data.osquery.subquery'} modifier='truncate' key={'dosub' + rowIndex} width={30}>{row?.osquerysubquery}</Td>}
                  {/* mitre column */}
                  {columns.includes('Rule mitre id') && <Td dataLabel={'Rule mitre id'} key={'rmi' + rowIndex} width={20}>
                    {row?.mitreid !== undefined && row?.mitreid !== null ?
                      (<List isPlain>
                        {row?.mitreid?.map((item, index) => (
                          <ListItem key={index + 'mitreid'}><Badge key={index + 'mitrebadge'}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('rule.mitre.id', item) }}>{item} </a></Badge></ListItem>
                        ))}
                      </List>) : (" ")}</Td>}
                  {columns.includes('Rule mitre tactic') && <Td dataLabel={'Rule mitre tactic'} key={'rmt' + rowIndex} width={20}>
                    {row?.mitretactic !== undefined && row?.mitretactic !== null ?
                      (<List isPlain variant={ListVariant.inline}>
                        {row?.mitretactic?.map((item, index) =>
                        (<a key={item}><ListItem key={index + item} >{item}</ListItem></a>
                        ))} </List>)
                      : (" ")}
                  </Td>}
                  {/* network insight column */}
                  {columns.includes('Severity') && (<Td dataLabel={'Severity'} key={'severity' + rowIndex}>
                    {row?.NIseverity ?
                      (renderNISeveritycolumn(row?.NIseverity))
                      : (" ")
                    } </Td>)}
                  {columns.includes('Signature') && (
                    <Td dataLabel={'Signature'} width={40} modifier="truncate" key={'sign' + rowIndex} >
                      {row?.signature ?
                        (row?.signature) : (" ")
                      }   </Td>)}
                  {columns.includes('Source IP : Port') && <Td dataLabel={'Source IP : Port'} key={'srcportip' + rowIndex} width={20}><b>{row?.srcip}</b> : {row?.srcport} </Td>}
                  {columns.includes(' ') && (
                    <Td dataLabel={' '}>
                      {row?.vlan ? (
                        row.vlan.map((item, index) => (
                          <Badge key={`badge-${index}`}>
                            <ArrowLeftIcon /> VLAN: {item} <ArrowRightIcon />
                          </Badge>
                        ))
                      ) : (
                        ' ' // Display a blank cell
                      )}
                    </Td>
                  )}
                  {columns.includes('Destination IP : Port') && <Td dataLabel={'Destination IP : Port'} key={'dstipport' + rowIndex} width={20}><b>{row?.dstip}</b> : {row?.dstport} </Td>}
                  {/* pcidss column */}
                  {columns.includes('Rule Pci_dss') && <Td dataLabel={'Rule Pci_dss'} width={10} key={'rulepci' + rowIndex}>{row?.rulepci}</Td>}
                  {/* rbi column */}
                  {columns.includes('Rule RBI') && <Td dataLabel={'Rule RBI'} width={10} key={'rulerbi' + rowIndex}>{row?.rulerbi.join(', ')}</Td>}
                  {/* gdpr column */}
                  {columns.includes('Rule Gdpr') && <Td dataLabel={'Rule Gdpr'} width={10} key={'rulegdpr' + rowIndex}>{row?.rulegdpr.join(', ')}</Td>}
                  {/* hipaa column */}
                  {columns.includes('Rule Hipaa') && <Td dataLabel={'Rule Hipaa'} width={10} key={'rulehipaa' + rowIndex}>{row?.rulehipaa.join(', ')}</Td>}
                  {/* Nist_800_53 column */}
                  {columns.includes('Rule Nist_800_53') && <Td dataLabel={'Rule Nist_800_53'} width={10} key={'rulenist' + rowIndex}>{row?.rulenist.join(', ')}</Td>}
                  {/* Tsc column */}
                  {columns.includes('Rule Tsc') && <Td dataLabel={'Rule Tsc'} width={10} key={'ruletsc' + rowIndex}>
                    {row?.ruletsc.join(', ')}
                  </Td>}
                  {/* GPG column */}
                  {columns.includes('Rule GPG') && <Td dataLabel={'Rule GPG'} width={10} key={'rulegpg' + rowIndex}>
                    {row?.rulegpg13 !== undefined && row?.rulegpg13 !== null && <Tooltip content="gpg13">
                      <span>{row?.rulegpg13.join(', ')}</span>
                    </Tooltip>}
                    <br />
                    {row?.rulegpg_13 !== undefined && row?.rulegpg_13 !== null && <Tooltip content="gpg_13">
                      <span>{row?.rulegpg_13.join(', ')}</span>
                    </Tooltip>}
                  </Td>}
                  {columns.includes('Rule Description') && <Td dataLabel={'Rule Description'} width={45} modifier="truncate" key={'rd' + rowIndex} >
                    <a className="pf-v5-u-color-100" onClick={() => { handleFilter('rule.description', row?.ruleDescription) }}>{row?.ruleDescription}</a></Td>}
                  {columns.includes('Rule level') && <Td dataLabel={'Rule level'} width={10} key={'rl' + rowIndex}>{renderRuleLevelcolumn(row?.ruleLevel)}</Td>}
                  {columns.includes('Rule ID') && <Td dataLabel={'Rule ID'} width={10} key={'ruleid' + rowIndex}>{row?.ruleId}</Td>}
                </Tr>
                <Tr isExpanded={isRowExpanded(row)}>
                  {/* <Td></Td> */}
                  <Td colSpan={columns.length} noPadding>
                    <ExpandableRowContent>
                      <Tabs
                        activeKey={activeTabKey}
                        onSelect={handleTabClick}
                        style={{ marginTop: "0px" }}
                        aria-label="Tabs in the EventLog"
                        role="region"
                      >
                        <Tab
                          eventKey={0}
                          key={'table-tab'}
                          title={<TabTitleText>Table</TabTitleText>}
                          aria-label="Table data"
                        >
                          <TabContentBody hasPadding>
                            <DescriptionList isHorizontal horizontalTermWidthModifier={{ default: '30ch' }}>
                              {expandedRowData[row.id]?.map((item, index) => (
                                <React.Fragment key={index}>
                                  <DescriptionListGroup key={index + 'dgrp'}>
                                    <DescriptionListTerm key={index + 'dterm'}>{item.key}</DescriptionListTerm>
                                    <DescriptionListDescription key={index + 'ddesc'}>{item.value}</DescriptionListDescription>
                                  </DescriptionListGroup>
                                </React.Fragment>
                              ))}
                            </DescriptionList>
                          </TabContentBody>
                        </Tab>
                        <Tab eventKey={1} title={<TabTitleText>JSON</TabTitleText>} key={'json-tab'}>
                          <TabContentBody hasPadding>
                            <pre style={{ whiteSpace: 'pre-wrap' }}>
                              {JSON.stringify(row?.details, null, 2)}
                            </pre>
                          </TabContentBody>
                        </Tab>
                      </Tabs>
                    </ExpandableRowContent>
                  </Td>
                </Tr>
              </Tbody>
            ))}
          </Table>
        </InnerScrollContainer>
      </>
      {/* <div >
        <Tooltip content={"Next 500 data"}>
          <Button onClick={() => SEPaginationApi(ScrollID)} size="sm">Next</Button>
        </Tooltip>{renderPagination('bottom')}

      </div> */}
      <Flex justifyContent={{ default: 'justifyContentFlexEnd' }} >
        <FlexItem alignSelf={{ default: 'alignSelfCenter' }}>
          <Tooltip content={"Next 500 data"}>
            <Button onClick={() => SEPaginationApi(ScrollID)} size="sm" isLoading={isloadnext}>Next</Button>
          </Tooltip>
        </FlexItem>
        <FlexItem>{renderPagination('bottom')}  </FlexItem>
      </Flex>
    </React.Fragment >
  );
});

const mapStateToProps = (state) => ({
  filterformdata: state.FilterData,
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(EventLogTable)