import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Divider,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  Tab,
  TabTitleText,
  Tabs,

} from '@patternfly/react-core';
import '@patternfly/react-core/dist/styles/base.css';
import dbApi from '../../services/DbApi';
import EAreaChart from '../AllCharts/EAreaChart';
import ELineStackChart from '../AllCharts/Elinestackchart';
import { connect, useDispatch } from 'react-redux';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import TableViewForChart from '../AllCharts/TableViewForChart';
import { Loader } from '../../util/Loadingstate';
import EPieChart from '../AllCharts/EPieChart';

const PolicyDashboard = forwardRef(function PolicyDashboard(props, ref) {
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [eventseries, setEventSeries] = useState([]);
  const [eventlabel, setEventLabel] = useState();
  const [evolutionseries, setEvolutionSeries] = useState([]);
  const [evolutionlabel, setEvolutionLabel] = useState();
  const [agentseries, setAgentseries] = useState([]);
  const [ruleseries, setRuleseries] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(0);
  //Loading States
  const [isloadevolution, setIsLoadEvolution] = useState(true)
  const [isloadevent, setIsLoadEvent] = useState(true)
  const [isloadagent, setIsLoadAgent] = useState(true)
  const [isloadrule, setIsLoadRule] = useState(true)
  const [modalStates, setModalStates] = useState({
    modalRuleDistribution: false,
    modalAgents: false,
  });
  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex)
  }

  // Opensearch/Policy_Monitoring_Dashboard_Events_over_time 
  const getEventsoverTime = async (data) => {
    try {
      setIsLoadEvent(true)
      let events = await dbApi.postApi("Opensearch/Policy_Monitoring_Dashboard_Events_over_time", data);
      console.log(events)
      let EventSeries = [];
      let Label = [];
      let resdata = events.Policy_Monitoring_Dashboard_Events_over_time
      if (Object.keys(resdata).length !== 0) {
        EventSeries = resdata.data.body.aggregations.Events_over_time.buckets
        Label = resdata.interval
        setEventSeries(EventSeries)
        setEventLabel(Label)
      }
      else {
        setEventSeries(EventSeries)
        setEventLabel(Label)
      }
      setIsLoadEvent(false)
    } catch (error) {
      console.log(error)
      setEventSeries([])
      setEventLabel('')
      setIsLoadEvent(false)
    }
  }
  const getTopAgents = async (data) => {
    try {
      setIsLoadAgent(true)
      let all_agents = await dbApi.postApi("Opensearch/Policy_Monitoring_Dashboard_Top_5_Agents", data);
      console.log(all_agents)
      let AgentSeries = [];
      let top_agents = all_agents.Policy_Monitoring_Dashboard_Top_5_Agents
      if (Object.keys(top_agents).length !== 0) {
        AgentSeries = top_agents.body.aggregations.Top_5_agents.buckets
        setAgentseries(AgentSeries)
      }
      else {
        setAgentseries(AgentSeries)
      }
      setIsLoadAgent(false)
    } catch (error) {
      console.log(error)
      setAgentseries([])
      setIsLoadAgent(false)
    }
  }

  const getRuleDistribution = async (data) => {
    try {
      setIsLoadRule(true)
      let all_rule = await dbApi.postApi("Opensearch/Policy_Monitoring_Dashboard_Rule_Distribution", data);
      console.log(all_rule)
      let RuleSeries = [];
      let policyrule = all_rule.Policy_Monitoring_Dashboard_Rule_Distribution
      if (Object.keys(policyrule).length !== 0) {
        RuleSeries = policyrule.body.aggregations.Rule_distribution.buckets
        setRuleseries(RuleSeries)
      }
      else {
        setRuleseries(RuleSeries)
      }
      setIsLoadRule(false)
    } catch (error) {
      console.log(error)
      setRuleseries([])
      setIsLoadRule(false)
    }
  }

  //Opensearch/Policy_Monitoring_Dashboard_Events_Per_Control_Type_Evolution
  const getTypeEvolution = async (data) => {
    try {
      setIsLoadEvolution(true)
      let event_per_evolution = await dbApi.postApi("Opensearch/Policy_Monitoring_Dashboard_Events_Per_Control_Type_Evolution", data);
      console.log(event_per_evolution);
      let EvolutionSeries = [];
      let Label = '';
      let resultant_data = event_per_evolution.Policy_Monitoring_Dashboard_Events_Per_Control_Type_Evolution
      if (Object.keys(resultant_data).length !== 0) {

        EvolutionSeries = resultant_data.data.body.aggregations["Events per control type evolution"].buckets
        Label = resultant_data.interval

        setEvolutionSeries(EvolutionSeries)
        setEvolutionLabel(Label)
      }
      else {
        setEvolutionSeries(EvolutionSeries)
        setEvolutionLabel(Label)

      }
      setIsLoadEvolution(false)
    }

    catch (error) {
      console.log(error)
      setEvolutionSeries([])
      setEvolutionLabel('')
      setIsLoadEvolution(false)
    }

  }

  const PolicyDashboardApi = async (startDate, endDate, allFields) => {
    const Fields = allFields
    const data = {
      startDate: startDate,
      endDate: endDate,
      filter: JSON.stringify(Fields),
    };
    console.log(data)
    try {
      setStartDate(startDate);
      setEndDate(endDate);
      getTopAgents(data);
      getRuleDistribution(data);
      getEventsoverTime(data);
      getTypeEvolution(data);
    }
    catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (props?.dates.startDate != "") {
      let Dates = props.dates
      PolicyDashboardApi(Dates.startDate, Dates.endDate, props.filterformdata)
    }
  }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata,]);


  const handleModalToggle = (modalName) => {
    setModalStates((prevStates) => ({
      ...prevStates,
      [modalName]: !prevStates[modalName],
    }));
  };

  const colorforevent = ['#8BC1F7', '#06C', '#002F5D', '#519DE9', '#004B95']

  const EventOverTime = useMemo(() => {
    return (
      isloadevent ? <Loader /> :
        <EAreaChart
          alertlevelSeries={eventseries}
          label={eventlabel}
          StartDateTime={startDate}
          EndDateTime={endDate}
          color={colorforevent}
          legendwidth={80}
          filterFields={'rule.description'}
        />
    )
  }, [eventseries, eventlabel, startDate, endDate, isloadevent])

  const RuleDistribution = useMemo(() => {
    return (
      isloadrule ? <Loader /> :
        ruleseries.length > 0 ?
          <EPieChart seriesData={ruleseries.slice(0, 5)} height='250px' color={colorforevent} filterFields={'rule.description'} />
          :
          <Emptystatefornodata />
    )
  }, [isloadrule, ruleseries])
  const TopAgents = useMemo(() => {
    return (
      isloadagent ? <Loader /> :
        agentseries.length > 0 ?
          <EPieChart seriesData={agentseries.slice(0, 5)} height='250px' color={colorforevent} filterFields={'agent.name'} />
          :
          <Emptystatefornodata />
    )
  }, [isloadagent, agentseries])
  return (<>
    <div>
      <Grid hasGutter>
        <GridItem md={12} sm={12} lg={6}>
          <Card isFullHeight>
            <CardTitle>Events Over Time</CardTitle>
            <Divider />
            <CardBody>{EventOverTime} </CardBody>
          </Card>
        </GridItem>
        {/* props?.hideCard ? */}
        <GridItem md={props?.hideCard ? 12 : 6} sm={12} lg={props?.hideCard ? 6 : 3} >
          <Card isFullHeight>
            <CardTitle>Rule Distribution</CardTitle>
            <Divider />
            <CardBody>{RuleDistribution} </CardBody>
            {ruleseries.length > 0 &&
              <CardFooter>
                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                  <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalRuleDistribution'); }}>
                    More
                  </Button>
                  <Modal
                    variant={ModalVariant.medium}
                    title={`Rule Distribution (${ruleseries.length})`}
                    isOpen={modalStates.modalRuleDistribution}
                    onClose={() => handleModalToggle('modalRuleDistribution')}
                  >
                    <Divider className='modal-Divider' />
                    <Card>
                      <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">

                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                          {isloadrule ? <Loader /> : <TableViewForChart Tabledata={ruleseries} columnData={["Rule", "Count"]} filterFields={'rule.description'} />}
                        </Tab>
                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                          {activeTabKey == 1 &&
                            ruleseries.length > 0 ?
                            <EPieChart seriesData={ruleseries} height='300px' color={colorforevent} filterFields={'rule.description'} />
                            :
                            <Emptystatefornodata />
                          }
                        </Tab>
                      </Tabs>
                    </Card>
                  </Modal>
                </Flex>
              </CardFooter>
            }
          </Card>
        </GridItem>
        {props?.hideCard ? "" :
          <GridItem md={6} sm={12} lg={3} >
            <Card isFullHeight>
              <CardTitle>Top 5 Agents</CardTitle>
              <Divider />
              <CardBody>{TopAgents}</CardBody>
              {agentseries.length > 0 &&
                <CardFooter>
                  <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                    <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalAgents'); }}>
                      More
                    </Button>
                    <Modal
                      variant={ModalVariant.medium}
                      title={`Top Agents (${agentseries.length})`}
                      isOpen={modalStates.modalAgents}
                      onClose={() => { handleModalToggle('modalAgents') }}
                    >
                      <Divider className='modal-Divider' />
                      <Card>
                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">

                          <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                            {isloadagent ? <Loader /> : <TableViewForChart Tabledata={agentseries} columnData={["Agent", "Count"]} filterFields={'agent.name'} />}
                          </Tab>
                          <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                            {activeTabKey == 1 && agentseries.length > 0 ?
                              <EPieChart seriesData={agentseries} height='300px' color={colorforevent} filterFields={'agent.name'} />
                              :
                              <Emptystatefornodata />
                            }
                          </Tab>
                        </Tabs>
                      </Card>
                    </Modal>
                  </Flex>
                </CardFooter>
              }
            </Card>
          </GridItem>
        }
      </Grid>
    </div>
    <div className="pf-v5-u-mt-md">
      <Grid hasGutter>
        <GridItem md={12} sm={12} lg={12} >
          <Card isFullHeight>
            <CardTitle>Events Per Control Type Evolution</CardTitle>
            <Divider />
            <CardBody>
              {isloadevolution ? <Loader /> :
                <ELineStackChart
                  lineseries={evolutionseries}
                  label={evolutionlabel}
                  StartDateTime={startDate}
                  EndDateTime={endDate}
                  color={colorforevent}
                  legendwidth={170}
                  filterFields={'data.title'}
                />
              }
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </div>

  </>
  )

})

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  selectedGroup: state.SelectedGroup,
  // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(PolicyDashboard)