import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import {
    Bullseye,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Divider,
    Grid,
    GridItem,
    SelectList,
    SelectOption,
    MenuToggle,
    Select,
    Chip,
    ListItem,
    List,
    ListVariant,
    Badge,
    CardFooter,
    Flex,
    Button,
    Modal,
    ModalVariant,
    TextVariants,
    Text,
    Tabs,
    Tab,
    TabTitleText,
    Popover,
    Icon,
    FlexItem,
    Progress,
    ProgressMeasureLocation,
    ProgressSize,
    ProgressVariant,
} from '@patternfly/react-core';
import dbApi from '../../services/DbApi'
import { connect, useDispatch } from 'react-redux';
import {useParams } from 'react-router-dom';
import  SeverityStatus  from '../SecurityEvents/SeverityStatus';
import  BenchmarkScore  from '../SecurityConfiguration/BenchmarkScore';
import { Loader,RoundSkeleton, TextSkeleton } from '../../util/Loadingstate';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import EWordCloud from '../AllCharts/EWordCloud';
import  ThreatRatio  from '../ThreatIntelPlatform/ThreatRatio';
import EventChart from '../CommonEventPages/EventChart';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import EDonutChart from '../AllCharts/EDonutChart';
import { Table, Tbody, Th, Thead, Tr, Td } from '@patternfly/react-table';
import { formatTableTimestamp } from '../../util/Timeutils';
import  TopConnection  from '../NetworkInsight/TopConnectionData';
import  GeoMap  from '../NetworkInsight/GeoMap';
import { TopUsers } from '../IntegrityMonitoring/TopUsers';
import { FilterData } from '../../Redux/Action';
import  VulnerabilityStatus  from './VulnerabilityStatus';
import { LatestScanProfile } from './LatestScanProfile';
import RankListTable from './RankListTable';
import { RankbarGraph } from './RankbarGraph';
import TableViewforChart from '../AllCharts/TableViewForChart';
import {  UpdateFilterDataIndDash } from 'src/util/FilterHelper';

const HealthAnalysis = forwardRef(function HealthAnalysis(props, ref) {
    const { agentname } = useParams()
    const { agentId } = useParams()
    const uniqueid = generateUniqueID()
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = React.useState(false);
    const [selected, setSelected] = React.useState('PCI DSS');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [latestProfile, setLatestProfile] = useState([])
    const [filterfield, setFilterField] = useState([])
    const [agentdetails, setAgentDetails] = useState([])
    const [severityStatus, setSeverityStatus] = useState([]);
    const [benchmark, setBenchmark] = useState(0)
    const [critical, setCritcal] = useState(0)
    const [high, setHigh] = useState(0)
    const [medium, setMedium] = useState(0)
    const [low, setLow] = useState(0)
    const [totalvul,setTotalvul]=useState(0)
    const [insightratio, setInsightRatio] = useState([])
    const [users, setUsers] = useState([]);
    const [riskScore, setRiskScore] = useState(0)
    const [riskRank, setRiskRank] = useState([])
    const [domainurldata, setDomainUrldata] = useState([]);
    const [chartlabel, setChartLabel] = useState('')
    const [chartseries, setChartSeries] = useState([])
    const [compliancedata, setComplianceData] = useState([])
    // const [complianceField, setComplianceField] = useState('')
    const [externalIP, setExternalIP] = useState([]);
    const [connectionseries, setConnectionSeries] = useState([]);
    const [connectionname, setConnectionName] = useState([]);
    const [geoLocation, setGeoLocation] = useState([]);
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [criticalRules, setCriticalRules] = useState([])
    //Loading States
    const [isloadagent, setLoadAgent] = useState(true)
    const [isloadstatus, setLoadStatus] = useState(true)
    const [isloadprofile, setLoadprofile] = useState(true)
    const [isloadsca, setIsLoadSCA] = useState(true)
    const [isloadseverity, setisloadSeverity] = useState(true)
    const [isloadInsight, setIsLoadInsight] = useState(true)
    const [isloadUsers, setIsLoadUsers] = useState(true)
    const [isloadscore, setIsloadScore] = useState(true)
    const [isloadDomain, setIsLoadDomain] = useState(true)
    const [iseventLoading, setIseventLoading] = useState(true)
    const [isLoadCompliance, setIsLoadCompliance] = useState(true)
    const [isloadexternalIp, setIsloadexternalIp] = useState(true)
    const [isloadConnection, setIsloadConnection] = useState(true)
    const [isloadMap, setLoadMap] = useState(true)
    const [isloadCritRule, setisLoadCritRule] = useState(true)
    //Modal states
    const [modalStates, setModalStates] = useState({
        modalExtIP: false,
        modalRiskRank: false,
        modallatestscan: false,
    });


    const handleModalToggle = (modalName) => {
        setModalStates((prevStates) => ({
            ...prevStates,
            [modalName]: !prevStates[modalName],
        }));
    };


    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex)
    }

    //Agent Detail by DB
    const getAgentDetail = async () => {
        let data = {
            limitStart: 0,
            limitLength: 2,
            search: '',
            sortBy: '',
            sortOrder: '',
            status: '',
            groupId: '',
            agentId: agentId
        }
        try {
            setLoadAgent(true)
            let res = await dbApi.postApi("opensearch/agent_list", data)
            console.log(res)
            let AllAgents = []
            if (res.data.data.length > 0) {
                AllAgents = res.data.data
                setAgentDetails(AllAgents)
                setLoadAgent(false)
            }
            else {
                setAgentDetails(AllAgents)
                setLoadAgent(false)
            }
        } catch (error) {
            console.log(error)
            setLoadAgent(false)
        }
    }

    //HealthAnalysis RiskScore  by DB
    const getRiskScore = async (data) => {
        try {
            setIsloadScore(true)
            let res = await dbApi.postApi("opensearch/get_agent_risk_score", data)
            console.log(res)
            let RiskScore = 0
            let RiskRank = []
            if (res.data.length > 0) {
                RiskScore = res.data[0].current_risk_score
                RiskRank = res.data
                setRiskScore(RiskScore)
                setRiskRank(RiskRank)
            }
            else {
                setRiskScore(RiskScore)
                setRiskRank(RiskRank)
            }
            setIsloadScore(false)
        } catch (error) {
            console.log(error)
            setIsloadScore(false)
        }
    }
    //Security Severity
    const getSecuritySeverity = async (data) => {
        try {
            setLoadStatus(true)
            let res = await dbApi.postApi("elasticsearch/Security_Dasbhoard_Severity_Status", data);
            console.log(res)
            let Severity = [];
            if (Object.keys(res.Security_Dasbhoard_Severity_Status).length !== 0) {
                let severity_status = res.Security_Dasbhoard_Severity_Status.body.aggregations.level_ranges;
                Severity = severity_status.buckets;
                setSeverityStatus(Severity);
            }
            else {
                setSeverityStatus(Severity);
            }
            setLoadStatus(false)
        } catch (error) {
            console.error('Error in getSecuritySeverity:', error);
            setLoadStatus(false)
        }
    };

    //SCA benchmark api
    // const getBenchmarkScore = async (data) => {
    //     try {
    //         let res = await dbApi.postApi("Opensearch/Get_Benchmark_Score_Health", data)
    //         console.log(res)
    //         let Benchmark = 0
    //         if (res.Get_Benchmark_Score_Health > 0) {
    //             Benchmark = res.Get_Benchmark_Score_Health
    //             setBenchmark(Benchmark)
    //         }
    //         else {
    //             setBenchmark(Benchmark)
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }



    //Vulnerability by severity  by DB
    const getvulnerabilityBySeverity = async (data) => {
        try {
            setisloadSeverity(true)
            let severity = await dbApi.postApi("opensearch/get_total_vulnerability_by_agent_id", data)
            // let severity = await dbApi.postApi("opensearch/get_risk_score_total_vulnerability", data);
            console.log(severity);
            let Vulnerability_buckets = severity.data
            let Critical = '0'
            let High = '0'
            let Medium = '0'
            let Low = '0'
            let Total=0
            if (!severity.error && Object.keys(Vulnerability_buckets).length > 0) {
                Critical = Vulnerability_buckets.critical_total
                High = Vulnerability_buckets.high_total
                Medium = Vulnerability_buckets.medium_total
                Low = Vulnerability_buckets.low_total
                Total=Vulnerability_buckets.total
                setCritcal(Critical);
                setHigh(High)
                setMedium(Medium)
                setLow(Low)
                setTotalvul(Total)
            }
            else {
                setCritcal(Critical);
                setHigh(High)
                setMedium(Medium)
                setLow(Low)
                setTotalvul(Total)
            }
            setisloadSeverity(false)
        }
        catch (error) {
            console.error(error);
            setCritcal(0);
            setHigh(0)
            setMedium(0)
            setLow(0)
            setTotalvul(0)
            setisloadSeverity(false)
        }
    }

    // Opensearch/Lastest_Scans_SCA
    const getLatestProfile = async (data) => {
        try {
            setLoadprofile(true)
            let AllProfile = await dbApi.postApi("Opensearch/Latest_Scans_SCA", data);
            console.log(AllProfile);
            let LatestProfile = []
            let Benchmark = 0
            let All_Profile = AllProfile.Latest_Scans_SCA
            if (Object.keys(All_Profile).length > 0) {
                Benchmark = All_Profile.averageBenchmarkScore
                LatestProfile = All_Profile.agent

                // const sum = Benchmark.reduce((accumulator, currentScore) => accumulator + currentScore, 0);
                // averagebenchamarkScore = sum / Benchmark.length;
                setLatestProfile(LatestProfile)
                setBenchmark(Benchmark)
            }
            else {
                setLatestProfile(LatestProfile)
                setBenchmark(Benchmark)
            }
            setLoadprofile(false)
        }
        catch (error) {
            console.log(error)
            setLoadprofile(false)
        }
    }

    //Threat log
    const getThreatRatio = async (data) => {
        try {
            setIsLoadInsight(true)
            let ratio = await dbApi.postApi('Opensearch/Threat_Ratio', data)
            console.log(ratio)
            let InsightRatio = []
            if (Object.keys(ratio.data).length !== 0) {
                InsightRatio = ratio.data
                setInsightRatio(InsightRatio)
            }
            else {
                setInsightRatio(InsightRatio)
            }
            setIsLoadInsight(false)

        } catch (error) {
            console.log(error)
            setIsLoadInsight(false)
        }
    }
    //API Call for TOP COUNTRIES
    const getTopCountry = async (data) => {
        try {
            setLoadMap(true)
            let AllCountry = await dbApi.postApi("Opensearch/Network_Top_country", data);
            console.log(AllCountry);
            let GeoLocation = []
            let All_Country = AllCountry.Network_Top_country
            if (Object.keys(All_Country).length !== 0) {
                GeoLocation = All_Country.body.aggregations.Top_Country.buckets
                setGeoLocation(GeoLocation)
            }
            else {
                setGeoLocation(GeoLocation)
            }
            setLoadMap(false)
        }
        catch (error) {
            console.log(error)
            setLoadMap(false)
        }
    }
    // Event on frame
    const getEventonFrame = async (data) => {
        try {
            setIseventLoading(true)
            let all_eventlog = await dbApi.postApi("elasticsearch/EventLog", data);
            console.log(all_eventlog);
            let ChartLabel = ''
            let ChartSeries = []
            if (Object.keys(all_eventlog.event).length !== 0) {
                let label = all_eventlog.event.interval
                let ChartData = all_eventlog.event.result.body.aggregations.date_histogram
                ChartSeries = ChartData.buckets;
                ChartLabel = label

                setChartLabel(ChartLabel)
                setChartSeries(ChartSeries)
            }
            else {
                setChartLabel(ChartLabel)
                setChartSeries(ChartSeries)
            }
            setIseventLoading(false)
        }
        catch (error) {
            console.log(error)
            setIseventLoading(false)
        }
    }

    //NotableThings
    const getCriticalRules = async (data) => {
        try {
            setisLoadCritRule(true)
            let allcritical = await dbApi.postApi("elasticsearch/Security_Dasbhoard_Rule_Description", data)
            console.log(allcritical)
            let critical_rules = allcritical.Security_Dasbhoard_Rule_Description
            let CriticalRules = []
            if (critical_rules.length > 0) {
                CriticalRules = critical_rules
                setCriticalRules(CriticalRules)
            }
            else {
                setCriticalRules(CriticalRules)
            }
            setisLoadCritRule(false)
        } catch (error) {
            console.log(error)
            setisLoadCritRule(false)
        }
    }
    //API for Top Connection
    const getTopConnection = async (data) => {
        try {
            setIsloadConnection(true)
            let AllConnection = await dbApi.postApi("Opensearch/Top_Connection_Agent", data);
            console.log(AllConnection);
            let ConnectionSeries = []
            let ConnectionName = []
            if (Object.keys(AllConnection.Top_Connection_Agent).length > 0) {
                let Top_connection_Name = AllConnection.Top_Connection_Agent.connection_name;
                let Top_connection_link = AllConnection.Top_Connection_Agent.Connection_Data

                // if (Top_connection_Name.length > 0) {
                ConnectionName = Top_connection_Name.slice(0, 15)
                // }
                // if (Top_connection_link.length > 0) {
                ConnectionSeries = Top_connection_link.slice(0, 15)
                // }
                setConnectionSeries(ConnectionSeries)
                setConnectionName(ConnectionName)
            }
            else {
                setConnectionSeries(ConnectionSeries)
                setConnectionName(ConnectionName)
            }
            setIsloadConnection(false)
        }
        catch (error) {
            console.log(error)
            setIsloadConnection(false)
        }
    }
    //External IP api
    const getExternalIP = async (data) => {
        try {
            setIsloadexternalIp(true)
            let AllIP = await dbApi.postApi("Opensearch/Agent_External_IP", data);
            console.log(AllIP);
            let All_IP = AllIP.Agent_External_IP;
            let ExternalIP = []
            if (All_IP.length > 0) {
                ExternalIP = All_IP.slice(0, 5)
                setExternalIP(ExternalIP)
            }
            else {
                setExternalIP(ExternalIP)
            }
            setIsloadexternalIp(false)
        }
        catch (error) {
            console.log(error)
            setIsloadexternalIp(false)
        }
    }
    //Top users Grid
    const getTopUsers = async (data) => {
        try {
            setIsLoadUsers(true)
            let allusers = await dbApi.postApi("elasticsearch/User_Behaviour_Monitoring_Top_Dstuser_Srcuser", data);
            console.log(allusers);
            const all_users = allusers.User_Behaviour_Monitoring_Top_Dstuser_Srcuser;
            let Users = []
            if (all_users.length > 0) {
                Users = all_users.filter(item => !item.key.includes('$'))
                setUsers(Users)
            }
            else {
                setUsers(Users)
            }
            setIsLoadUsers(false)
        }
        catch (error) {
            console.log(error)
            setIsLoadUsers(false)
        }
    }
    //Api Call for domain and url
    const getDomainandUrl = async (data) => {
        try {
            setIsLoadDomain(true)
            let alldata = await dbApi.postApi("Opensearch/Network_Top_url", data)
            console.log(alldata)
            let DomainUrl = []
            if (Object.keys(alldata.Network_Top_url).length !== 0) {
                let all_data = alldata.Network_Top_url.body.aggregations.Top_Url

                DomainUrl = all_data.buckets.slice(0, 200)

                setDomainUrldata(DomainUrl)
            }
            else {
                setDomainUrldata(DomainUrl)
            }
            setIsLoadDomain(false)
        } catch (error) {
            console.log(error)
            setIsLoadDomain(false)
        }
    }

    //Compliance api
    const getCompliances = async (data, selected) => {
        try {
            setIsLoadCompliance(true)
            let apiUrl = '';
            if (selected === 'GDPR') {
                apiUrl = "Opensearch/GDPR";

            }
            else if (selected === 'NIST 800-53') {
                apiUrl = "Opensearch/NIST";
            }
            else if (selected === 'HIPAA') {
                apiUrl = "Opensearch/Hipaa";
            }
            else if (selected === 'GPG13') {
                apiUrl = "Opensearch/GPG";
            }
            else if (selected === 'TSC') {
                apiUrl = "Opensearch/Tsc";
            }
            else {
                apiUrl = "Opensearch/Rule_Pci_Dss ";
            }
            let topCompli = await dbApi.postApi(apiUrl, data);
            console.log(topCompli);
            let compliances_type = ""
            // let complianceField = ''
            if (selected === 'GDPR') {
                compliances_type = topCompli.Gdpr.body.aggregations.Gdpr;
                // complianceField = 'rule.gdpr'
            }
            else if (selected === 'NIST 800-53') {
                compliances_type = topCompli.Nist.body.aggregations.Nist;
                // complianceField = 'rule.nist_800_53'
            }
            else if (selected === 'HIPAA') {
                compliances_type = topCompli.Hipaa.body.aggregations.Hipaa;
                // complianceField = 'rule.hipaa'
            }
            else if (selected === 'GPG13') {
                compliances_type = topCompli.Gpg.body.aggregations.Gpg;
                // complianceField = 'rule.gpg13'
            }
            else if (selected === 'TSC') {
                compliances_type = topCompli.Tsc.body.aggregations.Tsc;
                // complianceField = 'rule.tsc'
            }
            else {
                compliances_type = topCompli.Rule_Pci_Dss.body.aggregations["Rule.Pci_Dss"];
                // complianceField = 'rule.pci_dss'
            }
            let ComplianceData = [];

            if (compliances_type.buckets.length > 0) {
                ComplianceData = compliances_type.buckets.slice(0, 10);
            }
            setComplianceData(ComplianceData)
            // setComplianceField(complianceField)
            setIsLoadCompliance(false)
        }
        catch (error) {
            console.log(error)
            setIsLoadCompliance(false)
        }
    }




    const HealthAnalysisDashApi = async (startDate, endDate, allFields) => {
        const Fields = allFields

        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
        };
        console.log(data)
        try {
            setStartDate(startDate)
            setEndDate(endDate)
            setFilterField(Fields)
            await getAgentDetail()
            getRiskScore({ agent_id: agentId });
            getSecuritySeverity(data);
            getvulnerabilityBySeverity({ agentId: agentId });
            // getBenchmarkScore({ agentId: agentId })
            getLatestProfile({ AgentID: agentId })
            getThreatRatio(data);
            getEventonFrame(data)
            getTopConnection(data)
            getCriticalRules(data)
            getCompliances(data, 'PCI DSS');
            getTopUsers(data);
            getDomainandUrl(data);
            getExternalIP(data);
            getTopCountry(data)
        }
        catch (error) {
            console.log(error)
        }
    }

    // Dispatch the agent name
    useEffect(() => {
        if (props?.dates.startDate !== '') {
            // Dispatch action to set staticfield in Redux
            //check that agent.name is already exist or not if exist then replace the value of agent name
            dispatch(FilterData(UpdateFilterDataIndDash(props.filterformdata, 'agent.name', 'is', `${agentname}`)))}
    }, [dispatch,agentname]);
    // Use the dispatched agent and then call api 
    useEffect(() => {
        if (props?.dates.startDate !== '' && props.filterformdata.length != 0) {
            let Dates = props.dates;
            // Use dispatched data (filterFormData) in HealthAnalysisDashApi
            HealthAnalysisDashApi(Dates.startDate, Dates.endDate, props.filterformdata);
        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata]);

    const onSelect = (_event, value) => {
        setSelected(value);
        setIsOpen(false);
        const Fields = filterfield
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
        };
        getCompliances(data, value)
    };
    const onToggleClick = () => {
        setIsOpen(!isOpen);
    };
    const toggle = toggleRef =>
        <MenuToggle ref={toggleRef} onClick={onToggleClick}
            isExpanded={isOpen}> {selected}</MenuToggle>

    const selectItems = <SelectList>
        <SelectOption key="PCI DSS" value="PCI DSS" > PCI DSS </SelectOption>
        <SelectOption key="GDPR" value="GDPR"> GDPR </SelectOption>
        <SelectOption key="NIST" value="NIST 800-53"> NIST 800-53 </SelectOption>
        <SelectOption key="HIPAA" value="HIPAA"> HIPAA</SelectOption>
        <SelectOption key="GPG13" value="GPG13"> GPG13</SelectOption>
        <SelectOption key="TSC" value="TSC"> TSC</SelectOption>
    </SelectList>;
    const headerActionsCompliance =
        <Select id="single-select" isOpen={isOpen}
            selected={selected}
            onSelect={onSelect}
            onOpenChange={isOpen =>
                setIsOpen(!isOpen)
            }
            toggle={toggle} shouldFocusToggleOnSelect
        >
            {selectItems}
        </Select>


    const agentColumn = [
        "ID",
        "Status",
        "IP Address",
        "Version",
        "Groups",
        "Operating System",
        "Cluster Node",
        "Registration Date",
        "Last keep alive ",
    ]

   
    
    //agent status
    const renderStatuscolumn = (status) => {
        let badgeStyle = {};
        if (status === 'Active') {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#3E8635" };
            return <div><Badge style={badgeStyle} >{status}</Badge></div>
        }
        else if (status === 'Disconnected') {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" };
            return <div><Badge style={badgeStyle} >{status}</Badge></div>
        }
        else if (status === 'Never Connected') {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#6A6E73" };
            return <div><Badge style={badgeStyle} >{status}</Badge></div>
        }
        else if (status === 'Pending') {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
            return <div><Badge style={badgeStyle} >{status}</Badge></div>
        }
    };
    const VulnerabilitiesChart = useMemo(() => {
        const VulnerabilitiesStatus = {
            Critical: critical,
            High: high,
            Low: low,
            Medium: medium
        }
        
        return (
            isloadseverity ?
                <Bullseye>  <RoundSkeleton width='30%' /> </Bullseye>
                :
                <VulnerabilityStatus StatusData={VulnerabilitiesStatus}  TotalVulnerability={totalvul}/>
        )
    }, [isloadseverity, critical, high, medium, low])


    const SecurityConfiguration = useMemo(() => {
        return (
            isloadprofile ?
                <Bullseye> <RoundSkeleton width='30%' /></Bullseye>
                :
                <BenchmarkScore score={benchmark} height='130px' />
        )
    }, [isloadprofile, benchmark])

    const SecuritySeverity = useMemo(() => {
        return (
            isloadstatus ?
                <Bullseye> <RoundSkeleton width='30%' /></Bullseye>
                :
                <SeverityStatus severityData={severityStatus} height='110px' />
        )

    }, [isloadstatus, severityStatus])

    const TopConnectionChart = useMemo(() => {
        return (
            isloadConnection ? <Loader /> :
                <TopConnection ConnectionlinkData={connectionseries} ConnectionName={connectionname} height={300} />
        )
    }, [isloadConnection, connectionname, connectionseries])


    const ThreatAnalysis = useMemo(() => {
        return (
            isloadInsight ? <Loader /> : <ThreatRatio RatioData={insightratio} height='250px' legend={true} />
        )
    }, [isloadInsight, insightratio])

    const HATopUsers = useMemo(() => {
        return (
            isloadUsers ?
                <Loader /> :
                users.length > 0 ?
                    <EWordCloud cloudseries={users.slice(0, 70)} height={300} filterFields={'health'}  />
                    :
                    <Emptystatefornodata />
        )
    }, [isloadUsers, users])

    const EventOnFrame = useMemo(() => {
        return (
            iseventLoading ? <Loader /> :
                <EventChart EventSeries={chartseries} EventLabel={chartlabel} StartDateTime={startDate} EndDateTime={endDate} />
        )
    }, [iseventLoading, chartseries, chartlabel, startDate, endDate])

    const TopExternalIP = useMemo(() => {
        return (
            isloadexternalIp ? <Loader /> :
                externalIP.length == 0 ? <Emptystatefornodata /> :
                    <TopUsers UserTableData={externalIP} defaultColumn={["External IP", "count",]} />
        )
    }, [isloadexternalIp, externalIP])

    const TopDomainUrl = useMemo(() => {
        return (
            isloadDomain ? <Loader /> : domainurldata.length > 0 ?
                <EWordCloud cloudseries={domainurldata.slice(0, 70)} height={300} filterFields={'health'}  />
                :
                <Emptystatefornodata />
        )
    }, [isloadDomain, domainurldata])
    const TopCompliances = useMemo(() => {
        return (
            isLoadCompliance ? <Loader /> : compliancedata.length > 0 ?
                <EDonutChart seriesData={compliancedata} height="300px" color={['#519DE9', '#004B95', '#8BC1F7', '#06C', '#002F5D']} filterFields={'health'} />
                :
                <Emptystatefornodata />
        )
    }, [isLoadCompliance, compliancedata])

    const AgentDetails = useMemo(() => {
        return (
            isloadagent ? <TextSkeleton /> :
                <Table id="detail-log" variant='compact'>
                    <Thead>
                        <Tr key={'header-row'}>
                            {agentColumn.map((column, index) => (
                                <Th key={column + index}>{column}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    {agentdetails.length > 0 && (agentdetails.map((row, rowIndex) => {
                        // row?.dateAdd
                        const { formattedTimeStamp: formattedAddDate } = row?.registration_date ? formatTableTimestamp(row?.registration_date) : { formattedTimeStamp: null }
                        const { formattedTimeStamp: formattedLastKeepAlive } = row?.last_keep_alive ? formatTableTimestamp(row?.last_keep_alive) : { formattedTimeStamp: null };
                        return (
                            <Tbody key={uniqueid + rowIndex + 'agentdetail'}>
                                <Tr>
                                    <Td key={'id' + rowIndex} dataLabel={agentColumn[0]}> {row?.agent_id !== undefined && row?.agent_id !== null ? row?.agent_id : ''}</Td>
                                    <Td key={'status' + rowIndex} dataLabel={agentColumn[1]}>{renderStatuscolumn(row?.status_description)}</Td>
                                    <Td key={'ip' + rowIndex} dataLabel={agentColumn[2]}>{row?.ip}</Td>
                                    <Td key={'version' + rowIndex} dataLabel={agentColumn[3]}>{row.version}</Td>
                                    <Td key={'gname' + rowIndex} dataLabel={agentColumn[4]}>
                                        {row?.group_name !== undefined && row?.group_name !== null ?
                                            (<List isPlain variant={ListVariant.inline} >
                                                {row?.group_name.split(',').map((item, index) => (
                                                    <ListItem key={"li" + index}> <Chip key={"chip" + index} isReadOnly>{item}</Chip></ListItem>
                                                ))} </List>) : null}
                                    </Td>
                                    <Td key={'osname' + rowIndex} dataLabel={agentColumn[5]} modifier='truncate'>{row?.os_name !== undefined && row?.os_version !== undefined ? `${row?.os_name} ${row?.os_version}` : ' '}</Td>
                                    <Td key={'clusternode' + rowIndex} dataLabel={agentColumn[6]}>{row?.cluster_node !== undefined && row?.cluster_node !== null ? row?.cluster_node : ''}</Td>
                                    <Td key={'regdate' + rowIndex} dataLabel={agentColumn[7]}> {formattedAddDate}</Td>
                                    <Td key={'lkadate' + rowIndex} dataLabel={agentColumn[8]}> {formattedLastKeepAlive}</Td>
                                </Tr>
                            </Tbody>
                        )
                    }
                    ))}

                </Table>
        )
    }, [isloadagent, agentColumn, agentdetails])

    return <>
        <Card className="pf-v5-u-mt-sm" isFlat>
            {AgentDetails}
        </Card>
        <Grid hasGutter className="pf-v5-u-mt-sm">
            <GridItem md={12} lg={6} sm={12}>
                <Card isFullHeight isFlat >
                    <CardHeader>
                        <CardTitle>Health Analysis{' '}
                            <a className='pf-v5-u-danger-color-200' onClick={() => { handleModalToggle('modalRiskRank'); setActiveTabKey(0) }} key={'rank' + uniqueid}>
                                {`Risk score:${riskScore ?? 0}   Rank: ${riskRank.length > 0 && `${riskRank[0].rank} (${riskRank[0].group_name})`} `}</a>
                            {riskRank.length > 1 &&
                                <Popover aria-label="Basic popover" bodyContent={<div>
                                    {riskRank.slice(1).map((item, index) =>
                                        <List key={index}><ListItem key={item + index}>{item.group_name} :- {item.rank} </ListItem></List>
                                    )}
                                    {/* data */}
                                </div>} >
                                    <a><Text component={TextVariants.small}>..more</Text></a>
                                </Popover>}
                            <Modal
                                variant={ModalVariant.large}
                                title={`Position in rank list`}
                                isOpen={modalStates.modalRiskRank}
                                onClose={() => handleModalToggle('modalRiskRank')}
                            >
                                <Divider className='modal-Divider' />
                                <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                    <Tab eventKey={0} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                        <RankbarGraph />
                                    </Tab>
                                    <Tab eventKey={1} title={<TabTitleText>Table View</TabTitleText>}>
                                        <RankListTable AgentName={agentname} />
                                    </Tab>
                                </Tabs>
                            </Modal>
                        </CardTitle>
                    </CardHeader>
                    <Divider />
                    <CardBody>
                        <Grid>
                            <GridItem md={4} lg={4} sm={6}>
                                <Card isPlain >
                                    <CardTitle align="center" className='title-height'> Severity Status </CardTitle>
                                    {SecuritySeverity}
                                </Card>
                            </GridItem>
                            <GridItem md={4} lg={4} sm={6}>
                                <Card isPlain >
                                    <CardTitle align="center" className='title-height'> SCA (Benchmark) </CardTitle>
                                    {SecurityConfiguration}
                                </Card>
                            </GridItem>
                            <GridItem md={4} lg={4} sm={12}>
                                <Card isPlain >
                                    <CardTitle align="center" className='title-height'>Vulnerabilities</CardTitle>
                                    {VulnerabilitiesChart}
                                </Card>
                            </GridItem>
                        </Grid>

                    </CardBody>
                </Card>
            </GridItem>
            <GridItem md={12} lg={6} sm={12}>
                <Card isFlat isFullHeight>
                    <CardTitle>SCA : Latest Scans</CardTitle>
                    <Divider />
                    {isloadprofile ? <TextSkeleton /> :
                        latestProfile.length == 0 ? <CardBody><Text component={TextVariants.h3} >You don't have SCA scans in this agent.
                            <br />
                            <small>Check your agent settings to generate scans.</small></Text> </CardBody>
                            :
                            <>
                                <CardBody>
                                    <LatestScanProfile ProfileData={latestProfile.slice(0, 3)} />
                                </CardBody>
                                {latestProfile.length > 3 ?
                                    <CardFooter>
                                        <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                            <Button variant="link" onClick={() => { handleModalToggle('modallatestscan'); }}>
                                                More
                                            </Button>
                                            <Modal
                                                variant={ModalVariant.large}
                                                title='All latest scan profile'
                                                isOpen={modalStates.modallatestscan}
                                                onClose={() => handleModalToggle('modallatestscan')}
                                            >
                                                <Divider className='modal-Divider' />
                                                <LatestScanProfile ProfileData={latestProfile} />
                                            </Modal>
                                        </Flex>
                                    </CardFooter>
                                    : ""
                                }
                            </>
                    }

                </Card>
            </GridItem>
        </Grid>
        <Grid hasGutter className="pf-v5-u-mt-sm">
            <GridItem md={12} lg={3} sm={12}>
                <Card isFullHeight isFlat>
                    <CardTitle>Threat</CardTitle>
                    <Divider />
                    <CardBody>
                        <CardBody>{ThreatAnalysis} </CardBody>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem md={12} lg={3} sm={12}>
                <Card isFullHeight isFlat>
                    <CardTitle>Geo Map</CardTitle>
                    <Divider />
                    <CardBody>
                        {isloadMap ? <Loader /> :
                            <GeoMap TopLocation={geoLocation} />}
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem md={12} lg={6} sm={12}>
                <Card isFullHeight isFlat>
                    <CardTitle>Event On Frame</CardTitle>
                    <Divider />
                    <CardBody>{EventOnFrame}</CardBody>
                </Card>
            </GridItem>
        </Grid >
        <div className="pf-v5-u-mt-sm">
            <Grid hasGutter>
                <GridItem md={12} lg={4} sm={12}>
                    <Card isFullHeight isFlat>
                        <CardTitle>Notable Things (AI Impression)</CardTitle>
                        <Divider />
                        {isloadCritRule ? <Loader /> :
                            <TableViewforChart Tabledata={criticalRules.slice(0, 7)} columnData={["", ""]} filterFields={'rule.description'} Pagination={false} />
                        }
                    </Card>
                </GridItem>
                <GridItem md={12} lg={5} sm={12}>
                    <Card isFullHeight isFlat>
                        <CardTitle>Top Connection ({agentname})</CardTitle>
                        <Divider />
                        <CardBody>{TopConnectionChart}</CardBody>

                    </Card>
                </GridItem>
                <GridItem md={12} lg={3} sm={12}>
                    <Card isFullHeight isFlat>
                        <CardTitle>Top External IP</CardTitle>
                        <Divider />
                        <CardBody>{TopExternalIP}</CardBody>
                    </Card>
                </GridItem>
            </Grid>
        </div>
        <div className="pf-v5-u-mt-sm">
            <Grid hasGutter>
                <GridItem md={12} lg={4} sm={12}>
                    <Card isFullHeight isFlat>
                        <CardTitle>Top Users</CardTitle>
                        <Divider />
                        {HATopUsers}
                    </Card>
                </GridItem>
                <GridItem md={12} lg={4} sm={12}>
                    <Card isFullHeight isFlat>
                        <CardTitle>Top Domains and URLs</CardTitle>
                        <Divider />
                        {TopDomainUrl}
                    </Card>
                </GridItem>
                <GridItem md={12} lg={4} sm={12}>
                    <Card isFullHeight isFlat>
                        <CardHeader className="pf-v5-u-align-items-flex-start" actions={{ actions: headerActionsCompliance, hasNoOffset: false }}>
                            <CardTitle >Top Compliances</CardTitle>
                        </CardHeader>
                        <Divider />
                        {TopCompliances}
                    </Card>
                </GridItem>
            </Grid>
        </div>
    </>
})
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(HealthAnalysis)



