//2nd which is perfectly run and usable
import React, { useEffect, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
} from "@patternfly/react-table";
import { Emptystatefornodata } from "src/util/EmptyStateCard";
import { Badge, Button, List, ListItem, Pagination, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem } from "@patternfly/react-core";
import { UpdateFilterData } from 'src/util/FilterHelper';
import { FilterData } from 'src/Redux/Action';
import { connect, useDispatch } from 'react-redux';
const OsqueryTop5Rules = (props) => {
  const dispatch = useDispatch();
  const defaultColumns = [
    "Rule ID",
    "Rule Description ",
    "Rule Groups",
    "Count",
  ];
  const [columns, setColumns] = useState(defaultColumns);
  const [rows, setRows] = useState([]);
  const [defaultRows, setDefaultRows] = useState([]);
  const [selectedRowName, setSelectedRowName] = React.useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [itemcount, setItemcount] = useState(0)

  useEffect(() => {
    const newdefaultRows = props.RuleTableData.map((log) => {
      return {
        ruleid: log.ruleid,
        ruledesc: log.ruledesc,
        rulegroup: log?.rulegroup,
        count: log.count
      };
    });

    setDefaultRows(newdefaultRows)
    setItemcount(newdefaultRows.length)
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);
    // console.log(rows)
  }, [props.RuleTableData, rows.length]);

  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    // console.log("after slicing", "", startIndex, endIndex, slicedRows)
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);

  const handleSetPage = (_evt, newPage) => {
    setPage(newPage);
  };
  const handlePerPageSelect = (_evt, newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

  const renderPagination = (variant, isCompact) => (
    <Pagination
      isCompact={isCompact}
      itemCount={itemcount}
      page={page}
      perPage={perPage}
      onSetPage={handleSetPage}
      onPerPageSelect={handlePerPageSelect}
      variant={variant}
      titles={{
        paginationAriaLabel: `${variant} pagination`
      }}
    />
  );

  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }
  return (

    <React.Fragment>
      {props.RuleTableData.length === 0 ? (
        <Emptystatefornodata />
      ) : (<>
        {props?.IsModal && <Toolbar>
          <ToolbarContent>
            <ToolbarGroup align={{ default: 'alignRight' }}>
              <ToolbarItem>{renderPagination('top')}</ToolbarItem>
            </ToolbarGroup>
          </ToolbarContent>
        </Toolbar>}

        <Table variant="compact" aria-label="rulelog" id="rule-log-table" isStickyHeader>
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column}>{column}</Th>
              ))}
            </Tr>
          </Thead>
          {rows.map((row, rowIndex) => (
            <Tbody key={rowIndex} >
              <Tr key={rowIndex}
                onRowClick={() => setSelectedRowName(rowIndex)}
                isSelectable
                isClickable
                isRowSelected={selectedRowName === rowIndex}  >
                <Td width={10}>{row?.ruleid}</Td>
                <Td width={70} modifier="truncate" ><a className='pf-v5-u-color-100' onClick={() => { handleFilter('rule.description', row?.ruledesc) }}>{row?.ruledesc}</a></Td>
                <Td width={10} align="center">
                  {row?.rulegroup !== undefined && row?.rulegroup !== null ?
                    (<List isPlain>
                      {row?.rulegroup.slice(1)?.map((item, index) =>
                      (<Badge key={index}> <ListItem key={index}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('rule.groups', item) }}>{item}</a></ListItem></Badge>
                      ))} </List>)
                    : (" ")}
                </Td>
                <Td width={10}>{row?.count}</Td>
              </Tr>
            </Tbody>
          ))}
        </Table>
      </>
      )}
      {props?.IsModal && renderPagination('bottom')}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(OsqueryTop5Rules)