import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../../util/UniqueIdGenerator';
import { calculateTimeDifference, formatTime, formatTimestamp } from '../../../util/Timeutils';
import { formatValue } from '../../../util/ChartHelper';
import { connect, useDispatch } from 'react-redux';
const IncidentLineChart = ({ statusTrend, label, StartDateTime, EndDateTime, sidebarState,filterFormData, theme }) => {
  const id = generateUniqueID()
  useEffect(() => {
    if (statusTrend && statusTrend.length > 0) {
      const chartDom = document.getElementById(id + 'inlc');
      const myChart = echarts.init(chartDom);
      const linecolor = theme == 'dark-theme' ? '#595757' : '#d9d9d9'
      const textcolor = theme == 'dark-theme' ? '#fff' : '#000'
      const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(StartDateTime, EndDateTime);
      const Label = label;
      const uniqueDatesSet = new Set();
      const xAxisData = []

      for (let i = 0; i < statusTrend.length; i++) {
        const xvalue = statusTrend[i].time_bucket;
        uniqueDatesSet.add(xvalue);

      }
      const uniqueDatesArray = [...uniqueDatesSet];
      for (let k = 0; k < uniqueDatesArray.length; k++) {
        const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(uniqueDatesArray[k]);
        const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
        xAxisData.push({ x: formattedTime });
      }

      let interval = ''
      let lineheight = ''
      if (Label === '30 seconds') {
        interval = 4
        lineheight = 100
      }
      else if (Label === 'hour') {
        interval = 3
        lineheight = 95
      }
      else {
        interval = 2
        lineheight = 200
      }
      const option = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: "#000"
          }
        },

        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 'right',
          top: 10,
          itemWidth: 9.5,
          itemHeight: 10,
          itemGap: 6.5,
          icon: "rect",
          textStyle: {
            width:160,
            overflow: 'truncate',
            fontSize: 12,
            color: textcolor
          }
        },
        grid: {
          left: 0,
          right: '12%',
          bottom: '10%',
          top: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLabel: { interval: interval, rotate: -60, color: textcolor },
          boundaryGap: true,
          data: xAxisData.map(item => item.x),
          axisTick: {
            alignWithLabel: true
          },
          name: `Timestamp per ${Label}`,
          nameLocation: "middle",
          nameTextStyle: {
            align: "center",
            lineHeight: lineheight,
            fontSize: 14,
            color: textcolor,
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: formatValue,
            color: textcolor
          },
          splitLine: {
            lineStyle: {
              color: linecolor
            }
          },
          axisLine: {
            show: true
          },
        },
        series: [
          {
              name: 'Waiting',
              type: 'line',
              data: statusTrend.map(item => item.total_waiting??0),
              emphasis: {
                focus: 'series',
              },
              itemStyle: {
                color: '#C9190B',
              },
            },
            {
              name: 'Open',
              type: 'line',
              data: statusTrend.map(item => item.total_open??0),
              emphasis: {
                focus: 'series',
              },
              itemStyle: {
                color: '#ffbf00', 
              },
            },
            {
              name: 'Closed',
              type: 'line',
              data: statusTrend.map(item => item.total_close??0),
              emphasis: {
                focus: 'series',
              },
              itemStyle: {
                color: '#4CB140',
              },
            }
        ]
      };
      option && myChart.setOption(option);
      const resizeChart = () => {
        if (myChart) { // Check if chart exists before resizing
          myChart.resize();
        }
      };

    window.addEventListener('resize', resizeChart);
    return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
    };

    }

  }, [statusTrend, label, StartDateTime, EndDateTime, filterFormData, theme,sidebarState])
  return <div className="container">
    <div id={id + 'inlc'} style={{ width: '100%', height: '300px' }} ></div>
  </div>;
}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState:state.sidebarstate
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(IncidentLineChart)
