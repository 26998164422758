
import React, { useRef, useState } from 'react';
import {
    Button,
    Chip,
    ChipGroup,
    HelperText,
    HelperTextItem,
    TextInputGroup,
    TextInputGroupMain,
    TextInputGroupUtilities,
} from '@patternfly/react-core';
// import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-icon';

import { CheckCircleIcon, ExclamationCircleIcon, TimesIcon } from '@patternfly/react-icons';
const FilterInputTextBox = ({
    currentChips, setCurrentChips, indexNameValid,
    placeholder, isRuleLevel, RuleLevel
}) => {
    const [inputValue, setInputValue] = useState('');
    const [helperText, setHelperText] = useState('');
    const textInputGroupRef = useRef();
    const handleInputChange = (_event, value) => {
        const trimmedValue = value.trim();
        setInputValue(trimmedValue);
    };
    const deleteChip = chipToDelete => {
        const newChips = currentChips.filter(chip => !Object.is(chip, chipToDelete));
        setCurrentChips(newChips);
    };
    const clearChipsAndInput = () => {
        setCurrentChips([]);
        setInputValue('');
    };

    const addChip = newChipText => {
        // setCurrentChips([...currentChips, `${newChipText}`]);
        // setInputValue('');
        if (isRuleLevel) {
            // Validate and allow only one chip
            const numericValue = parseInt(newChipText, 10);
            if (!isNaN(numericValue) && numericValue >= RuleLevel && numericValue <= 15) {
                // Clear existing chips and add new chip
                setCurrentChips([`${numericValue}`]);
            } else {
                setHelperText(`Value must be between ${RuleLevel} and 15`);
            }
        } else {
            // For non-rule level, allow multiple chips
            setCurrentChips([...currentChips, newChipText]);
        }
        setInputValue('');
    };
    const handleEnter = () => {
        if (inputValue.length) {
            addChip(inputValue);
        }
    };

    const handleTextInputKeyDown = event => {
        switch (event.key) {
            case 'Enter':
                handleEnter();
                break;
        }
    };
    // console.log("indexNameValid", indexNameValid)
    const showClearButton = !!inputValue || !!currentChips.length;
    const showUtilities = showClearButton;
    const inputGroup = <div ref={textInputGroupRef}>
        <span className={indexNameValid != "" && typeof indexNameValid != "undefined" ? "pf-v5-c-form-control pf-m-" + indexNameValid : ''}>
            <TextInputGroup className='pf-v5-u-w-100'>
                <TextInputGroupMain
                    // icon={showSearchIcon && <SearchIcon />}
                    value={inputValue} onChange={handleInputChange}
                    // validated={indexNameValid}
                    //   onFocus={() => setMenuIsOpen(true)} 
                    onKeyDown={handleTextInputKeyDown}
                    placeholder={placeholder} aria-label="index-input">
                    <ChipGroup numChips={2}>
                        {currentChips.map(currentChip => <Chip key={currentChip} onClick={() => deleteChip(currentChip)}>
                            {currentChip}
                        </Chip>)}
                    </ChipGroup>
                </TextInputGroupMain>
                {showUtilities && <TextInputGroupUtilities>
                    {showClearButton && <Button variant="plain" onClick={clearChipsAndInput} aria-label="Clear button for chips and input">
                        <TimesIcon />
                    </Button>}
                </TextInputGroupUtilities>}
            </TextInputGroup>
            {
                indexNameValid != "" && <span className="pf-v5-c-form-control__utilities">
                    <span className="pf-v5-c-form-control__icon pf-m-status mt-5">
                        {indexNameValid == "success" ? <CheckCircleIcon /> : indexNameValid == "error" && <ExclamationCircleIcon />}</span>
                </span>
            }
        </span>
        {isRuleLevel && (
            <HelperText>
                <HelperTextItem>{helperText}</HelperTextItem>
            </HelperText>
        )}
    </div>;


    return inputGroup
};
export default FilterInputTextBox