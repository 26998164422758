import React, { useState, useEffect } from 'react';
import { Grid, GridItem, Title, TextInput, Button, ToggleGroup, ToggleGroupItem, InputGroup, InputGroupItem, ActionList, ActionListItem, Card, CardBody, TextInputGroup, TextInputGroupMain, Icon, TextInputGroupUtilities, Badge, DescriptionList, DescriptionListGroup, DescriptionListTerm, DescriptionListDescription, InputGroupText, TextArea } from '@patternfly/react-core';
import { EditIcon, CheckIcon, TimesIcon } from '@patternfly/react-icons';
import dbApi from 'src/services/DbApi';
import { useNavigate, useParams } from 'react-router-dom';
import SingleDateTimePicker from 'src/Components/CommonEventPages/SingleDateTimePicker';
import CommonSelectBox from 'src/Components/CommonEventPages/CommonSelectBox';
import { ChipGroup, Chip } from '@patternfly/react-core';
import { Loader } from 'src/util/Loadingstate';
import { alertBoxData } from 'src/Redux/Action';
import { useDispatch } from 'react-redux';
import { formatTableTimestamp } from 'src/util/Timeutils';

const fieldMappings = {
    tlp_amber: 'tlpAmber',
    pap_amber: 'papAmber',
};

const IncidentDetail = ({ incidentid, isEditDisable, onAlertDetailFetched }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const userRole = localStorage.getItem("role");
    const incidentIdToUse = id;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [severity, setSeverity] = useState('');
    const [tlpAmber, setTlpAmber] = useState('');
    const [papAmber, setPapAmber] = useState('');
    const [startDatetime, setStartDatetime] = useState('');
    const [finalStartDatetime, setFinalStartDatetime] = useState('');
    const [status, setStatus] = useState('');
    const [investigatorList, setInvestigatorList] = useState([]);
    const [selectedInvestigatorValue, setSelectedInvestigatorValue] = useState([]);
    const [error, setError] = useState(null);
    const [editingField, setEditingField] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [description, setDescription] = useState('');
    const [organization, setOrganization] = useState('');
    const [originalValues, setOriginalValues] = useState({});
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [inputTag, setInputTag] = useState('');
    const [showtags, setShowtags] = useState(true);
    const [isEditMode, setIsEditMode] = useState(true);
    const [createUserName, setCreateUserName] = useState('');
    const [readStatus, setReadStatus] = useState('');
    const [editMode, setEditMode] = useState({
        investigator: false,
    });
    useEffect(() => {
        if (typeof isEditDisable != "undefined") {
            setIsEditMode(isEditDisable)
        }
    }, [isEditDisable])

    useEffect(() => {
        if (incidentIdToUse) {
            getIncidentDetail(incidentIdToUse);
            getInvestigatorList();
        }
    }, [incidentIdToUse]);

    const getIncidentDetail = async (incidentId) => {
        try {
            setIsLoading(true);
            const res = await dbApi.postApi("incident_management/get_incident", { incidentId });
            console.log('incident id *****', res)
            if (res.data && res.data.length > 0) {
                const detail = res.data[0];
                setTitle(detail.title);
                setDescription(detail.description);
                setOrganization(detail.organization_name);
                setSeverity(detail.severity);
                setTlpAmber(detail.tlp_amber);
                setPapAmber(detail.pap_amber);
                let date1 = new Date(detail.start_datetime);
                setStartDatetime(date1);
                setStatus(detail.status);
                setCreateUserName(detail.create_by_user);
                setReadStatus(detail.read_status);
                const tags = detail.tags.map((obj) => ({
                    uniqueId: obj.unique_id,
                    tag: obj.tag,
                }
                ));
                setTags(tags)
                setSelectedInvestigatorValue(detail.user_id);
            }
            else if (!res.data || res.data.length === 0) {
                navigate('/incident-management');
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    };

    const getIncidentAlertDetail = async () => {
        const params = { incidentId: incidentIdToUse }
        try {
            const res = await dbApi.postApi('incident_management/get_incident_alert_details', params);
            console.log('get incident alert detail', res);
            const alertData = res.data;
            console.log('alert detail data----', alertData)
            if (onAlertDetailFetched) {
                onAlertDetailFetched(alertData);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (incidentIdToUse) {
            getIncidentAlertDetail();
        }
    }, [incidentIdToUse]);

    const getInvestigatorList = async () => {
        try {
            const res = await dbApi.postApi("user/get_user_list", { status: '1', role: ['Investigator'] });
            console.log('investigator', res);
            setInvestigatorList(res.data || []);
        } catch (error) {
            console.log(error);
        }
    };

    const handleEditClick = (fieldName) => {
        setEditingField(fieldName);

        if (fieldName === 'startDatetime') {
        } else {
            setInputValue(fieldMappings[fieldName] || '');
        }
        setOriginalValues((prevValues) => ({
            ...prevValues,
            [fieldName]: {
                title, severity, tlpAmber, papAmber, startDatetime, status, description
            }[fieldName]
        }));

        switch (fieldName) {
            case 'title':
                setInputValue(title);
                break;
            case 'description':
                setInputValue(description);
                break;
            case 'severity':
                setInputValue(severity);
                break;
            case 'tlpAmber':
                setInputValue(tlpAmber);
                break;
            case 'papAmber':
                setInputValue(papAmber);
                break;
            case 'startDatetime':
                setStartDatetime(startDatetime)
                break;
            case 'status':
                setInputValue(status);
                break;
            default:
                setInputValue('');
        }
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSaveClick = async (fieldName) => {
        try {
            const params = { incidentId: incidentIdToUse };
            let updatedValue;
            if (fieldName === 'startDatetime') {
                updatedValue = new Date(finalStartDatetime);
            } else {
                updatedValue = inputValue;  // Use input value for other fields
            }
            switch (fieldName) {
                case 'title':
                    setTitle(updatedValue);
                    break;
                case 'description':
                    setDescription(updatedValue);
                    break;
                case 'severity':
                    setSeverity(updatedValue);
                    break;
                case 'tlpAmber':
                    setTlpAmber(updatedValue);
                    break;
                case 'papAmber':
                    setPapAmber(updatedValue);
                    break;
                case 'startDatetime':
                    setStartDatetime(updatedValue);
                    break;
                case 'status':
                    setStatus(updatedValue);
                    break;
                default:
                    break;
            }

            params[fieldMappings[fieldName] || fieldName] = updatedValue;
            const res = await dbApi.putApi("incident_management/update_incident", params);

            if (res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                setEditingField(null);
                getIncidentDetail(incidentIdToUse);
            }
        }
        catch (error) {
            console.error('Failed to update incident:', error);
        }
    };
    const handleCancelClick = () => {
        if (editingField === 'startDatetime') {
        } else {
            setInputValue(originalValues[editingField]);
        }
        setEditingField(null);
    };

    const handleToggleChange = (fieldName, value) => {
        setInputValue(value);
    };


    const handleUpdateInvestigatorValue = (value) => {
        setSelectedInvestigatorValue(value);
    };

    const handleDateTimeChange = (newDate) => {
        setFinalStartDatetime(newDate);
    };

    const fields = [
        { label: 'Title', name: 'title', value: title },
        { label: 'Description', name: 'description', value: description },
        { label: 'Severity', name: 'severity', value: severity, options: ['L', 'M', 'H', 'C'] },
        { label: 'TLP', name: 'tlpAmber', value: tlpAmber, options: ['WHITE', 'AMBER', 'RED', 'GREEN'] },
        { label: 'PAP', name: 'papAmber', value: papAmber, options: ['WHITE', 'AMBER', 'RED', 'GREEN'] },
        {
            label: 'Start Date',
            name: 'startDatetime',
            value: formatTableTimestamp(startDatetime).formattedTimeStamp  // Format date and time
        },
        { label: 'Status', name: 'status', value: status, options: ['Open', 'Closed', 'Waiting'] },
    ];
    const handleInputTags = (event, value) => {
        setInputTag(value.trim());
    };
    const handleinvEditClick = () => {
        if (editMode.investigator) {
            handleInvestigatorUpdate(); // Save changes when exiting edit mode
        }
        setEditMode((prevState) => ({
            ...prevState,
            investigator: !prevState.investigator, // toggle investigator edit mode
        }));

    };

    const deleteTag = tagToDelete => {
        const newTags = tags.filter(tag => tag.uniqueId !== tagToDelete);
        setTags(newTags);
    };
    const clearTagsAndInput = () => {
        setTags([]);
        setInputTag('');
    };
    const addTag = newTagText => {
        const newTag = { uniqueId: '', tag: newTagText };
        setTags([...tags, newTag]);
        setInputValue('');
    };

    const handleEnter = () => {
        if (inputTag.length) {
            addTag(inputTag);
        }
    };
    const handleTextInputKeyDown = event => {
        if (event.key === 'Enter') {
            handleEnter();
        }
    };
    const showClearButton = !!inputTag || !!tags.length;
    const showUtilities = showClearButton;

    const handleAddTag = async () => {
        setShowtags(true)
        let params = {
            incidentId: incidentIdToUse,
            tags: tags
        };
        try {
            const res = await dbApi.putApi('incident_management/update_tags', params);
            console.log('incident tags', res);
            if (res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            } else {
                await getIncidentDetail(incidentIdToUse)
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
            }
        } catch (error) {
            console.error('Failed to update tags:', error);
            setIsLoading(false);
        }
    };

    const handleInvestigatorUpdate = async () => {
        setShowtags(true);
        const selectedInvestigator = investigatorList.find(item => item.user_id === selectedInvestigatorValue);

        if (!selectedInvestigator) {
            dispatch(alertBoxData([{ title: 'Please select a valid investigator.', variant: 'danger' }]));
            return;
        }
        const userId = selectedInvestigator.user_id;
        let params = {
            incidentId: incidentIdToUse,
            investigatorId: userId
        };
        try {
            const res = await dbApi.putApi('incident_management/update_incident_investigator', params);
            console.log('Investigator update response:', res);

            if (res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            } else {
                await getIncidentDetail(incidentIdToUse);
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
            }
        } catch (error) {
            console.error('Failed to update investigator:', error);
            setIsLoading(false);
        }
    };

    const tlpColorMap = {
        WHITE: { backgroundColor: '#00c0ef', color: '#fff' },
        AMBER: { backgroundColor: '#ffbf00', color: '#fff' },
        GREEN: { backgroundColor: '#008000', color: '#fff' },
        RED: { backgroundColor: '#C9190B', color: '#fff' }
    };

    const extendedTlpColorMap = {
        ...tlpColorMap, // Existing color mappings
        L: { backgroundColor: '#00c0ef', color: '#fff' }, // Blue
        M: { backgroundColor: '#ffbf00', color: '#fff' }, // Yellow
        H: { backgroundColor: '#C9190B', color: '#fff' }, // Red
        C: { backgroundColor: '#8B0000', color: '#fff' },// Dark Red
        Open: { backgroundColor: '#F0AB00', color: '#fff' },  // Blue
        Closed: { backgroundColor: '#3E8635', color: '#fff' }, // Red
        Waiting: { backgroundColor: '#C9190B', color: '#fff' } // Yellow
    };

    const getBadgeProps = (status) => {
        if (status === 0) {
            return { text: 'Unread', color: '#C9190B' };
        } else if (status === 1) {
            return { text: 'Read', color: '#00c0ef' };
        }
        return { text: 'Unknown', color: 'grey' }; // fallback for unexpected status
    };

    const statusBadge = getBadgeProps(readStatus);

    return (
        <div>
            <Card >
                <CardBody >
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <Grid hasGutter>
                                {/* Left Section */}
                                <GridItem lg={6} md={6} sm={12}>
                                    <DescriptionList isHorizontal>
                                        {fields.filter(field => !field.options).map((field, index) => (
                                            <DescriptionListGroup key={index}>
                                                <DescriptionListTerm className="pf-v5-u-font-weight-bold">
                                                    {field.label}:
                                                </DescriptionListTerm>
                                                <DescriptionListDescription>
                                                    {editingField === field.name ? (
                                                        field.name === 'startDatetime' ? (
                                                            <InputGroup>
                                                                <SingleDateTimePicker
                                                                    dateTime={startDatetime}
                                                                    onDateChange={handleDateTimeChange}
                                                                />

                                                                <InputGroupItem className="input-group-item">
                                                                    <Button variant="plain" onClick={() => handleSaveClick(field.name)}>
                                                                        <CheckIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                                <InputGroupItem className="input-group-item">
                                                                    <Button variant="plain" onClick={handleCancelClick}>
                                                                        <TimesIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                            </InputGroup>
                                                        ) : field.name === 'description' ? (
                                                            <InputGroup>
                                                                <TextArea
                                                                    className="pf-v5-u-mb-md custom-textarea"
                                                                    value={inputValue}
                                                                    onChange={handleInputChange}
                                                                    aria-label={`Edit ${field.label}`}
                                                                    resizeOrientation="vertical" // Allows vertical resizing of the textarea
                                                                />
                                                                <InputGroupItem className="input-group-item">
                                                                    <Button variant="plain" onClick={() => handleSaveClick(field.name)}>
                                                                        <CheckIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                                <InputGroupItem className="input-group-item">
                                                                    <Button variant="plain" onClick={handleCancelClick}>
                                                                        <TimesIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                            </InputGroup>
                                                        ) : (
                                                            <InputGroup>
                                                                <TextInput
                                                                    className="pf-v5-u-mb-md custom-textarea"
                                                                    value={inputValue}
                                                                    onChange={handleInputChange}
                                                                    aria-label={`Edit ${field.label}`}
                                                                />
                                                                <InputGroupItem  className="input-group-item">
                                                                    <Button variant="plain" onClick={() => handleSaveClick(field.name)}>
                                                                        <CheckIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                                <InputGroupItem  className="input-group-item">
                                                                    <Button variant="plain" onClick={handleCancelClick}>
                                                                        <TimesIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                            </InputGroup>
                                                        )
                                                    ) : (
                                                        <div>
                                                            {field.value || field.value === '' ? (
                                                                <>
                                                                    {field.value || 'No data available'}
                                                                    {
                                                                        isEditMode && userRole !== 'Analyst' && <Button variant="plain" onClick={() => handleEditClick(field.name)}>
                                                                            <EditIcon />
                                                                        </Button>
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>{
                                                                    isEditMode && userRole !== 'Analyst' && <Button variant="plain" onClick={() => handleEditClick(field.name)}>
                                                                        <EditIcon />
                                                                    </Button>
                                                                }</>
                                                            )}
                                                        </div>
                                                    )}
                                                </DescriptionListDescription>
                                            </DescriptionListGroup>
                                        ))}
                                        <DescriptionListGroup>
                                            <DescriptionListTerm className="pf-v5-u-font-weight-bold">
                                                Organization:
                                            </DescriptionListTerm>
                                            <DescriptionListDescription>
                                                {organization}
                                            </DescriptionListDescription>
                                        </DescriptionListGroup>
                                    </DescriptionList>
                                </GridItem>
                                {/* Right Section */}
                                <GridItem lg={6} md={6} sm={12}>
                                    <DescriptionList isHorizontal>
                                        {/* Fields with Options */}
                                        {fields.filter(field => field.options).map((field, index) => (
                                            <DescriptionListGroup key={index}>
                                                <DescriptionListTerm className="pf-v5-u-font-weight-bold">
                                                    {field.label}:
                                                </DescriptionListTerm>
                                                <DescriptionListDescription>
                                                    {editingField === field.name ? (
                                                        <InputGroup>
                                                            <ToggleGroup
                                                                isCompact
                                                                style={{ maxWidth: '700px', flexWrap: 'wrap', overflowX: 'auto' }}
                                                                className="custom-toggle-group"
                                                            >
                                                                {field.options.map((option, idx) => (
                                                                    <ToggleGroupItem
                                                                        key={idx}
                                                                        text={
                                                                            <div
                                                                                style={{
                                                                                    backgroundColor: inputValue === option ? extendedTlpColorMap[option]?.backgroundColor || '#fff' : '#fff', // Show color only when selected
                                                                                    color: inputValue === option ? extendedTlpColorMap[option]?.color || '#000' : '#000',
                                                                                    padding: '2px 20px',
                                                                                    borderRadius: '4px',
                                                                                }}
                                                                            >
                                                                                {option}
                                                                            </div>
                                                                        }
                                                                        isSelected={inputValue === option}
                                                                        onChange={() => handleToggleChange(field.name, option)}
                                                                    />
                                                                ))}
                                                                <InputGroupItem className="input-group-item">
                                                                    <Button variant="plain" onClick={() => handleSaveClick(field.name)}>
                                                                        <CheckIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                                <InputGroupItem className="input-group-item">
                                                                    <Button variant="plain" onClick={handleCancelClick}>
                                                                        <TimesIcon />
                                                                    </Button>
                                                                </InputGroupItem>
                                                            </ToggleGroup>
                                                        </InputGroup>
                                                    ) : (
                                                        <Badge
                                                            style={{
                                                                backgroundColor: extendedTlpColorMap[field.value]?.backgroundColor || '#fff',
                                                                color: extendedTlpColorMap[field.value]?.color || '#000',
                                                                padding: '2px 20px',
                                                                borderRadius: '2px',
                                                                fontSize: '13px',
                                                            }}
                                                        >
                                                            {field.value}
                                                        </Badge>
                                                    )}
                                                    {
                                                        isEditMode && userRole !== 'Analyst' &&
                                                        <Button
                                                            variant="plain"
                                                            onClick={() => handleEditClick(field.name)}
                                                            style={{ display: editingField === field.name ? 'none' : 'inline-flex' }}
                                                        >
                                                            <EditIcon />
                                                        </Button>
                                                    }
                                                </DescriptionListDescription>
                                            </DescriptionListGroup>
                                        ))}
                                        {/* Read Staus */}
                                        <DescriptionListGroup>
                                            <DescriptionListTerm className="pf-v5-u-font-weight-bold">Read Status:</DescriptionListTerm>
                                            <DescriptionListDescription>
                                                <Badge style={{
                                                    backgroundColor: statusBadge.color, padding: '2px 20px',
                                                    borderRadius: '2px',
                                                    fontSize: '13px',
                                                }}>{statusBadge.text}</Badge>
                                            </DescriptionListDescription>
                                        </DescriptionListGroup>
                                        <DescriptionListGroup>
                                            <DescriptionListTerm className="pf-v5-u-font-weight-bold">
                                                <Title headingLevel="h6">Investigator:</Title>
                                            </DescriptionListTerm>
                                            <DescriptionListDescription>
                                                {!editMode.investigator ? (
                                                    <>
                                                        <span>{createUserName || 'No investigator selected'}</span>
                                                        {isEditMode && userRole !== 'Analyst' && (
                                                            <Button variant="plain" onClick={handleinvEditClick}>
                                                                <EditIcon />
                                                            </Button>
                                                        )}
                                                    </>
                                                ) : (

                                                    <>
                                                        <CommonSelectBox
                                                            options={investigatorList.map((item) => ({
                                                                value: item.user_id,
                                                                children: item.name,
                                                            }))}
                                                            selectedOptions={selectedInvestigatorValue}
                                                            updateValue={handleUpdateInvestigatorValue}
                                                            placeholder="Select Investigator"
                                                        />
                                                        <Button variant="plain" onClick={handleinvEditClick} className="input-group-item">
                                                            <CheckIcon /> {/* Check icon */}
                                                        </Button>
                                                    </>
                                                )}
                                            </DescriptionListDescription>
                                        </DescriptionListGroup>
                                        {/* Tags Field */}
                                        <DescriptionListGroup>
                                            <DescriptionListTerm className="pf-v5-u-font-weight-bold">
                                                <Title headingLevel="h6">Tags:</Title>
                                            </DescriptionListTerm>
                                            <DescriptionListDescription>
                                                {showtags ? (
                                                    <>
                                                        <ChipGroup numChips={3}>
                                                            {tags.map((tag) => (
                                                                <Chip key={tag.tag} isReadOnly>
                                                                    {tag.tag}
                                                                </Chip>
                                                            ))}
                                                        </ChipGroup>
                                                        {
                                                            isEditMode && userRole !== 'Analyst' &&
                                                            <Button variant="plain" onClick={() => setShowtags(false)}>
                                                                <EditIcon />
                                                            </Button>
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        <TextInputGroup className="pf-v5-u-w-100">
                                                            <TextInputGroupMain
                                                                value={inputTag}
                                                                onChange={handleInputTags}
                                                                onKeyDown={handleTextInputKeyDown}
                                                                placeholder="Enter a new tag"
                                                                aria-label="tag-input"
                                                            >
                                                                <ChipGroup numChips={3}>
                                                                    {tags.map((tag) => (
                                                                        <Chip key={tag.uniqueId} onClick={() => deleteTag(tag.uniqueId)}>
                                                                            {tag.tag}
                                                                        </Chip>
                                                                    ))}
                                                                </ChipGroup>
                                                            </TextInputGroupMain>
                                                            {showUtilities && (
                                                                <TextInputGroupUtilities>
                                                                    {showClearButton && (
                                                                        <Button variant="plain" onClick={clearTagsAndInput} aria-label="Clear button for tags and input">
                                                                            <TimesIcon />
                                                                        </Button>
                                                                    )}
                                                                </TextInputGroupUtilities>
                                                            )}
                                                        </TextInputGroup>
                                                        <Button variant="plain" onClick={handleAddTag}>
                                                            <CheckIcon />
                                                        </Button>
                                                        <Button variant="plain" onClick={() => setShowtags(true)}>
                                                            <TimesIcon />
                                                        </Button>
                                                    </>
                                                )}
                                            </DescriptionListDescription>
                                        </DescriptionListGroup>
                                    </DescriptionList>
                                </GridItem>
                            </Grid>
                        </>
                    )}
                </CardBody>
            </Card >
        </div >
    );
};

export default IncidentDetail;
