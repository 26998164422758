import React, { useState, useRef, useEffect } from 'react';
import {
    Tabs,
    Tab,
    TabTitleText,
    TabContentBody,
    Card,
    Divider,
    CardBody,
    TextContent,
    FlexItem,
    Flex,
    Text,
    TextVariants,
    ModalVariant,
    CardHeader,
    Button,
    Badge,
    Tooltip,
    NotificationBadge,
    NotificationBadgeVariant,
    LabelGroup,
    Label,
} from '@patternfly/react-core';
import IncidentDetail from './IncidentDetail';
import { ArrowCircleLeftIcon, ChatIcon, ChessKnightIcon, ExclamationTriangleIcon, HistoryIcon, FolderOpenIcon, OutlinedClockIcon, PushedIcon, TaskIcon, ThumbsUpIcon, ThumbTackIcon, CalendarAltIcon, BellIcon, ChatIconConfig } from '@patternfly/react-icons';
import IncidentDetailTask from './IncidentDetailTask';
import ModalCommon from 'src/Components/CommonEventPages/ModalCommon';
import IncidentCommentModal from '../IncidentComment/IncidentCommentModal';
import { Link, useParams } from 'react-router-dom';
import dbApi from 'src/services/DbApi';
import { TextSkeleton } from 'src/util/Loadingstate';
import IncidentObservableList from '../incidentObservable/IncidentObservableList';
import IncidentTtpList from '../IncidentTtp/IncidentTtpList';
import IncidentTaskDetail from '../IncidentTaskDetail/IncidentTaskDetail';
import IncidentDetailAlert from './IncidentDetailAlert';
import IncidentAlertList from '../IncidentAlert/IncidentAlertList';
import { formatTableTimestamp } from 'src/util/Timeutils';
import LogHistoryList from './LogHistoryList';


const IncidentDetailMain = () => {
    const { id, taskid, alertid } = useParams();
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [incidentDetails, setIncidentDetails] = useState({});
    const [alertDetail, setAlertDetails] = useState({});
    const childIncidentCommentModalRef = useRef(null);
    const [isTaskTabShow, setIsTaskTabShow] = useState(false);
    const [isAlertTabShow, setIsAlertTabShow] = useState(false);
    const [taskTitle, setTaskTitle] = useState('');
    const [alertDetailData, setAlertDetailData] = useState([]);

    const handleAlertDetailFetched = (data) => {
        setAlertDetailData(data);
        console.log('Alert detail received from child:', data);
    };

    const handleTabClick = (_event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };
    const severityMap = {
        H: 'High',
        L: 'Low',
        M: 'Medium',
        C: 'Critical'
    };

    const badgeColors = {
        GREEN: '#008000',
        AMBER: '#ffbf00',
        RED: '#C9190B',
        WHITE: '#00c0ef',
        M: '#ffbf00', // Severity
        L: '#00c0ef', // Severity
        H: '#C9190B',  // Severity
        C: '#8B0000', // Severity
        Waiting: '#C9190B', // Status
        InProgress: 'blue', // Status
        Complete: 'green', // Status
        Open: '#F0AB00', // Status
        Closed: '#3E8635' // Status
    };


    const getBadgeColor = (value, type) => {
        switch (type) {
            case 'severity':
                return badgeColors[value] ? { backgroundColor: badgeColors[value], color: '#fff' } : { backgroundColor: 'grey', color: '#fff' };
            case 'tlp':
                return badgeColors[value] ? { backgroundColor: badgeColors[value], color: '#fff' } : { backgroundColor: 'grey', color: '#fff' };
            case 'status':
                return badgeColors[value] ? { backgroundColor: badgeColors[value], color: '#fff' } : { backgroundColor: 'grey', color: '#fff' };
            default:
                return { backgroundColor: 'grey', color: '#fff' };
        }
    };


    const updateIncidentReadStatus = async (data) => {
        try {
            const res = await dbApi.putApi('incident_management/update_incident', data);
            return res;
        } catch (error) {
            console.error('Error fetching incident details:', error);
        }
    }

    const getIncidentDetail = async () => {
        try {
            setIsLoading(true);
            const res = await dbApi.postApi('incident_management/get_incident', { incidentId: id });
            if (res.data && res.data.length > 0) {
                const data = res.data[0]
                if (res.data[0]['read_status'] == 0) {
                    const updateRes = await updateIncidentReadStatus({ incidentId: data.incident_id, readStatus: 1 });
                    if (!updateRes.error) {
                        data.read_status = 1;
                    }
                }
                setIncidentDetails(data);

            }
        } catch (error) {
            console.error('Error fetching incident details:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getIncidentDetail();
    }, [id]);

    useEffect(() => {
        if (taskid) {
            setIsTaskTabShow(true);
            setActiveTabKey(5);
        }
    }, [taskid]);


    const updateAlert = async (data) => {
        try {
            const res = await dbApi.putApi('incident_management/update_alert', data);
            return res;
        } catch (error) {
            console.error('Error fetching incident details:', error);
        }
    }

    const getAlertDetail = async (alertId) => {
        try {
            setIsLoading(true);
            const res = await dbApi.postApi('incident_management/get_alert', { uniqueId: alertId });
            if (res.data && res.data.length > 0) {
                const data = res.data[0];
                if (res.data[0]['read_status'] == 0) {
                    const updateRes = await updateAlert({ uniqueId: res.data[0]['unique_id'], readStatus: 1 });
                    if (!updateRes.error) {
                        data.read_status = 1;
                    }
                }
                setAlertDetails(data);
            }
        } catch (error) {
            console.error('Error fetching incident details:', error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (alertid) {
            setIsAlertTabShow(true);
            setActiveTabKey(6);
            getAlertDetail(alertid)
        }
    }, [alertid]);
    const { formattedTimeStamp: startDatetime } = incidentDetails.start_datetime
        ? formatTableTimestamp(incidentDetails.start_datetime)
        : { formattedTimeStamp: ' ' };

    const severityLabel = severityMap[incidentDetails.severity] || incidentDetails.severity; // Map to descriptive label
    return (
        <div>
            {isLoading ? (
                <TextSkeleton />
            ) : (
                <div className="pf-v5-u-p-sm">
                    <Card isFullHeight>
                        <CardBody>
                            <Flex>
                                <FlexItem>
                                    <TextContent>
                                        <Text component={TextVariants.h3}>
                                            <strong>ID : </strong>{incidentDetails.incident_id}
                                        </Text>
                                    </TextContent>
                                </FlexItem>
                                <FlexItem>
                                    <TextContent>
                                        <Text component={TextVariants.h2} className='pf-v5-u-font-weight-bold'>
                                            - {incidentDetails.title}
                                        </Text>
                                    </TextContent>
                                </FlexItem>
                                <FlexItem align={{ default: 'alignRight' }}>
                                    <Tooltip content="Back to incident management">
                                        <Link to='/incident-management/incident'>
                                            <Button variant="primary" size="sm">
                                                <ArrowCircleLeftIcon />
                                            </Button>
                                        </Link>
                                    </Tooltip>
                                </FlexItem>
                            </Flex>
                            <Flex>
                                <FlexItem>
                                    <TextContent>
                                        <Text component={TextVariants.h4}>
                                            <CalendarAltIcon /> {startDatetime}
                                        </Text>
                                    </TextContent>
                                </FlexItem>
                                <FlexItem>
                                    <TextContent>
                                        <Text component={TextVariants.h4}>
                                            <OutlinedClockIcon /> {incidentDetails.arrive_time}
                                        </Text>
                                    </TextContent>
                                </FlexItem>
                                <FlexItem>
                                    <TextContent>
                                        <Text component={TextVariants.h2}>
                                            <NotificationBadge
                                                variant={NotificationBadgeVariant.unread} // or NotificationBadgeVariant.unread based on your use case
                                                onClick={() => childIncidentCommentModalRef.current.setModalBox(true)}
                                            >
                                                <ChatIcon />
                                            </NotificationBadge>
                                        </Text>
                                    </TextContent>
                                </FlexItem>
                            </Flex>
                            <Flex>
                                <FlexItem>
                                    <Tooltip content={`Severity : ${severityLabel}`} position="bottom">
                                        <Badge isRead={false} style={getBadgeColor(incidentDetails.severity, 'severity')}>
                                            {incidentDetails.severity}
                                        </Badge>
                                    </Tooltip>
                                </FlexItem>
                                <FlexItem>
                                    <Tooltip content={`PAP : ${incidentDetails.pap_amber}`} position="bottom">
                                        <Badge isRead={false} style={getBadgeColor(incidentDetails.pap_amber, 'tlp')}>
                                            {incidentDetails.pap_amber}
                                        </Badge>
                                    </Tooltip>
                                </FlexItem>
                                <FlexItem>
                                    <Tooltip content={`TLP : ${incidentDetails.tlp_amber}`} position="bottom">
                                        <Badge isRead={false} style={getBadgeColor(incidentDetails.tlp_amber, 'tlp')}>
                                            {incidentDetails.tlp_amber}
                                        </Badge>
                                    </Tooltip>
                                </FlexItem>
                                <FlexItem>
                                    <Tooltip content={`Status : ${incidentDetails.status}`} position="bottom">
                                        <Badge isRead={false} style={getBadgeColor(incidentDetails.status, 'status')}>
                                            {incidentDetails.status}
                                        </Badge>
                                    </Tooltip>
                                </FlexItem>
                            </Flex>
                            <div className='pf-v5-u-mt-sm'>
                                {alertDetailData && alertDetailData.length > 0 ? (
                                    <LabelGroup numLabels={3} isCompact>
                                        {alertDetailData.map((item, index) => (
                                            <Label
                                                key={"label" + index}
                                                isCompact
                                                color="blue"
                                            >
                                                {item?.agent_name} ({item?.agent_id})
                                            </Label>
                                        ))}
                                    </LabelGroup>
                                ) : (
                                    null
                                )}
                            </div>
                        </CardBody>
                    </Card>
                    <Divider />

                    <div className="pf-v5-u-mt-sm">
                        <Card>
                            <CardBody>
                                <Tabs variant="light300" activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs-individual" role="region" isOverflowHorizontal width={40}>
                                    <Tab eventKey={0} title={<TabTitleText className="pf-v5-u-font-weight-bold"><FolderOpenIcon />  Details</TabTitleText>} aria-label="Horizontal overflow content users">
                                        {activeTabKey === 0 && (
                                            <TabContentBody >
                                                {incidentDetails ? (
                                                    <IncidentDetail incidentDetails={incidentDetails} onAlertDetailFetched={handleAlertDetailFetched} />
                                                ) : (
                                                    <p>Loading...</p>
                                                )}
                                            </TabContentBody>
                                        )}
                                    </Tab>
                                    <Tab eventKey={1} title={<TabTitleText className="pf-v5-u-font-weight-bold"><TaskIcon /> Tasks</TabTitleText>} aria-label="Horizontal overflow content users">
                                        {activeTabKey === 1 && (
                                            <TabContentBody >
                                                <IncidentDetailTask />
                                            </TabContentBody>
                                        )}
                                    </Tab>
                                    <Tab eventKey={2} title={<TabTitleText className="pf-v5-u-font-weight-bold"><ThumbTackIcon /> Observables</TabTitleText>} aria-label="Horizontal overflow content users">
                                        {activeTabKey === 2 && (
                                            <TabContentBody>
                                                <IncidentObservableList />
                                            </TabContentBody>
                                        )}
                                    </Tab>
                                    <Tab eventKey={3} title={<TabTitleText className="pf-v5-u-font-weight-bold"><ChessKnightIcon /> TTPs</TabTitleText>} aria-label="Horizontal overflow content users">
                                        {activeTabKey === 3 && (
                                            <TabContentBody>
                                                <IncidentTtpList />
                                            </TabContentBody>
                                        )}
                                    </Tab>
                                    <Tab eventKey={4} title={<TabTitleText className="pf-v5-u-font-weight-bold"><ExclamationTriangleIcon />  Alert</TabTitleText>} aria-label="Horizontal overflow content users">
                                        {activeTabKey === 4 && (
                                            <TabContentBody>
                                                {id && <IncidentAlertList incidentId={id} />}
                                            </TabContentBody>
                                        )}
                                    </Tab>

                                    {isTaskTabShow && (
                                        <Tab
                                            eventKey={5}
                                            title={<TabTitleText className="pf-v5-u-font-weight-bold"><TaskIcon /> {taskTitle || 'Task Detail'}</TabTitleText>}
                                            aria-label="Horizontal overflow content users"
                                        >
                                            {activeTabKey === 5 && (
                                                <TabContentBody>
                                                    <IncidentTaskDetail />
                                                </TabContentBody>
                                            )}
                                        </Tab>
                                    )}

                                    {
                                        isAlertTabShow && <Tab eventKey={6} title={<TabTitleText className="pf-v5-u-font-weight-bold"><ExclamationTriangleIcon /> Alert - {typeof alertDetail?.rule_description != "undefined" && alertDetail?.rule_description} </TabTitleText>} aria-label="Horizontal overflow content users">
                                            {activeTabKey === 6 && (
                                                <TabContentBody>
                                                    <IncidentDetailAlert alertData={alertDetail} />
                                                </TabContentBody>
                                            )}
                                        </Tab>
                                    }

                                    <Tab eventKey={7} title={<TabTitleText className="pf-v5-u-font-weight-bold"><HistoryIcon />  Log History</TabTitleText>} aria-label="Horizontal overflow content users">
                                        {activeTabKey === 7 && (
                                            <TabContentBody>
                                                {id && <LogHistoryList incidentId={id} />}
                                            </TabContentBody>
                                        )}
                                    </Tab>
                                </Tabs>
                            </CardBody>
                        </Card>
                    </div>
                    <ModalCommon
                        title="Comments on Alert"
                        onClose={() => childIncidentCommentModalRef.current.setModalBox(false)}
                        variant={ModalVariant.medium}
                        ref={childIncidentCommentModalRef}
                    >
                        <IncidentCommentModal />
                    </ModalCommon>
                </div>
            )}
        </div>
    );
};

export default IncidentDetailMain;
