import React, { useEffect, useState } from 'react'
import { ActionGroup, Button, Form, FormGroup, TextInput } from '@patternfly/react-core';
import { emptyCheck } from 'src/util/Validate';
import { connect, useDispatch } from 'react-redux';
import { alertBoxData } from 'src/Redux/Action';
import dbApi from '../../services/DbApi'
import ConfirmBox from '../ConfirmBox/ConfirmBox';
import { Loader } from 'src/util/Loadingstate';
const TapRegistrationForm = (props) => {
    const dispatch = useDispatch();
    const [tapname, setTapName] = useState('');
    const [validTapName, setValidTapName] = useState('');
    const [tapIp, setTapIp] = useState('');
    const [validTapIp, setValidTapIp] = useState('');
    const [tapUserName, setTapUserName] = useState('');
    const [validtapUserName, setValidTapUserName] = useState('');
    const [tapUserPass, setTapUserPass] = useState('');
    const [validtapUserPass, setValidTapUserPass] = useState('');
    const [taplocation, setTapLocation] = useState('');
    const [validtapLocation, setValidTapLocation] = useState('');
    const [actionType, setActionType] = useState('Add')
    const [orgUniqueID, setOrgUniqueID] = useState('')
    const [openorganization, setOpenOrganization] = useState('')
    const [isloading, setIsLoading] = useState(false);
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })
    useEffect(() => {
        if (props.OrgUniqueID !== '' && props.OrgName !== '') {
            setOrgUniqueID(props.OrgUniqueID)
            setOpenOrganization(props.OrgName)
        }
    }, [props.OrgUniqueID, props.OrgName])

    const getTapDetail = async () => {
        try {
            let param = {
                org_unique_id: orgUniqueID
            }
            setIsLoading(true)
            let res = await dbApi.postApi("Opensearch/getTap", param)
            console.log(res)
            if (res.getTap.length == 0) {
                setActionType('Add')
            }
            else {
                setActionType('Edit')
                const detail = res.getTap[0]
                setTapName(detail.tap_name)
                setTapIp(detail.tap_ip)
                setTapUserName(detail.username)
                setTapUserPass(detail.password)
                setTapLocation(detail.location_detail)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (orgUniqueID != '') { getTapDetail() }
    }, [orgUniqueID])

    const fieldChange = (value, type) => {
        switch (type) {
            case "tapname":
                setTapName(value)
                setValidTapName(emptyCheck(value))
                break;
            case "tapIp":
                setTapIp(value)
                setValidTapIp(emptyCheck(value))
                break;
            case "tapUserName":
                setTapUserName(value)
                setValidTapUserName(emptyCheck(value))
                break;
            case "tapUserPass":
                setTapUserPass(value)
                setValidTapUserPass(emptyCheck(value))
                break;
            case "taplocation":
                setTapLocation(value)
                setValidTapLocation(emptyCheck(value))
                break;
        }
    };

    function formValid() {
        let isValid = true;
        let status = emptyCheck(tapname)
        setValidTapName(status)
        if (status == 'error') {
            isValid = false
        }
        status = emptyCheck(tapIp)
        setValidTapIp(status)
        if (status == 'error') {
            isValid = false
        }
        status = emptyCheck(tapUserName)
        setValidTapUserName(status)
        if (status == 'error') {
            isValid = false
        }
        status = emptyCheck(tapUserPass)
        setValidTapUserPass(status)
        if (status == 'error') {
            isValid = false
        }
        status = emptyCheck(taplocation)
        setValidTapLocation(status)
        if (status == 'error') {
            isValid = false
        }

        return isValid
    }
    const finalSubmit = async () => {
        let params = {
            org_unique_id: orgUniqueID,
            tap_name: tapname,
            tap_ip: tapIp,
            username: tapUserName,
            password: tapUserPass,
            location_detail: taplocation
        }
        console.log(params)
        let apiurl = ''
        if (actionType == 'Add') {
            apiurl = 'Opensearch/insertTap'
        }
        else {
            apiurl = 'Opensearch/updateTap'
        }
        const res = await dbApi.postApi(apiurl, params)
        console.log(res)
        if (!res.error) {
            // if (actionType == 'Add') {
            //     props.updatemodalstate(true);
            // }else if(actionType=='Edit'){
            //     getTapDetail()
            // }
            dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
            props.updatemodalstate(false);
        }
        else {
            dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]))
        }
    }
    const submitForm = () => {
        if (actionType == 'Add') {
            if (formValid()) {
                setConfirmBox({
                    show: true,
                    msg: 'Add Tap Details.',
                    funCall: finalSubmit,
                    title: 'Are you sure? ',
                    position: 'default',
                    iconvariant: 'info',
                    submitbtnvariant: 'primary',
                })
            }
        }
        else {
            setConfirmBox({
                show: true,
                msg: `This edit cant't be reversed.`,
                funCall: finalSubmit,
                title: 'Are you sure? ',
                position: 'default',
                iconvariant: 'info',
                submitbtnvariant: 'primary',
            })
        }
    }

    const onCancelForm = () => {
        props.updatemodalstate(false);
    }
    return (
        <div>
            {isloading ? <Loader /> :
                <div>
                    <ConfirmBox confirmModal={confirmModalData} />
                    <Form>
                        <FormGroup fieldId="tapname" label='TAP Name' isRequired>
                            <TextInput isRequired type="text" id="tapname" name="tapname" value={tapname} validated={validTapName} onChange={(event, value) => fieldChange(value, 'tapname')} aria-describedby="tapname-helper" />
                        </FormGroup>
                        <FormGroup fieldId="tapIp" label='TAP IP' isRequired>
                            <TextInput isRequired type="text" id="tapIp" name="tapIp" value={tapIp} validated={validTapIp} onChange={(event, value) => fieldChange(value, 'tapIp')} />
                        </FormGroup>
                        <FormGroup fieldId="tapUserName" label='TAP User Name' isRequired>
                            <TextInput isRequired type="text" id="tapUserName" name="tapUserName" value={tapUserName} validated={validtapUserName} onChange={(event, value) => fieldChange(value, 'tapUserName')} />
                        </FormGroup>
                        <FormGroup fieldId="tapUserPass" label='TAP User Password' isRequired>
                            <TextInput isRequired type="text" id="tapUserPass" name="tapUserPass" value={tapUserPass} validated={validtapUserPass} onChange={(event, value) => fieldChange(value, 'tapUserPass')} />
                        </FormGroup>
                        <FormGroup fieldId="taplocation" label='TAP Location' isRequired>
                            <TextInput isRequired type="text" id="taplocation" name="taplocation" value={taplocation} validated={validtapLocation} onChange={(event, value) => fieldChange(value, 'taplocation')} />
                        </FormGroup>
                        <ActionGroup>
                            <Button variant="primary" onClick={() => submitForm()}>{actionType == 'Add' ? 'Submit' : 'Update'}</Button>
                            <Button variant="link" onClick={() => onCancelForm()}>Cancel</Button>
                        </ActionGroup>
                    </Form>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({})

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(TapRegistrationForm)