import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';
import { calculateTimeDifference, formatTime, formatTimestamp } from 'src/util/Timeutils';
import { connect } from 'react-redux';
import { formatValue } from 'src/util/ChartHelper';

const IncidentScatterChart = (props) => {
    const id = generateUniqueID();

    useEffect(() => {
        if (props.EventSeries && props.EventSeries.length > 0) {
            const chartDom = document.getElementById(id + 'scatter');
            const myChart = echarts.init(chartDom);

            const textcolor = props.theme === 'dark-theme' ? '#fff' : '#000';
            const linecolor = props.theme === 'dark-theme' ? '#595757' : '#d9d9d9';

            const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(props.StartDateTime, props.EndDateTime);
            const uniqueDatesSet = new Set();
            const scatterData = [];
            const Label = props.label;
            for (let i = 0; i < props.EventSeries.length; i++) {
                const item = props.EventSeries[i];
                const xvalue = item.time_bucket;
                const total = item.total ?? 0; // y-axis value (Severity)
                uniqueDatesSet.add(xvalue);
                scatterData.push([xvalue, total]);  // For scatter [time_bucket, time_diff]
            }


            const uniqueDatesArray = [...uniqueDatesSet];
            const xAxisData = [];
            for (let k = 0; k < uniqueDatesArray.length; k++) {
                const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(uniqueDatesArray[k]);
                const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
                xAxisData.push({ x: formattedTime });
            }


            const allCounts = props.EventSeries.flatMap(item => [
                item.total_low ?? 0,
                item.total_medium ?? 0,
                item.total_high ?? 0,
                item.total_critical ?? 0
            ]);
            const minSize = 5;
            const maxSize = 50;
            const minCount = Math.min(...allCounts);
            const maxCount = Math.max(...allCounts);

            const calculateSymbolSize = (count) => {
                if (maxCount === minCount) {
                    return minSize;
                }
                return minSize + (maxSize - minSize) * (count - minCount) / (maxCount - minCount);
            };
            let interval = '';
            let lineheight = '';
            if (Label === '30 seconds') {
                interval = 4;
                lineheight = 100;
            } else if (Label === 'hour') {
                interval = 3;
                lineheight = 95;
            } else {
                interval = 0;
                lineheight = 200;
            }

            const SeriesData = [
                {
                    name: 'Low',
                    type: 'scatter',
                    emphasis: {
                        focus: 'series',
                    },
                    itemStyle: {
                        color: '#00c0ef',
                    },
                    data: props.EventSeries.map(item => ({
                        value: item.total_low ?? 0,
                        symbolSize: calculateSymbolSize(item.total_low ?? 0), // Replace with your size calculation logic
                    })),
                },
                {
                    name: 'Medium',
                    type: 'scatter',
                    emphasis: {
                        focus: 'series',
                    },
                    itemStyle: {
                        color: '#ffbf00',
                    },
                    data: props.EventSeries.map(item => ({
                        value: item.total_medium ?? 0,
                        symbolSize: calculateSymbolSize(item.total_medium ?? 0), // Replace with your size calculation logic
                    })),
                },
                {
                    name: 'High',
                    type: 'scatter',
                    emphasis: {
                        focus: 'series',
                    },
                    itemStyle: {
                        color: '#C9190B',
                    },
                    data: props.EventSeries.map(item => ({
                        value: item.total_high ?? 0,
                        symbolSize: calculateSymbolSize(item.total_high ?? 0), // Replace with your size calculation logic
                    })),
                },
                {
                    name: 'Critical',
                    type: 'scatter',
                    emphasis: {
                        focus: 'series',
                    },
                    itemStyle: {
                        color: '#8B0000',
                    },
                    data: props.EventSeries.map(item => ({
                        value: item.total_critical ?? 0,
                        symbolSize: calculateSymbolSize(item.total_critical ?? 0), // Replace with your size calculation logic
                    })),
                },
            ];
            const option = {
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        color: "#000"
                    },
                    axisPointer: {
                        type: 'shadow'
                    },
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 'right',
                    top: 10,
                    itemWidth: 9.5,
                    itemHeight: 10,
                    itemGap: 6.5,
                    icon: "rect",
                    textStyle: {
                        width: 160,
                        overflow: 'truncate',
                        fontSize: 12,
                        color: textcolor
                    }
                },
                grid: {
                    left: 0,
                    right: '9%',
                    bottom: '10%',
                    top: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisLabel: { interval: interval, rotate: -60, color: textcolor },
                    boundaryGap: true,
                    data: xAxisData.map(item => item.x),
                    axisTick: {
                        alignWithLabel: true
                    },
                    name: `Timestamp per ${Label}`,
                    nameLocation: "middle",
                    nameTextStyle: {
                        align: "center",
                        lineHeight: lineheight,
                        fontSize: 14,
                        color: textcolor,
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: (value) => {
                            return Math.floor(value); // Ensures only whole numbers are displayed
                        },
                        color: textcolor
                    },
                    splitLine: { lineStyle: { color: linecolor } },
                    axisLine: { show: true },
                    lineHeight: 60,
                },
                series: SeriesData,
            };

            option && myChart.setOption(option);

            const resizeChart = () => {
                if (myChart) {
                    myChart.resize();
                }
            };

            window.addEventListener('resize', resizeChart);
            return () => {
                myChart.dispose();
                window.removeEventListener('resize', resizeChart);
            };
        }
    }, [props.Label, props.StartDateTime, props.EndDateTime, props.EventSeries, props.theme, props.sidebarState]);

    return <div id={id + 'scatter'} style={{ width: '100%', height: '270px' }} />;
};

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentScatterChart);
