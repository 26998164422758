
import React, { useState, useEffect } from 'react';
import {
    MenuToggle,
    Select,
    SelectOption,
    SelectList,
    SelectGroup,
    Toolbar,
    ToolbarContent,
    ToolbarGroup,
    ToolbarItem,
    Pagination,
    TextVariants,
    Text,
    Badge,
    SearchInput,
    Checkbox,
    TextListVariants,
    TextList,
    TextListItem,
    TextListItemVariants,
    TextContent,
} from '@patternfly/react-core';
import { Table, Thead, Tr, Th, Tbody, Td, InnerScrollContainer, ExpandableRowContent } from '@patternfly/react-table';
import SortAmountDownIcon from '@patternfly/react-icons/dist/esm/icons/sort-amount-down-icon';
import { formatTableTimestamp } from "src/util/Timeutils";
import { Emptystatefornodata } from "src/util/EmptyStateCard";
import { Link } from 'react-router-dom';
import dbApi from 'src/services/DbApi';
import { Loader } from 'src/util/Loadingstate';
import { connect, useDispatch } from 'react-redux';
import { UpdateFilterData } from 'src/util/FilterHelper';
import { FilterData } from 'src/Redux/Action';

const LatestScaPfLog = (props) => {
    const defaultColumns = {
        timestamp: "Time",
        agent_name: "Agent Name",
        rule_description: "Rule Description",
    };
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [columns, setColumns] = useState(defaultColumns);
    const [rows, setRows] = useState([]);
    const [selectedRowName, setSelectedRowName] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(props.IsModal ? 10 : 5);
    const [inputValue, setInputValue] = useState('')
    const [searchValue, setSearchValue] = useState('');
    const [activeSortIndex, setActiveSortIndex] = useState(0);
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
    const [totalItem, setTotalItem] = useState(0)
    const [activeSortDirection, setActiveSortDirection] = useState('desc');
    const [expandedRowNames, setExpandedRowNames] = useState([])
    const [expandedRowData, setExpandedRowData] = useState({});
    const [dataFilters, setDataFilters] = useState({
        status: ['Passed', 'Failed'],
    })

    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }

    const handleCheckChange = (event, checked, type) => {
        const checkboxId = event.target.id;
        let temp = dataFilters
        if (checked) {
            temp[type].push(checkboxId)
        } else {
            temp[type] = temp[type].filter(obj => obj != checkboxId)
        }
        setDataFilters(prevDataFilters => ({
            ...prevDataFilters,
            temp
        }));
        setPage(1)
    }
    //Get agents by calling API
    const getScaTotalLog = async (data) => {
        let status = []
        if (dataFilters.status.length > 0) {
            status = dataFilters.status
        }
        let param = {
            ...data,
            limit_start: (perPage * page) - perPage,
            limit_length: perPage,
            search: searchValue,
            sortBy: Object.keys(columns)[activeSortIndex],
            sortOrder: activeSortDirection,
            checkresult: status,
        }
        try {
            setIsLoading(true)
            let res = await dbApi.postApi("Opensearch/Sca_List ", param)
            console.log("Sca res1", res)
            let AllScaLog = []
            if (res.Sca_List.data.length > 0) {
                AllScaLog = res.Sca_List.data
                setRows(AllScaLog)
                setTotalItem(res.Sca_List.totalRecord)

                setIsLoading(false)
            }
            else {
                setRows([])
                setTotalItem(0)
                setIsLoading(false)
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        let staticfield = []
        if (props?.ScaFieldsgroup == 'cis') {
            staticfield = [{
                "fields": 'rule.rbi',
                'condition': 'does_not_exist',
            }]
        }
        else if (props?.ScaFieldsgroup == 'rbi') {
            staticfield = [{
                "fields": 'rule.rbi',
                'condition': 'exists',
            }]
        }
        else {
            staticfield = staticfield
        }
        const Fields = [...props.filterformdata, ...staticfield]
        const data = {
            startDate: props.dates.startDate,
            endDate: props.dates.endDate,
            filter: Fields.length > 0 ? JSON.stringify(Fields) : "[]",
        };
        if (props.dates.startDate != "") {
            getScaTotalLog(data)
        }
    }, [
        page,
        perPage,
        activeSortIndex,
        activeSortDirection,
        searchValue,
        dataFilters,
        props?.dates.startDate,
        props?.dates.endDate,
        props?.filterformdata,
        props?.selectedGroup,
        props?.ScaFieldsgroup
    ])

    //sorting
    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };
    // For Expansion
    const setRowExpanded = (row, isExpanding) => {
        const otherExpandedRowNames = expandedRowNames.filter((r) => r !== row.s_id);
        //for all row expansion simultaneously
        setExpandedRowNames(
            isExpanding ? [row.s_id] : []
        );
        if (!expandedRowData[row.s_id]) {
            const keyifiedData = keyify(row);
            setExpandedRowData({ [row.s_id]: keyifiedData });
        }
        const updatedExpandedRowNames = isRowExpanded(row)
            ? otherExpandedRowNames : [row.s_id];
        setExpandedRowNames(updatedExpandedRowNames);

    };
    const isRowExpanded = (row) => {
        return expandedRowNames.includes(row.s_id);
    };

    //Formatting for Expansion
    const keyify = (row) => {
        if (!row || !row.data_sca_check_id) {
            return [{ key: '', value: 'No details available' }];
        }
        // const { compliance } = row?.check || {};
        const rationale = row?.data_sca_check_rationale || 'N/A';
        const remediation = row?.data_sca_check_remediation || 'N/A';
        const description = row?.data_sca_check_description || 'N/A';
        const registry = row?.data_sca_check_registry || 'N/A';
        const command = row?.data_sca_check_command || 'N/A';
        const directory = row?.data_sca_check_directory || 'N/A';
        const process = row?.data_sca_check_process || 'N/A';
        const file = row?.data_sca_check_file || 'N/A';

        let targetsection;
        if (command !== 'N/A') {
            targetsection = (
                <TextList component={TextListVariants.dl}>
                    <TextListItem component={TextListItemVariants.dt}>Command: </TextListItem>
                    <TextListItem component={TextListItemVariants.dd}><div><Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" }}>{command}</Badge></div></TextListItem>
                </TextList>
            );
        } else if (registry !== 'N/A') {
            targetsection = (
                <TextList component={TextListVariants.dl}>
                    <TextListItem component={TextListItemVariants.dt}>Registry:</TextListItem>
                    <TextListItem component={TextListItemVariants.dd}><div><Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" }}>{registry}</Badge></div></TextListItem>
                </TextList>
            );
        }
        else if (file !== 'N/A') {
            targetsection = (
                <TextList component={TextListVariants.dl}>
                    <TextListItem component={TextListItemVariants.dt}>File:</TextListItem>
                    <TextListItem component={TextListItemVariants.dd}><div><Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" }}>{file}</Badge></div></TextListItem>
                </TextList>

            );
        }
        else if (directory !== 'N/A') {
            targetsection = (
                <TextList component={TextListVariants.dl}>
                    <TextListItem component={TextListItemVariants.dt}>Directory:</TextListItem>
                    <TextListItem component={TextListItemVariants.dd}><div><Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" }}>{directory}</Badge></div></TextListItem>
                </TextList>

            );
        }
        else if (process !== 'N/A') {
            targetsection = (
                <TextList component={TextListVariants.dl}>
                    <TextListItem component={TextListItemVariants.dt}>Process:</TextListItem>
                    <TextListItem component={TextListItemVariants.dd}><div><Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" }}>{process}</Badge></div></TextListItem>
                </TextList>

            );
        }
        return [
            { key: 'Rationale', value: rationale },
            { key: 'Remediation', value: remediation },
            { key: 'Description', value: description },
            { key: 'Target', value: targetsection },
        ];
    }


    //Pagination in Agent table 
    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage) => {
        setPerPage(newPerPage);
    };

    const renderPagination = (variant) => <Pagination
        itemCount={totalItem}
        // isCompact
        page={page}
        perPage={perPage}
        onSetPage={handleSetPage}
        onPerPageSelect={handlePerPageSelect}
        variant={variant}
    />;

    const onSearch = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        setSearchValue(inputValue)
        setPage(1)
    }



    const tableToolbar = <Toolbar id="sortable-toolbar" >
        <ToolbarContent>
            <ToolbarItem visibility={{
                md: 'hidden'
            }}>
                <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
                    if (value === 'asc' || value === 'desc') {
                        onSort(event, activeSortIndex, value);
                    } else {
                        onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'asc');
                    }
                }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
                    <SortAmountDownIcon />
                </MenuToggle>}>
                    <SelectGroup label="Sort column">
                        <SelectList>
                            {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                                {column}
                            </SelectOption>)}
                        </SelectList>
                    </SelectGroup>
                    <SelectGroup label="Sort direction">
                        <SelectList>
                            <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                                Ascending
                            </SelectOption>
                            <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                                Descending
                            </SelectOption>
                        </SelectList>
                    </SelectGroup>
                </Select>
            </ToolbarItem>
            <ToolbarGroup role="group" style={{ alignItems: 'center' }}>
                <ToolbarItem>
                    <SearchInput placeholder="Search..."
                        value={inputValue}
                        onChange={(_event, value) => setInputValue(value)}
                        onSearch={onSearch}
                        onClear={() => { setSearchValue(''); setInputValue('') }} />


                </ToolbarItem>
                <ToolbarGroup role="group"  >

                    <ToolbarItem> <Checkbox label="Passed" key='passed' isChecked={dataFilters.status.includes('Passed')} onChange={(event, checked) => handleCheckChange(event, checked, 'status')} aria-label="Passed" id="Passed" /></ToolbarItem>
                    <ToolbarItem> <Checkbox label="Failed" key='failed' isChecked={dataFilters.status.includes('Failed')} onChange={(event, checked) => handleCheckChange(event, checked, 'status')} aria-label="Failed" id="Failed" /></ToolbarItem>
                    <ToolbarItem> <Checkbox label="Not Applicable" key='not applicable' isChecked={dataFilters.status.includes('Not Applicable')} onChange={(event, checked) => handleCheckChange(event, checked, 'status')} aria-label="Not Applicable" id="Not Applicable" /></ToolbarItem>

                </ToolbarGroup>
            </ToolbarGroup>
            {props.IsModal ?
                <ToolbarItem align={{ default: 'alignRight' }}>
                    {renderPagination('top')}
                </ToolbarItem> : (null)}
        </ToolbarContent>
    </Toolbar>;


    const renderResult = (result) => {
        let badgeStyle = {};
        if (result === 'passed') {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#3e8635" };
            return (<div><Badge style={badgeStyle} ><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.sca.check.result', result) }}>{result}</a></Badge></div>)
        }
        else if (result === "failed") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
            return (<div><Badge style={badgeStyle} ><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.sca.check.result', result) }}>{result}</a></Badge></div>)
        }
        else if (result === "not applicable") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#2B9AF3  " };
            return (<div><Badge style={badgeStyle} ><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.sca.check.result', result) }}>{result}</a></Badge></div>)
        }
        else {
            return ' ';
        }
    };

    return <React.Fragment>
        <> {tableToolbar}
            {isLoading ? <Loader /> :
                <InnerScrollContainer>
                    <Table variant='compact' isStickyHeader gridBreakPoint="grid-md" >
                        <Thead aria-label='thead-sca'>
                            <Tr key={"header-row"} aria-label='row-head-sca'>
                                <Th key={'expand'} aria-label='expandall' screenReaderText='expansion' />
                                {Object.values(columns).map((column, columnIndex) => {
                                    // const stickyColumnsIndex = [0, 1];
                                    // const isSticky = stickyColumnsIndex.includes(columnIndex);
                                    const sortParams = {
                                        sort: {
                                            sortBy: {
                                                index: activeSortIndex,
                                                direction: activeSortDirection
                                            },
                                            onSort,
                                            columnIndex
                                        }
                                    };
                                    return <Th key={columnIndex + column} {...sortParams} isStickyColumn aria-label='sca-header'>{column} </Th>;
                                })}
                            </Tr>
                        </Thead>
                        <Tbody aria-label='tbody-sca'>
                            {rows.length > 0 ?
                                rows && rows.map((row, rowIndex) => {
                                    const { formattedTimeStamp } = formatTableTimestamp(row?.timestamp);
                                    return (
                                        <>
                                            <Tr key={row.agent_id}
                                                onRowClick={() => setSelectedRowName(rowIndex)}
                                                isSelectable
                                                isClickable
                                                isRowSelected={selectedRowName === rowIndex}
                                            >
                                                <Td
                                                    key={'expand-td' + rowIndex}
                                                    expand={{
                                                        rowIndex: rowIndex,
                                                        isExpanded: isRowExpanded(row),
                                                        onToggle: () => setRowExpanded(row, !isRowExpanded(row)),
                                                    }}
                                                />
                                                {/* <ExpandableRowContent key={'expandedata'}>
                                                        <pre>{JSON.stringify(row, null, 2)}</pre>
                                                    </ExpandableRowContent>
                                                </Td> */}
                                                <Td width={10} dataLabel="Time" key={rowIndex + 'time'}>{formattedTimeStamp}</Td>
                                                <Td width={10} dataLabel="Agent Name" key={rowIndex + 'agent_name'} ><Link to={"/individual-agent-dashboard/" + row?.agent_name + '/' + row?.agent_id} >{row?.agent_id} : {row?.agent_name}</Link></Td>
                                                <Td width={80} dataLabel="Rule Description" key={rowIndex + 'rule_desc'}>
                                                    <a><div><Badge key={"badge" + rowIndex}>{row?.data_sca_check_id}</Badge></div></a>{row?.rule_description}{renderResult(row?.data_sca_check_result)}</Td>

                                            </Tr>
                                            <Tr isExpanded={isRowExpanded(row)} key={rowIndex + "exp"}>
                                                <Td colSpan={10} noPadding>
                                                    <ExpandableRowContent key={rowIndex}>
                                                        {expandedRowData[row.s_id]?.map((item, index) =>
                                                            <React.Fragment key={item.key + index}>
                                                                <div className="pf-v5-u-p-sm" key={item.key + index}>
                                                                    <TextContent key={item.key + index}>
                                                                        <Text component={TextVariants.h3}>{item.key} </Text>
                                                                        <Text>{item.value}</Text>
                                                                    </TextContent>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    </ExpandableRowContent>
                                                </Td>
                                            </Tr>
                                        </>
                                    )
                                })
                                :
                                <Tr key='empty-row'>
                                    <Td colSpan={10} key='empty-data'>
                                        <Emptystatefornodata />
                                    </Td>
                                </Tr>
                            }
                        </Tbody>
                    </Table>
                </InnerScrollContainer>
            }
            {props.IsModal && renderPagination('bottom')}
        </>

    </React.Fragment >;
};
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate, selectedtime: state.Term },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    ScaFieldsgroup: state.ScaFields
});

const mapDispatchToProps = (dispatch) => { return {} }

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(LatestScaPfLog)