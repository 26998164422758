import React, { useEffect, useState } from "react";
import {
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Table,
} from "@patternfly/react-table";
import {
    Pagination,
    Select,
    SelectOption,
    SelectList,
    SelectGroup,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    MenuToggle,
    SearchInput,
    Bullseye,

} from "@patternfly/react-core";
import { connect, useDispatch } from "react-redux";
import { SearchIcon, SortAmountDownIcon } from "@patternfly/react-icons"
import { Loader } from "src/util/Loadingstate";
import { formatTableTimestamp } from "src/util/Timeutils";
import dbApi from '../../services/DbApi'

//if sorting and pagination is from ui side than take an example of security alert description.

const ChildIndexTable = (props) => {
    const dispatch = useDispatch();
    const defaultColumns = {
        index_name: "Index Name",
        size: "Consumed Size",
        last_datetime: "Timestamp",
    };
    const [columns, setColumns] = useState(defaultColumns);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalindices, setTotalIndices] = useState(0);
    const [activeSortIndex, setActiveSortIndex] = useState(2);
    const [activeSortDirection, setActiveSortDirection] = useState('desc');
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
    const [selectedRowName, setSelectedRowName] = useState('');
    const [loadindices, setLoadIndices] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [searchValue, setSearchValue] = useState('');
    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage, newPage) => {
        setPage(newPage);
        setPerPage(newPerPage);
    };

    const getIndices = async () => {
        try {
            let params = {
                // limitStart: (perPage * page) - perPage,
                // limitLength: perPage,
                // search: searchValue,
                // sortByCol: Object.keys(columns)[activeSortIndex],
                // sortOrder: activeSortDirection,
                parentId: props.ParentId,
            }
            setLoadIndices(true)
            let res = await dbApi.postApi("elasticsearch/index_Expand ", params)
            console.log(res)
            let total = 0
            let totaldata = []
            if (!res.index_Expand.error) {
                totaldata = res.index_Expand.index
                total = res.index_Expand.index.length
                setRows(totaldata)
                setTotalIndices(total)

            }
            else {
                setRows(totaldata)
                setTotalIndices(total)
            }
            setLoadIndices(false)
        } catch (error) {
            console.log(error)
            setRows([])
            setTotalIndices(0)
            setLoadIndices(false)
        }
    }
    useEffect(() => {
        getIndices()
    }, [props.ParentId
        // page,
        // perPage,
        // activeSortIndex,
        // activeSortDirection,
        // searchValue
    ])

    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };


    const renderPagination = (variant) =>
        <Pagination
            itemCount={totalindices}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
        />;

    const onSearch = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        setSearchValue(inputValue)
        setPage(1)
    }
    const tableToolbar = <Toolbar id="sortable-toolbar">
        <ToolbarContent>
            <ToolbarItem visibility={{ md: 'hidden' }}>
                <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
                    if (value === 'asc' || value === 'desc') {
                        onSort(event, activeSortIndex, value);
                    } else {
                        onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'desc');
                    }
                }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
                    <SortAmountDownIcon />
                </MenuToggle>}>
                    <SelectGroup label="Sort column">
                        <SelectList>
                            {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                                {column}
                            </SelectOption>)}
                        </SelectList>
                    </SelectGroup>
                    <SelectGroup label="Sort direction">
                        <SelectList>
                            <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                                Ascending
                            </SelectOption>
                            <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                                Descending
                            </SelectOption>
                        </SelectList>
                    </SelectGroup>
                </Select>
            </ToolbarItem>
            <ToolbarItem key={'search-input'} variant="search-filter">
                <SearchInput placeholder="Search..."
                    value={inputValue}
                    onChange={(_event, value) => setInputValue(value)}
                    onSearch={onSearch}
                    onClear={() => { setSearchValue(''); setInputValue('') }} />
            </ToolbarItem>
            <ToolbarItem align={{ default: 'alignRight' }}>{renderPagination('top')}</ToolbarItem>
        </ToolbarContent>
    </Toolbar>;


    return (
        <React.Fragment>
            {loadindices ?
                <Loader /> :
                rows.length == 0 ? <Bullseye>No child index are available.</Bullseye>
                    :
                    <>
                        {/* {tableToolbar} */}
                        <Table variant="compact" aria-label="indextable" id="indextable-table" isStickyHeader gridBreakPoint="">
                            <Thead >
                                <Tr key={'cahrt-table-header'}>

                                    {Object.values(columns).map((column, columnIndex) => {
                                        const sortParams = {
                                            sort: {
                                                sortBy: {
                                                    index: activeSortIndex,
                                                    direction: activeSortDirection
                                                },
                                                onSort,
                                                columnIndex
                                            }
                                        };// {...sortParams}
                                        return <Th key={column+columnIndex} isStickyColumn modifier="wrap">{column}</Th>;
                                    })}
                                </Tr>
                            </Thead>
                            {rows && rows.length > 0 &&
                                <Tbody>
                                    {rows.map((row, rowIndex) => {
                                        const { formattedTimeStamp } = formatTableTimestamp(row?.last_datetime);
                                        return (
                                            <>
                                                <Tr key={rowIndex + 'row'}
                                                    onRowClick={() => setSelectedRowName(rowIndex)}
                                                    isSelectable
                                                    isClickable
                                                    isRowSelected={selectedRowName === rowIndex} >
                                                    <Td width={50} dataLabel="Index Name" key={rowIndex + 'indexname'}>{row?.index_name}</Td>
                                                    <Td width={25} key={rowIndex + 'sizeused'} dataLabel="Consumed Size">{row?.size}</Td>
                                                    <Td width={25} key={rowIndex + 'time'} dataLabel="Timestamp">{formattedTimeStamp}</Td>
                                                </Tr>
                                            </>
                                        )
                                    })
                                    }
                                </Tbody>
                            }
                        </Table>
                    </>
            }
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(ChildIndexTable)
