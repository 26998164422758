import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import 'echarts-gl';
import worldJson from '../../util/world.json'
import { connect } from 'react-redux';

const ElinesGlChart = (props) => {

    useEffect(() => {
        let data = {
            "airportsFields": [
                "name",
                "city",
                "country",
                "longitude",
                "latitude"
            ],
            "airports": [
                ["Goroka", "Goroka", "Papua New Guinea", 145.391881, -6.081689],
                ["Sopot", "Sopot", "Bulgaria", 24.75, 42.65],
                ["San Jose", "San Jose", "United States", -121.8914, 37.3388],
                ["Nadzab", "Nadzab", "Papua New Guinea", 146.726242, -6.569828],
                ["Reykjavik", "Reykjavik", "Iceland", -21.940556, 64.13],
                ["Ahmedabad", "Ahmedabad", "India", 72.63465, 23.077242],
                ["Aurangabad", "Aurangabad", "India", 75.398114, 19.862728],
                ['Seattle', "Seattle", "United States", -122.3314, 47.6092],
            ],
            "routes": [
                [0, 1, 5],
                [0, 2, 5],
                [2, 2, 6],
                [3, 2, 4],
                [4, 5, 6],
                [5, 5, 7],
                [6, 1, 6],

            ]
        };
        const chartDom = document.getElementById('line3d');
        const myChart = echarts.init(chartDom);
        echarts.registerMap('world', worldJson); // Register the world map

        // myChart.showLoading();
        // myChart.hideLoading();

        function getAirportCoord(idx) {
            return [data.airports[idx][3], data.airports[idx][4]];
        }

        var routes = data.routes.map(function (route) {
            return [getAirportCoord(route[1]), getAirportCoord(route[2])];
        });

        const option = {
            backgroundColor: '#000',
            tooltip: {
                show: true,
                formatter: function (params) {
                    return `Value: ${params.value}`;
                }
            },
            geo3D: {
                map: 'world',
                shading: 'realistic',
                silent: true,
                environment: '#333',
                realisticMaterial: {
                    roughness: 0.8,
                    metalness: 0
                },
                postEffect: {
                    enable: true
                },
                groundPlane: {
                    show: false
                },
                light: {
                    main: {
                        intensity: 1,
                        alpha: 30
                    },
                    ambient: {
                        intensity: 0
                    }
                },
                viewControl: {
                    distance: 70,
                    alpha: 89,
                    panMouseButton: 'left',
                    rotateMouseButton: 'right'
                },
                itemStyle: {
                    color: '#000'
                },
                regionHeight: 0.5
            },
            series: [{
                type: 'lines3D',
                coordinateSystem: 'geo3D',
                blendMode: 'lighter',
                effect: {
                    show: true,
                    trailWidth: 2,
                    trailOpacity: 1,
                    trailLength: 0.4,
                    constantSpeed: 6
                },
                lineStyle: {
                    width: 1,
                    color: 'rgb(250, 10, 150)',
                    opacity: 3
                },
                data: routes
            }]
        }

        option && myChart.setOption(option);
        myChart.on('mouseover', function (params) {
            if (params.componentType === 'series') {
                console.log(`Mouse over on: ${params.data}`);
            }
        });

        const resizeChart = () => {
            if (myChart) {
                myChart.resize();
            }
        };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, []);

    return (
        <div className="container">
            <div id={'line3d'} style={{ width: '100%', height: '500px' }}></div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(ElinesGlChart);
