import { Button, DescriptionList, DescriptionListGroup, DescriptionListTerm, Divider, Flex, FlexItem, Form, FormGroup, Grid, GridItem, InputGroup, InputGroupItem, MenuToggle, Popover, Select, SelectList, SelectOption, Tab, TabContentBody, Tabs, TabTitleText, Text, TextInput, TextVariants } from '@patternfly/react-core';
import React, { useEffect, useRef, useState } from 'react'
import DateTimeRangePicker from '../CommonEventPages/DateTimePicker';
import { GloablInterval, StartEndDate } from 'src/Redux/Action';
import { connect, useDispatch } from 'react-redux';
import dbApi from 'src/services/DbApi';
import { TimeHandler } from 'src/util/Timeutils';
import { AngleDownIcon, OutlinedCalendarAltIcon, PlayIcon, RedoIcon } from '@patternfly/react-icons';

const IncidentFilter = (props) => {
    const dispatch = useDispatch();
    const timeInputRef = useRef(null);
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [InputValue, setInputValue] = useState("Last");
    const [selectedTime, setSelectedTime] = useState(24);
    const [selectedUnit, setSelectedUnit] = useState('hours');
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [RefreshTime, setRefreshTime] = useState('');
    const [selectedunitopen, setslectedunitopen] = useState(false);
    const [selectedcommontime, setSelectedCommonTime] = useState('Last 24 hours')
    const [GloablIntervalDuration, setGlobalIntervalduration] = useState('')
    const [startDate, setStartDate] = useState();
    const [refreshunit, setRefreshUnit] = useState('select');
    const [refreshunitopen, setRefreshunitopen] = useState(false);
    const [endDate, setEndDate] = useState();

    const handleTabClick = (_event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };
    const onRefreshClick = () => {
        setRefreshunitopen(!refreshunitopen);
    };
    const onRefreshUnitSelect = (_event, value) => {
        setRefreshUnit(value);
        setRefreshunitopen(false);
    };

    const RefreshStartButton = (value) => {
        setGlobalIntervalduration(value)
        dispatch(GloablInterval(25000))
    }

    const onUnitSelect = (_event, value) => {
        setSelectedUnit(value);
        setslectedunitopen(false);
    };
    const onunitToggleclick = () => {
        setslectedunitopen(!selectedunitopen);
    };
    //when manual time entered and click on apply
    const handleApplyClick = async (event) => {
        // alert(event)
        const timeValue = timeInputRef.current.value;
        const unitValue = selectedUnit; // Fetch unit value from state
        const inputValue = InputValue; // Fetch input value from state

        const endDate = await TimeApi();
        let startDate = new Date(endDate);
        switch (unitValue) {
            case "seconds":
                startDate.setSeconds(startDate.getSeconds() - timeValue);
                startDate = startDate;

                // interval='1s'
                break;
            case "minutes":
                startDate.setMinutes(startDate.getMinutes() - timeValue);
                startDate = startDate;
                // interval="1m"
                break;
            case "hours":
                startDate.setHours(startDate.getHours() - timeValue);
                startDate = startDate;
                // console.log(startDate, endDate)
                // interval="5m"
                break;
            case "days":
                startDate.setDate(startDate.getDate() - timeValue);
                startDate = startDate;
                // interval="1h"
                break;

            case "months":
                startDate.setMonth(startDate.getMonth() - timeValue);
                startDate = startDate;
                // interval="1d"
                break;
            case "years":
                startDate.setFullYear(startDate.getFullYear() - timeValue);
                startDate = startDate;
                // interval="1M"
                break;
            default:
                break;
        }

        // setTimeValue(timeValue);
        // setUnitValue(unitValue);
        setInputValue(inputValue);
        setIsPopoverOpen(false);
        setStartDate(startDate);
        setEndDate(endDate);
        setSelectedCommonTime(`${inputValue} ${timeValue} ${unitValue}`);
        dispatch(StartEndDate(startDate, endDate, `${inputValue} ${timeValue} ${unitValue}`));
    };

    const commontime = [
        { term: "All Time" },
        { term: "Today" },
        { term: "This week" },
        { term: "This month" },
        { term: "Last 15 minutes" },
        { term: "Last 30 minutes" },
        { term: "Last 1 hour" },
        { term: "Last 24 hours" },
        { term: "Last 7 days" },
        { term: "Last 30 days" },
        { term: "Last 90 days" },
        { term: "Last 1 year" },
    ];
    //Fixed or common use time 
    const handleItemClick = async (term) => {
        const {
            startDate,
            endDate,
            selectedTime,
            selectedUnit
        } = await TimeHandler(term, TimeApi, entireLog);
        setSelectedCommonTime(term);
        setIsPopoverOpen(false);
        // setLoading(true);
        setStartDate(startDate);
        setEndDate(endDate);
        setSelectedTime(selectedTime);
        setSelectedUnit(selectedUnit);
        // console.log("sd,",startDate,'ed,',endDate)
        dispatch(StartEndDate(startDate, endDate, term));
    }
    // const FilteredCommonTime = props.AllTimeDisplay ? commontime : commontime.slice(1)
    const FilteredCommonTime =  commontime

    const TimeApi = async () => {
        try {
            let currentDateTime = await dbApi.getApi("elasticsearch/getCurrentUtcDateTime");
            const CurrentTime = new Date(currentDateTime);
            console.log("currentTime",CurrentTime)
            return CurrentTime;
        }
        catch (error) {
            console.log(error);
        }
    }
    const entireLog = async () => {
        try {
            let entirelog = await dbApi.getApi("opensearch/get_vulnerability_datetime");
            console.log("entirelog", entirelog)
            const timestamp = entirelog.data.startDate
            const entireStartDate = new Date(timestamp);
            return entireStartDate;
        }
        catch (error) {
            console.log(error);
        }
    }


    const fetchData = async () => {
        try {
            if (props?.dates.startDate == "") {
                handleItemClick('Last 24 hours');
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [props?.dates.startDate, props?.dates.endDate,]);

    return (
        <div> <Grid hasGutter>
             <GridItem sm={11} md={11} lg={11}>
            <InputGroup>
                <InputGroupItem>
                    <Popover
                        aria-label="Basic popover"
                        showClose={false}
                        hasAutoWidth
                        bodyContent={(hide) => (
                            <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs-individual" role="region" >
                                <Tab eventKey={0} title={<TabTitleText className="pf-v5-u-font-weight-bold">Quick select</TabTitleText>} aria-label="Agentlists">
                                    <TabContentBody className='pf-v5-u-mt-sm'>
                                        <Form>
                                            <Flex spaceItems={{ default: "spaceItemsXs" }}>
                                                <FlexItem>
                                                    <FormGroup>
                                                        <TextInput type="text" id="simple-text" value={InputValue} style={{ width: "100px" }} />
                                                    </FormGroup>
                                                </FlexItem>
                                                <FlexItem>
                                                    <FormGroup>
                                                        <TextInput
                                                            type="number"
                                                            id="simple-form-text"
                                                            ref={timeInputRef}
                                                            style={{ width: "100px" }}
                                                            value={selectedTime}
                                                            onChange={(_event, value) => setSelectedTime(value)}
                                                        />
                                                    </FormGroup>
                                                </FlexItem>
                                                <FlexItem>
                                                    <FormGroup>
                                                        <Select id="unit-select" isOpen={selectedunitopen} selected={selectedUnit} onSelect={onUnitSelect}
                                                            onOpenChange={isOpen => setslectedunitopen(isOpen)}
                                                            toggle={(toggleRef) =>
                                                                <MenuToggle ref={toggleRef}
                                                                    onClick={onunitToggleclick}
                                                                    isExpanded={selectedunitopen}>
                                                                    {selectedUnit}
                                                                </MenuToggle>}
                                                            shouldFocusToggleOnSelect>
                                                            <SelectList>
                                                                <SelectOption value="seconds">seconds</SelectOption>
                                                                <SelectOption value="minutes">minutes</SelectOption>
                                                                <SelectOption value="hours">hours</SelectOption>
                                                                <SelectOption value="days" >days</SelectOption>
                                                                <SelectOption value="months" >months</SelectOption>
                                                                <SelectOption value="years" >years</SelectOption>
                                                            </SelectList>
                                                        </Select>
                                                    </FormGroup>
                                                </FlexItem>
                                                <FlexItem>
                                                    <FormGroup>
                                                        <Button variant="secondary" onClick={() => { handleApplyClick(); hide(); }}> Apply </Button>
                                                    </FormGroup>
                                                </FlexItem>
                                            </Flex>
                                        </Form>
                                        <div className="pf-v5-u-mt-sm">
                                            <Text component={TextVariants.h5}  >Commonly Used </Text>
                                            <DescriptionList columnModifier={{ default: "3Col" }} isCompact>
                                                {FilteredCommonTime.map((item, index) => (
                                                    <DescriptionListGroup key={index}>
                                                        <DescriptionListTerm onClick={() => handleItemClick(item.term)} >
                                                            <Button variant="link" isInline onClick={() => { hide() }}> {item.term} </Button>
                                                        </DescriptionListTerm>
                                                    </DescriptionListGroup>
                                                ))}
                                            </DescriptionList>
                                            <Divider />
                                        </div>
                                    </TabContentBody>
                                </Tab>
                                <Tab eventKey={1} title={<TabTitleText className="pf-v5-u-font-weight-bold">Custom dates</TabTitleText>} aria-label="Agentlists-agentlesslists">
                                    <TabContentBody className='pf-v5-u-mt-sm'>
                                        <DateTimeRangePicker onClose={() => hide()}
                                            initialStartDate={startDate}
                                            initialEndDate={endDate}
                                            onDatesChange={(newStartDate, newEndDate) => {
                                                setStartDate(newStartDate);
                                                setEndDate(newEndDate);
                                            }} />
                                    </TabContentBody>
                                </Tab>
                            </Tabs>
                        )}
                        footerContent={
                            <div>
                                <Text component={TextVariants.h6}>Refresh every</Text>
                                <Form>
                                    <Flex spaceItems={{ default: "spaceItemsMd" }}>
                                        <FlexItem>
                                            <TextInput type="number" id="simple-form-number"
                                                style={{ width: "150px" }}
                                                value={RefreshTime}
                                                onChange={(_event, value) => setRefreshTime(value)}
                                            />
                                        </FlexItem>
                                        <FlexItem>
                                            <Select id="unit-select" isOpen={refreshunitopen} selected={refreshunit} onSelect={onRefreshUnitSelect}
                                                onOpenChange={isOpen => setRefreshunitopen(isOpen)}
                                                toggle={(toggleRef) =>
                                                    <MenuToggle ref={toggleRef}
                                                        onClick={onRefreshClick}
                                                        isExpanded={refreshunitopen}>
                                                        {refreshunit}
                                                    </MenuToggle>}
                                                shouldFocusToggleOnSelect>
                                                <SelectList>
                                                    <SelectOption value="seconds">seconds</SelectOption>
                                                    <SelectOption value="minutes">minutes</SelectOption>
                                                    <SelectOption value="hours">hours</SelectOption>
                                                    <SelectOption value="days" >days</SelectOption>
                                                    <SelectOption value="months" >months</SelectOption>
                                                    <SelectOption value="years" >years</SelectOption>
                                                </SelectList>
                                            </Select>
                                        </FlexItem>
                                        <FlexItem>
                                            <Button isDisabled={refreshunit !== 'select' && RefreshTime ? false : true}
                                                icon={<PlayIcon />}
                                                onClick={() => RefreshStartButton(RefreshTime * 1000)}
                                            >Start</Button>
                                        </FlexItem>
                                    </Flex>
                                </Form>
                            </div>
                        }
                    > 
                    <Button variant="control" aria-label="popover-calender-button">
                            <OutlinedCalendarAltIcon /> <AngleDownIcon />
                        </Button>
                    </Popover>
                </InputGroupItem>
                <InputGroupItem isFill>
                    <TextInput
                        name="textInput-with-popover-1"
                        id="textInput-with-popover-1"
                        type="text"
                        isStartTruncated
                        aria-label="first input example with popover"
                        value={props?.dates.selectedcommontime}
                        onChange={(e) => setSelectedCommonTime(e.target.value)}
                    />
                </InputGroupItem>
            </InputGroup>
            </GridItem>
            <GridItem sm={1} md={1} lg={1}>
                <Button icon={<RedoIcon />} onClick={() => handleItemClick(props?.dates.selectedcommontime)} style={{ width: '100%', maxWidth: '100%' }} />
            </GridItem>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate, selectedcommontime: state.Term },
    filterformdata: state.FilterData,
    FilterRuleLevel: state.filterrulelevel
});

const mapDispatchToProps = (dispatch) => {
    return {
        SetDates: (startDate, endDate) => { (StartEndDate(startDate, endDate)); },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(IncidentFilter)