import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Divider,
    Flex,
    Grid,
    GridItem,
    Icon,
    Modal,
    Progress,
    Text,
    TextContent,
    TextVariants,
    ModalVariant,
    Tabs,
    Tab,
    TabTitleText,
    Bullseye,
    FlexItem,
    Tooltip,
    CardHeader,
    DropdownList,
    DropdownItem,
    Dropdown,
    MenuToggle,
    EmptyState,
} from '@patternfly/react-core'
import { connect } from 'react-redux'
import { formatValue } from 'src/util/ChartHelper'
import IncidentPieChart from './IncidentChart/IncidentPieChart'
import DbApi from 'src/services/DbApi'
import { Loader, TextSkeleton } from 'src/util/Loadingstate'
import IncidentLineChart from './IncidentChart/IncidentLineChart'
import IncidentOverTimeChart from './IncidentChart/IncidentOverTimeChart'
import IncidentBarChart from './IncidentChart/IncidentBarChart'
import IncidentMttrChart from './IncidentChart/IncidentMttrChart'
import IncidentScatterChart from './IncidentChart/IncidentScatterChart'
import { Emptystatefornodata } from 'src/util/EmptyStateCard'

const OverviewDashboard = forwardRef(function OverviewDashboard(props, ref) {
    const [filterFields, setFilterFields] = React.useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [alertcount, setAlertCount] = useState(0);
    const [mttrData, setMttrData] = useState(0);
    const [mttrLabel, setMttrLabel] = useState('')
    const [incidentcount, setIncidentCount] = useState(0);
    const [taskcount, setTaskCount] = useState(0);
    const [isevolutiontimeOpen, setIsEvolutionTimeOpen] = React.useState(false);
    const [selectedterm, setSelectedterm] = React.useState('Last 24 hour');
    const [incidentwaiting, setIncidentWaiting] = useState(0);
    const [openincident, setOpenIncident] = useState(0);
    const [closedincident, setClosedIncident] = useState(0);
    const [taskwaiting, setTaskWaiting] = useState(0);
    const [inProgress, setInProgress] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [low, setLow] = useState(0);
    const [medium, setMedium] = useState(0);
    const [high, setHigh] = useState(0);
    const [critical, setCritical] = useState(0);
    const [incidentTrend, setIncidentTrend] = useState([]);
    const [incidentTrendlabel, setIncidentTrendlabel] = useState('');
    const [incidentOverTime, setIncidentOverTime] = useState([]);
    const [incidentOverTimelabel, setIncidentOverTimelabel] = useState('');
    const [assignedInvestigator, setAssignedInvestigator] = useState([])
    const [assignedinvLabel, setAssignedInvLabel] = useState('')
    const [closedByseverity, setClosedBySeverity] = useState([])
    const [closedByseverityLabel, setClosedBySeverityLabel] = useState([])


    //Loader states
    const [isloadalert, setIsLoadAlert] = useState(true);
    const [isloadincident, setIsLoadIncident] = useState(false);
    const [isloadtask, setIsLoadTask] = useState(true);
    const [isloadseverity, setIsLoadSeverity] = useState(true);
    const [isloadincidenttrend, setIsLoadIncidentTrend] = useState(true);
    const [isloadincidentOverTime, setIsLoadIncidentOverTime] = useState(true);
    const [isloadmttr, setIsLoadMttr] = useState(true);
    const [isloadusers, setIsLoadUsers] = useState(true);
    const [isloadcloseBySeverity, setIsLoadClosedBySeverity] = useState(true);

    const colorScheme = ['#C9190B', '#ffbf00', '#4CB140'];
    const getTotalIncident = async (data) => {
        try {
            setIsLoadIncident(true);
            let res = await DbApi.postApi("incident_management/get_incident_total_count", data);
            console.log('incident total count', res);

            // Initialize counts to 0
            let totalIncident = 0;
            let incidentwaiting = 0;
            let open = 0;
            let closed = 0;

            if (!res.error) {
                res.data.forEach(item => {
                    totalIncident += parseInt(item.total_count);
                    if (item.status === "Waiting") {
                        incidentwaiting = parseInt(item.total_count);
                    } else if (item.status === "Open") {
                        open = parseInt(item.total_count);
                    } else if (item.status === "Closed") {
                        closed = parseInt(item.total_count);
                    }
                });
                setIncidentCount(totalIncident);
                setIncidentWaiting(incidentwaiting);
                setOpenIncident(open);
                setClosedIncident(closed);
            } else {
                // Handle error case by resetting counts to 0
                setIncidentCount(totalIncident);
                setIncidentWaiting(incidentwaiting)
                setOpenIncident(open)
                setClosedIncident(closed)
            }
            setIsLoadIncident(false);
        } catch (error) {
            console.log(error);
            setIsLoadIncident(false);
        }
    };
    const getTotalAlert = async (data) => {
        try {
            setIsLoadAlert(true)
            let res = await DbApi.postApi("incident_management/get_alert_total_count", data)
            console.log(res)
            let totalalert = 0
            if (!res.error) {
                totalalert = res.data[0].total_count
                setAlertCount(totalalert)
            }
            else {
                setAlertCount(totalalert)
            }
            setIsLoadAlert(false)
        } catch (error) {
            console.log(error)
            setIsLoadAlert(false)
        }
    }
    const getSeverityWise = async (data) => {
        try {
            setIsLoadSeverity(true)
            let res = await DbApi.postApi("/incident_management/get_incident_total_count_severity_wise", data)
            console.log('severity wise', res)
            let low = 0;
            let medium = 0;
            let high = 0;
            let critical = 0

            if (!res.error) {
                res.data.forEach(item => {
                    if (item.severity === "L") {
                        low = parseInt(item.total_count);
                    } else if (item.severity === "M") {
                        medium = parseInt(item.total_count);
                    } else if (item.severity === "H") {
                        high = parseInt(item.total_count);
                    }
                    else if (item.severity === "C") {
                        critical = parseInt(item.total_count);
                    }
                });
                setLow(low);
                setMedium(medium);
                setHigh(high);
                setCritical(critical);
            } else {
                setLow(low);
                setMedium(medium);
                setHigh(high);
                setCritical(critical);
            }
            setIsLoadSeverity(false);
        } catch (error) {
            console.log(error);
            setIsLoadSeverity(false);
        }
    };
    const getTotalTask = async (data) => {
        try {
            setIsLoadTask(true);
            let res = await DbApi.postApi("incident_management/get_task_total_count", data);
            console.log('task total count', res);
            let totaltask = 0;
            let waiting = 0;
            let inprogress = 0;
            let completed = 0;

            if (!res.error) {
                res.data.forEach(item => {
                    totaltask += parseInt(item.total_count);
                    if (item.status === "Waiting") {
                        waiting = parseInt(item.total_count);
                    } else if (item.status === "InProgress") {
                        inprogress = parseInt(item.total_count);
                    } else if (item.status === "Completed") {
                        completed = parseInt(item.total_count);
                    }
                });
                setTaskCount(totaltask);
                setTaskWaiting(waiting);
                setInProgress(inprogress);
                setCompleted(completed);
            } else {
                setTaskCount(totaltask);
                setTaskWaiting(waiting)
                setInProgress(inprogress)
                setCompleted(completed)
            }
            setIsLoadTask(false);
        } catch (error) {
            console.log(error);
            setIsLoadTask(false);
        }
    };


    const IncidentTrend = async (data) => {
        try {
            setIsLoadIncidentTrend(true)
            let res = await DbApi.postApi("incident_management/get_incident_trend_date_histogram", data)
            console.log('incident trend line data', res)
            let series = [];
            let label = ""
            if (Object.keys(res.data).length > 0) {
                series = res.data.HistogramData;
                label = res.data.interval
                setIncidentTrend(series)
                setIncidentTrendlabel(label)
            }
            else {
                setIncidentTrend(series)
                setIncidentTrendlabel(label)
            }
            setIsLoadIncidentTrend(false)
        } catch (error) {
            console.log(error)
            setIncidentTrend([])
            setIncidentTrendlabel('')
            setIsLoadIncidentTrend(false)
        }
    }

    const IncidentOverTimeApi = async (data) => {
        try {
            setIsLoadIncidentOverTime(true)

            const param = { ...data, status: ["Open", "Closed"] };
            let res = await DbApi.postApi("incident_management/get_incident_trend_date_histogram", param)
            console.log('incident bar chart data', res)
            let series = [];
            let label = ""
            if (Object.keys(res.data).length > 0) {
                series = res.data.HistogramData;
                label = res.data.interval
                setIncidentOverTime(series)
                setIncidentOverTimelabel(label)
            }
            else {
                setIncidentOverTime(series)
                setIncidentOverTimelabel(label)
            }
            setIsLoadIncidentOverTime(false)
        } catch (error) {
            console.log(error)
            setIncidentOverTime([])
            setIncidentOverTimelabel('')
            setIsLoadIncidentOverTime(false)
        }
    }

    const getMTTRApi = async (data) => {
        try {
            setIsLoadMttr(true)
            let res = await DbApi.postApi("/incident_management/get_incident_mttr_date_histogram", data)
            console.log('MTTR chart data', res);
            let mmttrres = []
            let label = ''
            if (!res.error) {
                mmttrres = res.data.HistogramData
                label = res.data.interval
                setMttrData(mmttrres)
                setMttrLabel(label)
            }
            else {
                setMttrData(mmttrres)
                setMttrLabel(label)
            }
            setIsLoadMttr(false)
        } catch (error) {
            console.log(error)
            setIsLoadMttr(false)
        }
    }

    const getAssignedInvestigator = async (data) => {
        try {
            setIsLoadUsers(true)
            let res = await DbApi.postApi("/incident_management/get_incident_assign_user_data_histogram", data)
            console.log('assigned investigator data', res)
            let totalusers = []
            let label = ''
            if (!res.error) {
                totalusers = res.data.HistogramData
                label = res.data.interval
                setAssignedInvestigator(totalusers)
                setAssignedInvLabel(label)
            }
            else {
                setAssignedInvestigator(totalusers)
                setAssignedInvLabel(label)
            }
            setIsLoadUsers(false)
        } catch (error) {
            console.log(error)
            setIsLoadUsers(false)
        }
    }
    const getClosedBySverity = async (data) => {
        try {
            setIsLoadClosedBySeverity(true)
            let res = await DbApi.postApi("/incident_management/get_incident_severity_date_histogram", data)
            console.log('incidnet closed by severity', res)
            let closeseverity = res.data.HistogramData
            let label = res.data.interval
            console.log('label of close severity', label)
            if (!res.error) {
                setClosedBySeverity(closeseverity)
                setClosedBySeverityLabel(label)
                console.log('total severity user data---', closeseverity)
            }
            else {
                setClosedBySeverity(closeseverity)
            }
            setIsLoadClosedBySeverity(false)
        } catch (error) {
            console.log(error)
            setIsLoadClosedBySeverity(false)
        }
    }

    const IncidentDashboardApi = async (startDate, endDate) => {
        const data = {
            startDate: startDate,
            endDate: endDate
        }
        console.log("data", data)
        try {
            setStartDate(startDate)
            setEndDate(endDate)
            getTotalAlert(data)
            getTotalIncident(data)
            getTotalTask(data)
            getSeverityWise(data)
            IncidentTrend(data)
            IncidentOverTimeApi(data)
            getMTTRApi(data)
            getAssignedInvestigator(data)
            getClosedBySverity(data)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (props.dates.startDate != "") {
            let Dates = props.dates
            IncidentDashboardApi(Dates.startDate, Dates.endDate)
        }
    }, [props?.dates.startDate, props?.dates.endDate]);
    const severityColors = ['#8B0000', '#C9190B', '#ffbf00', '#00c0ef'];
    const IncidentsTrend = useMemo(() => {
        return (
            isloadincidenttrend ? <Loader /> :
                incidentTrend.length == 0 ?
                    <Emptystatefornodata /> :
                    <IncidentLineChart
                        statusTrend={incidentTrend}
                        label={incidentTrendlabel}
                        StartDateTime={startDate}
                        EndDateTime={endDate}
                    />
        )
    }, [incidentTrend, incidentTrendlabel, startDate, endDate, isloadincidenttrend])

    const IncidentResolution = useMemo(() => {
        const noIncidentData = (critical ?? 0) === 0 && (high ?? 0) === 0 && (medium ?? 0) === 0 && (low ?? 0) === 0;

        return (isloadseverity ? <Loader /> :
            noIncidentData ?
                <Emptystatefornodata /> :
                <IncidentPieChart
                    ActionData={[
                        { key: 'Critical', doc_count: critical ?? 0 },
                        { key: 'High', doc_count: high ?? 0 },
                        { key: 'Medium', doc_count: medium ?? 0 },
                        { key: 'Low', doc_count: low ?? 0 }
                    ]}
                    height='200px'
                    color={severityColors}
                    sidebarState={false}
                />
        )
    }, [isloadseverity, low, medium, high, critical])

    const IncidentStackChart = useMemo(() => {
        const noIncidentData = (incidentwaiting ?? 0) === 0 && (openincident ?? 0) === 0 && (closedincident ?? 0) === 0;
        return (isloadincident ? <Loader /> :
            noIncidentData ?
                <Emptystatefornodata /> :
                <IncidentPieChart
                    ActionData={[
                        { key: 'Waiting', doc_count: incidentwaiting ?? 0 },
                        { key: 'Open', doc_count: openincident ?? 0 },
                        { key: 'Closed', doc_count: closedincident ?? 0 },
                    ]}
                    height='200px'
                    color={colorScheme}
                />
        )
    }, [incidentwaiting, openincident, closedincident, isloadincident])

    const MTTR = useMemo(() => {
        return (
            isloadmttr ? <Loader /> :
                mttrData.length == 0 ?
                    <Emptystatefornodata /> :
                    <IncidentMttrChart
                        EventSeries={mttrData}
                        Label={mttrLabel}
                        StartDateTime={startDate}
                        EndDateTime={endDate}
                    />
        )
    }, [isloadmttr, mttrData, startDate, endDate])
    const AssignedInvestigator = useMemo(() => {
        return (
            isloadusers ? <Loader /> :
                assignedInvestigator.length == 0 ?
                    <Emptystatefornodata /> :
                    <IncidentBarChart
                        seriesData={assignedInvestigator}
                        Label={assignedinvLabel}
                    />
        )
    }, [isloadusers, assignedInvestigator])

    const IncidentOverTime = useMemo(() => {
        return (
            isloadincidentOverTime ? <Loader /> :
                incidentOverTime.length == 0 ?
                    <Emptystatefornodata /> :
                    <IncidentOverTimeChart
                        statusTrend={incidentOverTime}
                        label={incidentOverTimelabel}
                        StartDateTime={startDate}
                        EndDateTime={endDate}
                    />
        )
    }, [incidentOverTime, incidentOverTimelabel, startDate, endDate, isloadincidentOverTime])

    const IncidentClosedBySeverity = useMemo(() => {
        return (
            isloadcloseBySeverity ? <Loader /> :
                closedByseverity.length == 0 ?
                    <Emptystatefornodata /> :
                    <IncidentScatterChart
                        EventSeries={closedByseverity}
                        label={closedByseverityLabel}
                        StartDateTime={startDate}
                        EndDateTime={endDate}
                    />
        )
    }, [isloadcloseBySeverity, startDate, endDate, closedByseverity])
    const onSelect = (_event, value) => {
        setSelectedterm(value);
        setIsEvolutionTimeOpen(false);
    };

    const onToggleClick = () => {
        setIsEvolutionTimeOpen(!isevolutiontimeOpen);
    };
    const toggle = toggleRef =>
        <MenuToggle ref={toggleRef} onClick={onToggleClick}
            isExpanded={isevolutiontimeOpen}> {selectedterm}</MenuToggle>
    const Timelist = [
        { term: "Today", key: "option1" },
        { term: "This week", key: "option2" },
        { term: "Last 15 minutes", key: "option3" },
        { term: "Last 30 minutes", key: "option4" },
        { term: "Last 1 hour", key: "option5" },
        { term: "Last 24 hours", key: "option6" },
        { term: "Last 7 days", key: "option7" },
        { term: "Last 30 days", key: "option8" },
        { term: "Last 90 days", key: "option9" },
        { term: "Last 1 year", key: "option10" },
    ]

    const timedropdown =
        <Dropdown
            isOpen={isevolutiontimeOpen}
            onSelect={onSelect}
            onOpenChange={isevolutiontimeOpen => setIsEvolutionTimeOpen(isevolutiontimeOpen)}
            toggle={toggle}
            ouiaId="BasicDropdown"
            shouldFocusToggleOnSelect isScrollable>
            {Timelist.map((item, index) => (
                <DropdownList key={index}>
                    <DropdownItem key={item.key}
                        value={item.term}
                    >{item.term}</DropdownItem>
                </DropdownList>
            ))}
        </Dropdown>

    return (
        <>
            <Grid hasGutter className='pf-v5-u-mt-sm'>
                <GridItem md={5} sm={12} lg={5}>
                    <Card isFullHeight isRounded>
                        <CardTitle align="center" className='title-height'>Incident Overview</CardTitle>
                        {isloadincident ? <div style={{ marginLeft: '40px' }}><Bullseye><TextSkeleton height='50px' width='70%' /></Bullseye></div> :
                            <CardBody>
                                <Flex className='pf-v5-u-justify-content-center' spaceItems={{ default: 'spaceItemsXs', lg: 'spaceItemsXl' }}>
                                    <FlexItem>
                                        <Text component={TextVariants.h1} className='pf-v5-u-text-align-center '>
                                            <span className='pf-v5-u-font-size-4xl'>{incidentcount}</span>
                                        </Text>
                                        <Text component={TextVariants.small} className='pf-v5-u-color-100 grid-text-center'> Incident</Text>
                                    </FlexItem>
                                    <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                    <FlexItem>
                                        <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-size-4xl pf-v5-u-danger-color-100 '>
                                            {formatValue(incidentwaiting ?? 0)}
                                        </Text>
                                        <Text component={TextVariants.small} className='pf-v5-u-danger-color-100 grid-text-center'> Waiting</Text>
                                    </FlexItem>
                                    <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                    <FlexItem>
                                        <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-size-4xl pf-v5-u-warning-color-100'>
                                            {formatValue(openincident ?? 0)}
                                        </Text>
                                        <Text component={TextVariants.small} className='pf-v5-u-warning-color-100 grid-text-center'> Open</Text>
                                    </FlexItem>
                                    <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                    <FlexItem>
                                        <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-size-4xl pf-v5-u-success-color-100'>
                                            {formatValue(closedincident ?? 0)}
                                        </Text>
                                        <Text component={TextVariants.small} className='pf-v5-u-success-color-100 grid-text-center'> Closed</Text>
                                    </FlexItem>
                                </Flex>
                            </CardBody>
                        }
                    </Card>
                </GridItem>
                <GridItem md={5} sm={12} lg={5}>
                    <Card isFullHeight isRounded>
                        <CardTitle align="center" className='title-height' >Task Overview</CardTitle>
                        {isloadtask ? <div style={{ marginLeft: '40px' }}><Bullseye> <TextSkeleton height='50px' width='70%' /></Bullseye></div> :
                            <CardBody>
                                <Flex className='pf-v5-u-justify-content-center' spaceItems={{ default: 'spaceItemsXs', lg: 'spaceItemsXl' }}>
                                    <FlexItem>
                                        <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-size-4xl'>
                                            <Tooltip content={taskcount ?? 0}><div>{formatValue(taskcount ?? 0)}</div></Tooltip>
                                        </Text>
                                        <Text component={TextVariants.small} className='pf-v5-u-color-100 grid-text-center'> Task</Text>
                                    </FlexItem>
                                    <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                    <FlexItem>
                                        <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-size-4xl pf-v5-u-danger-color-100 '>
                                            {formatValue(taskwaiting ?? 0)}
                                        </Text>
                                        <Text component={TextVariants.small} className='pf-v5-u-danger-color-100 grid-text-center'> Waiting</Text>
                                    </FlexItem>
                                    <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                    <FlexItem>
                                        <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-size-4xl pf-v5-u-warning-color-100'>
                                            {formatValue(inProgress ?? 0)}
                                        </Text>
                                        <Text component={TextVariants.small} className='pf-v5-u-warning-color-100 grid-text-center'> In Progress</Text>
                                    </FlexItem>
                                    <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                    <FlexItem>
                                        <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-size-4xl pf-v5-u-success-color-100 '>
                                            {formatValue(completed ?? 0)}
                                        </Text>
                                        <Text component={TextVariants.small} className='pf-v5-u-success-color-100 grid-text-center'> Completed</Text>
                                    </FlexItem>
                                </Flex>
                            </CardBody>
                        }
                    </Card>
                </GridItem>
                <GridItem md={2} sm={12} lg={2}>
                    <Card isFullHeight isRounded>
                        <CardTitle align="center" className='title-height'>Total Alert</CardTitle>
                        <CardBody>
                            <Text component={TextVariants.h1} className='pf-v5-u-text-align-center  pf-v5-u-font-size-4xl'>
                                {isloadalert ?
                                    <Bullseye>
                                        <TextSkeleton />
                                    </Bullseye> : <><Tooltip content={alertcount}><div>{formatValue(alertcount)}</div></Tooltip></> ?? 0}
                            </Text>
                        </CardBody>
                    </Card>
                </GridItem>
            </Grid>
            <div className="pf-v5-u-mt-md">
                <Grid hasGutter>
                    <GridItem md={4} sm={12} lg={4}>
                        <Card isFullHeight isRounded>
                            <CardHeader >
                                <CardTitle className='title-height'>Incident Trend</CardTitle>
                            </CardHeader>

                            <Divider />
                            <CardBody >{IncidentsTrend}</CardBody>
                        </Card>
                    </GridItem>
                    <GridItem md={4} sm={12} lg={4}>
                        <Card isRounded isFullHeight>
                            <CardTitle className='title-height'>Incidents Break Down</CardTitle>
                            <Divider />
                            <CardBody>
                                {IncidentStackChart}
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem md={4} sm={12} lg={4}>
                        <Card isFullHeight isRounded>
                            <CardTitle className='title-height'>Incident Resolution BreakDown</CardTitle>
                            <Divider />
                            <CardBody >{IncidentResolution}</CardBody>
                        </Card>
                    </GridItem>
                </Grid>
                <div className="pf-v5-u-mt-md">
                    <Grid hasGutter>
                        <GridItem md={6} sm={12} lg={6}>
                            <Card isFullHeight isRounded>
                                <CardTitle className='title-height'>MTTR  Open Vs Closed</CardTitle>
                                <Divider />
                                <CardBody >{MTTR}</CardBody>
                            </Card>
                        </GridItem>
                        <GridItem md={6} sm={12} lg={6}>
                            <Card isFullHeight isRounded>
                                <CardTitle className='title-height'>Incident Over Time
                                </CardTitle>
                                <Divider />
                                <CardBody >{IncidentOverTime}</CardBody>
                            </Card>
                        </GridItem>

                    </Grid>
                </div>
            </div>
            <div className="pf-v5-u-mt-md">
                <Grid hasGutter>
                    <GridItem md={6} sm={12} lg={6}>
                        <Card isFullHeight isRounded>
                            <CardTitle >Assigned Investigators</CardTitle>
                            <Divider />
                            <CardBody >{AssignedInvestigator}</CardBody>
                            {/* <CardFooter>
                                <TextContent>
                                    <Text className="pf-v5-u-font-size-sm">Updated Interval 24hrs | Last Update: july 6th 2023 13:48:17  Update Now</Text>
                                </TextContent>
                            </CardFooter> */}
                        </Card>
                    </GridItem>
                    <GridItem md={6} sm={12} lg={6}>
                        <Card isFullHeight isRounded>
                            <CardTitle >Incident Closed by Severity
                            </CardTitle>
                            <Divider />
                            <CardBody >{IncidentClosedBySeverity}</CardBody>
                        </Card>
                    </GridItem>
                </Grid>
            </div>
        </>
    )
}
)
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
});

const mapDispatchToProps = (dispatch) => { return {} }
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(OverviewDashboard)

