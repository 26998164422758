import React from 'react'
import {
  PageSection,
  Card,
  CardBody,
  CardTitle} from '@patternfly/react-core';
import IncidentTaskList from './IncidentTaskList';
const IncidentTaskMain = () => {
  const [count, setCount] = React.useState(0);
  const setTaskTotalCount = (totalCount) => {
    setCount(totalCount);
  }

  return (
    <div>
      <PageSection padding={{ default: 'noPadding' }} className='pf-v5-u-p-sm'>
        <Card>
          <CardTitle>  My tasks ({count})</CardTitle>
          <CardBody>
            <IncidentTaskList setTaskCount={setTaskTotalCount} />
          </CardBody>
        </Card>
      </PageSection>
    </div>
  )
}
export default IncidentTaskMain;