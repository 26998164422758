import React, { useState, useEffect, useMemo } from 'react';
import {
  Chip,
  MenuToggle,
  Select,
  SelectOption,
  SelectList,
  SelectGroup,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  Pagination,
  TextVariants,
  Text,
  Button,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  Modal,
  ModalVariant,
  Badge,
  Card,
  ChipGroup,
  TextInput,
  Tooltip,
  Icon,
  MenuToggleStatus,
  Truncate,
  TextContent,
  TextList,
  TextListItem,
  Popover,
  Bullseye,
  EmptyState,
  EmptyStateHeader,
  EmptyStateIcon,
} from '@patternfly/react-core';
import { Table, Thead, Tr, Th, Tbody, Td, InnerScrollContainer } from '@patternfly/react-table';
import SortAmountDownIcon from '@patternfly/react-icons/dist/esm/icons/sort-amount-down-icon';
import { formatTableTimestamp } from '../../util/Timeutils';
import { ArrowCircleUpIcon, CopyIcon, EditIcon, EyeIcon, PlusCircleIcon, SearchIcon, TrashAltIcon } from '@patternfly/react-icons';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { Link } from 'react-router-dom';
import DeployAgent from './DeployAgent';
import dbApi from '../../services/DbApi'
import { Loader } from 'src/util/Loadingstate';
import { connect, useDispatch } from 'react-redux';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';
import ConfirmBox from '../ConfirmBox/ConfirmBox';
import { alertBoxData } from 'src/Redux/Action';
import { emptyCheck, selectmenuoption, selectmultipleoption } from 'src/util/Validate';
import CommonSelectBox from '../CommonEventPages/CommonSelectBox';
import { handleCopy } from 'src/util/FilterHelper';
import CommonMultiSelectBox from '../CommonEventPages/CommonMultiSelectBox';

const AgentTable = (props) => {
  const dispatch = useDispatch()
  const defaultColumns = {
    agent_id: "ID",
    agent_name: "Name",
    ip: "IP",
    group_name: "Group(s)",
    os_name: "Operating System",
    cluster_node: "Cluster Node",
    version: "Version",
    registration_date: "Registration Date",
    last_keep_alive: "Last Keep Alive",
    status: "Status",
    synced: "Synced",
    agent_key: 'Agent Key',
  };
  const uniqueid = generateUniqueID()
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState(defaultColumns);
  const [rows, setRows] = useState([]);
  const [selectedRowName, setSelectedRowName] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [activeSortIndex, setActiveSortIndex] = useState(7);
  const [activeSortDirection, setActiveSortDirection] = useState('desc');
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const [currentAgentId, setCurrentAgentId] = useState(null);

  const [totalItem, setTotalItem] = useState(0)
  const [copyTip, setCopytip] = useState('Copy')
  const [confirmModalData, setConfirmBox] = useState({
    show: false, msg: '',
    funCall: () => { }, funParam: {}, title: '',
    position: '',
    iconvariant: '',
    submitbtnvariant: '',
    action: '',
  })

  const [actiontype, setActionType] = useState('Add')
  const [loadDetails, setLoadDetails] = useState(false)
  const [agentIP, setAgentIp] = useState('')
  const [selectedOrg, setSelectedOrg] = useState('');
  const [Organization, setOrganization] = useState([]);
  const [validselectedOrg, setValidSelectedOrg] = useState('')
  const [agentname, setAgentName] = useState('');
  const [validAgentName, setValidAgentName] = useState('');
  const [selectedIP, setSelectedIP] = useState([]);
  const [Groups, setGroups] = useState([]);
  const [multiSelectedGroups, setmultiSelectedGroups] = useState(['default'])
  const [selectedCriticality, setSelectedCriticality] = useState([]);
  const [validCriticality, setValidCriticality] = useState('')
  const [validgroup, setValidGroup] = useState('')
  const [validIpaddress, setValidIpAddress] = useState('')
  const [newagentdetail, setNewAgentDetail] = useState({})
  const [newagentdetailmodal, setNewAgentDetailModal] = useState(false)

  const handleNewAgentDetailModal = _event => {
    setNewAgentDetailModal(!newagentdetailmodal);
  };
  const [fieldvalues, setFieldValues] = useState([{
    value: 'any',
    children: 'Any'
  }])
  const handleAgentnameInput = (_event, value) => {
    setAgentName(value);
    setValidAgentName(emptyCheck(value))
  };
  const excludeOrder = ['Last Keep Alive', 'Agent Key']

  //Get agents by calling API
  const getAgentList = async () => {
    let data = {
      limitStart: (perPage * page) - perPage,
      limitLength: perPage,
      search: props?.searchValue,
      sortBy: Object.keys(columns)[activeSortIndex],
      sortOrder: activeSortDirection,
      status: props?.FilterStatus.join(','),
      agentId: ''
    }
    try {
      setIsLoading(true)
      let res = await dbApi.postApi("opensearch/agent_list", data)
      console.log(res)
      let All_agents = []
      if (res.data.data.length > 0) {
        All_agents = res.data.data
        setRows(All_agents)
        setTotalItem(res.data.totalRecord)
        setIsLoading(false)
      }
      else {
        setRows([])
        setTotalItem(0)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setRows([])
      setTotalItem(0)
    }
  }

  useEffect(() => {
    getAgentList()
  }, [page,
    perPage,
    activeSortIndex,
    activeSortDirection,
    props?.searchValue,
    props?.FilterStatus,
    props?.selectedGroup,
    props?.refreshCall
  ])

  //Toolbar Deploy agent modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalToggle = _event => {
    setIsModalOpen(!isModalOpen);
  };
  //add new agent modal 
  const [isANAModalOpen, setIsANAModalOpen] = useState(false);

  const blankForm = () => {
    setGroups([])
    setAgentName('');
    setValidAgentName('');
    setSelectedIP('');
    setValidIpAddress('');
    setSelectedOrg('');
    setValidSelectedOrg('');
    setmultiSelectedGroups(['default']);
    setValidGroup('');
    setValidCriticality('')
    setSelectedCriticality('')
  }

  const handleAddNewAgentModal = async (type) => {

    setActionType(type)
    if (!isANAModalOpen) {
      await getOrganizationList();
    }
    setIsANAModalOpen(!isANAModalOpen);
  };

  //sorting
  const onSort = (event, index, direction) => {
    setActiveSortIndex(index);
    setActiveSortDirection(direction);
  };


  //Pagination in Agent table 
  const handleSetPage = (_evt, newPage) => {
    setPage(newPage);
  };
  const handlePerPageSelect = (_evt, newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

  const renderPagination = (variant) => <Pagination
    itemCount={totalItem}
    // isCompact
    variant={variant}
    page={page}
    perPage={perPage}
    onSetPage={handleSetPage}
    onPerPageSelect={handlePerPageSelect} />;


  const Deployagent = <>
    <Modal
      variant={ModalVariant.large}
      showClose={false}
      isOpen={isModalOpen}
      aria-labelledby="modal-wizard-label"
      aria-describedby="modal-wizard-description"
      onClose={handleModalToggle} hasNoBodyWrapper>

      <DeployAgent CloseWizard={() => setIsModalOpen(false)} />
    </Modal>
  </>

  const renderStatuscolumn = (status) => {
    let badgeStyle = {};
    if (status === 'Active') {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#3E8635" };
      return <Badge style={badgeStyle} >{status}</Badge>
    }
    else if (status === 'Disconnected') {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" };
      return <Badge style={badgeStyle} >{status}</Badge>
    }
    else if (status === 'Never Connected') {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#6A6E73" };
      return <Badge style={badgeStyle} >{status}</Badge>
    }
    else if (status === 'Pending') {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
      return <Badge style={badgeStyle} >{status}</Badge>
    }
    else if (status === 'New Registered') {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
      return <Badge style={badgeStyle} >{status}</Badge>
    }
  };
  const handleDeleteChip = (id) => {
    // alert(id)
    props?.updateFilterStatus()
  }
  //toolbar
  const getChipText = (status) => {
    switch (status) {
      case '1':
        return `Status: Active`;
      case '0':
        return `Status: Disconnected`;
      case '2':
        return `Status: Pending`;
      case '3':
        return `Status: Never Connected`;
      case '4':
        return `Status: New Registered`;
    }
  }

  const ciritcalityOptions = [
    { value: '5', children: 'high' },
    { value: '3', children: 'medium' },
    { value: '1', children: 'low' },
  ]
  const handleMultiSelectChange = (selected) => {
    setmultiSelectedGroups(selected)
  };
  const updateGroupSelectBox = (value, type = 'valueselect', index = 'Value') => {
    setmultiSelectedGroups(value)
    setValidGroup(selectmultipleoption(value))
  }
  const UpdateCriticalityBox = (value, type = 'valueselect', index = 'Value') => {
    setSelectedCriticality(value)
    setValidCriticality(selectmenuoption(value))
  }
  const updateIpSelectBox = (value, type = 'valueselect', index = 'Value') => {
    setSelectedIP(value)
    setValidIpAddress(selectmenuoption(value))
  }
  const updateSelectOption = (option) => {
    setFieldValues(option)
  }
  const updateOrgBox = (value, type = 'valueselect', index = 'Value') => {
    setSelectedOrg(value)
    setValidSelectedOrg(selectmenuoption(value))
  }

  function formValid() {
    let ret = true;
    let status = emptyCheck(agentname)
    setValidAgentName(status)
    if (status == 'error') {
      ret = false
    }
    let menuStatus = selectmenuoption(selectedIP)
    setValidIpAddress(menuStatus)
    if (menuStatus == 'danger') {
      ret = false
    }
    selectmenuoption(selectedOrg)
    setValidSelectedOrg(menuStatus)
    if (menuStatus == 'danger') {
      ret = false
    }
    selectmultipleoption(multiSelectedGroups)
    setValidGroup(menuStatus)
    if (menuStatus == 'danger') {
      ret = false
    }
    selectmenuoption(selectedCriticality)
    setValidCriticality(menuStatus)
    if (menuStatus == 'danger') {
      ret = false
    }

    return ret
  }

  const getOrganizationList = async () => {
    try {
      let res = await dbApi.postApi("opensearch/get_organization_list", { id: '' });
      console.log(res)
      let orglist = []
      if (!res.error) {
        orglist = res.data
        setOrganization(orglist)
      }
      else {
        setOrganization(orglist)
      }
    } catch (error) {
      console.log(error)
      setOrganization([])
    }

  }
  const getAllGroups = async (orgId = '') => {
    try {
      let param = { orgUniqueId: orgId }
      let res = await dbApi.postApi('Opensearch/List_Of_Groups', param);
      console.log('groups', res);
      let groups = []
      if (!res.error) {
        groups = res.groupNames
        setGroups(groups)
      }
      else {
        setGroups(groups)
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (selectedOrg != '') { getAllGroups(selectedOrg); }
  }, [selectedOrg])


  const finalSubmitNewAgent = async () => {
    try {
      let params = {}
      if (actiontype == 'Add') {
        params = {
          name: selectedOrg + '_' + agentname,
          ip: selectedIP,
          Group_Name: multiSelectedGroups.join(','),
          risk_type: selectedCriticality,
          action: '1'
        }
      }
      else {
        params = {
          name: '',
          ip: '',
          Group_Name: '',
          risk_type: selectedCriticality,
          action: '2',
          agentId: currentAgentId
        }
      }
      setIsLoading(true)
      let res = await dbApi.postApi('Opensearch/Agent_Add', params)
      console.log(res)
      if (!res.error) {
        if (actiontype == 'Add') { setNewAgentDetail(res) }
        dispatch(alertBoxData([{ title: res.message, variant: 'success' }]))
        getAgentList()
        if (actiontype == 'Add') { handleNewAgentDetailModal() }
      }
      else {
        dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]))
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }
  const submitForm = () => {
    if (actiontype == 'Add') {
      if (formValid()) {
        handleAddNewAgentModal()
        setConfirmBox({
          show: true,
          msg: 'Add New Agent',
          funCall: finalSubmitNewAgent,
          title: 'Are you sure? ',
          position: 'default',
          iconvariant: 'info',
          submitbtnvariant: 'primary',
        })
      }
    }
    else {
      handleAddNewAgentModal()
      setConfirmBox({
        show: true,
        msg: `This edit can't be reversed`,
        funCall: finalSubmitNewAgent,
        title: 'Are you sure? ',
        position: 'default',
        iconvariant: 'info',
        submitbtnvariant: 'primary',
      })
    }

  }
  const AddnewAgentModal = <React.Fragment>
    <Modal variant={ModalVariant.small}
      title={actiontype == 'Add' ? 'Add New Agent' : `Edit Agent Details `}
      isOpen={isANAModalOpen}
      onClose={() => { handleAddNewAgentModal(); }}
      actions={[<Button key="create" variant="primary" form="modal-with-form-form" onClick={() => { submitForm() }}>
        {actiontype == 'Add' ? 'Submit' : 'Update'}
      </Button>, <Button key="cancel" variant="link" onClick={handleAddNewAgentModal}>
        Cancel
      </Button>]}
    >
      {loadDetails ? <Loader /> :
        <Form id="modal-with-form-form" isHorizontal>
          <FormGroup label="Organization" isRequired fieldId="orgbox">
            <CommonSelectBox
              options={Organization.map((item, index) => ({
                value: item.unique_id,
                children: item.name,
              }))
              }
              isDisabled={actiontype == 'Edit' && true}
              placeholder="Select Organization"
              updateValue={updateOrgBox}
              createOption={false}
              selectedOptions={selectedOrg}
              validStatus={validselectedOrg}
            />
          </FormGroup>
          <FormGroup label="Group" isRequired fieldId="group-box">
            <CommonMultiSelectBox
              initialOptions={Groups.map((item, index) => ({
                value: item,
                children: item,
              }))
              }
              createOption={false}
              onSelectChange={handleMultiSelectChange}
              isDisabled={actiontype == 'Edit' && true}
              updateValue={updateGroupSelectBox}
              placeholderText="Select a group"
              ariaLabel="Your aria label"
              selectedOptions={multiSelectedGroups}
              clearButtonAriaLabel="Clear input value"
              validStatus={validgroup}
            />
          </FormGroup>
          <FormGroup fieldId="basic-form-radio-group" label="Agent Criticality" isRequired>
            <CommonSelectBox
              options={ciritcalityOptions}
              isLoading={false}
              placeholder="Select criticality"
              isDisabled={false}
              type="criticality"
              indexVal={''}
              updateValue={UpdateCriticalityBox}
              selectedOptions={selectedCriticality}
              validStatus={validCriticality}
            />
          </FormGroup>
          <FormGroup label="Agent Name" isRequired>
            <Flex display={{ default: 'inlineFlex' }} spaceItems={{ default: 'spaceItemsXs' }} >
              {actiontype == 'Add' && <FlexItem>{selectedOrg}</FlexItem>}
              <FlexItem >
                <TextInput isRequired type="text" isDisabled={actiontype == 'Edit' && true} id="modal-with-agent-name" name="modal-with-agent-name" value={agentname} validated={validAgentName} onChange={handleAgentnameInput} />
              </FlexItem></Flex>

          </FormGroup>
          <FormGroup label="IP Address" isRequired fieldId="ipaddressbox">
            {actiontype == 'Edit' ?
              <TextInput isDisabled name="ip" value={agentIP} />
              : <CommonSelectBox
                options={fieldvalues}
                placeholder="Select"
                updateValue={updateIpSelectBox}
                createOption={true}
                isDisabled={actiontype == 'Edit' && true}
                selectedOptions={selectedIP}
                updateOptions={updateSelectOption}
                validStatus={validIpaddress}
              />}
          </FormGroup>
        </Form>
      }

    </Modal>
  </React.Fragment>
  const ActionGroupItems = <React.Fragment>
    <ToolbarItem>
      <Button variant="link" icon={<PlusCircleIcon />} onClick={handleModalToggle}>Generate Agent S/W Link</Button>
      {Deployagent}
    </ToolbarItem>
    <ToolbarItem>
      <Button variant="link" icon={<PlusCircleIcon />} onClick={() => { handleAddNewAgentModal('Add'); blankForm(); }}>Add New Agent</Button>
      {AddnewAgentModal}
    </ToolbarItem>
    <ToolbarItem> {renderPagination('top')}</ToolbarItem>
  </React.Fragment>;
  const tableToolbar = <Toolbar id="sortable-toolbar">
    <ToolbarContent>
      <ToolbarItem>
        <Text component={TextVariants.h1} className='pf-v5-u-font-weight-bold'>
          Agents ({totalItem})
        </Text>
      </ToolbarItem>
      {props?.FilterStatus.length > 0 &&
        <ToolbarItem>
          {props?.FilterStatus.map((item, index) => {
            return (
              <ChipGroup>
                <Chip key={index} onClick={() => handleDeleteChip(index)}>
                  {getChipText(item)}
                </Chip>
              </ChipGroup>)
          })}
        </ToolbarItem>
      }
      <ToolbarItem visibility={{ md: 'hidden' }}>
        <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
          if (value === 'asc' || value === 'desc') {
            onSort(event, activeSortIndex, value);
          } else {
            onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'asc');
          }
        }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
          <SortAmountDownIcon />
        </MenuToggle>}>
          <SelectGroup label="Sort column">
            <SelectList>
              {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                {column}
              </SelectOption>)}
            </SelectList>
          </SelectGroup>
          <SelectGroup label="Sort direction">
            <SelectList>
              <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                Ascending
              </SelectOption>
              <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                Descending
              </SelectOption>
            </SelectList>
          </SelectGroup>
        </Select>
      </ToolbarItem>
      <ToolbarItem align={{ default: 'alignRight' }}>{ActionGroupItems} </ToolbarItem>
    </ToolbarContent>
  </Toolbar>;

  const finalDelete = async (deleteid, status) => {
    let Status = ''
    if (status == 'Never Connected') {
      Status = 'never_connected'
    } else {
      Status = status.toLowerCase()
    }
    let param = {
      agentId: deleteid,
      status: Status
    }
    try {
      console.log(deleteid)
      setIsLoading(true)
      let res = await dbApi.postApi("Opensearch/Agent_Delete", param)
      console.log(res)
      if (!res.Agent_Delete.error) {
        dispatch(alertBoxData([{ title: res.Agent_Delete.message, variant: 'success' }]))
        getAgentList()
      }
      else {
        dispatch(alertBoxData([{ title: res.Agent_Delete.message, variant: 'danger' }]))
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const onDelete = (id, status) => {
    setConfirmBox({
      show: true,
      title: 'Permanently delete agent?',
      msg: "Are you sure you want to delete this agent? You will lost all the records of this agent.",
      funCall: () => { finalDelete(id, status) },
      funParam: {},
      position: 'default',
      iconvariant: 'warning',
      submitbtnvariant: 'danger',
    })
  }

  const upgradeVersion = async (agentid) => {
    try {
      console.log(agentid)
      let param = { agents: agentid }
      let res = await dbApi.postApi("Opensearch/update_version", param)
      console.log(res)
      if (!res.error) {
        dispatch(alertBoxData([{ title: res.message, variant: 'success' }]))
        getAgentList()
      }
      else {
        dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]))
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const onUpgrade = (id) => {
    setConfirmBox({
      show: true,
      title: 'Upgrade agent version?',
      msg: "Are you sure to upgrade the version of agent.",
      funCall: () => { upgradeVersion(id) },
      funParam: {},
      position: 'default',
      iconvariant: 'info',
      submitbtnvariant: 'primary',
    })
  }
  console.log('newagentdetail', newagentdetail)
  const NewAgentDetail = useMemo(() => {
    return (<Modal title="New Agent" variant={ModalVariant.small} isOpen={newagentdetailmodal} onClose={handleNewAgentDetailModal} ouiaId="detailagentmodal">
      {newagentdetail !== undefined && newagentdetail !== null ? <TextContent>
        <TextList component="dl">
          <TextListItem component="dt">Agent Id</TextListItem>
          <TextListItem component="dd">{newagentdetail.id}</TextListItem>
          <TextListItem component="dt">Agent Name</TextListItem>
          <TextListItem component="dd">{selectedOrg}_{agentname}</TextListItem>
          <TextListItem component="dt">Agent IP</TextListItem>
          <TextListItem component="dd">{selectedIP}</TextListItem>
          <TextListItem component="dt">Agent Key</TextListItem>
          <TextListItem component="dd">{newagentdetail.key}
            {/* <Button variant="plain" aria-label="Action"  onClick={() => { handleCopy(newagentdetail.key); setCopytip('Copied!') }}><Tooltip content={copyTip}><CopyIcon /></Tooltip></Button> */}
          </TextListItem>
        </TextList>
      </TextContent> :
        <Bullseye>
          <EmptyState>
            <EmptyStateHeader
              titleText="No Data"
              icon={<EmptyStateIcon icon={SearchIcon} />}
              headingLevel="h2"
            />
          </EmptyState>
        </Bullseye>
      }
    </Modal>)
  }, [newagentdetail, selectedOrg, agentname, selectedIP])


  const getAgentDetail = async () => {
    let param = {
      agentId: currentAgentId
    }
    try {
      setLoadDetails(true)
      let res = await dbApi.postApi("Opensearch/getAgentInfo", param)
      console.log(res)
      if (!res.error) {
        let result = res.getAgentInfo
        let criticality = ciritcalityOptions.find((obj) => obj.value == result.Criticality)
        if (Organization && Organization.length > 0 && result.organization !== '') {
          let orgname = Organization.find((obj) => obj.unique_id == result.organization)
          setSelectedOrg(orgname.unique_id)
          getAllGroups(orgname.unique_id)
        }
        if (selectedOrg == '') { setGroups(result.groupName); setmultiSelectedGroups(result.groupName) }
        if (Groups && Groups.length > 0) {
          let groupname = Groups.filter(option => result.groupName.some(item => item == option)).map(obj => obj)
          setmultiSelectedGroups(groupname)
        }

        setAgentName(result.agentName)
        setSelectedCriticality(criticality.value)
        setAgentIp(result.agentIp)
        setValidAgentName('success');
        setValidIpAddress('success')
        setValidSelectedOrg('success')
        setValidGroup('success')
        setValidCriticality('success')


      }
      setLoadDetails(false)
      setIsANAModalOpen(!isANAModalOpen);
    } catch (error) {
      console.log(error)
      setLoadDetails(false)
    }
  }

  const onEditAgentDetails = async (id) => {
    blankForm();
    setActionType('Edit')
    setCurrentAgentId(id)
    if (!isANAModalOpen) {
      await getOrganizationList();
    }
  }

  useEffect(() => {

    (async () => {
      if (Organization.length > 0 && currentAgentId != null) {
        await getAgentDetail();
      }
    })()

  }, [currentAgentId, Organization])

  return <React.Fragment>
    <ConfirmBox confirmModal={confirmModalData} />
    {NewAgentDetail}
    <>
      {tableToolbar}
      {isLoading ? <Card><Loader /></Card> :
        <InnerScrollContainer key="scroller">
          <Table aria-label="Sortable Agent Table" variant='compact' isStickyHeader gridBreakPoint="grid-md" id='agent-table'  >
            <Thead>
              <Tr key='ag-thead-row'>
                {Object.values(columns).map((column, columnIndex) => {
                  const stickyColumnsIndex = [0, 1];
                  const isSticky = stickyColumnsIndex.includes(columnIndex);
                  const sortParams = {
                    sort: {
                      sortBy: {
                        index: activeSortIndex,
                        direction: activeSortDirection
                      },
                      onSort,
                      columnIndex
                    }
                  };
                  return !excludeOrder.includes(column) ? <Th key={columnIndex + uniqueid} {...sortParams} isStickyColumn={isSticky} >{column}</Th> : <Th key={columnIndex + uniqueid} isStickyColumn={isSticky}>{column}</Th>;
                })}
                {localStorage.getItem("role") == 'Admin' && <Th screenReaderText='Action' key={'actioncell'} />}
              </Tr>
            </Thead>
            <Tbody key={'maintablewithdata'}>
              {rows && rows.length > 0 ? rows.map((row, rowIndex) => {
                const { formattedTimeStamp: formattedRegDate } = row?.registration_date ? formatTableTimestamp(row?.registration_date) : { formattedTimeStamp: null };
                const { formattedTimeStamp: formattedLastKeepalive } = row?.last_keep_alive ? formatTableTimestamp(row?.last_keep_alive) : { formattedTimeStamp: null };
                return (
                  <Tr key={rowIndex + row.agent_id}
                    onRowClick={() => setSelectedRowName(rowIndex)}
                    isSelectable
                    isClickable
                    isRowSelected={selectedRowName === rowIndex}
                  >
                    <>
                      <Td key={rowIndex + 'agent_id'} dataLabel={columns[0]}>{row?.agent_id}</Td>
                      <Td key={rowIndex + 'agent_name'} dataLabel={columns[1]}><Link to={"/individual-agent-dashboard/" + row?.agent_name + '/' + row?.agent_id}>{row?.agent_name}</Link></Td>
                      <Td key={rowIndex + 'agent_ip'} dataLabel={columns[2]} width={10}>{row?.ip} </Td>
                      <Td key={rowIndex + 'agent_groupname'} dataLabel={columns[3]} width={10}>
                        {row?.group_name !== undefined && row?.group_name !== null ?
                          (<ChipGroup numChips={2}>
                            {row?.group_name.split(',').map((item, index) => (
                              <Chip key={"chip" + index + uniqueid} isReadOnly>{item}</Chip>
                            ))}
                          </ChipGroup>) : null}
                      </Td>
                      <Td dataLabel={columns[4]} key={rowIndex + 'agent_osname'} width={15}><Truncate content={`${row?.os_name !== undefined ? row?.os_name : ''} ${row?.os_version !== undefined ? row?.os_version : ''}`} /> </Td>
                      <Td dataLabel={columns[5]} width={15} key={rowIndex + 'agent_clusternode'}>{row?.cluster_node} </Td>
                      <Td dataLabel={columns[6]} key={rowIndex + 'agent_version'}>
                        {localStorage.getItem("role") == 'Admin' ? <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                          <FlexItem> {row?.version}</FlexItem>
                          {row?.shouldUpdate == true && row?.status_description == 'Active' && <FlexItem key={'upgadeversion'}>
                            <Tooltip content='Upgrade Available'><Icon status='info' onClick={() => { onUpgrade(row?.agent_id) }}><ArrowCircleUpIcon /></Icon></Tooltip>
                          </FlexItem>}
                        </Flex> : row?.version}
                      </Td>
                      <Td dataLabel={columns[7]} width={15} key={rowIndex + 'agent_regdate'}>{formattedRegDate} </Td>
                      <Td dataLabel={columns[8]} width={15} key={rowIndex + 'agent_lkadate'}>{formattedLastKeepalive} </Td>
                      <Td dataLabel={columns[9]} width={10} key={rowIndex + 'agent_status'}>{renderStatuscolumn(row?.status_description)} </Td>
                      <Td dataLabel={columns[10]} key={rowIndex + 'agent_sync'}>{row.synced} </Td>
                      <Td dataLabel={columns[11]} key={rowIndex + 'agentkey'}>
                        {row?.agent_key !== undefined && row?.agent_key !== null ? <Popover aria-label="Basic popover" hasAutoWidth bodyContent={<div>{row?.agent_key}  <Button variant="plain" aria-label="Action" onClick={() => { handleCopy(row?.agent_key); setCopytip('Copied!') }}><Tooltip content={copyTip}><CopyIcon /></Tooltip></Button> </div>}>
                          <a><EyeIcon /></a>
                        </Popover> : ''}
                      </Td>
                      {localStorage.getItem("role") == 'Admin' && <Td isActionCell key={rowIndex + 'delete agent'}>
                        <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                          <FlexItem key={'agentdetails'}>
                            <Tooltip content='Edit Agent Details'><Icon status='info' onClick={() => { onEditAgentDetails(row?.agent_id) }}><EditIcon /></Icon></Tooltip>
                          </FlexItem>
                          <FlexItem key={'delagent'}>
                            <Tooltip content='Delete'><Icon status='danger' onClick={() => { onDelete(row?.agent_id, row?.status_description) }}><TrashAltIcon /></Icon></Tooltip>
                          </FlexItem>
                        </Flex>
                      </Td>}
                    </>
                  </Tr>
                )
              })
                :
                <Tr>
                  <Td colSpan={10} key='empty-data'>
                    <Emptystatefornodata />
                  </Td>
                </Tr>
              }
            </Tbody>
          </Table>
        </InnerScrollContainer>
      }{renderPagination('bottom')}
    </>

  </React.Fragment >;
};
const mapStateToProps = (state) => ({
  selectedGroup: state.SelectedGroup,
  groupsoption: state.Allgroups
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(AgentTable)