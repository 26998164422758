import React, { useEffect, useRef, useState } from 'react';
import { Select, SelectOption, SelectList, MenuToggle, TextInputGroup, TextInputGroupMain, TextInputGroupUtilities, Button, Alert } from '@patternfly/react-core';
import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-icon';
import dbApi from 'src/services/DbApi';
import IncidentDetail from '../IncidentDetail/IncidentDetail';

const ExistingAlertModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [selectOptions, setSelectOptions] = useState([]);
    const [focusedItemIndex, setFocusedItemIndex] = useState(null);
    const [activeItemId, setActiveItemId] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const textInputRef = useRef();

    const fetchIncidentList = async () => {
        try {
            const response = await dbApi.postApi('incident_management/get_existing_incident_list', {
                title: filterValue,
                limits: 100
            });
            const incidents = response.data;

            // Log the fetched incidents
            console.log('Fetched incidents:', incidents);

            setSelectOptions(incidents.map(incident => ({
                value: incident.incident_id,
                children: incident.title
            })));
            console.log('Select options:', selectOptions);
        } catch (error) {
            console.error('Error fetching incident list:', error.message);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            setIsOpen(true);
        }
        fetchIncidentList();
    }, [filterValue]);



    const createItemId = value => {
        if (!value) {
            console.warn('Value is undefined or empty for createItemId');
            return '';
        }
        return `select-typeahead-${value.replace(' ', '-')}`;
    };

    const setActiveAndFocusedItem = itemIndex => {
        setFocusedItemIndex(itemIndex);
        const focusedItem = selectOptions[itemIndex];
        if (focusedItem) {
            setActiveItemId(createItemId(focusedItem.value));
        }
    };

    const resetActiveAndFocusedItem = () => {
        setFocusedItemIndex(null);
        setActiveItemId(null);
    };
    const closeMenu = () => {
        setIsOpen(false);
        resetActiveAndFocusedItem();
    };
    const onInputClick = () => {
        if (!isOpen) {
            setIsOpen(true);
        } else if (!inputValue) {
            closeMenu();
        }
    };
    const selectOption = (value, content) => {
        setInputValue(String(content));
        setFilterValue('');
        setSelected((value));
        console.log('Selected incident ID:', value);
        closeMenu();
    };
    const onSelect = (_event, value) => {
        if (value) {
            console.log('On select triggered with value:', value); // Log the value
            const selectedOption = selectOptions.find(option => option.value === value);
            if (selectedOption) {
                selectOption(selectedOption.value, selectedOption.children);
            } else {
                console.warn('Selected option not found:', value); // Log if the option is not found
            }
        }
    };


    const onTextInputChange = (_event, value) => {
        console.log('Input value changed:', value); // Debugging input value
        setInputValue(value);
        if (value.length >= 4) { // Only set filterValue if the input length is 4 or more
            setFilterValue(value);
        } else {
            setFilterValue(''); // Clear filter if input is less than 4 characters
        }
        resetActiveAndFocusedItem();
        if (value !== selected) {
            setSelected('');
        }
    };

    const filteredOptions = filterValue.length >= 4
        ? selectOptions.filter(option =>
            option.children.toLowerCase().includes(filterValue.toLowerCase())
        )
        : selectOptions;

    const handleMenuArrowKeys = key => {
        let indexToFocus = 0;
        if (!isOpen) {
            setIsOpen(true);
        }
        if (selectOptions.every(option => option.isDisabled)) {
            return;
        }
        if (key === 'ArrowUp') {
            if (focusedItemIndex === null || focusedItemIndex === 0) {
                indexToFocus = selectOptions.length - 1;
            } else {
                indexToFocus = focusedItemIndex - 1;
            }
            while (selectOptions[indexToFocus].isDisabled) {
                indexToFocus--;
                if (indexToFocus === -1) {
                    indexToFocus = selectOptions.length - 1;
                }
            }
        }
        if (key === 'ArrowDown') {
            if (focusedItemIndex === null || focusedItemIndex === selectOptions.length - 1) {
                indexToFocus = 0;
            } else {
                indexToFocus = focusedItemIndex + 1;
            }
            while (selectOptions[indexToFocus].isDisabled) {
                indexToFocus++;
                if (indexToFocus === selectOptions.length) {
                    indexToFocus = 0;
                }
            }
        }
        setActiveAndFocusedItem(indexToFocus);
    };

    const onInputKeyDown = event => {
        const focusedItem = focusedItemIndex !== null ? selectOptions[focusedItemIndex] : null;
        switch (event.key) {
            case 'Enter':
                if (isOpen && focusedItem && !focusedItem.isAriaDisabled) {
                    onSelect(undefined, focusedItem.value);
                }
                if (!isOpen) {
                    setIsOpen(true);
                }
                break;
            case 'ArrowUp':
            case 'ArrowDown':
                event.preventDefault();
                handleMenuArrowKeys(event.key);
                break;
        }
    };

    const onToggleClick = () => {
        setIsOpen(!isOpen);
        textInputRef?.current?.focus();
    };
    const onClearButtonClick = () => {
        setSelected('');
        setInputValue('');
        setFilterValue('');
        resetActiveAndFocusedItem();
        textInputRef?.current?.focus();
    };

    const toggle = toggleRef => (
        <MenuToggle
            ref={toggleRef}
            variant="typeahead"
            aria-label="Typeahead creatable menu toggle"
            onClick={onToggleClick}
            isExpanded={isOpen}
            isFullWidth
        >
            <TextInputGroup isPlain>
                <TextInputGroupMain
                    value={inputValue}
                    onClick={onInputClick}
                    onChange={onTextInputChange}
                    onKeyDown={onInputKeyDown}
                    id="create-typeahead-select-input"
                    autoComplete="off"
                    innerRef={textInputRef}
                    placeholder="Select an incident"
                    aria-activedescendant={activeItemId}
                    role="combobox"
                    isExpanded={isOpen}
                    aria-controls="select-create-typeahead-listbox"
                />
                {inputValue && (
                    <TextInputGroupUtilities>
                        <Button
                            variant="plain"
                            onClick={onClearButtonClick}
                            aria-label="Clear input value"
                        >
                            <TimesIcon aria-hidden />
                        </Button>
                    </TextInputGroupUtilities>
                )}
            </TextInputGroup>
        </MenuToggle>
    );

    return (
        <>
            <Select
                maxMenuHeight
                id="create-typeahead-select"
                isOpen={isOpen}
                selected={selected}
                onSelect={onSelect}
                onOpenChange={isOpen => !isOpen && closeMenu()}
                toggle={toggle}
                shouldFocusFirstItemOnOpen={false}
            >
                <SelectList id="select-create-typeahead-listbox">
                    {showMessage && (
                        <Alert variant="info" title="Please enter 4 to 5 characters to search." isInline />
                    )}
                    {filteredOptions.map((option, index) => {
                        return (
                            <SelectOption
                                key={option.value}
                                value={option.value}
                                isFocused={focusedItemIndex === index}
                                onClick={() => {
                                    console.log('Clicked option:', option);
                                    selectOption(option.value, option.children);
                                }}
                            >
                                {option.children}
                            </SelectOption>
                        );
                    })}
                </SelectList>
            </Select>
            {selected && (
                <IncidentDetail isEditDisable={false} incidentid={selected} />
            )}
        </>
    );
};

export default ExistingAlertModal;
