import React from 'react';
import {
    CalendarMonth,
    InputGroup,
    InputGroupItem,
    TextInput,
    Button,
    Popover,
    Dropdown,
    DropdownItem,
    DropdownList,
    MenuToggle,
    yyyyMMddFormat
} from '@patternfly/react-core';
import OutlinedCalendarAltIcon from '@patternfly/react-icons/dist/esm/icons/outlined-calendar-alt-icon';
import OutlinedClockIcon from '@patternfly/react-icons/dist/esm/icons/outlined-clock-icon';

const SingleDateTimePicker = ({ dateTime, onDateChange }) => {
    const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
    const [isTimeOpen, setIsTimeOpen] = React.useState(false);
    const [valueDate, setValueDate] = React.useState('DD-MM-YYYY');
    const [valueTime, setValueTime] = React.useState('HH:MM');
    const [dateObj, setDateObj] = React.useState(null);

    const times = Array.from(new Array(24), (_, i) => i);
    const defaultTime = '00:00';

    const today = new Date();
    const startDate = today.toISOString().split('T')[0];

    const disablePastDates = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date >= today;
    };

    const onToggleCalendar = () => {
        setIsCalendarOpen(!isCalendarOpen);
        setIsTimeOpen(false);
    };

    const onToggleTime = () => {
        setIsTimeOpen(!isTimeOpen);
        setIsCalendarOpen(false);
    };

    React.useEffect(()=>{
        if(dateTime)
        {
            const todayDateObj = new Date(dateTime);
            todayDateObj.setHours(0, 0, 0, 0); // Resets the time to 00:00:00
            setDateObj(todayDateObj)
            const formattedDate = yyyyMMddFormat(dateTime)
            setValueDate(formattedDate)
            const hours = String(dateTime.getHours()).padStart(2, '0');
            const minutes = String(dateTime.getMinutes()).padStart(2, '0');
            const time = `${hours}:${minutes}`;
            setValueTime(time);
        }    
    },[dateTime])


    const addTimeToDate = (date, hoursToAdd, minutesToAdd) => 
    {
        // Add hours and minutes to the date
        date.setHours(date.getHours() + hoursToAdd);
        date.setMinutes(date.getMinutes() + minutesToAdd);
        return date;
    };

    const onSelectCalendar = (_event, newValueDate) => {
        const newDate = yyyyMMddFormat(newValueDate);
        // Get today's date with the time set to 00:00:00
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        if (newDate < todayDate) {
            return; // Do nothing if the selected date is in the past
        }
        setDateObj(newValueDate)
        setValueDate(newDate);
        setIsCalendarOpen(!isCalendarOpen);
        if (valueTime === 'HH:MM') {
            setValueTime(defaultTime);
        }

      };
    
     const onSelectTime = (ev,date) => {
        setValueTime(ev?.currentTarget?.textContent);
        setIsTimeOpen(!isTimeOpen);
    };

      React.useEffect(()=>{
        if(valueDate && valueDate!="DD-MM-YYYY")
        {
            let date1 = new Date(dateObj)
            let updatedDate = date1
            if(valueTime!="00:00")
            {
                const timeArray = valueTime.split(":")
                updatedDate = addTimeToDate(date1, parseInt(timeArray[0]), parseInt(timeArray[1]));
            }    
            onDateChange(updatedDate.toISOString());
        }    
      },[valueDate,valueTime])

    const timeOptions = times.map(time => (
        <DropdownItem key={time}>{`${String(time).padStart(2, '0')}:00`}</DropdownItem>
    ));

    const calendar = (
        <CalendarMonth
            date={''}
            rangeStart={startDate}
            onChange={onSelectCalendar}
            validators={[disablePastDates]}
            placeholder="DD-MM-YYYY"

        />
    );

    const timeDropdown = (
        <Dropdown
            onSelect={onSelectTime}
            isOpen={isTimeOpen}
            onOpenChange={isOpen => setIsTimeOpen(isOpen)}
            toggle={toggleRef => (
                <MenuToggle
                    ref={toggleRef}
                    onClick={onToggleTime}
                    isExpanded={isTimeOpen}
                    aria-label="Time picker"
                    icon={<OutlinedClockIcon aria-hidden="true" />}
                />
            )}
        >
            <DropdownList style={{ maxHeight: '200px', overflowY: 'auto' }}>
                {timeOptions}
            </DropdownList>
        </Dropdown>
    );

    const calendarButton = (
        <Button variant="control" aria-label="Toggle the calendar" onClick={onToggleCalendar}>
            <OutlinedCalendarAltIcon />
        </Button>
    );

    return (
        <div style={{ width: '300px' }}>
            <Popover
                position="bottom"
                bodyContent={calendar}
                showClose={false}
                isVisible={isCalendarOpen}
                hasNoPadding
                hasAutoWidth
            >
                <InputGroup>
                    <InputGroupItem>
                        <TextInput
                            type="text"
                            id="date-time"
                            value={`${valueDate} ${valueTime}`}
                            aria-label="date and time picker demo"
                            readOnly
                        />
                    </InputGroupItem>
                    <InputGroupItem>{calendarButton}</InputGroupItem>
                    <InputGroupItem>{timeDropdown}</InputGroupItem>
                </InputGroup>
            </Popover>
        </div>
    );
};

export default SingleDateTimePicker;
