import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MenuToggle, Toolbar, ToolbarContent, ToolbarItem, Pagination, Button, SearchInput, ModalVariant, OverflowMenu, OverflowMenuGroup, OverflowMenuItem, OverflowMenuDropdownItem, Dropdown, DropdownList, ToolbarToggleGroup, ToolbarGroup, ToolbarFilter, DataList, DataListItem, DataListItemRow, DataListCheck, DataListItemCells, DataListCell, Modal, TextContent, Card } from '@patternfly/react-core';
import { Table, Thead, Tr, Th, Tbody, Td, InnerScrollContainer } from '@patternfly/react-table';
import { EllipsisVIcon, FilterIcon, PlusCircleIcon } from '@patternfly/react-icons';
import { useParams } from 'react-router-dom';
import dbApi from 'src/services/DbApi';
import { EmptystateforIncident } from 'src/util/EmptyStateCard';
import { Loader } from 'src/util/Loadingstate';
import { useDispatch } from 'react-redux';
import { alertBoxData } from 'src/Redux/Action';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import ModalCommon from 'src/Components/CommonEventPages/ModalCommon';
import IncidentTtModal from './IncidentTtpModal';
import { formatTableTimestamp } from 'src/util/Timeutils';

const IncidentTtpList = () => {
    const dispatch = useDispatch();
    const userRole = localStorage.getItem("role");
    const columns = {
        tactic: 'Tactic',
        technique: 'Technique',
        sub_technique: 'SubTechnique',
        last_datetime: 'Last Date and Time',
    };

    const orderColumns = [
        {
            label: 'Tactic',
            col: 'tactic'
        },
        {
            label: 'Technique',
            col: 'technique'
        },
        {
            label: 'SubTechnique',
            col: 'sub_technique'
        },
        {
            label: 'Last Date and Time',
            col: 'last_datetime'
        }
    ]
    const defaultColumns = ['Tactic', 'Technique', ' SubTechnique', 'Last Date and Time'];
    const defaultRows = [];
    const excludeOrder = ['SubTechnique']
    const excludeWrapCol = ['Last Date and Time']
    const { id } = useParams();
    const childTaskDetailModalref = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [activeSortIndex, setActiveSortIndex] = useState(3);
    const [activeSortDirection, setActiveSortDirection] = useState('DESC');
    const [totalItem, setTotalItem] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [isKebabDropdownOpen, setIsKebabDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [currentCategory, setCurrentCategory] = useState('Name');
    const [selectedRowName, setSelectedRowName] = useState('');
    const [filters, setFilters] = useState([]);
    const [filteredColumns, setFilteredColumns] = useState(defaultColumns);
    const [filteredRows, setFilteredRows] = useState(defaultRows);
    const [managedColumns, setManagedColumns] = useState(defaultColumns);
    const [managedRows, setManagedRows] = useState(defaultRows);
    const [checkedState, setCheckedState] = useState(Array(defaultColumns.length).fill(true));
    const [paginatedRows, setPaginatedRows] = useState([]);
    const [dataFilters, setDataFilters] = useState({
        name: [],
    })

    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    });
    const [selectedRows, setSelectedRows] = useState([]);
    const isRowSelected = row => selectedRows.includes(row.unique_id);
    const setRowSelected = (row, isSelecting) => {
        setSelectedRows(prevSelected => {
            const otherSelectedRows = prevSelected.filter(r => r !== row.unique_id);
            const newSelectedRows = isSelecting ? [...otherSelectedRows, row.unique_id] : otherSelectedRows;
            console.log('Selected Row IDs:', newSelectedRows); // Log the selected row IDs
            return newSelectedRows;
        });
    };

    const getTtpList = async () => {
        let orderCol = 'last_datetime';
        if (activeSortIndex > 0) {
            const orderResult = orderColumns.find((obj) => obj.label == filteredColumns[activeSortIndex])
            if (orderResult && !excludeOrder.includes(orderResult.label)) {
                orderCol = orderResult.col; // Only set orderCol if it's not excluded
            }
            console.log('orderResult', orderResult);
            console.log('orderCol', orderCol)
        }
        const data = {
            limitStart: (perPage * page) - perPage,
            limitLength: perPage,
            sortByCol: orderCol,
            sortOrder: activeSortDirection.toUpperCase(),
            search: dataFilters.name.length > 0 ? dataFilters.name[0] : '',
            incidentId: id,
        };

        try {
            setIsLoading(true);
            const res = await dbApi.postApi('incident_management/ttp_list', data);
            console.log('TTP_list', res);
            if (res && res.data) {
                setRows(res.data || []);
                setTotalItem(res.totalRecord);
                setManagedRows(res.data);
                setPaginatedRows(res.data);
            } else {
                setRows([]);
                setTotalItem(0);
                setManagedRows([]);
                setPaginatedRows([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching incidents:', error);
            setRows([]);
            setTotalItem(0);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getTtpList();
    }, [page, perPage, activeSortIndex, activeSortDirection, searchValue, dataFilters]);


    const onSort = (event, index, direction) => {
        console.log('sortIndex', activeSortIndex)
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };

    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };

    const handlePerPageSelect = (_evt, newPerPage, newPage) => {
        setPerPage(newPerPage);
        setPage(newPage);
    };

    const renderPagination = (variant) => (
        <Pagination
            itemCount={totalItem}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
        />
    );

    const matchCheckboxNameToColumn = (name) => {
        switch (name) {
            case 'check1':
                return 'Tactic';
            case 'check2':
                return 'Technique';
            case 'check3':
                return 'SubTechnique';
            case 'check4':
                return 'Last Date and Time';
        }
    };
    const matchSelectedColumnNameToAttr = (name) => {
        switch (name) {
            case 'Tactic':
                return 'tactic';
            case 'Technique':
                return 'technique';
            case 'SubTechnique':
                return 'sub_technique';
            case 'Last Date and Time':
                return 'last_datetime';

        }
    };

    const selectAllColumns = () => {
        unfilterAllData();
        setCheckedState(Array(defaultColumns.length).fill(true));
    };

    const handleModalToggle = (_event) => {
        setIsModalOpen(!isModalOpen);
    };

    const onSave = () => {
        setManagedColumns(filteredColumns);
        console.log("filteredColumns")
        console.log(filteredColumns)
        setPaginatedRows(filteredRows);
        setIsModalOpen(!isModalOpen);
    };
    const removePropFromObject = (object, keys) =>
        keys.reduce((obj, prop) => {
            const { [prop]: _, ...keep } = obj;
            return keep;
        }, object);

    const filterData = (checked, name) => {
        const selectedColumn = matchSelectedColumnNameToAttr(name);
        const filteredRows = [];
        if (checked) {
            const updatedFilters = filters.filter((item) => item !== selectedColumn);
            const filteredColumns = defaultColumns.filter(
                (column) => !updatedFilters.includes(matchSelectedColumnNameToAttr(column))
            );
            managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));

            setFilters(updatedFilters);
            setFilteredColumns(filteredColumns);
            setFilteredRows(filteredRows);
        } else {
            let updatedFilters = filters;
            if (typeof selectedColumn != 'undefined') {
                updatedFilters.push(selectedColumn);
            }
            const filteredColumns = managedColumns.filter(
                (column) => !filters.includes(matchSelectedColumnNameToAttr(column))
            );
            managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));

            setFilters(updatedFilters);
            setFilteredColumns(filteredColumns);
            setFilteredRows(filteredRows);
        }
    };

    const unfilterAllData = () => {
        setFilters([]);
        setFilteredColumns(defaultColumns);
        setFilteredRows(managedRows);
    };


    const handleChange = (event, checked) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        filterData(checked, matchCheckboxNameToColumn(target.name));
        const checkedIndex = defaultColumns.findIndex((element) => element === matchCheckboxNameToColumn(target.name));
        const updatedCheckedState = [...checkedState];
        updatedCheckedState[checkedIndex] = value;
        setCheckedState(updatedCheckedState);
    };

    const finalRemoveTask = async () => {
        try {
            setIsLoading(true);
            console.log({ taskUniqueId: selectedRows });
            const ret = await dbApi.deleteApi('incident_management/delete_ttp', { uniqueId: selectedRows });
            let type = 'danger';
            if (!ret.error) {
                type = 'success';
                getTtpList();
            }
            dispatch(alertBoxData([{ title: ret.message, variant: type }]));
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const removeTask = () => {
        setConfirmBox({
            show: true,
            title: 'Permanently delete alert?',
            msg: "Are you sure you want to delete this alert?",
            funCall: finalRemoveTask,
            funParam: {},
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        })
    }

    const tableActionItems = [
        <OverflowMenuDropdownItem key="1" onClick={() => handleModalToggle()}>  Manage Columns</OverflowMenuDropdownItem>,
        ...(userRole !== 'Analyst' ? [<OverflowMenuDropdownItem key="2" onClick={() => removeTask()} isDisabled={selectedRows.length == 0}>  Remove</OverflowMenuDropdownItem>] : [])
    ];

    const tableAction =
        <Dropdown onSelect={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} onOpenChange={isKebabDropdownOpen => setIsKebabDropdownOpen(isKebabDropdownOpen)} toggle={toggleRef => <MenuToggle ref={toggleRef} aria-label="overflow menu" variant="plain" onClick={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} isExpanded={false}>
            <EllipsisVIcon />
        </MenuToggle>} isOpen={isKebabDropdownOpen}>
            <DropdownList >{tableActionItems}</DropdownList>
        </Dropdown>

    const onDelete = (type = '', id = '') => {
        if (type) {
            let temp = dataFilters
            temp[type].splice(0, 1);
            if (type == "tlp") {
                temp['tlpChip'].splice(0, 1);
            }
            setDataFilters(prevDataFilters => ({
                ...prevDataFilters,
                temp
            }));
        } else {
            setDataFilters({ name: [] })
        }
    };

    const onInputChange = newValue => {
        setInputValue(newValue)
    };

    const onNameInput = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        let temp = dataFilters
        temp.name[0] = inputValue
        setDataFilters(prevDataFilters => ({
            ...prevDataFilters,
            temp
        }));
    };

    function buildFilterDropdown() {
        return <React.Fragment>

            <ToolbarFilter chips={dataFilters.name} deleteChip={onDelete} categoryName="name" showToolbarItem={currentCategory === 'Name'}>
                <SearchInput aria-label="name filter" placeholder="Filter by name..." onChange={(_event, value) => onInputChange(value)} value={inputValue} onClear={() => {
                    onInputChange('');
                }} onSearch={onNameInput} />
            </ToolbarFilter>
        </React.Fragment>;
    }


    function renderToolbar() {
        return <Toolbar id="toolbar-with-chip-groups" clearAllFilters={onDelete} collapseListedFiltersBreakpoint="xl">
            <ToolbarContent>
                <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
                    <ToolbarGroup variant="filter-group" style={{
                        lineHeight: '22px',
                        alignItems: 'center'
                    }}>
                        {buildFilterDropdown()}
                    </ToolbarGroup>
                </ToolbarToggleGroup>
                <ToolbarItem variant="overflow-menu">
                    <OverflowMenu breakpoint="md">
                        <OverflowMenuGroup groupType="button" isPersistent>
                            <OverflowMenuItem isPersistent>
                                {tableAction}
                            </OverflowMenuItem>
                            {userRole !== 'Analyst' && <OverflowMenuItem>
                                <Button variant="primary" icon={<PlusCircleIcon />} onClick={() => childTaskDetailModalref.current.setModalBox(true)}> Add TTP</Button>
                            </OverflowMenuItem>}
                        </OverflowMenuGroup>
                    </OverflowMenu>
                </ToolbarItem>
                <ToolbarItem variant="pagination">{renderPagination('top')}</ToolbarItem>
            </ToolbarContent>
        </Toolbar>;
    }

    const handleModalClose = async (isSuccess) => {
        if (isSuccess) {
            await getTtpList();
        }
        setIsLoading(false);
    };
    const tableHeader = useMemo(() => {
        return <Thead>
            <Tr>
                <Th key={'chk'} screenReaderText='key' />
                {managedColumns.map((column, columnIndex) => {
                    const sortParams = {
                        sort: {
                            sortBy: {
                                index: activeSortIndex,
                                direction: activeSortDirection
                            },
                            onSort,
                            columnIndex
                        }
                    };
                    return !excludeOrder.includes(column) ? <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} {...sortParams} >{column}</Th> : <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} >{column}</Th>;
                })}
            </Tr>
        </Thead>

    }, [activeSortIndex, activeSortDirection, managedColumns])

    const renderModal = () => {
        return (
            <Modal
                title="Manage columns"
                isOpen={isModalOpen}
                variant="small"
                description={
                    <TextContent>
                        <Button isInline onClick={selectAllColumns} variant="link">
                            Select all
                        </Button>
                    </TextContent>
                }
                onClose={handleModalToggle}
                actions={[
                    <Button key="save" variant="primary" onClick={onSave}>
                        Save
                    </Button>,
                    <Button key="cancel" variant="secondary" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                ]}
            >
                <DataList aria-label="Table column management" id="table-column-management" isCompact>
                    {
                        defaultColumns.map((obj, index) => {
                            return <DataListItem key={index} aria-labelledby={"col-mgmt-item-" + index}>
                                <DataListItemRow>
                                    <DataListCheck
                                        aria-labelledby={"col-mgmt-item-" + index}
                                        checked={checkedState[index]}
                                        name={"check" + (index + 1)}
                                        id={"check" + index}
                                        onChange={handleChange}
                                    />
                                    <DataListItemCells
                                        dataListCells={[
                                            <DataListCell id={"col-mgmt-item-" + index} key={"col-mgmt-item-" + index}>
                                                <label htmlFor={"check" + index}>{obj}</label>
                                            </DataListCell>
                                        ]}
                                    />
                                </DataListItemRow>
                            </DataListItem>
                        })
                    }
                </DataList>
            </Modal>
        );
    };

    return (
        <>
            {renderToolbar()}
            {isLoading ? (
                <Loader />
            ) : (
                <InnerScrollContainer key="scroller">
                    <Table aria-label="Incident Observable Table" variant="compact">
                        {tableHeader}
                        <Tbody>
                            {rows && rows.length > 0 ? (
                                paginatedRows.map((row, rowIndex) => {
                                    const { formattedTimeStamp: lastDatetime } = formatTableTimestamp(row.last_datetime);

                                    return (
                                        <Tr key={rowIndex + row.incident_id}
                                            onRowClick={() => setSelectedRowName(row.unique_id)}
                                            isSelectable
                                            isClickable
                                            isRowSelected={selectedRowName === row.unique_id}>
                                            <Td
                                                key={"chk-" + rowIndex}
                                                select={{
                                                    rowIndex,
                                                    onSelect: (_event, isSelecting) => setRowSelected(row, isSelecting),
                                                    isSelected: isRowSelected(row),
                                                    isDisabled: row?.process_status == 1,
                                                }}
                                            />
                                            {
                                                typeof row?.tactic != 'undefined' &&
                                                <Td dataLabel={columns.tactic}>
                                                    {row.tactic}
                                                </Td>
                                            }
                                            {
                                                typeof row?.technique != 'undefined' &&
                                                <Td dataLabel={columns.technique}>
                                                    {row.technique}
                                                </Td>
                                            }
                                            {
                                                typeof row?.sub_technique != 'undefined' &&
                                                <Td dataLabel={columns.sub_technique}>
                                                    {row.sub_technique}
                                                </Td>
                                            }

                                            {
                                                typeof row?.last_datetime != 'undefined' &&
                                                <Td dataLabel={columns.last_datetime}>
                                                    {lastDatetime}
                                                </Td>
                                            }
                                        </Tr>
                                    );
                                })
                            ) : (
                                <Tr>
                                    <Td colSpan={Object.keys(columns).length} key="empty-data">
                                        <EmptystateforIncident />
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </InnerScrollContainer>
            )}
             {renderPagination('bottom')}
            {renderModal()}
            <ModalCommon
                title="Create new TTP(s)"
                ref={childTaskDetailModalref}
                variant={ModalVariant.medium}
                onclose={() => childTaskDetailModalref.current.setModalBox(false)}
            >
                <IncidentTtModal
                    onTaskAdded={getTtpList}
                    IncidentId={id}
                    onclose={() => childTaskDetailModalref.current.setModalBox(false)}
                    onModalClose={handleModalClose}
                />
            </ModalCommon>
            <ConfirmBox confirmModal={confirmModalData} />
        </>
    );
};
export default IncidentTtpList;
