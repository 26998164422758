import { Empty_State, FieldData_EventBar } from '../Constant/constants'

export const eventfieldData = (payload) => {
  return {
    type: FieldData_EventBar,
    data: payload
  }
}
export const allMappingKeys = (payload) => {
  return {
    type: 'Mapping_keys',
    data: payload
  }
}
export const usedTheme = (payload) => ({
  type: 'Used_Theme',
  data: payload
});

export const showLoader = ((payload) => {
  return {
    type: 'START_LOADING',
    data: {
      show:payload
    }
  }
})

export const StartEndDate =((startDate,endDate,term,currentdatetime)=>{
  return{
  type:'Start_End_Date',
  data:{
    StartDate:startDate,
    EndDate:endDate,
    Term:term,
    // currentDateTime:currentdatetime
  }
  }
})
export const FilterData = ((payload) => {
  return {
    type: 'Form_Filter',
    data: payload
  }
})
export const Filterrulelevel = ((payload) => {
  return {
    type: 'Filter_rule_level',
    data: payload
  }
})
export const SearchedText=((payload)=>{
  return{
    type:'Search_Text',
    data:payload
  }
})

export const alertBoxData = (payload) => ({
  type: 'ALERT_BOX',
  data: payload
});

export const removeAlertData = (payload) => ({
  type: 'REMOVE_ALERT',
  data: payload
});

export const AllGroups = (payload) => ({
  type: 'All_Groups_List',
  data: payload
});
export const selectedGroup=((payload)=>{
  return{
    type:'Selected_Group',
    data:payload
  }
})
export const callHeaderAG=((payload)=>{
  return{
    type:'Header_Group_Agent',
    data:payload
  }
})

export const GloablInterval = (payload) => ({
  type: 'Global_Interval',
  data: payload
});

export const IncludeNetworkeve = (payload) => ({
  type: 'Inc_NW_Eve',
  data: payload
});
export const IncludeScafields = (payload) => ({
  type: 'ScaFields',
  data: payload
});

export const SidebarOpenclose = (payload) => ({
  type: 'Sidebar_state',
  data: payload
});