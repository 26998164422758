import React, { useEffect, useState, useRef, forwardRef, useMemo } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Divider,
    Flex,
    FlexItem,
    Grid,
    GridItem,
    Modal,
    Progress,
    Text,
    TextContent,
    TextVariants,
    ModalVariant,
    ProgressVariant,
    ProgressSize,
    Tooltip,
    Bullseye,
    Tabs,
    Tab,
    TabTitleText,
    TabContentBody,
} from '@patternfly/react-core'
import dbApi from 'src/services/DbApi'
import ScaPFTrend from './ScaPFTrend';
import TopSCAAsset from './TopSCAAsset';
import LatestPassedfailedLog from './BkLatestPfLog';
import BenchmarkScore from './BenchmarkScore';
import TopSCARule from './TopSCARule';
import AffectedAgent from './AffectedAgentTable';
import { connect, useDispatch } from 'react-redux';
import { FilterData } from 'src/Redux/Action';
import { Emptystatefornodata } from 'src/util/EmptyStateCard';
import { Loader, RoundSkeleton, TextSkeleton } from 'src/util/Loadingstate';
import LatestScaPfLog from './LatestScaPfLog';
import TableViewForChart from '../AllCharts/TableViewForChart';
import { formatValue } from 'src/util/ChartHelper';
import { UpdateFilterData } from 'src/util/FilterHelper';

const SCADashboard = forwardRef(function SCADashboard(props, ref) {
    const dispatch = useDispatch();
    const childLatestPFLogsRef = useRef(null);
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [affectedagents, setAffectedAgents] = useState(0)
    const [passed, setpassed] = useState(0)
    const [failed, setFailed] = useState(0)
    const [notapplicable, setNotapplicable] = useState(0)
    const [benchmark, setBenchmark] = useState(0)
    const [assessments, setAssessments] = useState(0)
    const [passedratio, setPassedRatio] = useState(0)
    const [failedratio, setFailedratio] = useState(0)
    const [statusTrend, setStatusTrend] = useState([]);
    const [statusTrendlabel, setStatusTrendlabel] = useState([]);
    const [assetseries, setAssetSeries] = useState([]);
    const [moreassetseries, setMoreAssetSeries] = useState([]);
    const [toprule, setTopRule] = useState([]);
    const [moreruledesc, setMoreRuleDesc] = useState([]);
    const [latestpfLog, setlatestpfLog] = useState([]);
    const [pflogscrollId, setPFlogScrollID] = useState('');
    //Loading states
    const [isloadTrend, setIsLoadTrend] = useState(false)
    const [isloadAgent, setIsLoadAgent] = useState(false)
    const [isloadToprule, setIsLoadToprule] = useState(false)
    const [isloadPf, setIsLoadPf] = useState(false)

    // const [morelatestpfLog, setMorelatestpfLog] = useState([]);
    //Modal states
    const [modalStates, setModalStates] = useState({
        modalAssets: false,
        modalLatestpf: false,
        modalrules: false,
        modalaffectedagent: false,

    });
    const handleModalToggle = (modalName) => {
        setModalStates((prevStates) => ({
            ...prevStates,
            [modalName]: !prevStates[modalName],
        }));
    };
    const [activeTabKey, setActiveTabKey] = useState(0);
    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex)
    }
    const getScaStatusTrend = async (data) => {
        try {
            setIsLoadTrend(true)
            let ScaTrend = await dbApi.postApi("Opensearch/Sca_Passed_Failed_Trend", data);
            console.log(ScaTrend);
            let StatusTrend = [];
            let StatusTrendlabel = ""
            let trendData = ScaTrend.Sca_Passed_Failed_Trend
            if (Object.keys(trendData).length !== 0) {
                StatusTrend = trendData.Sca_Passed_Failed_Trend;
                StatusTrendlabel = trendData.interval;
                setStatusTrend(StatusTrend)
                setStatusTrendlabel(StatusTrendlabel)
            }
            else {
                setStatusTrend(StatusTrend)
                setStatusTrendlabel(StatusTrendlabel)
            }
            setIsLoadTrend(false)
        }
        catch (error) {
            console.log(error)
            setStatusTrend([])
            setStatusTrendlabel('')
            setIsLoadTrend(false)
        }
    }
    const getScaAllAssement = async (data) => {
        try {
            setIsLoadAgent(true)
            // let sca = await dbApi.postApi("Opensearch/Security_Configuration_Assement", data)
            let sca = await dbApi.postApi("Opensearch/Get_Total_Sca_Passed_Failed_Not_Applicable", data)
            console.log(sca);
            let Assessments = 0
            let Passed = 0
            let Failed = 0
            let NotApplicable = 0
            let PassedRatio = 0
            let FailedRatio = 0
            let AllCount = sca.Get_Total_Sca_Passed_Failed_Not_Applicable

            if (Object.keys(AllCount).length > 0) {
                Assessments = AllCount.all_assessment
                //passed failed notapplicable agent
                Passed = AllCount.passed_count
                Failed = AllCount.failed_count
                NotApplicable = AllCount.not_applicable_count
                //ratio 
                PassedRatio = AllCount.passed_percentage
                FailedRatio = AllCount.failed_percentage

                setAssessments(Assessments)
                setpassed(Passed)
                setFailed(Failed)
                setNotapplicable(NotApplicable)
                setPassedRatio(PassedRatio)
                setFailedratio(FailedRatio)
            }
            else {
                setAssessments(Assessments)
                setpassed(Passed)
                setFailed(Failed)
                setNotapplicable(NotApplicable)
                setPassedRatio(PassedRatio)
                setFailedratio(FailedRatio)
            }
            setIsLoadAgent(false)

        }
        catch (error) {
            console.log(error);
            setAssessments(0)
            setpassed(0)
            setFailed(0)
            setNotapplicable(0)
            setPassedRatio(0)
            setFailedratio(0)
            setIsLoadAgent(false)
        }
    }

    const getBenchmarkScore = async (data) => {
        try {
            let res = await dbApi.postApi("Opensearch/Get_Benchmark_Score", data)
            console.log("benchmark", res)
            let Benchmark = 0
            if (res.Get_Benchmark_Score > 0) {
                Benchmark = res.Get_Benchmark_Score
                setBenchmark(Benchmark)
            }
            else {
                setBenchmark(Benchmark)
            }
        } catch (error) {
            console.log(error)
            setBenchmark(0)
        }
    }

    const getTopAgent = async (data) => {
        try {
            let res = await dbApi.postApi("Opensearch/Top_Agent_Sca", data)
            console.log(res)
            let AffectedAgents = 0
            let Assetseries = [];
            let MoreAssetseries = []
            let AllAgents = res.Top_Agent_Sca
            if (AllAgents.length > 0) {
                AffectedAgents = AllAgents.length
                Assetseries = AllAgents.slice(0, 5);
                MoreAssetseries = AllAgents
                setAssetSeries(Assetseries)
                setMoreAssetSeries(MoreAssetseries)
                setAffectedAgents(AffectedAgents)
            }
            else {
                setAssetSeries(Assetseries)
                setMoreAssetSeries(MoreAssetseries)
                setAffectedAgents(AffectedAgents)
            }

        } catch (error) {
            console.log(error)
            setAssetSeries([])
            setMoreAssetSeries([])
            setAffectedAgents(0)
        }
    }
    const getTopSCARule = async (data) => {
        try {
            setIsLoadToprule(true)
            let res = await dbApi.postApi("Opensearch/Top_Rules_SCA ", data)
            console.log(res)
            let TopRule = []
            let AllRules = res.Top_Rules_SCA
            if (AllRules.length !== 0) {
                TopRule = AllRules
                setTopRule(TopRule)
            }
            else {
                setTopRule(TopRule)
            }
            setIsLoadToprule(false)
        } catch (error) {
            console.log(error)
            setTopRule([])
            setIsLoadToprule(false)
        }
    }
    // Opensearch/Security_Configuration_Latest_Passed_Failed 
    const getLatestPF = async (data) => {
        try {
            setIsLoadPf(true)
            let latest_pf = await dbApi.postApi("Opensearch/Security_Configuration_Latest_Passed_Failed", data);
            console.log(latest_pf)
            let logdata = latest_pf.Security_Configuration_Latest_Passed_Failed
            let Log = [];
            let scrollId = ''
            if (Object.keys(logdata).length !== 0) {
                Log = logdata.Security_Configuration.body.hits.hits
                scrollId = logdata.Security_Configuration.body._scroll_id

                setlatestpfLog(Log)
                setPFlogScrollID(scrollId)
            }
            else {
                setlatestpfLog(Log)
                setPFlogScrollID(scrollId)
            }
            setIsLoadPf(false)

        }
        catch (error) {
            console.log(error)
            setlatestpfLog([])
            setPFlogScrollID('')
            setIsLoadPf(false)
        }
    }
    useEffect(() => {
        if (childLatestPFLogsRef.current) {
            childLatestPFLogsRef.current.setLatestPFLog(latestpfLog, pflogscrollId, modalStates.modalLatestpf);
        }
    }, [latestpfLog, pflogscrollId, modalStates.modalLatestpf]);

    const setLatestPFData = () => {
        let data = latestpfLog
        setlatestpfLog([])
        setTimeout(function () { setlatestpfLog(data) }, 100);
    }

    const SecurityConfigurationApi = async (startDate, endDate, allFields) => {
        let staticfield = []
        if (props?.ScaFieldsgroup == 'cis') {
            staticfield = [{
                "fields": 'rule.rbi',
                'condition': 'does_not_exist',
            }]
        }
        else if (props?.ScaFieldsgroup == 'rbi') {
            staticfield = [{
                "fields": 'rule.rbi',
                'condition': 'exists',
            }]
        }
        else {
            staticfield = staticfield
        }
        const Fields = [...allFields, ...staticfield]
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
        };

        try {
            setStartDate(startDate);
            setEndDate(endDate)
            getBenchmarkScore(data);
            getScaAllAssement(data);
            getScaStatusTrend(data);
            getTopAgent(data)
            getTopSCARule(data)
            getLatestPF(data);
            console.log(data)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (props.dates.startDate != "") {
            let Dates = props.dates
            SecurityConfigurationApi(Dates.startDate, Dates.endDate, props.filterformdata)
        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata, props?.ScaFieldsgroup]);

    const setAssetData = () => {
        let assetdata = moreassetseries
        setMoreAssetSeries([])
        setTimeout(function () { setMoreAssetSeries(assetdata) }, 100)
    }

    const SCApfTrend = useMemo(() => {
        return (
            isloadTrend ? <Loader /> : statusTrend.length > 0 ?
                <ScaPFTrend
                    ScaTrendData={statusTrend}
                    ScaTrendlabel={statusTrendlabel}
                    StartDateTime={startDate}
                    EndDateTime={endDate} />
                :
                <Emptystatefornodata />
        )
    }, [isloadTrend, statusTrend, statusTrendlabel, startDate, endDate])

    const SCATopAgents = useMemo(() => {
        return (
            isloadAgent ? <Loader /> : assetseries.length > 0 ?
                <TopSCAAsset AssetsData={assetseries} height={250} />
                :
                <Emptystatefornodata />
        )
    }, [isloadAgent, assetseries])
    return (<>
        {/* <Bullseye> */}
        <Grid hasGutter>
            <GridItem md={6} lg={2} sm={6}>
                <Card isFullHeight isRounded >
                    <CardTitle align="center" className='title-height' >Benchmark Score</CardTitle>
                    {isloadAgent ?
                        <Bullseye> <RoundSkeleton width='30%' /></Bullseye>
                        :
                        <BenchmarkScore score={benchmark ?? 0} height='100px' />
                    }
                </Card>
            </GridItem>
            <GridItem md={6} lg={2} sm={6}>
                <Card isFullHeight isRounded >
                    <CardTitle align="center" className='title-height'>Affected Agents</CardTitle>
                    <CardBody >
                        <TextContent>
                            {isloadAgent ? <TextSkeleton /> :
                                <Text component={TextVariants.h1} className='pf-v5-u-info-color-100 pf-v5-u-text-align-center'>
                                    <a onClick={() => { handleModalToggle('modalaffectedagent') }} className='pf-v5-u-font-size-4xl pf-v5-u-color-100'>
                                        {/* <Icon size='md' isInline><SecurityIcon /></Icon> */}
                                        {affectedagents ?? 0}</a>
                                    <Modal
                                        variant={ModalVariant.large}
                                        title={`Affected Agents (${affectedagents})`}
                                        isOpen={modalStates.modalaffectedagent}
                                        onClose={() => { handleModalToggle('modalaffectedagent') }}
                                    >

                                        <Divider className='modal-Divider' />
                                        <Card>
                                            <AffectedAgent AgentData={moreassetseries} />
                                        </Card>
                                    </Modal>
                                </Text>
                            }
                        </TextContent>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem md={8} lg={6} sm={8}>
                <Card isFullHeight isRounded>
                    <CardTitle align="center" className='title-height'>Assessments</CardTitle>
                    <CardBody >
                        {isloadAgent ? <TextContent><TextSkeleton /> </TextContent> :
                            <Flex className='pf-v5-u-justify-content-center' spaceItems={{ default: 'spaceItemsXs', lg: 'spaceItemsLg' }} alignItems={{ default: 'alignItemsCenter' }}>
                                <FlexItem>
                                    {isloadAgent ? <TextSkeleton /> :
                                        <React.Fragment>
                                            <Tooltip content={assessments}>
                                                <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-size-4xl pf-v5-u-font-weight-bold'>
                                                    <a className='pf-v5-u-color-100'> {formatValue(assessments) ?? 0}</a>
                                                </Text>
                                            </Tooltip>
                                            <Text component={TextVariants.small} className='pf-v5-u-color-100 grid-text-center'>Total</Text>
                                        </React.Fragment>
                                    }
                                </FlexItem>
                                <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                <FlexItem>
                                    <Tooltip content={`Passed: ${passed}`}>
                                        <React.Fragment>
                                            <Text component={TextVariants.h1} className='pf-v5-u-font-weight-bold pf-v5-u-font-size-4xl pf-v5-u-text-align-center'>
                                                <a onClick={() => dispatch(FilterData(UpdateFilterData(props.filterformdata, 'data.sca.check.result', 'is', 'passed')))} className=' pf-v5-u-success-color-200'>  {formatValue(passed) ?? 0}</a>
                                            </Text>
                                            <Text component={TextVariants.small} className='pf-v5-u-success-color-100 grid-text-center'>Passed</Text>
                                        </React.Fragment>
                                    </Tooltip>
                                </FlexItem>
                                <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                <FlexItem>
                                    <Tooltip content={`Failed: ${failed}`}>
                                        <React.Fragment>
                                            <Text component={TextVariants.h1} className=' pf-v5-u-font-weight-bold pf-v5-u-font-size-4xl pf-v5-u-text-align-center'>
                                                <a onClick={() => dispatch(FilterData(UpdateFilterData(props.filterformdata, 'data.sca.check.result', 'is', 'failed')))} className='pf-v5-u-danger-color-200'> {formatValue(failed) ?? 0} </a>
                                            </Text>
                                            <Text component={TextVariants.small} className='pf-v5-u-danger-color-100 grid-text-center'>Failed</Text>
                                        </React.Fragment>
                                    </Tooltip>
                                </FlexItem>
                                <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                <FlexItem>
                                    <Tooltip content={`Not Applicable: ${notapplicable}`}>
                                        <React.Fragment>
                                            <Text component={TextVariants.h1} className=' pf-v5-u-text-align-center pf-v5-u-font-weight-bold pf-v5-u-font-size-4xl'>
                                                <div><a onClick={() => dispatch(FilterData(UpdateFilterData(props.filterformdata, 'data.sca.check.result', 'is', 'not applicable')))} className='pf-v5-u-info-color-100' >{formatValue(notapplicable) ?? 0}</a></div>
                                            </Text>
                                            <Text component={TextVariants.small} className='pf-v5-u-info-color-100 grid-text-center'>Not Applicable</Text>
                                        </React.Fragment>
                                    </Tooltip>
                                </FlexItem>
                            </Flex>

                        }
                    </CardBody>
                </Card>
            </GridItem>
            {/* <GridItem md={4} lg={2} sm={12}>
                <Card isFullHeight isRounded>
                    <CardTitle align="center" className='title-height'>Not Applicable </CardTitle>
                    <CardBody >
                        <TextContent>
                            {isloadAgent ? <TextSkeleton /> :
                                <Text component={TextVariants.h1} className='pf-v5-u-info-color-100 pf-v5-u-text-align-center'>
                                    <a onClick={() => dispatch(FilterData([{ fields: 'data.sca.check.result', condition: 'is', value: 'not applicable' }]))}
                                        className='pf-v5-u-font-size-4xl'> 
                                        <Tooltip content={notapplicable}><div>{formatValue(notapplicable) ?? 0}</div></Tooltip></a>
                                </Text>
                            }
                        </TextContent>
                    </CardBody>
                </Card>
            </GridItem> */}
            <GridItem md={4} lg={2} sm={4}>
                <Card isFullHeight isRounded >
                    <CardTitle align="center" className='title-height'>Detection & Remediation Ratio</CardTitle>
                    {/* <CardBody className='pf-v5-u-mt-md  pf-v5-u-mt-md-on-lg   pf-v5-u-mt-xl-on-md pf-v5-u-mt-xl-on-xl'> */}
                    {isloadAgent ?
                        <div > <TextSkeleton /></div> :
                        <>
                            <CardBody className='pf-v5-u-mt-lg'>
                                <Progress aria-label="passed"
                                    value={passedratio}
                                    size={ProgressSize.sm}
                                    style={{ '--pf-v5-c-progress__bar--before--BackgroundColor': '#3E8635' }}
                                    label={`${passedratio}%`}
                                />

                                <Progress aria-label="failed"
                                    value={failedratio}
                                    size={ProgressSize.sm}
                                    style={{ '--pf-v5-c-progress__bar--before--BackgroundColor': '#C9190B' }}
                                    label={`${failedratio}%`}
                                />
                            </CardBody>
                        </>
                    }
                </Card>
            </GridItem>
        </Grid>
        {/* </Bullseye> */}
        <Grid hasGutter className='pf-v5-u-mt-sm'>
            <GridItem md={props?.hideCard ? 12 : 12} sm={props?.hideCard ? 12 : 12} lg={props?.hideCard ? 12 : 6}>
                <Card isFullHeight>
                    <CardTitle >SCA Passed/Failed Status Trend</CardTitle>
                    <Divider />
                    <CardBody> {SCApfTrend}</CardBody>
                </Card>
            </GridItem>
            {props?.hideCard ? '' :
                <GridItem md={12} sm={12} lg={6}>
                    <Card isFullHeight >
                        <CardTitle > Top Affected Agents ({moreassetseries.length})</CardTitle>
                        <Divider />
                        <CardBody > {SCATopAgents} </CardBody>
                        {moreassetseries.length > 0 &&
                            <CardFooter>
                                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                    <Button variant="link" onClick={() => { handleModalToggle('modalAssets'); setAssetData(); setActiveTabKey(0) }}> More </Button>
                                    <Modal
                                        variant={ModalVariant.large}
                                        title={`Top Affected Agents (${moreassetseries.length})`}
                                        isOpen={modalStates.modalAssets}
                                        onClose={() => handleModalToggle('modalAssets')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                                <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                    {isloadAgent ? <Loader /> : <TableViewForChart Tabledata={moreassetseries.map((item) => ({ key: item.agent_name, count: item.total_count }))} columnData={["Agent", "Count"]} filterFields={'agent.name'} />}
                                                </Tab>
                                                <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                    {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>   {isloadAgent ? <Loader /> : moreassetseries.length > 0 ?
                                                        <TopSCAAsset AssetsData={moreassetseries} height={600} />
                                                        :
                                                        <Emptystatefornodata />
                                                    }</TabContentBody>}
                                                </Tab>
                                            </Tabs>
                                        </Card>
                                    </Modal>
                                </Flex>
                            </CardFooter>
                        }
                    </Card>
                </GridItem>
            }
        </Grid>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={12} sm={12} lg={5}>
                    <Card isFullHeight>
                        <CardTitle>Top Rules</CardTitle>
                        <Divider />
                        <CardBody>
                            {isloadToprule ? <Loader /> :
                                <TopSCARule RuleDesc={toprule.slice(0, 10)} inModal={false} />
                            }
                        </CardBody>
                        {toprule.length > 0 &&
                            <CardFooter>
                                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                    <Button variant="link" onClick={() => handleModalToggle('modalrules')}> More </Button>
                                    <Modal
                                        variant={ModalVariant.large}
                                        title='Top Rules'
                                        isOpen={modalStates.modalrules}
                                        onClose={() => handleModalToggle('modalrules')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            <TopSCARule RuleDesc={toprule} inModal={true} />
                                        </Card>
                                    </Modal>
                                </Flex>
                            </CardFooter>
                        }
                    </Card>
                </GridItem>
                <GridItem md={12} sm={12} lg={7}>
                    <Card isFullHeight>
                        <CardTitle > Latest Passed/Failed </CardTitle>
                        <Divider />
                        <CardBody >
                            {isloadPf ? <Loader /> :
                                // <LatestPassedfailedLog ref={childLatestPFLogsRef} />
                                <LatestScaPfLog IsModal={false} />
                            }
                        </CardBody>
                        {toprule.length > 0 &&
                            <CardFooter>
                                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                    <Button variant="link" onClick={() => { handleModalToggle('modalLatestpf'); setLatestPFData() }}> More </Button>
                                    <Modal
                                        // variant={ModalVariant}
                                        title='Latest Passed/Failed'
                                        isOpen={modalStates.modalLatestpf}
                                        onClose={() => handleModalToggle('modalLatestpf')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            {/* <LatestPassedfailedLog ref={childLatestPFLogsRef} /> */}
                                            <LatestScaPfLog IsModal={true} />
                                        </Card>
                                    </Modal>
                                </Flex>
                            </CardFooter>
                        }
                    </Card>
                </GridItem>
            </Grid>
        </div>
    </>
    )
}
)

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    ScaFieldsgroup: state.ScaFields
});

const mapDispatchToProps = (dispatch) => { return {} };

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(React.memo(SCADashboard))