import { ActionGroup, Badge, Button, FormGroup, Grid, GridItem, Switch, TextArea, ToggleGroup, ToggleGroupItem, Tooltip } from '@patternfly/react-core';
import { InfoAltIcon, SaveIcon, SkullIcon } from '@patternfly/react-icons';
import React, { useState, useEffect } from 'react';
import dbApi from 'src/services/DbApi';
import { useDispatch } from 'react-redux';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import CommonSelectBox from 'src/Components/CommonEventPages/CommonSelectBox';
import { alertBoxData } from 'src/Redux/Action';
import IndexInputGroup from 'src/Components/OnBoarding/IndexInputGroup';
import { Loader } from 'src/util/Loadingstate';
import { selectmenuoption } from 'src/util/Validate';

const IncidentObservableModal = ({ onclose, IncidentId, onModalClose }) => {
    const dispatch = useDispatch();
    const initialFormset = {};
    const [formData, setFormData] = useState([initialFormset]);
    const [tlp, setTlp] = useState('AMBER');
    const [description, setDescription] = useState('');
    const [validDescription, setValidDescription] = useState('');
    const [value, setValue] = useState('');
    const [validValue, setValidValue] = useState('');
    const [assignee, setAssignee] = useState('');
    const [validAssignee, setValidAssignee] = useState('');
    const [typeList, setTypeList] = useState([]);
    const [selectedInvestigatorValue, setSelectedInvestigatorValue] = useState('');
    const [type, setType] = useState('');
    const [validType, setValidType] = useState('');
    const [investigatorValid, setInvestigatorValid] = useState('');
    const [chips, setChips] = useState([]);
    const [indexNameValid, setIndexNameValid] = useState('');
    const [isLoader, setIsLoader] = useState(false);
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    });
    const [isChecked, setIsChecked] = React.useState(false);
    const [isIoc, setIsIoc] = useState(false);
    const [hasBeenSighted, setHasBeenSighted] = useState(false);
    const [ignoreSimilarity, setIgnoreSimilarity] = useState(false);

    const handleIsIcoToggle = () => {
        setIsIoc(!isIoc);
    };
    const handleChange = (_event, checked) => {
        setIsChecked(checked);
        setHasBeenSighted(checked);
    };
    const handleUpdateTypeValue = (value) => {
        setType(value);
        setValidType(selectmenuoption(value));
    };

    const handleSetChips = (newChips) => {
        setChips(newChips);
    };

    const handleTlpToggle = (event, _isSelected) => {
        const id = event.currentTarget.id;
        setTlp(id);
    }

    const handleFieldChange = (value, field) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));

        switch (field) {
            case 'type':
                setType(value);
                setValidType(selectmenuoption(value));
                break;
            case 'description':
                setDescription(value);
                setValidDescription(emptyCheck(value));
                break;
            case 'value':
                setValue(value);
                setValidValue(emptyCheck(value));
                break;
            case 'assignee':
                setAssignee(value);
                setValidAssignee(emptyCheck(value));
                break;
            default:
                break;
        }
    };

    const emptyCheck = (value) => {
        return value ? 'success' : 'error';
    };

    const resetForm = () => {
        setType('');
        setDescription('');
        setSelectedInvestigatorValue('');
        setInvestigatorValid('');
    };

    const finalSubmit = async () => {
        setIsLoader(true);
        const params = {
            incidentId: IncidentId,
            type: type,
            description: description,
            tlp: tlp,
            value: value,
            isIoc: isIoc,
            hasBeenSighted: hasBeenSighted,
            ignoreSimilarity: ignoreSimilarity,
        };

        try {
            let res = await dbApi.postApi('incident_management/add_observable', params);

            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                resetForm();
                onclose();
                onModalClose(true);
            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
        } catch (error) {
            console.error('API call failed:', error);
            onModalClose(false);
        }
        setIsLoader(false);
    };

    const formValidation = () => {
        let isValid = true;
        const typeStatus = selectmenuoption(type);
        setValidType(typeStatus);
        if (typeStatus === 'error') {
            isValid = false;
        }
        const descriptionStatus = emptyCheck(description);
        setValidDescription(descriptionStatus);
        if (descriptionStatus === 'error') {
            isValid = false;
        }
        const valueStatus = emptyCheck(value);
        setValidValue(valueStatus);
        if (valueStatus === 'error') {
            isValid = false;
        }
        const chipsStatus = emptyCheck(chips.length > 0 ? 'valid' : '');
        setIndexNameValid(chipsStatus);
        if (chipsStatus === 'error') {
            isValid = false;
        }
        return isValid;
    };

    const submitForm = () => {
        if (formValidation(formData)) {
            setConfirmBox({
                show: true,
                msg: 'Are you sure?',
                funCall: finalSubmit,
                title: 'Add Observable.',
                position: 'default',
                iconvariant: 'info',
                submitbtnvariant: 'primary',
            });
        }
    };

    const getTypeList = async () => {
        try {
            const res = await dbApi.getApi("incident_management/get_observable_type");
            console.log('observable_type', res)
            if (res?.length > 0) {
                setTypeList(res);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getTypeList();
    }, []);

    const tlpColorMap = {
        WHITE: { backgroundColor: '#00c0ef', color: '#000' },
        AMBER: { backgroundColor: '#F0AB00', color: '#000' },
        GREEN: { backgroundColor: '#2E8B57', color: '#fff' },
        RED: { backgroundColor: '#C9190B', color: '#fff' }
    };
    return (
        <>
            {isLoader ? <Loader /> : (
                <div>
                    <div className="pf-v5-u-mt-md">
                        <Grid>
                            <GridItem lg={6}>
                                <CommonSelectBox
                                    options={typeList.map(item => ({
                                        value: item.id,
                                        children: item.title
                                    }))}
                                    isDisabled={false}
                                    createOption={false}
                                    selectedOptions={type}
                                    placeholder="Type"
                                    updateValue={handleUpdateTypeValue}
                                    validStatus={validType} // Add this line to show validation status
                                />
                            </GridItem>
                        </Grid>
                    </div>
                    <Grid className='pf-v5-u-mt-lg'>
                        <GridItem lg={2}>
                            <FormGroup
                                label="TLP"
                                isRequired
                                className='pf-v5-u-font-weight-bold'
                                fieldId="simple-form-group-05"
                                helperTextInvalid="TLP is required"
                                labelIcon={
                                    <Tooltip content={<div>Traffic Light Protocol</div>}>
                                        <InfoAltIcon />
                                    </Tooltip>
                                }
                            />
                        </GridItem>
                        <GridItem lg={5}>
                            <ToggleGroup aria-label="TLP">
                                {[
                                    { text: 'WHITE', bgColor: '#00c0ef', color: '#000' },
                                    { text: 'GREEN', bgColor: '#2E8B57', color: '#fff' },
                                    { text: 'AMBER', bgColor: '#F0AB00', color: '#000' },
                                    { text: 'RED', bgColor: '#C9190B', color: '#fff' }
                                ].map(({ text }) => (
                                    <ToggleGroupItem
                                        key={text}
                                        className='custom-toggle-group'
                                        buttonId={text}
                                        isSelected={tlp === text}
                                        onChange={handleTlpToggle}
                                        text={
                                            <div
                                                style={{
                                                    backgroundColor: tlp === text ? tlpColorMap[text]?.backgroundColor : '#fff', // Show color only when selected
                                                    color: tlp === text ? tlpColorMap[text]?.color : '#000',
                                                    padding: '4px 10px',
                                                    borderRadius: '4px',
                                                }}
                                            >
                                                {text}
                                            </div>
                                        }
                                    >
                                        <Badge
                                            style={{
                                                backgroundColor: tlp === text ? tlpColorMap[text]?.backgroundColor : '#fff', // Show color only when selected
                                                color: tlp === text ? tlpColorMap[text]?.color : '#000',
                                                fontSize: '10px',
                                            }}
                                        >
                                            {text}
                                        </Badge>
                                    </ToggleGroupItem>
                                ))}
                            </ToggleGroup>

                        </GridItem>
                    </Grid>
                    <FormGroup fieldId="value" className="pf-v5-u-mt-md">
                        <TextArea
                            isRequired
                            value={value}
                            placeholder="Value"
                            onChange={(e) => handleFieldChange(e.target.value, 'value')}
                            id="value"
                            name="value"
                            validated={validValue}
                        />
                    </FormGroup>
                    <Grid className='pf-v5-u-mt-lg'>
                        <GridItem lg={2}>
                            <FormGroup
                                label="Has been sighted"
                                className='pf-v5-u-font-weight-bold'
                                fieldId="simple-form-group-05"
                            />
                        </GridItem>
                        <GridItem lg={2}>
                            <Switch id="no-label-switch-on" aria-label="Message when on" isChecked={isChecked} onChange={handleChange} />
                        </GridItem>
                        <GridItem lg={2}>
                            <FormGroup
                                label="Is IOC"
                                className='pf-v5-u-font-weight-bold'
                                fieldId="simple-form-group-05"
                            />
                        </GridItem>
                        <GridItem lg={2}>
                            <Button
                                variant="plain"
                                aria-label="Toggle star"
                                onClick={handleIsIcoToggle}
                                className='pf-v5-u-font-size-xl'
                                style={{marginTop:'-7px'}}
                            >
                                {isIoc ? <SkullIcon  color='#C9190B' /> : <SkullIcon />}
                            </Button>
                        </GridItem>
                    </Grid>
                    <div className='pf-v5-u-mt-sm'>
                        <IndexInputGroup
                            placeholder='Enter Tags name'
                            currentChips={chips}
                            setCurrentChips={handleSetChips}
                            indexNameValid={indexNameValid}

                        />
                    </div>
                    <FormGroup fieldId="description" className="pf-v5-u-mt-md">
                        <TextArea
                            isRequired
                            value={description}
                            placeholder="Description"
                            onChange={(e) => handleFieldChange(e.target.value, 'description')}
                            id="description"
                            name="description"
                            validated={validDescription}
                        />
                    </FormGroup>
                    <ActionGroup className="pf-v5-u-mt-md">
                        <Button variant="primary" className="pf-v5-u-mr-md" icon={<SaveIcon />} onClick={submitForm}>
                            Create
                        </Button>
                        <Button variant="secondary" onClick={onclose}>Cancel</Button>
                    </ActionGroup>
                    <ConfirmBox confirmModal={confirmModalData} />
                </div>
            )}
        </>
    );
};

export default IncidentObservableModal;
