import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
    Select,
    SelectOption,
    SelectList,
    MenuToggle,
    TextInputGroup,
    TextInputGroupMain,
    TextInputGroupUtilities,
    Button,
    Spinner,
    Truncate
} from '@patternfly/react-core';
import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-icon';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { CheckCircleIcon, ExclamationCircleIcon } from '@patternfly/react-icons';

const CommonSelectBox = ({
    options,
    isDisabled,
    createOption,
    selectedOptions,
    placeholder,
    updateValue,
    type,
    indexVal,
    updateOptions,
    validStatus,

}) => {
    // console.log("initial options", options)
    const id = generateUniqueID()
    const [selected, setSelected] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [selectOptions, setSelectOptions] = useState(options);
    const [optionList, setOptionList] = useState(options);
    const [focusedItemIndex, setFocusedItemIndex] = useState(null);
    const [activeItem, setActiveItem] = useState(null);
    const [onCreation, setOnCreation] = React.useState(false);
    const textInputRef = useRef();


    useEffect(() => {
        setSelectOptions(options)
        setOptionList(options)
    }, [options])

    // useEffect(() => {

    //     if(typeof clearOption!="undefined" && clearOption)
    //     {
    //         console.log("clearOption = " + clearOption)
    //         setSelectOptions([])
    //         setOptionList([])
    //     }
    // }, [clearOption])

    useEffect(() => {
        let newSelectOptions = optionList;
        if (filterValue != "") {
            newSelectOptions = optionList.filter(
                (menuItem) =>
                    String(menuItem.value).toLowerCase().includes(filterValue.toLowerCase()));
            if (createOption) {
                if (!newSelectOptions.length) {
                    newSelectOptions = [
                        {
                            isDisabled: false,
                            children: `Hit to add "${filterValue}" as a custom option`,
                            value: 'create'
                        }
                    ];
                }
            }
            else {
                if (!newSelectOptions.length) {
                    newSelectOptions = [
                        {
                            isDisabled: false,
                            children: `Not available option "${filterValue}"`,
                            value: 'no results'
                        }
                    ];
                }

            }
            if (!isOpen) {
                setIsOpen(true);
            }
        }
        setSelectOptions(newSelectOptions);
        setFocusedItemIndex(null);
        setActiveItem(null);
    }, [filterValue, onCreation]);

    const onToggleClick = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (typeof selectOptions != "undefined" && selectedOptions != "") {
            setSelectOptions(selectOptions)
            let result = selectOptions.find((obj) => obj.value == selectedOptions)
            if (typeof result != "undefined") {
                setInputValue(result.children)
                setSelected(result.value)
            } else if (selectedOptions == "") {
                setInputValue('')
                setSelected('')
            }
        } else if (selectedOptions == "") {
            setInputValue('')
            setSelected('')
        }
    }, [selectedOptions])


    const onSelect = (_event, value) => {
        if (value) {
            if (value === 'create') {
                if (!optionList.some(item => item.value === filterValue)) {
                    let newSelectOptions = [...optionList, {
                        value: filterValue,
                        children: filterValue
                    }];
                    setSelectOptions(newSelectOptions)
                    setOptionList(newSelectOptions)

                    if (typeof updateOptions != "undefined") {
                        updateOptions(newSelectOptions)
                    }
                }
                setInputValue(filterValue);
                setSelected(filterValue);

                updateValue(filterValue, type, indexVal)

                setOnCreation(!onCreation);
                setFilterValue('');
            }
            else if (value && value !== 'no results') {
                // console.log('--', value)
                setInputValue(value);
                setFilterValue('');
                setSelected(value);
                updateValue(value, type, indexVal)
            }
        }
        setIsOpen(false);
        setFocusedItemIndex(null);
        setActiveItem(null);
    };

    const onTextInputChange = (_event, value) => {
        setInputValue(value);
        setFilterValue(value);
    };

    const onInputKeyDown = (event, type, index) => {
        const enabledMenuItems = selectOptions.filter(option => !option.isDisabled);
        const [firstMenuItem] = enabledMenuItems;
        const focusedItem = focusedItemIndex ? enabledMenuItems[focusedItemIndex] : firstMenuItem;
        switch (event.key) {
            case 'Enter':
                if (isOpen) {
                    if (focusedItem.value !== 'no results' && focusedItem.value !== 'create') {
                        setInputValue(String(focusedItem.children));
                        setFilterValue('');
                        setSelected(String(focusedItem.value));
                        updateValue(focusedItem.value, type, index)
                    }
                    else if (focusedItem.value === 'create') {
                        onSelect(undefined, focusedItem.value);
                        setIsOpen(prevIsOpen => !prevIsOpen);
                        setFocusedItemIndex(null);
                        setActiveItem(null);
                    }
                }
                setIsOpen(prevIsOpen => !prevIsOpen);
                setFocusedItemIndex(null);
                setActiveItem(null);
                break;
            case 'Tab':
            case 'Escape':
                setIsOpen(false);
                setActiveItem(null);
                break;
            case 'ArrowUp':
            case 'ArrowDown':
                event.preventDefault();
                handleMenuArrowKeys(event.key);
                break;
        }
    };
    const handleMenuArrowKeys = key => {
        let indexToFocus;
        if (isOpen) {
            if (key === 'ArrowUp') {
                if (focusedItemIndex === null || focusedItemIndex === 0) {
                    indexToFocus = selectOptions.length - 1;
                } else {
                    indexToFocus = focusedItemIndex - 1;
                }
            }
            if (key === 'ArrowDown') {
                if (focusedItemIndex === null || focusedItemIndex === selectOptions.length - 1) {
                    indexToFocus = 0;
                } else {
                    indexToFocus = focusedItemIndex + 1;
                }
            }
            setFocusedItemIndex(indexToFocus);
            const focusedItem = selectOptions.filter(option => !option.isDisabled)[indexToFocus];
            setActiveItem(`select-typeahead-${focusedItem.value.replace(' ', '-')}`);
        }
    };

    const toggle = toggleRef => (
        <MenuToggle
            ref={toggleRef}
            variant="typeahead"
            onClick={onToggleClick}
            isExpanded={isOpen}
            isDisabled={isDisabled}
            isFullWidth
            status={validStatus}
        >
            <TextInputGroup isPlain>
                <TextInputGroupMain
                    value={inputValue}
                    onClick={onToggleClick}
                    onChange={onTextInputChange}
                    onKeyDown={onInputKeyDown}
                    id="typeahead-select-input"
                    autoComplete="off"
                    innerRef={textInputRef}
                    placeholder={placeholder}
                    {...(activeItem && {
                        'aria-activedescendant': activeItem
                    })}
                    role="combobox"
                    isExpanded={isOpen}
                    onselect
                    aria-controls="select-typeahead-listbox"
                />

                <TextInputGroupUtilities>
                    {!!inputValue && (
                        <Button
                            variant="plain"
                            onClick={() => {
                                setSelected('');
                                setInputValue('');
                                setFilterValue('');
                                updateValue('', type, indexVal);
                                textInputRef?.current?.focus();
                            }}
                            aria-label="Clear input value"
                        >
                            <TimesIcon aria-hidden />
                        </Button>
                    )}
                </TextInputGroupUtilities>
            </TextInputGroup>
        </MenuToggle>
    );
    return (
        <Select
            id="typeahead-select"
            isOpen={isOpen}
            selected={selected}
            onSelect={onSelect}
            onOpenChange={() => { setIsOpen(false); }}
            toggle={toggle}
            isScrollable
        >
            <SelectList id="select-typeahead-listbox" style={{ width: '300px' }}>
                {selectOptions.map((option, index) => (
                    <SelectOption
                        key={option.value + 'selection' + index}
                        isFocused={focusedItemIndex === index}
                        className={option.className}
                        onClick={() => setSelected(option.value)}
                        id={`select-typeahead-${id}`}
                        {...option}
                        ref={null}
                    >
                    </SelectOption>
                ))}
            </SelectList>
        </Select>
    );
};

export default React.memo(CommonSelectBox)
