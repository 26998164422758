//2nd which is perfectly run and usable
import React, { useCallback, useEffect, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  ExpandableRowContent,
  Table,
  InnerScrollContainer,
} from "@patternfly/react-table";
import {
  Badge,
  Button,
  DescriptionList,
  DescriptionListDescription,
  DescriptionListGroup,
  DescriptionListTerm,
  FlexItem,
  Flex,
  Icon,
  List,
  ListItem,
  ListVariant,
  Pagination,
  Tab,
  Tabs,
  TabTitleText,
  TabContentBody,
  Toolbar,
  ToolbarItem,
  ToolbarGroup,
  ToolbarContent,
  Tooltip,
  Truncate,
  ModalVariant,
  Modal,
} from "@patternfly/react-core";
import dbApi from '../../services/DbApi'
import { PlusCircleIcon, PlusIcon, SecurityIcon } from "@patternfly/react-icons";
import { formatTableTimestamp } from "../../util/Timeutils";
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { UpdateFilterData } from "../../util/FilterHelper";
import { FilterData } from "../../Redux/Action";
import ModalCommon from "../CommonEventPages/ModalCommon";
import IncidentAlertModal from "../IncidentManagement/IncidentAlert/IncidentAlertModal";
import IncidentModal from "../IncidentManagement/IncidentModal";
import IncidentAlertAdd from "../IncidentManagement/IncidentAlert/IncidentAlertAdd";

const { forwardRef, useRef, useImperativeHandle } = React;

const DashboardSecurityAlerts = forwardRef((props, ref) => {
  const [isAlwaysVisible, setIsAlwaysVisible] = React.useState(true);
  const [SecurityAlertsLog, setSecurityAlertsLog] = React.useState([])
  const [ScrollID, setScrollID] = React.useState('')
  const [columns, setColumns] = useState();
  useImperativeHandle(ref, () => ({
    // setSecurityAlertsTable(data = [], scrollId = '',startdate='',enddate='')
    setSecurityAlertsTable(data = [], scrollId = '', column = []) {
      setBeforescrolldata([])
      setSecurityAlertsLog(data)
      setScrollID(scrollId)
      setColumns(column)
    }
  }));

  const dispatch = useDispatch();
  const [itemcount, setItemcount] = useState(0)
  const [defaultRows, setDefaultRows] = useState([]);
  const [scrolldata, setScrollData] = useState([]);
  const [beforescrolldata, setBeforescrolldata] = useState([]);
  const [rows, setRows] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [expandedRowNames, setExpandedRowNames] = useState([]);
  const [expandedRowData, setExpandedRowData] = useState({});
  const [selectedRowName, setSelectedRowName] = useState('');

  // const [statusSelection, setStatusSelection] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isloadnext, setIsloadNext] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const alertModalRef = useRef();
  const [alertIndex, setAlertIndex] = useState(null);
  const [alertId, setAlertId] = useState(null);
  const handleModalToggle = (index, id) => {
    setAlertIndex(index);
    console.log('alert index', index)
    setAlertId(id);
    console.log('alert id', id)
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false); // Close modal
  };
  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex);
  };
  //Scroll API call for next data
  const SAPaginationApi = async (ScrollID) => {
    const data = {
      scroll_Id: ScrollID
    }
    try {
      setIsloadNext(true)
      let Scrolldata = await dbApi.postApi("Opensearch/Latest_scroll", data)
      console.log(Scrolldata)
      const nextdata = Scrolldata.Latest_scroll
      let scrolldata = []
      if (nextdata.length > 0) {
        scrolldata = nextdata
      }
      setScrollData(scrolldata);
      setIsloadNext(false)
    }
    catch (error) {
      console.log(error)
      setIsloadNext(false)
    }
  }
  // const SAPaginationApi = async (ScrollID) => {
  //   // const Fields=[{}]
  //   const data = {
  //     search_after: ScrollID, 
  //     startDate: startDate,
  //     endDate: endDate,
  //     filter: JSON.stringify(props?.filterformdata)

  //   }
  //   try {
  //     let Scrolldata = await dbApi.postApi("elasticsearch/Security_Dasbhoard_Security_Alerts", data)
  //     console.log(Scrolldata)
  //     const nextdata = Scrolldata.Security_Dasbhoard_Security_Alerts.Security_Dasbhoard_Security.body.hits.hits
  //     let scrolldata = []
  //     if (nextdata.length > 0) {
  //       scrolldata = nextdata
  //     }
  //     // console.log("nextsearchafterkey",nextdata.slice(-1)[0]._source.timestamp)
  //     setScrollData(scrolldata);
  //     // console.log(data)
  //   }
  //   catch (error) {
  //     console.log(error)
  //   }
  // }
  const resetPagination = () => {
    setPage(page);
    setPerPage(perPage); // Set to your desired default perPage value
  };

  // console.log("scrolldata",scrolldata)

  //useEffect for main data 
  useEffect(() => {
    let afterScrolldata = []

    if (beforescrolldata.length === 0) {
      afterScrolldata = SecurityAlertsLog
    } else {
      afterScrolldata = [...beforescrolldata, ...scrolldata]
    }

    setBeforescrolldata(afterScrolldata)
    resetPagination()
    const newdefaultRows = afterScrolldata.map((log) => {
      const { formattedTimeStamp } = formatTableTimestamp(log._source.timestamp);//localStorage.getItem("timezone")
      return {
        id: log._id,
        timestamp: formattedTimeStamp,
        ruleLevel: log._source.rule.level,
        agentId: log._source.agent.id,
        agentName: log._source.agent.name,
        techniques: log._source.rule?.mitre?.id,
        tactics: log._source.rule?.mitre?.tactic,
        category: log._source?.data?.alert?.category,
        protocol: log._source?.data?.proto,
        ruleDescription: log._source.rule.description,
        rulegroup: log._source.rule?.groups,
        ruleId: log._source.rule.id,
        dstuser: log._source?.data?.dstuser,
        targetuser: log._source?.data?.win?.eventdata?.targetUserName,
        ipaddress: log._source?.data?.win?.eventdata?.ipAddress,
        processname: log._source?.data?.win?.eventdata?.logonProcessName,
        url: log._source?.data?.url,
        srcip: log._source?.data?.srcip,
        sourceIp: log._source?.data?.win?.eventdata?.sourceIp,
        destinationip: log._source?.data?.win?.eventdata?.destinationIp,
        param4: log._source?.data?.win?.eventdata?.param4,
        param2: log._source?.data?.win?.eventdata?.param2,
        param3: log._source?.data?.win?.eventdata?.param3,
        cvss3: log._source?.data?.vulnerability?.cvss?.cvss3?.base_score,
        vectorattack: log._source.data?.vulnerability?.cvss?.cvss3?.vector?.attack_vector,
        query: log._source.data?.query,
        arpcacheadd: log._source.data?.osquery?.columns?.address,
        countryname: log._source.GeoLocation?.country_name,

        details: log,
      };
    });

    setDefaultRows(newdefaultRows)
    setItemcount(newdefaultRows.length)
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    // const filteredRows = newdefaultRows.filter(row => onFilter(row, searchValue));
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    // console.log("after initial slicing", "", startIndex, endIndex, slicedRows)
    setRows(slicedRows);
  }, [SecurityAlertsLog, scrolldata]);


  //Use Effect for pagination 
  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);

  const onSetPage = (_event, newPage) => {
    setPage(newPage);
  };
  const onPerPageSelect = (_event, newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };


  const setRowExpanded = (row, isExpanding) => {
    const otherExpandedRowNames = expandedRowNames.filter((r) => r !== row.id);
    setExpandedRowNames(
      isExpanding ? [row.id] : []
    );
    if (!expandedRowData[row.id]) {
      let agentGroupName = typeof row?.details?._source?.agent?.labels?.group != "undefined" ? row?.details?._source?.agent?.labels?.group : "";
      let agentName = typeof row?.details?._source?.agent?.name != "undefined" ? row?.details?._source?.agent?.name : "";
      let agentID = typeof row?.details?._source?.agent?.id != "undefined" ? row?.details?._source?.agent?.id : "";
      const keyifiedData = keyify(row?.details, "", agentName, agentID, agentGroupName);
      setExpandedRowData({ [row.id]: keyifiedData, })
    }
    const updatedExpandedRowNames = isRowExpanded(row) ? otherExpandedRowNames : [row.id];
    setExpandedRowNames(updatedExpandedRowNames);
  };
  const isRowExpanded = (row) => {
    return expandedRowNames.includes(row.id);
  };


  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }

  const renderRuleLevelcolumn = (ruleLevel) => {
    let tooltipContent = "";
    let badgeStyle = {};
    if (ruleLevel >= 6) {
      tooltipContent = "Medium";
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
      if (ruleLevel >= 12) {
        tooltipContent = "Critical";
        badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
      }
      return (
        <Tooltip content={tooltipContent}>
          <Badge style={badgeStyle} >{ruleLevel}</Badge>
        </Tooltip>
      );
    }
    else {
      return ruleLevel;
    }
  };

  const renderSeverityColumn = (severity, ruleLevel) => {
    let badgeStyle = {};
    let tooltipContent = " ";
    if (ruleLevel >= 12) {
      tooltipContent = "Severity: Critical";
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000" };
      return (
        <Tooltip content={tooltipContent}><Badge style={badgeStyle} onClick={() => { handleFilter('rule.level', ruleLevel) }}><a className='pf-v5-u-color-light-100' ><Icon isInline iconSize="sm"><SecurityIcon /></Icon> C</a></Badge></Tooltip>
      )
    }
    else if (ruleLevel >= 9) {
      tooltipContent = "Severity: High";
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" };
      return (
        <Tooltip content={tooltipContent}><Badge style={badgeStyle} onClick={() => { handleFilter('rule.level', ruleLevel) }}><a className='pf-v5-u-color-light-100'><Icon isInline iconSize="sm"><SecurityIcon /></Icon> H</a></Badge></Tooltip>
      )
    }
    else {
      return null;
    }
  }

  const hyperlinkConditions = {
    'agent.id': (value) => value !== '000',
    'agent.name': (value) => value !== '000',
    'data.capture_file': (value) => value !== '000',
  };

  const keyify = (obj, prefix = "", agentName = '', agentID = '', agentGroupName = '') => {
    const skipKeys = ["_score", "_version", "_index", "sort", "@timestamp"];
    const result = [];
    Object.keys(obj).forEach((el) => {
      const fullkey = prefix + el;
      if (skipKeys.includes(el)) {
        return;
      }
      else if (Array.isArray(obj[el])) {
        const value = obj[el].join(", ");
        result.push({ key: fullkey, value });
      } else if (typeof obj[el] === "object" && obj[el] !== null) {
        if (el === "_source") {
          result.push(...keyify(obj[el], prefix, agentName, agentID, agentGroupName));
        }
        else {
          result.push(...keyify(obj[el], fullkey + ".", agentName, agentID, agentGroupName));
        }
      }
      else {
        if (hyperlinkConditions[fullkey] && hyperlinkConditions[fullkey](obj[el])) {
          // if (fullkey === 'agent.id' || fullkey === 'agent.name') {
          //   result.push({ key: fullkey, value: <Link to={`/individual-agent-dashboard/${agentName}/${agentID}`}>{obj[el]}</Link> });
          // } else {
          //   result.push({ key: fullkey, value: <a href={obj[el]}>{obj[el]}</a> });
          // }
          if (fullkey == 'data.capture_file' && agentGroupName != "") {
            const matchingGroup = props?.allgroups.find(group => group.groupName.toUpperCase() == agentGroupName);
            if (matchingGroup.tap_ip != null) {
              const tipIp = matchingGroup.tap_ip;
              const capturefile = obj[el].split("/")
              result.push({
                key: fullkey,
                value: <a href={`http://${tipIp}:8085/webshark/index.html?file=${capturefile[capturefile.length - 1]}`} target="_blank" rel="noopener noreferrer">{obj[el]}</a>
              });
            }
            else {
              result.push({ key: fullkey, value: obj[el] })
            }
          }
          else if (fullkey === 'agent.id' || fullkey === 'agent.name') {
            result.push({ key: fullkey, value: <Link to={`/individual-agent-dashboard/${agentName}/${agentID}`}>{obj[el]}</Link> });
          }
        }
        else if (typeof obj[el] === 'boolean') {
          result.push({ key: fullkey, value: obj[el] ? 'true' : 'false' });
        }
        else {
          // Check if the current field needs to be highlighted
          const fieldData = props.filterformdata.find(item => item.fields === fullkey);
          if (fieldData && obj[el] === fieldData.value) {
            result.push({
              key: fullkey,
              value: <span
                style={{ backgroundColor: props.theme == 'dark-theme' ? 'red' : 'yellow' }}>
                {obj[el]}</span>
            });
          }
          else {
            result.push({ key: fullkey, value: obj[el] });
          }
        }
        // else { result.push({ key: fullkey, value: obj[el] }); }
      }
    });
    const sortedResult = result.sort((a, b) => {
      if (a.key < b.key) return -1;
      if (a.key > b.key) return 1;
      return 0
    });

    return sortedResult;
  };


  const renderPagination = (variant) => <Pagination
    itemCount={itemcount}
    perPage={perPage}
    page={page}
    variant={variant}
    onSetPage={onSetPage}
    onPerPageSelect={onPerPageSelect}
  />



  const renderBadgeWithTooltip = (data, tooltipContent, style, field) => {

    if (!data || data === "::1" || data === "127.0.0.1" || data.includes('$') || data == undefined && data == null && data == '') {
      return null;
    }
    return (
      <ListItem key={tooltipContent}>
        <Tooltip content={tooltipContent} >
          <div><Badge style={style} onClick={() => { handleFilter(field, data) }} ><a className='pf-v5-u-color-light-100' ><div><Truncate content={data} tooltipPosition='bottom' /></div></a></Badge></div>
        </Tooltip>
      </ListItem>
    );
  };
  const descbadgestyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
  const paramstyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" }
  return (
    <React.Fragment >

      {props.Pagination ?
        <Toolbar inset={{
          default: 'insetNone',
          md: 'insetSm',
          xl: 'inset2xl',
          '2xl': 'insetLg'
        }}>
          <ToolbarContent>
            {/* {props.searchbar ?
            <ToolbarItem variant="search-filter" style={{ width: '60%' }} >
              <SearchInput
                placeholder="Search..."
                value={searchValue}
                onChange={(_event, value) => onSearchChange(value)}
                onClear={() => onSearchChange('')} />
              
            </ToolbarItem>
            : '  '
          } */}

            <ToolbarGroup align={{ default: 'alignRight' }}>
              <ToolbarItem> <Button onClick={() => SAPaginationApi(ScrollID)} size="sm" isLoading={isloadnext}>Next</Button></ToolbarItem>
              <ToolbarItem variant="pagination" >{renderPagination('top')}</ToolbarItem>
            </ToolbarGroup>
          </ToolbarContent>
        </Toolbar>
        : ''}
      <InnerScrollContainer>
        <Table variant="compact"
          aria-label="securityalertsLogTable"
          id="securityalerts-log-table"
          isStickyHeader
          gridBreakPoint="grid-md"
        // scrolling={true}
        >
          <Thead>
            <Tr>
              <Th key={'expand'} aria-label="expand-alert" />{columns && columns.map((column, index) => (<Th key={index} >{column}</Th>))}
            </Tr>
          </Thead>
          {rows.length > 0 && rows.map((row, rowIndex) => (

            <Tbody isExpanded={isRowExpanded(row)} key={rowIndex}>
              <Tr
                onRowClick={() => setSelectedRowName(rowIndex)}
                isSelectable
                isClickable
                isRowSelected={selectedRowName === rowIndex}
                className={row?.ruleLevel >= 8 ? "pf-v5-u-background-color-danger" : ""}
              >
                <Td
                  expand={
                    row.details
                      ? {
                        rowIndex: rowIndex,
                        isExpanded: isRowExpanded(row),
                        onToggle: () => { setRowExpanded(row, !isRowExpanded(row)); setActiveTabKey(0) }

                      }
                      : undefined
                  }
                  key={'expand-id' + rowIndex}
                >
                  <ExpandableRowContent key={rowIndex}>
                  </ExpandableRowContent>
                </Td>
                {columns.includes('Time') && <Td dataLabel={'Time'}> {row?.timestamp} </Td>}
                {columns.includes('Rule Level') && <Td dataLabel={'Rule Level'}><a onClick={() => { handleFilter('rule.level', row?.ruleLevel) }}>{renderRuleLevelcolumn(row?.ruleLevel)}</a></Td>}
                {columns.includes('Agent Name') && <Td dataLabel={'Agent Name'}>
                  <Link to={"/individual-agent-dashboard/" + row?.agentName + '/' + row?.agentId}>{row?.agentId}: {row?.agentName}</Link></Td>}
                {columns.includes('Technique(s)') && <Td dataLabel={'Technique(s)'}>
                  {row?.techniques !== undefined && row?.techniques !== null ?
                    (<List isPlain>
                      {/* rule.mitre.id */}
                      {row?.techniques?.map((item, index) => (
                        <ListItem key={index}> <Badge key={index}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('rule.mitre.id', item) }}>{item}</a> </Badge></ListItem>
                      ))}
                    </List>) : (" ")}
                </Td>}
                {columns.includes('Tactic(s)') && <Td dataLabel={'Tactic(s)'}>
                  {row?.tactics !== undefined && row?.tactics !== null ?
                    (<List isPlain variant={ListVariant.inline}>
                      {row?.tactics?.map((item, index) =>
                      (<ListItem key={index} style={{ color: "#0066CC" }}>{item}</ListItem>
                      ))} </List>)
                    : (" ")}</Td>}
                {columns.includes('Protocol/Category') && <Td dataLabel={'Protocol/Category'}>
                  {/* <a>{row?.ruleId}</a> */}
                  {row?.protocol !== undefined && row?.protocol !== null && row.protocol}
                  {row?.category !== undefined && row?.category !== null && row.category}

                </Td>}
                {columns.includes('Rule Description & key objects') && <Td dataLabel={'Rule Description & key objects'} width={30} >
                  {/* {row?.ruleDescription} */}
                  <Flex spacer={{ default: 'spacerSm' }}>
                    {localStorage.getItem("role") == 'Admin' && <FlexItem>
                      <Tooltip content="Add Incident">
                        <Button variant="plain" onClick={() => handleModalToggle(row.details._index, row.details._id)} >
                          <Icon status="info">
                            <PlusCircleIcon />
                          </Icon>
                        </Button>
                      </Tooltip>
                    </FlexItem>}
                    <FlexItem>
                      {row?.ruleDescription && (
                        <>
                          {/* <Truncate content={row?.ruleDescription}/> */}
                          {row?.ruleLevel !== undefined && row?.ruleLevel !== null ? renderSeverityColumn(row?.severity, row?.ruleLevel) : " "}{' '}
                          <a className="pf-v5-u-color-100" onClick={() => { handleFilter('rule.description', row?.ruleDescription) }}>{row?.ruleDescription}</a>
                          <List isPlain variant={ListVariant.inline}>
                            {renderBadgeWithTooltip(row?.targetuser, 'target user name', descbadgestyle, 'data.win.eventdata.targetUserName')}
                            {renderBadgeWithTooltip(row?.ipaddress, 'IP Address', descbadgestyle, 'data.win.eventdata.ipAddress')}
                            {renderBadgeWithTooltip(row?.processname, 'Process name', descbadgestyle, 'data.win.eventdata.logonProcessName')}
                            {renderBadgeWithTooltip(row?.url, 'URL', descbadgestyle, 'data.url')}
                            {renderBadgeWithTooltip(row?.srcip, 'Source IP', descbadgestyle, 'data.srcip')}
                            {renderBadgeWithTooltip(row?.destinationip, 'Destination IP', descbadgestyle, 'data.win.eventdata.destinationIp')}
                            {renderBadgeWithTooltip(row?.sourceIp, 'Source IP', descbadgestyle, 'data.win.eventdata.sourceIp')}
                            {renderBadgeWithTooltip(row?.dstuser, 'dst user', descbadgestyle, 'data.dstuser')}
                            {renderBadgeWithTooltip(row?.param4, 'Parameters', descbadgestyle, 'data.win.eventdata.param4')}
                            {renderBadgeWithTooltip(row?.query, 'Query', descbadgestyle, 'data.query')}
                            {renderBadgeWithTooltip(row?.countryname, 'country name', descbadgestyle, 'GeoLocation.country_name')}
                            {renderBadgeWithTooltip(row?.arpcacheadd, 'ARP cache address', descbadgestyle, 'data.osquery.columns.address')}

                            {/* {renderBadgeWithTooltip(row?.param2, 'Param',paramstyle)} */}
                            {/* {renderBadgeWithTooltip(row?.param3, 'Parameters')} */}

                          </List>
                          {(row?.cvss3 > 7 && row?.vectorattack) ? <Badge style={{ "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" }}>exploitable : {row?.vectorattack}</Badge> : " "}

                        </>

                      )}
                    </FlexItem>
                  </Flex>
                </Td>}
                {columns.includes('Rule Group(s)') && <Td dataLabel={'Rule Group(s)'}>
                  {row?.rulegroup !== undefined && row?.rulegroup !== null ?
                    (<List isPlain>
                      {row?.rulegroup.slice(1)?.map((item, index) =>
                      (<Badge key={index}><ListItem key={"li" + index}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('rule.groups', item) }}>{item}</a></ListItem></Badge>
                      ))} </List>)
                    : (" ")}
                </Td>}
                {columns.includes('Rule ID') && <Td dataLabel={'Rule ID'}><a>{row?.ruleId}</a></Td>}
              </Tr>
              <Tr isExpanded={isRowExpanded(row)}>
                {/* <Td></Td> */}
                <Td colSpan={11} noPadding>
                  <ExpandableRowContent key={rowIndex}>
                    <Tabs
                      activeKey={activeTabKey}
                      onSelect={handleTabClick}
                      style={{ marginTop: "0px" }}
                      aria-label="Tabs in the Security Alerts"
                      role="region"
                    >
                      <Tab
                        eventKey={0}
                        title={<TabTitleText>Table</TabTitleText>}
                        aria-label="Table data"
                      >
                        <TabContentBody hasPadding >
                          <DescriptionList isHorizontal horizontalTermWidthModifier={{ default: '30ch' }} >
                            {expandedRowData[row.id]?.map((item, index) => (
                              <React.Fragment key={index}>
                                <DescriptionListGroup>
                                  <DescriptionListTerm key={"item-" + index}> {item.key}</DescriptionListTerm>
                                  <DescriptionListDescription key={"desc-" + index}>{item.value}</DescriptionListDescription>
                                </DescriptionListGroup>
                              </React.Fragment>
                            ))}
                          </DescriptionList>
                        </TabContentBody>
                      </Tab>
                      <Tab eventKey={1} title={<TabTitleText>JSON</TabTitleText>}>
                        <TabContentBody hasPadding>
                          <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {JSON.stringify(row.details, null, 2)}
                          </pre>
                        </TabContentBody>
                      </Tab>
                    </Tabs>
                  </ExpandableRowContent>
                </Td>
              </Tr>
            </Tbody>
          ))}
          <Tbody>
            {rows.length === 0 &&
              <Tr>
                <Td colSpan={10}>
                  <Emptystatefornodata />
                </Td>
              </Tr>}
          </Tbody>
        </Table>
      </InnerScrollContainer>
      {
        <Flex justifyContent={{ default: 'justifyContentFlexEnd' }} spaceItems={{ default: 'spaceItemsXs' }}>
          <FlexItem alignSelf={{ default: 'alignSelfCenter' }}>
            <Button onClick={() => SAPaginationApi(ScrollID)} size="sm" isLoading={isloadnext}>Next</Button>
          </FlexItem>
          <FlexItem>{renderPagination('bottom')}
          </FlexItem>
        </Flex>
      }

      <Modal
        title="Add Incident"
        isOpen={isModalOpen} // Control modal visibility
        onClose={handleModalClose} // Handle close
        variant={ModalVariant.large}
        ref={alertModalRef}
      >
        {/* Form to be displayed inside the modal */}
        <IncidentAlertAdd
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          alertIndex={alertIndex}
          alertId={alertId}
        />
      </Modal>
    </React.Fragment>
  )
})
const mapStateToProps = (state) => ({
  filterformdata: state.FilterData,
  theme: state.theme,
  allgroups: state.Allgroups,
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(DashboardSecurityAlerts)
