import axios from "axios";
import TokenService from "./TokenService";
// const API_URL ='http://10.5.5.82:7000/'
const API_URL = process.env.REACT_APP_API_URL;
// const API_URL ='http://10.5.5.82:5000/'
const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalToken();

    if (token != "undefined" && token != null) {
      config.headers["Authorization"] = 'Bearer ' + token;
      config.headers["userId"] = localStorage.getItem("userId")
      //localStorage.getItem("userId")
    } else if (token == "undefined" || token == null || token == "") {
       window.location.replace('/'); //error when login via otp
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const excludeUrl = ["/login/verify-user","/login/verify_otp","/onboardingform/:uniqueId"]
    if (!excludeUrl.includes(originalConfig.url) && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await instance.post("/login/regenerate-token", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });
          if (rs.status == 200) {
            const accessToken = rs.data.token.access_token;
            if (accessToken == false) {
              window.location.replace('/');
              return Promise.reject(err);
            } else {
              TokenService.updateLocalToken(accessToken);
              if (originalConfig.isMultipart) { // <-- Check for the custom flag
                originalConfig.headers['Content-Type'] = 'multipart/form-data';
              }
              return instance(originalConfig);
            }
          }
          else {
            return Promise.reject(err);
          }
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);
export default instance;
