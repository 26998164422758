import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Modal, Button, ActionGroup, Spinner, Radio, FlexItem, Flex, Card, CardBody } from '@patternfly/react-core';
import dbApi from 'src/services/DbApi';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import IncidentModal from '../IncidentModal';
import ExistingAlertModal from './ExistingAlertModal';

const IncidentAlertAdd = ({ incidentId, onclose, alertId, alertIndex, onModalClose }) => {
    const [isLoader, setIsLoader] = useState(false);
    const [incidentType, setIncidentType] = useState('new'); // 'new' selected by default
    const [formData, setFormData] = useState(null);
    const [isLoading, setIsLoading] = useState('');
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    });
    const [isExistingModalOpen, setIsExistingModalOpen] = useState(false);
    const [isIncidentModalOpen, setIsIncidentModalOpen] = useState(false);

    useEffect(() => {
        fetchFormData(); 
    }, [alertId, alertIndex]); 


    const fetchFormData = async () => {
        setIsLoader(true);
        const params = {
            index: alertIndex,
            docId: alertId,
        };
        console.log('index and id', params)
        try {
            const res = await dbApi.postApi('incident_management/get_new_register_incident_form_data', params);
            console.log('get_new_register_incident_form_data', res)
            if (!res.error) {
                setFormData(res.data);
            } else {
                console.error('Error fetching form data:', res.message);
            }
        } catch (error) {
            console.error('API call failed:', error);
        } finally {
            setIsLoader(false);
        }
    };

    const handleIncidentTypeChange = (type) => {
        setIncidentType(type);
        if (type === 'existing') {
            setIsExistingModalOpen(true);
        } else {
            setIsExistingModalOpen(false);
        }
        if (type === 'new') {
            setIsIncidentModalOpen(true);
        }
        else {
            setIsIncidentModalOpen(false)
        }
    };

    const handleModalClose = async (isSuccess) => {
        setIsLoading(false);
    };

    return (
        <>
            {isLoader ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <Form>
                    {/* Radio button selection */}
                    <FormGroup fieldId="incident-type">
                        <Flex spacer={{ default: 'spacerXl' }}>
                            <FlexItem>
                                <Radio
                                    id="new-incident"
                                    name="incidentType"
                                    label="New Incident Registration"
                                    isChecked={incidentType === 'new'}
                                    onChange={() => handleIncidentTypeChange('new')}
                                    className='pf-v5-u-mr-xl'
                                />
                            </FlexItem>
                            <FlexItem>
                                <Radio
                                    id="existing-incident"
                                    name="incidentType"
                                    label="Add to Existing Incident"
                                    isChecked={incidentType === 'existing'}
                                    onChange={() => handleIncidentTypeChange('existing')}
                                />
                            </FlexItem>
                        </Flex>
                    </FormGroup>
                    {incidentType === 'new' ? (
                        <Card>
                            <CardBody>
                                <IncidentModal
                                    onclose={() => setIsIncidentModalOpen(false)}
                                    onModalClose={handleModalClose}
                                    incidentFormData={formData}
                                />
                            </CardBody>
                        </Card>
                    ) : (
                        <Card style={{height:'450px'}}>
                            <CardBody>
                                <ExistingAlertModal
                                    isOpen={isExistingModalOpen}
                                    onClose={() => setIsExistingModalOpen(false)}
                                    incidentId={incidentId}
                                    onModalClose={handleModalClose}
                                />
                            </CardBody>
                        </Card>
                    )}
                </Form>
            )}
            <ConfirmBox confirmModal={confirmModalData} />
        </>
    );
};

export default IncidentAlertAdd;
