import { ActionGroup, Button, FormGroup, TextArea, TextInput } from '@patternfly/react-core';
import { SaveIcon } from '@patternfly/react-icons';
import React, { useState, useEffect } from 'react';
import dbApi from 'src/services/DbApi';
import { useDispatch } from 'react-redux';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import CommonSelectBox from 'src/Components/CommonEventPages/CommonSelectBox';
import { alertBoxData } from 'src/Redux/Action';
import { emptyCheck, selectmenuoption } from 'src/util/Validate';
import { Loader } from 'src/util/Loadingstate';

const IncidentTaskModal = ({ onclose, IncidentId, onModalClose }) => {
  const dispatch = useDispatch();
  const initialFormset = {
    title: '',
    incidentId: IncidentId,
    description: '',
    assigneeId: ''
  };
  const [formData, setFormData] = useState([initialFormset]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [assignee, setAssignee] = useState('');
  const [validTitle, setValidTitle] = useState('');
  const [validAssignee, setValidAssignee] = useState('');
  const [validDescription, setValidDescription] = useState('');
  const [investigatorList, setInvestigatorList] = useState([]);
  const [selectedInvestigatorValue, setSelectedInvestigatorValue] = useState('');
  const [investigatorValid, setInvestigatorValid] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [confirmModalData, setConfirmBox] = useState({
    show: false,
    msg: '',
    funCall: '',
    funParam: {},
  });
  const handleUpdateInvestigatorValue = (value) => {
    setSelectedInvestigatorValue(value);
    setInvestigatorValid(emptyCheck(value));
  };

  const handleFieldChange = (value, field) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));

    switch (field) {
      case 'title':
        setTitle(value);
        setValidTitle(emptyCheck(value));
        break;
      case 'description':
        setDescription(value);
        setValidDescription(emptyCheck(value));
        break;
      case 'assignee':
        setAssignee(value);
        setValidAssignee(selectmenuoption(value));
        break;
      default:
        break;
    }
  };

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setSelectedInvestigatorValue('');
    setInvestigatorValid('');
  };

  const finalSubmit = async () => {
    setIsLoader(true)
    let params = {
      incidentId: IncidentId,
      title: title,
      description: description,
      assigneeId: selectedInvestigatorValue
    };
    console.log('drop down assigne--',params);
    try {

      let res = await dbApi.postApi('incident_management/add_task', params);
      if (!res.error) {
        dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
        console.log('API response add task:', res);
        resetForm();
        onclose();
        onModalClose(true);
      } else {
        dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
      }
    }
    catch (error) {
      console.error('API call failed:', error.response || error.message || error);
      onModalClose(false);
    }
    setIsLoader(false);
  };

  const formValidation = () => {
    let isValid = true;
    const titleStatus = emptyCheck(title);
    setValidTitle(titleStatus);
    if (titleStatus === 'error') {
      isValid = false;
    }
    const descriptionStatus = emptyCheck(description);
    setValidDescription(descriptionStatus);
    if (descriptionStatus === 'error') {
      isValid = false;
    }
    const assigneeStatus = selectmenuoption(selectedInvestigatorValue);
    setInvestigatorValid(assigneeStatus);
    if (assigneeStatus === 'error') {
      isValid = false;
    }
    return isValid;
  };

  const submitForm = () => {
    if (formValidation(formData)) {
      setConfirmBox({
        show: true,
        msg: 'Are you sure?',
        funCall: finalSubmit,
        title: 'Add Task.',
        position: 'default',
        iconvariant: 'info',
        submitbtnvariant: 'primary',
      });
    }
  };

  const getInvestigatorList = async () => {
    try {
      const res = await dbApi.postApi("user/get_user_list", { status: '1', role: ['Investigator','Analyst'] });
      console.log('get user list====',res)
      setInvestigatorList(res.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getInvestigatorList();
  }, []);

  return (
    <>
      {isLoader ? <Loader /> :(
        <>
        <FormGroup
            isRequired
            fieldId="title"
            helperTextInvalid="Title is required"
            validated={validTitle}
          >
            <TextInput
              value={title}
              isRequired
              placeholder="Title"
              type="text"
              id="title"
              aria-describedby="title-helper"
              name="title"
              onChange={(e) => handleFieldChange(e.target.value, 'title')}
              validated={validTitle}
            />
          </FormGroup>
          <FormGroup fieldId="description" className="pf-v5-u-mt-md">
            <TextArea
              isRequired
              value={description}
              placeholder="Description"
              onChange={(e) => handleFieldChange(e.target.value, 'description')}
              id="description"
              name="description"
              validated={validDescription}
            />
          </FormGroup>
      <div className="pf-v5-u-mt-md">
        <CommonSelectBox
          options={investigatorList.map(item => ({
            value: item.user_id,
            children: `${item.name} (${item.role})`
          }))}
          isDisabled={false}
          createOption={false}
          selectedOptions={selectedInvestigatorValue}
          placeholder="Assignee"
          updateValue={handleUpdateInvestigatorValue}
          validStatus={investigatorValid}
        />
      </div>
      <ActionGroup className="pf-v5-u-mt-md">
        <Button variant="primary" className="pf-v5-u-mr-md" icon={<SaveIcon />} onClick={submitForm}>
          Create
        </Button>
        <Button variant="secondary" onClick={onclose}>Cancel</Button>
      </ActionGroup>
      <ConfirmBox confirmModal={confirmModalData} />
      </>
        )}
    </>
  )
}


export default IncidentTaskModal;
