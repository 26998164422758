
import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { connect } from 'react-redux';
import { StaticTactics } from 'src/util/CommonData';

const RadarChart = (props) => {
    const id = generateUniqueID()

    useEffect(() => {
        const chartDom = document.getElementById(id + 'rdc');
        const myChart = echarts.init(chartDom);
        const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
        const maxCount = Math.max(...props.radarseries.map(bucket => bucket.doc_count));

        const setValue = StaticTactics.map(indicator => {
            const foundIndicator = props.radarseries.find(bucket => bucket.key === indicator.name);
            return { value: foundIndicator ? foundIndicator.doc_count : 0, name: indicator.name };
        })

        const setValueOnly = setValue.map((obj) => obj.value)
        const option = {
            title: [
                {
                    text: props?.title ? props?.title : null,
                    textStyle: {
                        color: textcolor
                    }
                }
            ],
            radar: {
                // indicator: props.radarseries.map((item => ({ name: item.key,max:maxCount}))),
                indicator: StaticTactics.map((item) => ({
                    name: `${item.index}.${item.name}`,
                    max: Math.ceil(maxCount * 1.1),//maxCount,//i used this and from this warning is persisting
                    // min:0
                })),
                center: props.Center ? props.Center : ['50%', '50%'],
                alignTicks: false,
                axisName: {
                    color: textcolor,
                    formatter: (value) => {
                        const maxLength = 8;  // Adjust this number to fit your design
                        return value.length > maxLength ? `${value.substring(0, maxLength)}...` : value;
                    },
                    // ellipsis:'...',
                    // overflow: "truncate",
                    // width: 120,
                },
                startAngle: 100,
                radius: ["0%", "72%"],
                scale: true, // Logarithmic scale
                splitNumber: 5,  // Limits the number of axis ticks to 5
            
            },
            series: [
                {
                    name: 'mitre techniques',
                    type: 'radar',

                    data: [{
                        name: 'Mitre Tactics',
                        value: setValueOnly
                    }],
                    areaStyle: {
                        color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                            {
                                color: 'rgba(124, 181, 255, 0.1)',
                                offset: 0
                            },
                            {
                                color: 'rgba(124, 181, 255, 0.9)',
                                offset: 1
                            }
                        ])
                    }
                }
            ],

            tooltip: {
                trigger: 'item',
                position: [30, 30],
                textStyle: {
                    color: "#000"
                },
                formatter: function (params) {
                    let sortData = []
                    let seq = setValueOnly.length
                    setValueOnly.map((obj, index) => {
                        sortData.push(`${seq--}` + '. ' + setValue[index].name + ' : ' + obj);
                        // sortData += setValue[index].name + ':' + obj + '<br>';
                    })
                    var ReverseData = [...sortData].reverse();
                    const Ordereddata = ReverseData.join('<br>');
                    return Ordereddata;
                }

            },
        };
        // Set chart options
        // option.animationDuration = 0;
        option && myChart.setOption(option);
        // if (props.filterFields != 'health') {
        //     myChart.on('click', 'series.wordCloud', function (param) {
        //         const Fields = props.filterFields ? props.filterFields : param.data.nestedkey
        //         handleFilter(Fields, param.data.name)
        //         if (props?.reDirect) {
        //             window.location.href = "#/Security-Events";
        //         }
        //     });
        // }
        const resizeChart = () => {
            if (myChart) { // Check if chart exists before resizing
                myChart.resize();
            }
        };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, [props.radarseries, props.theme, props.sidebarState, props.Center]);


    return <div className="container">
        <div id={id + 'rdc'} style={{ width: '100%', height: props?.height }} > </div>
    </div>
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(RadarChart)