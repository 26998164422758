import React, { Children, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import {
    ActionGroup,
    Button,
    Flex,
    FlexItem,
    Icon,
    TextInput,
} from '@patternfly/react-core';
import { emailCheck, emptyFormCheck, } from '../../util/Validate.js';
import { alertBoxData } from 'src/Redux/Action.js';
import dbApi from '../../services/DbApi'
import ConfirmBox from '../ConfirmBox/ConfirmBox.js';
import { PlusCircleIcon, TrashIcon } from '@patternfly/react-icons';
import { Loader } from 'src/util/Loadingstate.js';
const { forwardRef, useImperativeHandle } = React;
const AddMembers = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    // {"orgGroupId":"1","groupName":"2","index":["wazuh-alerts-*"],"status":"1","isParent":true}

    const initialFormset = {
        name: '',
        designation: '',
        email: '',
        contact: '',
        validEmail: '',
        validName: '',
        uniqueID: ''
    }
    const [formData, setFormData] = useState([initialFormset]);
    const [isloadgetGroup, setIsLoadgetGroup] = useState(false)
    const [actionType, setActionType] = useState('Add')
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })

    // useImperativeHandle(ref, () => ({
    //     setOrganizationForm(rowId = '', Orgname = '') {
    //         setListRowId(rowId)
    //         setOrganizationName(Orgname)
    //         setFormData([initialFormset])

    //     }
    // }));



    //Add Row
    const addNewRow = (rowIndex) => {
        setFormData([...formData, initialFormset])
    }

    //Remove Row
    const removeRow = (index) => {
        let newrow = [...formData]
        newrow.splice(index, 1)
        setFormData(newrow);
    }

    const fieldChange = (e, i) => {
        const { name, value } = e.target;
        const newForm = [...formData];
        newForm[i][name] = value;
        setFormData(newForm);
    }
    // const fieldChange = (e, i) => {
    //     const { name, value } = e.target;
    //     setFormData((prevFormData) => {
    //       const newForm = [...prevFormData];
    //       newForm[i] = { ...newForm[i], [name]: value };
    //       return newForm;
    //     });
    //   };

    // const fieldChange = useCallback((e, i) => {
    //     const { name, value } = e.target;
    //     const newForm = [...formData];
    //     newForm[i][name] = value;
    //     setFormData(newForm);
    // }, [formData]);


    function formValid(formData) {
        let isValid = true;
        const newFormData = formData.map((item) => {
            const validName = emptyFormCheck(item.name);
            const validEmail = emailCheck(item.email);
            if (validName == 'error') isValid = false;
            if (validEmail == 'error') isValid = false;
            return {
                ...item,
                validName,
                validEmail
            };
        });
        setFormData(newFormData);
        return isValid;
    }

    // console.log(formData)
    const submitForm = () => {
        if (formValid(formData)) {
            setConfirmBox({
                show: true,
                msg: 'Are you sure?',
                funCall: finalSubmit,
                title: 'Add members for email alerts.',
                position: 'default',
                iconvariant: 'info',
                submitbtnvariant: 'primary',
            })
            // props.closeonSubmit();
        }
    }

    // opensearch/add_organization_group for add the groups to organization
    const finalSubmit = async () => {
        let AllMembers = formData.map((item) => ({
            ...(item.uniqueID !== '' && { unique_id: item.uniqueID }),
            name: item.name,
            designation: item.designation,
            email: item.email,
            contact: item.contact,
        }))
        let data = {
            email_Status: props.emailStatus ? '1' : '0',
            rule_level: props.ruleLevel,
            org_unique_id: props.OrgUniqueID,
            Member: AllMembers
        }


        console.log(data)
        try {
            let res = await dbApi.postApi("Opensearch/insertUpdateALerting", data)
            console.log(res)
            let status = 'danger'
            if (!res.error) {
                status = 'success'
                dispatch(alertBoxData([{ title: res.message, variant: status }]));
            }
            else {
                dispatch(alertBoxData([{ title: res.message, variant: status }]));
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (props.MemberData.length > 0) {
            let updatedFormData = props.MemberData.map((item) => {
                return {
                    uniqueID: item.member_unique_id,
                    name: item.member_name,
                    designation: item.designation,
                    email: item.email,
                    contact: item.contact,
                }
            })
            setFormData(updatedFormData);
        }
    }, [props.MemberData])

// console.log("formdata",formData)
    // Opensearch/deleteMemberALerting 
    const finaldelete = async (data) => {
        let param = {
            unique_id: data
        }
        try {
            let res = await dbApi.deleteApi("Opensearch/deleteMemberALerting", param)
            console.log("delete", res)
            let status = 'danger'
            if (!res.error) {
                status = 'success'
                dispatch(alertBoxData([{ title: res.message, variant: status }]));
                props.getMemberCall()
            }
            else {
                dispatch(alertBoxData([{ title: res.message, variant: status }]));
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteMember = (id) => {
        setConfirmBox({
            show: true,
            msg: 'Are you sure?',
            funCall: () => finaldelete(id),
            title: 'Delete Member',
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        })
    }
    return <React.Fragment>
        <ConfirmBox confirmModal={confirmModalData} />
        {isloadgetGroup ? <Loader /> :
            formData.map((row, rowIndex) => {
                return (<React.Fragment key={rowIndex+'div'}>
                    <div className="pf-v5-u-p-sm" key={rowIndex}>
                        <Flex spaceItems={{ default: 'spaceItemsXs' }} display={{ default: 'inlineFlex' }} className='pf-v5-u-display-flex pf-v5-u-flex-nowrap'>
                            <FlexItem key={'name-' + rowIndex}>
                                <TextInput isRequired placeholder="name" type="text" id="name" name="name" aria-describedby="name-helper" value={row.name ?? ''} validated={row.validName} onChange={(event) => fieldChange(event, rowIndex)} />
                            </FlexItem>
                            <FlexItem key={'designation-' + rowIndex}>
                                <TextInput placeholder="designation" type="text" id="designation" name="designation" aria-describedby="designation-helper" value={row.designation ?? ''} onChange={(event) => fieldChange(event, rowIndex)} />
                            </FlexItem>
                            <FlexItem key={'email-' + rowIndex}>
                                <TextInput isRequired placeholder="email" type="email" id="email" name="email" value={row.email ?? ''} aria-describedby="email-helper" validated={row.validEmail} onChange={(event) => fieldChange(event, rowIndex)} />
                            </FlexItem>
                            <FlexItem key={'contact-' + rowIndex}>
                                <TextInput placeholder="mobile no" maxLength={10} type="tel" id="contact" name="contact" aria-describedby="contact" value={row.contact ?? ''} onChange={(event) => fieldChange(event, rowIndex)} />
                            </FlexItem>
                            {formData.length - 1 == rowIndex && <FlexItem key={5}><a><Icon status="info" onClick={() => { addNewRow(rowIndex); }}><PlusCircleIcon /></Icon></a></FlexItem>}
                            {formData.length !== 1 &&
                                <FlexItem key={6}><a><Icon status="danger"
                                    onClick={() => {
                            if (row.uniqueID !== '') 
                                { deleteMember(row.uniqueID) }
                            else{
                               removeRow(rowIndex); 
                            }
                            // { row.uniqueID == '' && removeRow(rowIndex); }
                                    }}
                                ><TrashIcon /></Icon></a></FlexItem>}
                        </Flex>

                    </div>
                </React.Fragment>
                )
            })
        }
        <ActionGroup>
            <Button variant="primary" onClick={() => { submitForm(); }} >{actionType == 'Add' ? 'Submit' : 'Update'}</Button>
        </ActionGroup>
    </React.Fragment >
});


const mapStateToProps = (state) => ({})

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(AddMembers)