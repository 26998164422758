import React, { useRef, useState, useEffect } from 'react'
import { css } from '@patternfly/react-styles';
import {
  ActionGroup, BackgroundImage, Brand, Button, Bullseye, EmptyStateIcon,
  Flex, FlexItem, Form, FormGroup, FormHelperText,
  HelperText, HelperTextItem, InputGroup, InputGroupItem,
  List, ListItem, ListVariant, Login, LoginFooter, LoginFooterItem,
  LoginHeader, LoginMainBody, LoginMainFooterBandItem, LoginMainHeader,
  ModalVariant, Stack, StackItem, TextInput,
  InputGroupText,
  ValidatedOptions,
} from '@patternfly/react-core';
import dbApi from '../../services/DbApi'
import brandImg from '../../Assets/images/soc-brand-img.svg'
import bgImage from '../../Assets/images/black.jpeg'
import CustomModalBox from './CustomModal';
import global_success_color_100 from '@patternfly/react-tokens/dist/esm/global_success_color_100';
import { INVALID_EMAIL, RESET_PASSWORD } from 'src/Constant/constants';
import { emailCheck, emptyCheck } from 'src/util/Validate';
import TokenService from 'src/services/TokenService';
import { alertBoxData, FilterData } from 'src/Redux/Action';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import "./Login.css"
import { CheckCircleIcon, ExclamationCircleIcon, EyeIcon, EyeSlashIcon, RedoIcon } from '@patternfly/react-icons';
import EmptystateModal from './EmptystateModal';
import { TextSkeleton } from 'src/util/Loadingstate';
import { min } from 'date-fns';
import ConfirmBox from '../ConfirmBox/ConfirmBox';
import InquiryForm from '../InquiryForm/InquiryForm';

const MainLogin = () => {
  const childForgotModalref = useRef(null);
  const childInquiryFormref = useRef(null);
  const childMessageModalref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //login form states
  const [showHelperText, setShowHelperText] = useState(false);
  const [username, setUsername] = useState('');
  const [isValidUsername, setIsValidUsername] = useState(true);
  const [password, setPassword] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [isloading, setIsLoading] = useState(false)
  const [logbtndisable, setLogbtnDisable] = useState(false)

  //otp states 
  const [otpFormShow, setOtpFormShow] = useState(false)
  const [loadverifyotp, setLoadVerifyotp] = useState(false)
  const [verifybtndisable, setVerifybtnDisable] = useState(false)
  const [loadresendotp, setLoadResendotp] = useState(false)
  const [inputotp, setInputOtp] = useState('')
  const [idforotp, setIdForOtp] = useState('')
  const [minutes, setMinutes] = useState(4);
  const [seconds, setSeconds] = useState(59);

  //forgot password steps
  const [email, setEmail] = useState('');
  const [validemail, setValidEmail] = useState('default')
  const [emailhelper, setEmailHelper] = useState('')
  const [helperText, setHelperText] = useState('');
  const [confirmModalData, setConfirmBox] = useState({
    show: false,
    msg: '',
    funCall: '',
    funParam: {},
    title: '',
    position: '',
    iconvariant: '',
    submitbtnvariant: '',
  })

  //captcha state
  const [captchasum, setCaptchaSum] = useState('')
  const [validcaptcha, setvalidcaptcha] = useState(true)
  const [captchavalue1, setCaptchavalue1] = useState(0)
  const [captchavalue2, setCaptchavalue2] = useState(0)
  const [captchauniqueId, setCaptchaUniqueId] = useState('')
  const [isloadcaptcha, setIsLoadCaptcha] = useState(false)
  const [captchadisable, setCaptchaDisable] = useState(false)



  useEffect(() => {
    if (otpFormShow) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, otpFormShow]);//Re run this effect whenever seconds changes


  const handleEmailInputChange = (_event, value) => {
    setEmail(value);
    setValidEmail(emailCheck(value))
  };

  const handleUsernameChange = value => {
    setUsername(value);
    setIsValidUsername(true)
    setHelperText('')
  };
  const handlePasswordChange = value => {
    setPassword(value);
    setIsValidPassword(true)
    setHelperText('')
  };
  const handleOtpchange = (_event, value) => {
    setInputOtp(value);
  };
  const handleCaptchaChange = value => {
    setCaptchaSum(value);
    setvalidcaptcha(emptyCheck(value))
  };

  useEffect(() => {
    TokenService.setToken("")
  }, []);


  function forgotFormValid() {
    let ret = true;
    let status;
    status = emailCheck(email)
    setValidEmail(status)
    if (status == 'error') {
      setEmailHelper(INVALID_EMAIL)
      ret = false
    }
    return ret
  }

  const finalEmailCall = async () => {
    try {
      let res = await dbApi.postApi("login/request_new_password", { email: email })
      console.log(res)
      childMessageModalref.current.setModalBox(true)
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmitEmail = async () => {
    if (forgotFormValid()) {
      childForgotModalref.current.setModalBox(false)
      setConfirmBox({
        show: true,
        msg: '',
        funCall: finalEmailCall,
        funParam: '',
        title: 'Are you sure?',
        position: 'default',
        iconvariant: '',
        submitbtnvariant: 'primary',
      })
    }
  }

  const forgotCredentials = (<LoginMainFooterBandItem>
    {/* <Button variant='link' isInline
      className='pf-v5-u-mr-sm'
      onClick={() => childInquiryFormref.current.setModalBox(true)}>
      Inquiry ? </Button>
    <CustomModalBox
      title="Inquiry Form"
      description='We will get in touch with you shortly'
      onclose={() => childInquiryFormref.current.setModalBox(false)}
      variant={ModalVariant.medium}
      ref={childInquiryFormref}
      icon={''}
    >
      <InquiryForm onclose={() => childInquiryFormref.current.setModalBox(false)} />
    </CustomModalBox> */}

    <Button variant='link' isInline
      onClick={() => childForgotModalref.current.setModalBox(true)}>
      Forgot Password? </Button>
    <CustomModalBox
      title="Forgot password"
      onclose={() => { setEmail(''); setValidEmail('default') }}
      variant={ModalVariant.small}
      ref={childForgotModalref}
      icon={''}
    >
      <Form id="modal-with-form-form">
        <FormGroup label="E-mail" isRequired fieldId="email" >
          <TextInput isRequired type="email" id="email" validated={validemail} name="email" value={email} onChange={handleEmailInputChange} />
          {validemail == 'error' && <FormHelperText>
            <HelperText>
              <HelperTextItem variant={validemail}>
                {emailhelper}
              </HelperTextItem>
            </HelperText>
          </FormHelperText>}
        </FormGroup>
      </Form>
      <br />
      <Flex>
        {/* <FlexItem>Don't have account?<RegistrationForm /></FlexItem> */}
        <FlexItem align={{ default: 'alignRight' }}> <Button variant="primary"
          onClick={() => {
            onSubmitEmail();
            // childForgotModalref.current.setModalBox(false)
          }}>
          Submit</Button></FlexItem>
      </Flex>
    </CustomModalBox>

  </LoginMainFooterBandItem>)

  const onLoginButtonClick = async (event) => {
    try {
      event.preventDefault();
      setIsValidUsername(!!username);
      setIsValidPassword(!!password);
      setvalidcaptcha(!!captchasum)
      setShowHelperText(!username || !password || !captchasum);
      setHelperText('Enter the Username, Password and Captcha ')
      if (username && password && captchasum) {
        setIsValidUsername(true)
        setIsValidPassword(true)
        setvalidcaptcha(true)
        setHelperText('')
        let data = {
          username: username,
          password: password,
          unique_id: captchauniqueId,
          Total: captchasum

        }
        setIsLoading(true)
        setLogbtnDisable(true)
        let res = await dbApi.postApi('login/verify_user', data)
        console.log(res)
        if (!res.error) {
          if (res.authData.twoAuth) {
            setOtpFormShow(true)
            setIdForOtp(res.authData.uniqueID)
          }
          else {
            let data = res.authData
            TokenService.setUser(data)
            dispatch(alertBoxData([{ title: res.message, variant: "success" }]));
            dispatch(FilterData([]))
            if (data.role == 'Analyst') { navigate('/incident-management') }
            else {
              navigate('/home')
            }
          }
        } else {
          dispatch(alertBoxData([{ title: res.message, variant: "danger" }]));
        }
        setIsLoading(false)
        setLogbtnDisable(false)
      }
      else if (!username && password && captchasum) {
        setHelperText("Enter the username")
        setIsLoading(false)
        setLogbtnDisable(false)
      } else if (username && !password && captchasum) {
        setHelperText("Enter the Password")
        setIsLoading(false)
        setLogbtnDisable(false)
      }
      else if (username && password && !captchasum) {
        setHelperText("Enter Captcha")
        setIsLoading(false)
        setLogbtnDisable(false)
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setLogbtnDisable(false)
    }
  };
  const getCaptcha = async () => {
    try {
      setIsLoadCaptcha(true)
      setCaptchaDisable(true)
      let res = await dbApi.getApi("login/captcha")
      console.log(res)
      if (!res.error) {
        // unique_id
        setCaptchavalue1(res.value_1)
        setCaptchavalue2(res.value_2)
        setCaptchaUniqueId(res.unique_id)
      }
      setIsLoadCaptcha(false)
      setCaptchaDisable(false)
    }
    catch (error) {
      console.log(error)
      setIsLoadCaptcha(false)
      setCaptchaDisable(false)
    }
  }
  useEffect(() => {
    getCaptcha()
  }, [])
  const onVerifyOtp = async () => {
    let data = {
      uniqueID: idforotp,
      otpValue: inputotp
    }
    try {
      setLoadVerifyotp(true)
      setVerifybtnDisable(true)
      let res = await dbApi.postApi("login/verify_otp", data)
      console.log(res)
      if (!res.error) {
        let data = res.authData
        TokenService.setUser(data)
        dispatch(alertBoxData([{ title: res.message, variant: "success" }]));
        dispatch(FilterData([]))
        // navigate('/home')
        if (data.role == 'Analyst') { navigate('/incident-management') }
        else {
          navigate('/home')
        }
      }
      else {
        dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]))
      }
      setLoadVerifyotp(false)
      setVerifybtnDisable(false)
    } catch (error) {
      console.log(error)
      setLoadVerifyotp(false)
      setVerifybtnDisable(false)
    }
  }
  const onResendOtp = async () => {
    try {
      setLoadResendotp(true)
      console.log(idforotp)
      let res = await dbApi.postApi("login/regenrate_otp", { uniqueID: idforotp, })
      console.log(res)
      if (!res.error) {
        setIdForOtp(res.UniqueId)
        setMinutes(4);
        setSeconds(59);
      }
      setLoadResendotp(false)
    } catch (error) {
      console.log(error)
      setLoadResendotp(false)
    }
  }
  // login/verify_otp 
  const otpForm = <React.Fragment>
    <Form onSubmit={(e) => { e.preventDefault(); onVerifyOtp(); }}>
      <FormGroup label="Enter OTP" isRequired fieldId="otp" >
        <TextInput isRequired type="text" id="otp" maxLength={50} name="otp" value={inputotp} onChange={handleOtpchange} />
      </FormGroup>
      <Flex>
        {/* <FlexItem>  Didn't get the code?</FlexItem> */}
        <FlexItem align={{ default: 'alignLeft' }}>Time Remaining: <span style={{ fontWeight: 600 }}>{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds} </span></FlexItem>
        <FlexItem align={{ default: 'alignRight' }}> <Button key="resendbtn" variant="link" form="resendbtn" isLoading={loadresendotp} onClick={onResendOtp} isDisabled={seconds > 0 || minutes > 0 || loadresendotp}>Resend</Button></FlexItem>
      </Flex>
    </Form>
    <br />
    <Stack>
      <StackItem>
        <Button key="verifyotp" variant="primary" isBlock form="verify-otp" onClick={onVerifyOtp} isLoading={loadverifyotp} isDisabled={verifybtndisable}>Verify</Button>
      </StackItem>
    </Stack>
  </React.Fragment>
  const HeaderBrand = (
    <React.Fragment>
      <Brand src={brandImg} alt={''} />
    </React.Fragment>
  );
  const Header = <LoginHeader headerBrand={HeaderBrand} />;
  const Footer = (
    <LoginFooter>
      <p>Unify Your Security. Simplify Your Response. - Secsphere SOC</p>
      <List variant={ListVariant.inline}>
        <React.Fragment>
          <ListItem>
            <LoginFooterItem href="#">Terms of Use </LoginFooterItem>
          </ListItem>
          <ListItem>
            <LoginFooterItem href="#">Help</LoginFooterItem>
          </ListItem>
          <ListItem>
            <LoginFooterItem href="#">Privacy Policy</LoginFooterItem>
          </ListItem>
        </React.Fragment>
      </List>
    </LoginFooter>
  );
  const LoginForm = <Form >
    {showHelperText && (
      <FormHelperText>
        <HelperText>
          <HelperTextItem variant={!isValidUsername || !isValidPassword || !validcaptcha ? 'error' : 'default'} icon={!isValidUsername || !isValidPassword || !validcaptcha ? ExclamationCircleIcon : ''}>
            {helperText}
          </HelperTextItem>
        </HelperText>
      </FormHelperText>
    )}
    <FormGroup label='Username' isRequired fieldId='username'>
      <TextInput
        isRequired
        id='username'
        validated={isValidUsername ? 'default' : 'error'}
        type="text"
        name="username"
        value={username}
        onChange={(_event, value) => handleUsernameChange(value)}
      />
    </FormGroup>
    <FormGroup label='Password' isRequired fieldId='password'>
      <InputGroup>
        <InputGroupItem isFill>
          <TextInput
            isRequired
            validated={isValidPassword ? 'default' : 'error'}
            name='password'
            type={passwordHidden ? 'password' : 'text'}
            aria-label="Password input"
            value={password}
            onChange={(event, value) => handlePasswordChange(value,)}
          />
        </InputGroupItem>
        <InputGroupItem>
          <Button
            variant="control"
            onClick={() => setPasswordHidden(!passwordHidden)}
            aria-label={passwordHidden ? 'Show password' : 'Hide password'}
          >
            {passwordHidden ? <EyeIcon /> : <EyeSlashIcon />}
          </Button>
        </InputGroupItem>
      </InputGroup>
    </FormGroup>
    <FormGroup label='Solve this' isRequired fieldId='captchavalue' >
      <InputGroup>
        <InputGroupText id="captchaequation" className="pf-v5-u-color-100" style={{ width: '100px', display: 'flex', justifyContent: 'center' }}> {isloadcaptcha ? <TextSkeleton width='70%' /> : `${captchavalue1 ?? 0} + ${captchavalue2 ?? 0}  =`} </InputGroupText>
        <InputGroupItem isFill>
          <TextInput id="captchavalue" maxLength={3} validated={validcaptcha ? '' : ValidatedOptions.error} name='captchavalue' onChange={(event, value) => handleCaptchaChange(value)} type="text" aria-label="captcha" />
        </InputGroupItem>
        <InputGroupItem>
          <Button variant="secondary" aria-label="captcha recall" isDisabled={captchadisable} onClick={() => getCaptcha()}><RedoIcon /> </Button>
        </InputGroupItem>
      </InputGroup>
    </FormGroup>
    {!otpFormShow && <Flex>
      <FlexItem align={{ default: 'alignRight' }}>
        {forgotCredentials}
      </FlexItem>
    </Flex>}
    <ActionGroup>
      <Button variant="primary" type="submit" onClick={onLoginButtonClick} isBlock isLoading={isloading} isDisabled={logbtndisable} >Login</Button>
    </ActionGroup>
  </Form>

  const otptitle = `We've sent a verification code to your email - ${username}`
  return (
    <React.Fragment>
      <ConfirmBox confirmModal={confirmModalData} />
      <BackgroundImage src={bgImage} />
      <Login header={Header} footer={Footer} >
        <LoginMainHeader title={!otpFormShow ? "Log in to your account" : 'Otp Verification'}
          subtitle={!otpFormShow ? 'Please enter your login details to access your account.' : otptitle}
        // headerUtilities={headerUtilities}
        />
        <LoginMainBody>
          {/* {LoginForm} */}
          <>
            <CustomModalBox
              title=""
              onclose={() => { setEmail(''); setValidEmail('default') }}
              variant={ModalVariant.small}
              ref={childMessageModalref}
              icon={''}
            >
              <EmptystateModal
                titleText="Check your Email"
                bodyText={RESET_PASSWORD}
                showFooter={false}
                onFooterFun={() => { }}
                icon={<EmptyStateIcon icon={CheckCircleIcon} color={global_success_color_100.var} />}
                buttonVariant="primary"
                buttonText="OK"
              />
            </CustomModalBox>
            {!otpFormShow ? LoginForm : otpForm}
          </>

        </LoginMainBody>
        {/* {(socialMediaLoginContent || forgotCredentials || signUpForAccountMessage) && (
          <LoginMainFooter
            socialMediaLoginContent={socialMediaLoginContent}
            socialMediaLoginAriaLabel={socialMediaLoginAriaLabel}
            forgotCredentials={forgotCredentials}
            signUpForAccountMessage={signUpForAccountMessage}
          />
        )} */}
      </Login>
    </React.Fragment>
  )
}

export default MainLogin