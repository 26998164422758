import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  ActionGroup,
  Banner,
  Button,
  Card,
  CardBody,
  ExpandableSection,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  Grid,
  GridItem,
  Icon,
  Radio,
  TextInput,
  Tooltip
} from '@patternfly/react-core'
import { emptyArrayCheck, emptyCheck, emptyFormCheck, selectmenuoption, selectmultipleoption } from 'src/util/Validate';
import CommonSelectBox from '../CommonEventPages/CommonSelectBox';
import ConfirmBox from '../ConfirmBox/ConfirmBox';
import { HelpIcon, InfoCircleIcon, PlusCircleIcon, TrashIcon } from '@patternfly/react-icons';
import { connect, useDispatch } from 'react-redux';
import MultiselectBox from '../CommonEventPages/MultiselectBox';
import dbApi from '../../services/DbApi'
import { alertBoxData } from 'src/Redux/Action';
import AlertingruleListTable from './AlertingruleListTable';
import { Loader } from 'src/util/Loadingstate';
import FilterInputTextBox from './FilterInputTextBox';

const AlertingRule = (props) => {
  const dispatch = useDispatch()
  const ConditionOptions = [
    { value: "Equal_To", children: "equal to", type: ['long', 'keyword'] },
    { value: "Not_Equal_To", children: "not equal to ", type: ['long', 'keyword'] },
    { value: "Equal_To_Greater_Than", children: "equal to greater than", type: ['long'] },
    { value: "Equal_To_Less_Than", children: "equal to less than", type: ['long'] },
    { value: "Greater_Than", children: "greater than", type: ['long'] },
    { value: "Less_Than", children: "less than", type: ['long'] },
  ]

  const FieldOptions = [
    { value: "group_name", children: "group_name", type: 'keyword' },
    { value: "rule_level", children: "rule_level", type: 'long' },
    { value: "rule_description", children: "rule_description", type: 'keyword' },
  ]

  const priorityOptions = [
    { value: 'C', children: 'critical' },
    { value: 'H', children: 'high' },
    { value: 'M', children: 'medium' },
    { value: 'L', children: 'low' },
  ]
  const [actiontype, setActionType] = useState('Add')
  const [titlename, setTitleName] = useState('');
  const [validtitlename, setValidtitlename] = useState('');
  const [agentoptions, setAgentOptions] = useState([])
  const [multiselectedAgents, setmultiselectedAgents] = useState([])
  const [validAgents, setValidAgents] = useState('')
  const [emailOptions, setEmailOptions] = useState([])
  const [multiselectedEmails, setmultiselectedEmails] = useState([])
  const [validEmails, setValidEmails] = useState('')
  const [selectedPriority, setSelectedPriority] = useState([]);
  const [validPriority, setValidPriority] = useState('')
  const [checkActionType, setCheckActionType] = useState('');
  const [expandedSections, setExpandedSections] = useState(Array(5).fill(true));
  const [currentFormId, setCurrentFormId] = useState('')
  const [loadDetails, setLoadDetails] = useState(false)
  const [callList, setCallList] = useState('')
  const [confirmModalData, setConfirmBox] = useState({
    show: false,
    msg: '',
    funCall: '',
    funParam: {},
    title: '',
    position: '',
    iconvariant: '',
    submitbtnvariant: '',
  })
  //search rule list multiple rows states 
  const initialFormset = {
    uniqueID: '',
    fieldName: '',
    conditionName: '',
    valueInput: [],
    value: '',
    fieldNameValid: '',
    conditionNameValid: '',
    valueInputValid: '',
    validValue: ''
  }
  const [formData, setFormData] = useState([initialFormset]);

  useEffect(() => {
    if (props.MemberData.length > 0) {
      let emails = props.MemberData.map((item) => ({
        value: item.member_unique_id,
        children: item.email
      }))
      setEmailOptions(emails)
    }
  }, [props.MemberData])

  const onToggle = (index) => {
    const newExpandedSections = [...expandedSections];
    newExpandedSections[index] = !newExpandedSections[index]; // Toggle the expanded state of the clicked section
    setExpandedSections(newExpandedSections);
  };
  const fieldChange = (value, type) => {
    switch (type) {
      case "titlename":
        setTitleName(value)
        setValidtitlename(emptyCheck(value))
        break;
    }
  };

  const handleRadioChange = (value) => {
    setCheckActionType(value);
  };

  const FormfieldChange = (e, i) => {
    const { name, value } = e.target;
    const newForm = [...formData];
    newForm[i][name] = value;
    setFormData(newForm);
  }
  const handleMultiSelectAgents = (selected, type) => {
    // console.log("selected", selected)
    switch (type) {
      case "agents":
        setmultiselectedAgents(selected)
        setValidAgents(selectmultipleoption(selected))
        break;
      case "emails":
        setmultiselectedEmails(selected)
        setValidEmails(selectmultipleoption(selected))
        break;
    }
  };
  const updateAgentsBox = (value, type = 'valueselect', index = 'Value') => {
    setmultiselectedAgents(value)
    setValidAgents(selectmultipleoption(value))
  }
  const updateEmailBox = (value, type = 'valueselect', index = 'Value') => {
    setmultiselectedEmails(value)
    setValidEmails(selectmultipleoption(value))
  }

  const updatePriorityBox = (value, type = 'valueselect', index = 'Value') => {
    setSelectedPriority(value)
    setValidPriority(selectmenuoption(value))
  }

  function formValid(formData) {
    let isValid = true;
    let status = emptyCheck(titlename)
    setValidtitlename(status)
    if (status == 'error') {
      isValid = false
    }
    let menuStatus = selectmultipleoption(multiselectedAgents)
    setValidAgents(menuStatus)
    if (menuStatus == 'danger') {
      isValid = false
    }
    menuStatus = selectmultipleoption(multiselectedEmails)
    setValidEmails(menuStatus)
    if (menuStatus == 'danger') {
      isValid = false
    }
    menuStatus = selectmenuoption(selectedPriority)
    setValidPriority(menuStatus)
    if (menuStatus == 'danger') {
      isValid = false
    }

    const newFormData = formData.map((item) => {

      const fieldNameValid = selectmenuoption(item.fieldName);
      const conditionNameValid = selectmenuoption(item.conditionName);
      const valueInputValid = emptyArrayCheck(item.valueInput)
      // const validValue = emptyFormCheck(item.value)
      // if (validValue == 'error') isValid = false;
      if (fieldNameValid == 'danger') isValid = false;
      if (conditionNameValid == 'danger') isValid = false;
      if (valueInputValid == 'error') isValid = false;
      return {
        ...item,
        fieldNameValid,
        conditionNameValid,
        valueInputValid,
        // validValue
      };
    });
    setFormData(newFormData);
    return isValid
  }

  //Add Row
  const addNewRow = (rowIndex) => {
    setFormData([...formData, initialFormset])
  }

  //Remove Row
  const removeRow = (index) => {
    let newrow = [...formData]
    newrow.splice(index, 1)
    setFormData(newrow);
  }

  //get agent option for dropdown 
  // Opensearch/agentListIdAlertingRule
  const getAgentOption = async (orgId) => {
    try {
      let param = {
        organization_unique_id: orgId
      }
      let res = await dbApi.postApi("Opensearch/agentListIdAlertingRule", param)
      console.log(res)
      let agents = []
      if (res.data.length > 0) {
        agents = res.data
        setAgentOptions(agents)
      }
      else {
        setAgentOptions(agents)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (props.OrgUniqueID !== '') {
      getAgentOption(props.OrgUniqueID)
    }
  }, [props.OrgUniqueID])
  //Field select box
  const fieldBox = useMemo(() => {
    return (FieldName, rowIndex, validfield) => {
      const updateFieldSelectBox = (value, type = 'fieldselect', index = 'field') => {

        const updatedFormData = [...formData];
        updatedFormData[rowIndex].fieldName = value;
        updatedFormData[rowIndex].fieldNameValid = selectmenuoption(value);
        setFormData(updatedFormData);
      }
      return (<CommonSelectBox
        key={rowIndex + 'fieldbox'}
        options={FieldOptions}
        isLoading={false}
        placeholder="Select field"
        isDisabled={false}
        type="fields"
        indexVal={rowIndex}
        updateValue={updateFieldSelectBox}
        selectedOptions={FieldName}
        validStatus={validfield}
      />)
    }
  }, [FieldOptions, formData, setFormData,])


  //condition select box
  const conditionBox = useMemo(() => {
    return (ConditionName, rowIndex, validcondition, FieldName) => {
      const updateConditionBox = (value, type = 'fieldselect', index = 'field') => {
        const updatedFormData = [...formData];
        updatedFormData[rowIndex].conditionName = value;
        updatedFormData[rowIndex].conditionNameValid = selectmenuoption(value);
        setFormData(updatedFormData);
      }
      const selectedFieldType = FieldOptions.find(field => field.value === FieldName)?.type
      const filteredConditionOptions = ConditionOptions.filter(option => {
        if (!option.type) {
          return true
        } else {
          return option.type.includes(selectedFieldType); // Only show options with matching selectedFieldType
        }
      });

      return (<CommonSelectBox
        key={rowIndex + 'conditionbox'}
        options={filteredConditionOptions}
        isLoading={false}
        placeholder="Select condition"
        isDisabled={false}
        type="conditions"
        indexVal={rowIndex}
        updateValue={updateConditionBox}
        selectedOptions={ConditionName}
        validStatus={validcondition}
      />)
    }
  }, [ConditionOptions, formData, setFormData, ConditionOptions, FieldOptions])

  const finalSubmit = async () => {
    let Allrules = formData.map((item) => ({
      uniqueId: item.uniqueID,//pass when we update the rule condition value
      columns: item.fieldName,
      conditions: item.conditionName,
      // value: item.value
      value:JSON.stringify(item.valueInput)
    }))
    let data = {
      title: titlename,
      priority: selectedPriority,
      actionType: checkActionType,
      unique_id: currentFormId,//pass when we update the form or whole rule from the table 
      organization_id: props.OrgUniqueID,
      rules: [
        {
          agentId: multiselectedAgents,
          memberUniqueId: multiselectedEmails,
          rulesConditions: Allrules
        }
      ]
    }
    console.log(data)
    try {
      let res = await dbApi.postApi("Opensearch/insert_update_Alerts_Rules_Agent_condition_member", data)
      console.log(res)
      let status = 'danger'
      if (!res.error) {
        status = 'success'
        dispatch(alertBoxData([{ title: res.message, variant: status }]));
        onCancelForm()
        setCallList(Date.now())
      }
      else {
        dispatch(alertBoxData([{ title: res.message, variant: status }]));
      }

    } catch (error) {
      console.log(error)
    }
  }


  const submitForm = () => {
    if(actiontype=='Add'){
    if (formValid(formData)) {
      setConfirmBox({
        show: true,
        msg: 'Add Detail Form.',
        funCall: finalSubmit,
        title: 'Are you sure? ',
        position: 'default',
        iconvariant: 'info',
        submitbtnvariant: 'primary',
      })
    }
  }
  else{
    setConfirmBox({
      show: true,
      msg: `This edits can't be reversed.`,
      funCall: finalSubmit,
      title: 'Are you sure? ',
      position: 'default',
      iconvariant: 'info',
      submitbtnvariant: 'primary',
    })
  }
  }

  const onCancelForm = () => {
    setActionType('Add')
    setTitleName('')
    setValidtitlename('')
    setmultiselectedAgents([])
    setValidAgents('')
    setmultiselectedEmails([])
    setValidEmails('')
    setCheckActionType('')
    setSelectedPriority([])
    setValidPriority('')
    setFormData([initialFormset])
    setCurrentFormId('')
  }

  const getRulesDetails = async (id) => {
    setActionType('Edit')
    setCurrentFormId(id)
    try {
      let param = {
        rule_unique_id: id
      }
      setLoadDetails(true)
      let res = await dbApi.postApi("Opensearch/getRulesAgentConditionMember", param)
      console.log("res", res)
      if (!res.error) {
        let detail = res.alertsRules[0]
        let result = priorityOptions.find((obj) => obj.value == detail.priority)
        let ruleAgents = agentoptions.filter(option => res.ruleAgent.some(agent => agent.agent_name == option.agent_name)).map(obj => obj.agent_id)
        let memberemails = emailOptions.filter(option => res.alertsRulesMember.some(item => item.email == option.children)).map(obj => obj.value)
        let updatedFormData = res.rulesConditions.map((item) => {
          return {
            uniqueID: item.unique_id,
            fieldName: item.columns,
            conditionName: item.conditions,
            valueInput: JSON.parse(item.value),
          }
        })

        setFormData(updatedFormData);
        setTitleName(detail.title)
        setCheckActionType(detail.action_type)
        setSelectedPriority(result.value)
        setmultiselectedAgents(ruleAgents)
        setmultiselectedEmails(memberemails)
      }
      setLoadDetails(false)
    } catch (error) {
      console.log(error)
      setLoadDetails(false)
    }
  }

  const finaldelete = async (data) => {
    let param = {
      unique_id: data
    }
    try {
      let res = await dbApi.deleteApi("Opensearch/deleteAlertsRulesCondition", param)
      console.log("delete", res)
      let status = 'danger'
      if (!res.error) {
        status = 'success'
        dispatch(alertBoxData([{ title: res.message, variant: status }]));
        getRulesDetails(currentFormId)
      }
      else {
        dispatch(alertBoxData([{ title: res.message, variant: status }]));
      }
    } catch (error) {
      console.log(error)
    }
  }

  const delteFilterCondition = (id) => {
    setConfirmBox({
      show: true,
      msg: 'Are you sure?',
      funCall: () => finaldelete(id),
      title: 'Delete Member',
      position: 'default',
      iconvariant: 'warning',
      submitbtnvariant: 'danger',
    })
  }

  return (
    <div>
      <ConfirmBox confirmModal={confirmModalData} />
      <Card>
        <CardBody>
          <ExpandableSection displaySize="lg" toggleText={'Detail Form'} onToggle={() => onToggle(0)} isExpanded={expandedSections[0]}>
            {loadDetails ? <Loader /> : <Form >
              <Grid hasGutter md={4}>
                <GridItem span={12}>
                  <FormGroup fieldId="simple-form-name-01" label='Rule Name' isRequired>
                    <TextInput type="text" id="simple-form-name-01" name="simple-form-name-01" aria-describedby="simple-form-name-01-helper"
                      value={titlename} validated={validtitlename} onChange={(event, value) => fieldChange(value, 'titlename')} />
                  </FormGroup>
                </GridItem>
                <GridItem>
                  <FormGroup label="Agents" fieldId="simple-form-email-01" isRequired>
                    <MultiselectBox
                      initialOptions={agentoptions.map((item) => ({
                        value: item.agent_id,
                        children: item.agent_name
                      }))}
                      createOption={false}
                      onSelectChange={(selected) => handleMultiSelectAgents(selected, 'agents')}
                      isDisabled={false}
                      updateValue={updateAgentsBox}
                      placeholderText="Select Agents"
                      ariaLabel="Your aria label"
                      clearButtonAriaLabel="Clear input value"
                      selectedOptions={multiselectedAgents}
                      validStatus={validAgents}
                    />
                  </FormGroup>
                </GridItem>
                <GridItem>
                  <FormGroup label="Members Email" fieldId="basic-form-checkbox-group" isRequired>
                    <MultiselectBox
                      initialOptions={emailOptions}
                      createOption={false}
                      onSelectChange={(value) => handleMultiSelectAgents(value, 'emails')}
                      isDisabled={false}
                      updateValue={updateEmailBox}
                      placeholderText="Select Member Emails"
                      ariaLabel="Your aria label"
                      clearButtonAriaLabel="Clear input value"
                      selectedOptions={multiselectedEmails}
                      validStatus={validEmails}
                    />
                  </FormGroup>
                </GridItem>
                <GridItem>
                  <FormGroup fieldId="basic-form-radio-group" label="Priority" isRequired>
                    <CommonSelectBox
                      options={priorityOptions}
                      isLoading={false}
                      placeholder="Select priority"
                      isDisabled={false}
                      type="priority"
                      indexVal={''}
                      updateValue={updatePriorityBox}
                      selectedOptions={selectedPriority}
                      validStatus={validPriority}
                    />
                  </FormGroup>
                </GridItem>
                <GridItem span={12}>
                  <FormGroup role="radiogroup" isInline isRequired labelIcon={
                    <Tooltip content={'Choose an action type for the implementation of the filter rule.'}>
                      <Button variant='plain'> <HelpIcon /> </Button>
                    </Tooltip>} fieldId="horizontal-form-radio-group" hasNoPaddingTop label="Action type">
                    <Radio name="actiontype" label="Email alerts" id="1" isChecked={checkActionType == "1"} onChange={() => handleRadioChange("1")} />
                    <Radio name="actiontype" label="Incident registered" id="2" isChecked={checkActionType == "2"} onChange={() => handleRadioChange("2")} />
                    <Radio name="actiontype" label="Emails alerts/Incident registered" id="3" isChecked={checkActionType == "3"} onChange={() => handleRadioChange("3")} />
                  </FormGroup>
                </GridItem>
                <GridItem span={12}>
                  <FormGroup label="Filter Criteria" fieldId="simple-form-phone-01" isRequired>
                    <Banner screenReaderText="Info banner" variant="blue">
                      <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                        <FlexItem>
                          <InfoCircleIcon />
                        </FlexItem>
                        <FlexItem>Press enter after typing complete value of field in value box to create tags.</FlexItem>
                      </Flex>
                    </Banner>
                    <Banner screenReaderText="Info banner" variant="blue">
                      <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                        <FlexItem>
                          <InfoCircleIcon />
                        </FlexItem>
                        <FlexItem>please enter the rule level is greater than equal to {props.ruleLevel} or less than equal to 15.</FlexItem>
                      </Flex>
                    </Banner>
                    {formData.map((row, rowIndex) => {
                      return (
                        <div className="pf-v5-u-p-sm" key={rowIndex}>
                          <Flex spaceItems={{ default: 'spaceItemsXs' }} key={rowIndex + row.orgGroupId}>
                            <FlexItem key={1}>
                              {fieldBox(row.fieldName, rowIndex, row.fieldNameValid)}
                            </FlexItem>
                            <FlexItem key={2}>
                              {conditionBox(row.conditionName, rowIndex, row.conditionNameValid, row.fieldName)}
                            </FlexItem>
                            <FlexItem key={3}>
                              <FilterInputTextBox
                                placeholder="enter values"
                                currentChips={row.valueInput}
                                setCurrentChips={newValue => {
                                  const updatedFormData = [...formData];
                                  updatedFormData[rowIndex].valueInput = newValue;
                                  updatedFormData[rowIndex].valueInputValid = emptyArrayCheck(newValue);
                                  setFormData(updatedFormData);
                                }}
                                indexNameValid={row.valueInputValid}
                                RuleLevel={props.ruleLevel}
                                isRuleLevel={row.fieldName === 'rule_level'} // Pass this as a prop
                                // helperText={`You can add only a value greater than ${props.ruleLevel} and less than or equal to 15`}
                              />
                            </FlexItem>
                            {/* <FlexItem key={3}>
                              <TextInput isRequired placeholder="value" type="text" id="value" name="value" aria-describedby="value-helper" value={row.value ?? ''} validated={row.validValue} onChange={(event) => FormfieldChange(event, rowIndex)} />
                            </FlexItem> */}
                            {formData.length - 1 == rowIndex && <FlexItem key={5}><a><Icon status="info" onClick={() => { addNewRow(rowIndex); }}><PlusCircleIcon /></Icon></a></FlexItem>}
                            {formData.length !== 1 && <FlexItem key={6}><a>
                              <Icon status="danger"
                                onClick={() => {
                                  if (row.uniqueID !== '') { delteFilterCondition(row.uniqueID) }
                                  else {
                                    removeRow(rowIndex);
                                  }
                                }}
                              ><TrashIcon /></Icon></a></FlexItem>}
                          </Flex>
                        </div>
                      )
                    })}
                  </FormGroup>
                </GridItem>
              </Grid>
              <ActionGroup>
                <Button variant="primary" onClick={() => submitForm()}>{actiontype=='Add'?'Submit':'Update'}</Button>
                <Button variant="link" onClick={() => onCancelForm()}>Cancel</Button>
              </ActionGroup>
            </Form>}
          </ExpandableSection>
          <br />
          <ExpandableSection displaySize="lg" toggleText={'Rule List'} onToggle={() => onToggle(1)} isExpanded={expandedSections[1]}>
            <AlertingruleListTable OrganizationID={props.OrgUniqueID} onUpdateruleformRef={getRulesDetails} Refresh={callList} />
          </ExpandableSection>
        </CardBody>
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({})

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(AlertingRule)