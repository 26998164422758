import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Bullseye, Card, CardBody, CardTitle, Grid, GridItem, PageSection, Text, TextVariants } from '@patternfly/react-core'
import IndexTable from './IndexTable'
import dbApi from '../../services/DbApi'
import { Loader, TextSkeleton } from 'src/util/Loadingstate'

const IndexManagementMain = (props) => {
    const [totalindices, setTotalIndices] = useState(0);
    const [overallconsumed, setOverallConsumed] = useState(0);
    const [loadindices, setLoadIndices] = useState(true)

    const getIndicies = async () => {
        try {
            setLoadIndices(true)
            let res = await dbApi.getApi("elasticsearch/indices")
            console.log(res)
            setLoadIndices(false)
        } catch (error) {
            console.log(error)
            setLoadIndices(false)
        }
    }

    useEffect(() => {
        getIndicies()
    }, [])

    const UpdatetotalIndexes = (total, size) => {
        setTotalIndices(total)
        setOverallConsumed(size)
    }
    return (
        <React.Fragment>
            <div className="pf-v5-u-px-sm pf-v5-u-mt-sm">
                <Bullseye>
                    <Grid hasGutter align="center">
                        <GridItem md={6} lg={6} sm={6}>
                            <Card isRounded isFullHeight>
                                <CardTitle align="center">Total Index</CardTitle>
                                <CardBody>
                                    {loadindices ? <TextSkeleton /> :
                                        <Text component={TextVariants.h1} className='pf-v5-u-font-size-4xl pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                            {totalindices}
                                        </Text>
                                    }
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem md={6} lg={6} sm={6}>
                            <Card isRounded isFullHeight>
                                <CardTitle align="center"> Overall Consumed Size</CardTitle>
                                <CardBody>
                                    {loadindices ? <TextSkeleton /> : <Text component={TextVariants.h1} className='pf-v5-u-font-size-4xl pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                        {overallconsumed} GB
                                    </Text>}
                                </CardBody>
                            </Card>
                        </GridItem>
                    </Grid>
                </Bullseye>
            </div>
            <div className="pf-v5-u-mt-md pf-v5-u-px-sm">
                <Card>
                    <IndexTable Totalindex={UpdatetotalIndexes} LoadIndices={loadindices} />
                </Card>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(IndexManagementMain)