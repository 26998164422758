import {
    Grid, GridItem, Button, InputGroup, InputGroupItem, Card, CardBody, ToggleGroup, ToggleGroupItem, TextArea,
    Flex,
    FlexItem,
    Icon,
    Tooltip,
    Gallery,
    GalleryItem,
    DescriptionList,
    DescriptionListGroup,
    DescriptionListTerm,
    DescriptionListDescription,
    Pagination,
    Badge
} from '@patternfly/react-core';
import { EditIcon, CheckIcon, TimesIcon, UserCircleIcon, TrashIcon, CalendarAltIcon, PlusCircleIcon, ListAltIcon, UploadIcon, FileIcon, FilePdfIcon, FileExcelIcon, FileWordIcon } from '@patternfly/react-icons';
import React, { useState, useEffect, useRef } from 'react';
import dbApi from 'src/services/DbApi';
import { useParams } from 'react-router-dom';
import { alertBoxData } from 'src/Redux/Action';
import { useDispatch } from 'react-redux';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import { formatTableTimestamp } from 'src/util/Timeutils';
import ModalCommon from 'src/Components/CommonEventPages/ModalCommon';
import { Loader } from 'src/util/Loadingstate';

const IncidentTaskDetail = () => {
    const dispatch = useDispatch();
    const userRole = localStorage.getItem("role");
    const modalRef = useRef();
    const fileInputRef = useRef(null);
    const { id, taskid } = useParams();
    const [taskDetail, setTaskDetail] = useState(null); // Combined task detail state
    const [taskLogs, setTaskLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editingField, setEditingField] = useState(null);
    const [isAddingLog, setIsAddingLog] = useState(false);
    const [taskLog, setTaskLog] = useState('');
    const [editingLogIndex, setEditingLogIndex] = useState(null);
    const [editedLogValue, setEditedLogValue] = useState('');
    const [selectedFile, setSelectedFile] = useState('');
    const [selectedFileName, setSelectedFileName] = useState(null);
    const [modalImage, setModalImage] = useState(null);
    const [editedStatus, setEditedStatus] = useState('');
    const [isEditingText, setIsEditingText] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState({});
    const [isLoadingLog, setIsLoadingLog] = useState(false);
    const [isEditingFile, setIsEditingFile] = useState(false);
    const [confirmModalData, setConfirmBox] = useState({
        show: false, msg: '',
        funCall: () => { }, funParam: {}, title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    })

    useEffect(() => {
        getTaskDetail();
    }, [taskid]);
    const [newFile, setNewFile] = useState(null);


    const getTaskDetail = async () => {
        try {
            setIsLoading(true);
            const taskRes = await dbApi.postApi("incident_management/get_task", { taskUniqueId: taskid });
            const logRes = await dbApi.postApi("incident_management/get_task_log", { taskUniqueId: taskid });

            if (taskRes.data && taskRes.data.length > 0) {
                const detail = taskRes.data[0];
                setTaskDetail({
                    title: detail.title,
                    description: detail.description,
                    status: detail.status,
                    assigneeName: detail.assignee_name,
                    createDateTime: detail.last_datetime
                });
            }
            if (logRes) {
                setTaskLogs(logRes);
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setIsLoadingLog(false);
        }
    };

    const handleEditClick = (fieldName) => {
        if (fieldName === 'file') {
            setIsEditingFile(true);
            setSelectedFileName(taskDetail.document);
        } else {
            setEditingField(fieldName);
            if (fieldName === 'title' || fieldName === 'description') {
                setInputValue(taskDetail[fieldName]);
            }

        }
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Only trigger click if ref is not null
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);  // Set the selected file in state
            setSelectedFileName(file.name);  // Update the UI with the selected file name
            console.log('Selected file:', file);
        }
    };

    const downloadFile = async (docUniqueId) => {
        try {
            const response = await dbApi.getApi(`incident_management/download/${docUniqueId}`, true, 'blob');
            const contentDisposition = response.headers['content-disposition'];
            let fileName = "";
            if (contentDisposition) {
                const match = contentDisposition.match(/filename="?(.+)"?/);
                if (match && match[1]) {
                    fileName = match[1];
                }
            }
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error during file download:', error);
        }
    };


    const handleSaveLog = async () => {
        setIsLoadingLog(true)

        try {
            if (!taskid || !taskLog) {
                console.error('Task ID or task log is missing');
                return;
            }
            const formData = new FormData();
            formData.append("taskUniqueId", taskid);
            formData.append("description", taskLog);
            if (selectedFile) {
                formData.append("file", selectedFile);
            }
            const res = await dbApi.postFormDataApi("incident_management/add_task_log", formData);

            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));

                const fileType = selectedFile ? selectedFile.type : null;
                const imageData = res.image_data || '';
                const documentName = res.document || 'No document';
                const docUniqueId = res.doc_unique_id || null;
                const newLog = {
                    description: taskLog,
                    fileType: fileType,
                    image_data: imageData,
                    document: documentName,
                    doc_unique_id: docUniqueId
                };
                setTaskLogs([newLog, ...taskLogs]);
                setTaskLog('');
                setIsAddingLog(false);
                getTaskDetail();
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                setSelectedFile(null);
                setSelectedFileName('');

            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
            setIsLoadingLog(false)

        } catch (error) {
            console.error('Failed to add task log:', error);
            setIsLoadingLog(false)

        }
    };




    const handleSaveClick = async (fieldName, value) => {
        setIsLoadingLog(true)
        try {
            const res = await dbApi.putApi("incident_management/update_task", {
                taskUniqueId: taskid,
                [fieldName]: value
            });

            if (!res.error) {
                setTaskDetail(prev => ({ ...prev, [fieldName]: value }));
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                getTaskDetail();
                setEditingField(null);
            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
            setIsLoadingLog(false)
        } catch (error) {
            console.error('Failed to update task:', error);
            setIsLoadingLog(false)

        }
    };


    const handleCancelClick = () => {
        setEditingField(null);
        setSelectedFile(null);
        setEditedStatus('');
        setSelectedFileName('');
    };

    const handleAddLogClick = () => {
        setIsAddingLog(!isAddingLog);
        setTaskLog('');
        setSelectedFileName('')
    };

    const handleImageClick = (imageData) => {
        setModalImage(imageData);
        modalRef.current.setModalBox(true);
    };

    const handleEditLogClick = (index, type) => {
        setEditingLogIndex(index);

        if (type === 'text') {
            setIsEditingText(true);
            setIsEditingFile(false);
            setEditedLogValue(taskLogs[index].description || '');
        } else if (type === 'file') {
            setIsEditingText(false);
            setIsEditingFile(true);
            setSelectedFile(null);
        }
    };


    const handleSaveEditedLog = async (type, index) => {
        setIsLoadingLog(true);
        try {

            const logId = taskLogs[index]?.unique_id;
            if (!logId) {
                throw new Error("Log ID is missing");
            }
            const formData = new FormData();
            formData.append("uniqueId", logId);
            formData.append("description", editedLogValue || taskLogs[index].description);
            if (type === 'file') {
                if (selectedFile) {
                    formData.append("file", selectedFile);

                } else {
                    throw new Error("No file selected for upload");
                }
            }
            const res = await dbApi.postFormDataApi("incident_management/update_task_log", formData);

            if (!res.error) {
                const updatedLogs = [...taskLogs];
                updatedLogs[index] = {
                    ...updatedLogs[index],
                    description: editedLogValue || updatedLogs[index].description,
                    document: res.document || updatedLogs[index].document,
                    doc_unique_id: res.doc_unique_id || updatedLogs[index].doc_unique_id,
                    image_data: res.image_data || updatedLogs[index].image_data
                };
                setTaskLogs(updatedLogs);
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                setEditingLogIndex(null);
                setIsEditingText(false);
                setIsEditingFile(false);
                setSelectedFile(null);
                setEditedLogValue("");
                getTaskDetail();
            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
            setIsLoadingLog(false);
        } catch (error) {
            console.error('Failed to update task log:', error);
            dispatch(alertBoxData([{ title: error.message, variant: 'danger' }]));
            setIsLoadingLog(false);
        }
    };


    const handleCancelLogEdit = () => {
        setEditingLogIndex(null);
        setIsEditingText(false);
        setIsEditingFile(false);
        setSelectedFile(null);
        setEditedLogValue("");
    };

    const fields = [
        { label: 'Title', name: 'title', value: taskDetail?.title },
        { label: 'Description', name: 'description', value: taskDetail?.description },
        { label: 'Status', name: 'status', value: taskDetail?.status, options: ['Waiting', 'InProgress', 'Completed'] },
    ];

    const deleteGroup = async (logId) => {
        try {
            setIsLoading(true);
            let res = await dbApi.deleteApi("incident_management/delete_task_log", { uniqueId: logId });
            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: "success" }]));
                setTaskLogs(taskLogs.filter(log => log.unique_id !== logId));
            } else {
                dispatch(alertBoxData([{ title: res.message, variant: "danger" }]));
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error.message || error);
            setIsLoading(false);
        }
    };

    const onDeleteGroup = (logId) => {
        setConfirmBox({
            show: true,
            msg: `Permanently delete this log?`,
            funCall: () => deleteGroup(logId),
            funParam: {},
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        });
    };


    const renderFilePreview = (index) => {

        if (!selectedFile) return null;

        const fileUrl = URL.createObjectURL(selectedFile);
        const isImage = selectedFile.type.startsWith('image/');

        return (
            <div style={{ marginTop: '10px' }}>
                {isImage ? (
                    <img
                        src={fileUrl}
                        alt="File Preview"
                        style={{ maxHeight: '70px', cursor: 'pointer' }}
                    />
                ) : (
                    <div>
                        <strong>File Selected:</strong> {selectedFile.name}
                    </div>
                )}
                <InputGroup>
                    <InputGroupItem>
                        {isLoadingLog ? (

                            <Loader />) : (
                            <Button variant="plain" onClick={() => handleSaveEditedLog('file', index)}>
                                <CheckIcon />
                            </Button>
                        )}
                    </InputGroupItem>
                    <InputGroupItem>
                        <Button variant="plain" onClick={handleCancelLogEdit}>
                            <TimesIcon />
                        </Button>
                    </InputGroupItem>
                </InputGroup>
            </div>
        );
    };
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const paginatedTaskLogs = Array.isArray(taskLogs) ? taskLogs.slice(startIndex, endIndex) : [];

    const onSetPage = (_event, pageNumber) => {
        setPage(pageNumber);
    };
    const onPerPageSelect = (_event, perPageNumber) => {
        setPerPage(perPageNumber);
        setPage(1);
    };

    const extendedTlpColorMap = {
        'Waiting': { backgroundColor: '#00c0ef', color: '#fff' },
        'InProgress': { backgroundColor: '#ffbf00', color: '#fff' },
        'Completed': { backgroundColor: '#008000', color: '#fff' },
    };

    return (
        <>
            <Card>
                <CardBody>
                    <>
                        <DescriptionList className='pf-v5-u-mt-md' isHorizontal columnModifier={{ xl: '2Col', lg: '1Col', md: '1Col', sm: '1Col' }} horizontalTermWidthModifier={{
                            default: '10ch'
                        }}>
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    {fields.map((field, index) => (
                                        <DescriptionListGroup key={index}>
                                            <DescriptionListTerm className="pf-v5-u-font-weight-bold">
                                                {field.label}:
                                            </DescriptionListTerm>
                                            <DescriptionListDescription>
                                                {editingField === field.name ? (
                                                    field.name === 'status' ? (
                                                        <ToggleGroup aria-label="Status toggle group" className="custom-toggle-group">
                                                            <Flex spaceItems={{ default: 'spaceItemsNone' }}>
                                                                {field.options.map((option, idx) => (
                                                                    <FlexItem>
                                                                        <ToggleGroupItem
                                                                            key={idx}
                                                                            text={
                                                                                <div
                                                                                    style={{
                                                                                        backgroundColor: editedStatus === option ? extendedTlpColorMap[option]?.backgroundColor || '#fff' : '#fff',
                                                                                        color: editedStatus === option ? extendedTlpColorMap[option]?.color || '#000' : '#000',
                                                                                        padding: '2px 20px',
                                                                                        borderRadius: '4px',
                                                                                    }}
                                                                                >
                                                                                    {option}
                                                                                </div>
                                                                            }
                                                                            isSelected={editedStatus === option}
                                                                            onChange={() => setEditedStatus(option)}
                                                                        />
                                                                    </FlexItem>
                                                                ))}
                                                                <FlexItem>
                                                                    {isLoadingLog ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <Button variant="plain" onClick={() => handleSaveClick(field.name, editedStatus)}>
                                                                            <CheckIcon /> {/* Save button */}
                                                                        </Button>
                                                                    )}
                                                                    <Button variant="plain" onClick={handleCancelClick}>
                                                                        <TimesIcon /> {/* Cancel button */}
                                                                    </Button>
                                                                </FlexItem>
                                                            </Flex>
                                                        </ToggleGroup>
                                                    ) : (
                                                        <InputGroup>
                                                            <TextArea
                                                                value={inputValue}
                                                                onChange={(e) => setInputValue(e.target.value)}
                                                                rows={2}
                                                                style={{ width: '100%', padding: '2px' }}
                                                                className='custom-textarea'
                                                            />
                                                            <InputGroupItem className="input-group-item">
                                                                <Button variant="plain" onClick={() => handleSaveClick(field.name, inputValue)}>
                                                                    <CheckIcon />
                                                                </Button>
                                                                <Button variant="plain" onClick={handleCancelClick}>
                                                                    <TimesIcon />
                                                                </Button>
                                                            </InputGroupItem>
                                                        </InputGroup>
                                                    )
                                                ) : (
                                                    <div>
                                                        {field.name === 'status' ? (
                                                            <Badge
                                                                style={{
                                                                    backgroundColor: extendedTlpColorMap[field.value]?.backgroundColor || '#fff',
                                                                    color: extendedTlpColorMap[field.value]?.color || '#000',
                                                                    padding: '4px 20px',
                                                                    borderRadius: '4px',
                                                                    fontSize: '13px',
                                                                }}
                                                            >
                                                                {field.value}
                                                            </Badge>
                                                        ) : (
                                                            field.value
                                                        )}
                                                        {(userRole !== 'Analyst' || field.name === 'status') && (
                                                            <Button variant="plain" onClick={() => handleEditClick(field.name)}>
                                                                <EditIcon />
                                                            </Button>
                                                        )}
                                                    </div>
                                                )}
                                            </DescriptionListDescription>
                                        </DescriptionListGroup>
                                    ))}
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>Task Logs</DescriptionListTerm>
                                        <DescriptionListDescription>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Button
                                                    variant="plain"
                                                    onClick={handleAddLogClick}
                                                    style={{ marginLeft: '10px', padding: 0 }}
                                                >
                                                    <Tooltip content="Add task log">
                                                        <Icon status='info'>
                                                            <PlusCircleIcon />
                                                        </Icon>
                                                    </Tooltip>
                                                </Button>
                                            </div>
                                        </DescriptionListDescription>
                                    </DescriptionListGroup>
                                </>
                            )}
                        </DescriptionList>
                    </>
                    <Card isPlain >
                        <CardBody className='custom-card'>
                            <Grid>
                                <GridItem lg={12} className='pf-v5-u-mb-sm'>
                                    {isAddingLog ? (
                                        <InputGroup>
                                            <InputGroupItem isFill>
                                                <TextArea
                                                    type="text"
                                                    value={taskLog}
                                                    onChange={(e) => setTaskLog(e.target.value)}
                                                    aria-label="Add log"
                                                    resizeOrientation="horizontal"
                                                    placeholder="Enter your task log here..."
                                                />
                                            </InputGroupItem>
                                            <InputGroupItem>
                                                {/* Hidden file input */}
                                                <input
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    aria-label="Upload file"
                                                    style={{ display: 'none' }}
                                                    ref={fileInputRef}
                                                />
                                                <Tooltip content="Upload File">
                                                    <Button variant="plain" onClick={() => fileInputRef.current.click()}>
                                                        <UploadIcon />
                                                    </Button>
                                                </Tooltip>
                                            </InputGroupItem>
                                            <InputGroupItem>
                                                {selectedFileName && (
                                                    <Card>
                                                        <CardBody>
                                                            <p>
                                                                <strong>Selected file:</strong> {selectedFileName}
                                                            </p>
                                                        </CardBody>
                                                    </Card>
                                                )}
                                            </InputGroupItem>
                                            <InputGroupItem>
                                                {isLoadingLog ? (
                                                    <Loader />
                                                ) : (
                                                    <Button variant="plain" onClick={handleSaveLog}>
                                                        <CheckIcon />
                                                    </Button>
                                                )}
                                            </InputGroupItem>
                                            <InputGroupItem>
                                                <Button variant="plain" onClick={handleAddLogClick}>
                                                    <TimesIcon />
                                                </Button>
                                            </InputGroupItem>
                                        </InputGroup>
                                    ) : null}
                                </GridItem>
                            </Grid>
                            <Grid>
                                <GridItem lg={12}>
                                    {taskLogs.length > 0 && (
                                        <>
                                            <Pagination
                                                itemCount={taskLogs.length}
                                                perPage={perPage}
                                                page={page}
                                                onSetPage={onSetPage}
                                                onPerPageSelect={onPerPageSelect}
                                                variant="top"
                                            />
                                            <Gallery hasGutter minWidths={{ default: '250px' }} className='pf-v5-u-mt-md'>
                                                {paginatedTaskLogs.map((log, index) => {

                                                    const isImage = log.image_data && log.fileType && log.fileType.startsWith('image');
                                                    const { formattedTimeStamp: lastDatetime } = log?.last_datetime
                                                        ? formatTableTimestamp(log.last_datetime)
                                                        : { formattedTimeStamp: ' ' };

                                                    return (
                                                        <GalleryItem key={index}>
                                                            <Card isCompact isFullHeight>
                                                                <CardBody>
                                                                    {editingLogIndex === index ? (
                                                                        <>
                                                                            {isEditingText && (
                                                                                <>
                                                                                    <TextArea
                                                                                        value={editedLogValue}
                                                                                        onChange={(e) => setEditedLogValue(e.target.value)}
                                                                                        aria-label="Edit task log"
                                                                                    />
                                                                                    {editedLogValue !== taskLogs[startIndex + index].description && (
                                                                                        isLoadingLog ? (
                                                                                            <Loader />
                                                                                        ) : (
                                                                                            <Button variant="plain" onClick={() => handleSaveEditedLog('text', index)}>
                                                                                                <CheckIcon />
                                                                                            </Button>
                                                                                        )
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            {isEditingFile && (
                                                                                <>
                                                                                    <div>
                                                                                        {taskLogs[startIndex + index].document ? (
                                                                                            <p><strong>Current file:</strong> {taskLogs[startIndex + index].document}</p>
                                                                                        ) : (
                                                                                            <p><strong>No file attached yet</strong></p>
                                                                                        )}
                                                                                        <Card className='pf-v5-u-mt-md'>
                                                                                            <CardBody>
                                                                                                {!selectedFile ? (
                                                                                                    <Button variant="plain" onClick={() => fileInputRef.current.click()}>
                                                                                                        <UploadIcon /> Choose File for Upload
                                                                                                    </Button>
                                                                                                ) : (
                                                                                                    <p><strong>Selected file:</strong> {selectedFile.name}</p>
                                                                                                )}
                                                                                            </CardBody>
                                                                                        </Card>
                                                                                        <input
                                                                                            type="file"
                                                                                            ref={fileInputRef}
                                                                                            style={{ display: 'none' }}
                                                                                            onChange={(e) => setSelectedFile(e.target.files[0])}
                                                                                            accept=".pdf,.txt,.xlsx,.xls,.doc,.docx,.png,.jpg,.jpeg"
                                                                                        />
                                                                                        <>
                                                                                            {selectedFile && (
                                                                                                <>
                                                                                                    {isLoadingLog ? (
                                                                                                        <Loader />
                                                                                                    ) : (
                                                                                                        <Button variant="plain" onClick={() => handleSaveEditedLog('file', index)}>
                                                                                                            <CheckIcon />
                                                                                                        </Button>
                                                                                                    )}

                                                                                                    <Button variant="plain" onClick={handleCancelLogEdit}>
                                                                                                        <TimesIcon />
                                                                                                    </Button>
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                            {!isEditingFile && (
                                                                                <Button variant="plain" onClick={handleCancelLogEdit}>
                                                                                    <TimesIcon />
                                                                                </Button>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <div>
                                                                            <Flex>
                                                                                <FlexItem>
                                                                                    <p>
                                                                                        <strong className='pf-v5-u-mr-md'>
                                                                                            <UserCircleIcon />
                                                                                        </strong>
                                                                                        {taskDetail?.assigneeName}
                                                                                    </p>
                                                                                </FlexItem>
                                                                                <FlexItem align={{ default: 'alignRight' }}>
                                                                                    <Tooltip content="Delete comment">
                                                                                        <Button
                                                                                            variant="plain"
                                                                                            aria-label="Delete log"
                                                                                            onClick={() => onDeleteGroup(log.unique_id)}
                                                                                            style={{ marginLeft: '5px' }}
                                                                                        >
                                                                                            <Icon status="danger">
                                                                                                <TrashIcon />
                                                                                            </Icon>
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                </FlexItem>
                                                                            </Flex>
                                                                            <Flex>
                                                                                <FlexItem>
                                                                                    <strong className='pf-v5-u-mr-md'>
                                                                                        <CalendarAltIcon />
                                                                                    </strong>
                                                                                    {lastDatetime}
                                                                                </FlexItem>
                                                                            </Flex>
                                                                            <Flex>
                                                                                <ListAltIcon />
                                                                                <FlexItem>
                                                                                    <div style={{ marginLeft: '-8px' }}>{log.description}</div>
                                                                                </FlexItem>
                                                                                <FlexItem>
                                                                                    <Button variant="plain" onClick={() => handleEditLogClick(startIndex + index, 'text')}>
                                                                                        <EditIcon />
                                                                                    </Button>
                                                                                </FlexItem>
                                                                            </Flex>
                                                                            {log.image_data || log.imagePreviewUrl ? (
                                                                                <Flex>
                                                                                    <FlexItem>
                                                                                        <img
                                                                                            src={log.image_data}
                                                                                            alt="Task Image"
                                                                                            style={{ maxHeight: '70px', marginTop: '10px', cursor: 'pointer' }}
                                                                                            onClick={() => handleImageClick(log.image_data)}
                                                                                        />
                                                                                    </FlexItem>
                                                                                    <FlexItem>
                                                                                        {log.doc_unique_id && log.document && (
                                                                                            <Button variant="link" isInline onClick={() => downloadFile(log.doc_unique_id)}>
                                                                                                {log.document}
                                                                                            </Button>
                                                                                        )}
                                                                                    </FlexItem>
                                                                                    <FlexItem>
                                                                                        <Button variant="plain" onClick={() => handleEditLogClick(index, 'file')}>
                                                                                            <EditIcon />
                                                                                        </Button>
                                                                                    </FlexItem>
                                                                                </Flex>
                                                                            ) : (
                                                                                log.doc_unique_id && log.document && (
                                                                                    <Card>
                                                                                        <CardBody>
                                                                                            <Flex direction={{ default: 'column' }}>
                                                                                                <FlexItem>
                                                                                                    <Icon size="lg">
                                                                                                        {log.document.toLowerCase().endsWith('.pdf') && <FilePdfIcon />}
                                                                                                        {log.document.toLowerCase().endsWith('.txt') && <FileIcon />}
                                                                                                        {log.document.toLowerCase().endsWith('.xlsx') && <FileExcelIcon />}
                                                                                                        {log.document.toLowerCase().endsWith('.xls') && <FileExcelIcon />}
                                                                                                        {log.document.toLowerCase().endsWith('.doc') && <FileWordIcon />}
                                                                                                        {log.document.toLowerCase().endsWith('.docx') && <FileWordIcon />}
                                                                                                        {!['.pdf', '.txt', '.xlsx', '.xls', '.doc', '.docx'].some(ext =>
                                                                                                            log.document.toLowerCase().endsWith(ext)) && <FileIcon />
                                                                                                        }
                                                                                                    </Icon>
                                                                                                </FlexItem>
                                                                                                <FlexItem>
                                                                                                    <Tooltip content="Download File" position="bottom">
                                                                                                        <Button variant="link" isInline onClick={() => downloadFile(log.doc_unique_id)}>
                                                                                                            {log.document || 'file name'}
                                                                                                        </Button>
                                                                                                    </Tooltip>
                                                                                                </FlexItem>
                                                                                                {/* Edit button for document */}
                                                                                                <FlexItem>
                                                                                                    <Button variant="plain" onClick={() => handleEditLogClick(index, 'file')}>
                                                                                                        <EditIcon />
                                                                                                    </Button>
                                                                                                </FlexItem>
                                                                                            </Flex>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                )
                                                                            )}
                                                                            {(!log.image_data && log.document == null) && (
                                                                                <>
                                                                                    <Card isFullHeight className='pf-v5-u-mt-lg'>
                                                                                        <Button variant="plain" onClick={handleClick}>
                                                                                            <UploadIcon style={{ marginLeft: '-16px', marginRight: '10px' }} /> Choose File for Upload
                                                                                        </Button>
                                                                                    </Card>
                                                                                    <input
                                                                                        type="file"
                                                                                        ref={fileInputRef}
                                                                                        style={{ display: 'none' }}
                                                                                        onChange={(e) => {
                                                                                            const file = e.target.files[0];
                                                                                            setSelectedFile(file);
                                                                                        }}
                                                                                        accept=".pdf,.txt,.xlsx,.xls,.doc,.docx,.png,.jpg,.jpeg"
                                                                                    />
                                                                                    {selectedFiles && renderFilePreview(index)}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </CardBody>
                                                            </Card>
                                                        </GalleryItem>
                                                    );
                                                })}
                                            </Gallery>
                                            <div className='pf-v5-u-mt-md'>
                                                <Pagination
                                                    itemCount={taskLogs.length}
                                                    perPage={perPage}
                                                    page={page}
                                                    onSetPage={onSetPage}
                                                    onPerPageSelect={onPerPageSelect}
                                                    variant="bottom"
                                                />
                                            </div>
                                        </>
                                    )}
                                </GridItem>
                            </Grid>
                        </CardBody>
                    </Card>
                </CardBody >
            </Card >
            <ConfirmBox confirmModal={confirmModalData} />
            <ModalCommon
                ref={modalRef}
                title="Image Preview"
                onClose={() => setModalImage(null)}
            >
                {modalImage && (
                    <img
                        src={modalImage}
                        alt="Modal Task Image"
                        style={{ maxWidth: '100%', Height: '300px' }}
                    />
                )}
            </ModalCommon>
        </>
    );
};

export default IncidentTaskDetail;
