import React, { useEffect, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
} from "@patternfly/react-table";
import { Emptystatefornodata } from "../../util/EmptyStateCard";
import {
  Badge, Pagination, Toolbar,
  ToolbarContent, ToolbarItem, Tooltip
} from "@patternfly/react-core";
import { connect, useDispatch } from "react-redux";
import { FilterData } from "../../Redux/Action";
import { UpdateFilterData } from "../../util/FilterHelper";


const TableViewForChart = (props) => {

  const dispatch = useDispatch();
  const [columns, setColumns] = useState(props.columnData);
  const [defaultRows, setDefaultRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedRowName, setSelectedRowName] = useState('');
  const handleSetPage = (_evt, newPage, perPage, startIdx, endIdx) => {
    setPage(newPage);
  };
  const handlePerPageSelect = (_evt, newPerPage, newPage) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };
  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }

  useEffect(() => {
    const newdefaultRows = props.Tabledata.sort((a, b) => b.doc_count - a.doc_count).map((log) => {
      return {

        key: log.key ? log.key : log.topuser,
        count: log.doc_count ? log.doc_count : log.count,
        rulelevel: log.Critical_levels,
        typeofkey: log.type,
        agentID: log.Agent_ID,
        ruleid: log.ruleId,
      };
    });
    setDefaultRows(newdefaultRows)
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);
  }, [props.Tabledata]);

  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);


  const renderPagination = (variant, isCompact) =>
    <Pagination
      isCompact={isCompact}
      itemCount={defaultRows.length}
      page={page}
      perPage={perPage}
      onSetPage={handleSetPage}
      onPerPageSelect={handlePerPageSelect}
      variant={variant}
    />;
  const renderRuleLevelcolumn = (ruleLevel) => {
    let tooltipContent = "";
    let badgeStyle = {};
    if (ruleLevel > 6 && ruleLevel < 12) {
      tooltipContent = "Medium";
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
    }
    else if (ruleLevel >= 12) {
      tooltipContent = "Critical";
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
    }
    else {
      tooltipContent = "low";
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#0066CC " };
    }
    return (
      <Tooltip content={tooltipContent}>
        <Badge style={badgeStyle} >{ruleLevel}</Badge>
      </Tooltip>
    );

  };


  return (
    <React.Fragment>
      {props.Tabledata.length === 0 ? (
        <Emptystatefornodata />
      ) : (
        <>
          <Toolbar>
            <ToolbarContent>
              <ToolbarItem variant="pagination">{props.Pagination !== false && renderPagination('top', false)}</ToolbarItem>
            </ToolbarContent>
          </Toolbar>
          <Table variant="compact" aria-label="IPlog" id="IPlog-table" isStickyHeader gridBreakPoint="">
            <Thead >
              <Tr key={'cahrt-table-header'}>
                {columns.map((column, index) => (
                  <Th key={column + index}>{column}</Th>
                ))}
              </Tr>
            </Thead>
            {rows.length > 0 && (rows.map((row, rowIndex) => {
              const effectiveIndex = (page - 1) * perPage + rowIndex;
              const BackgroundColor = page === 1 && effectiveIndex < 10;
              const Fields = props.filterFields ? props.filterFields : row?.typeofkey
              const filterKey = props.filterFields === 'rule.id' ? row?.ruleid : row?.key
              return (
                <Tbody key={rowIndex + 'tablebody'}>
                  <Tr key={rowIndex + 'row'}
                    onRowClick={() => setSelectedRowName(rowIndex)}
                    isSelectable
                    isClickable
                    isRowSelected={selectedRowName === rowIndex}
                    className={BackgroundColor ? "pf-v5-u-background-color-danger" : ""} >
                    <Td width={60} modifier='truncate' key={rowIndex + 'rulelevel'}>
                      {row?.rulelevel ?
                        (<>{renderRuleLevelcolumn(row?.rulelevel[0].key)} {row?.key}</>)
                        :
                        (row?.key)
                      }
                    </Td>

                    <Td width={40} key={rowIndex + 'rulecount'}><a onClick={() => { handleFilter(Fields, filterKey) }}>{row?.count}</a></Td>

                  </Tr>
                </Tbody>
              )
            }
            ))}
          </Table>
          {props.Pagination !== false && renderPagination('bottom', false)}
        </>
      )}
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(TableViewForChart)
