import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Divider,
    Flex,
    Grid,
    GridItem,
    Modal,
    ModalVariant,
    Tabs,
    Tab,
    TabTitleText,
    TabContentBody,
} from '@patternfly/react-core'
import dbApi from '../../services/DbApi'
import { connect, useDispatch } from 'react-redux'
import EScatterChart from '../AllCharts/EScatterChart'
import EDonutChart from '../AllCharts/EDonutChart'
import EAreaChart from '../AllCharts/EAreaChart'
import { Loader } from 'src/util/Loadingstate'
import TableViewForChart from '../AllCharts/TableViewForChart'
import { Emptystatefornodata } from 'src/util/EmptyStateCard'
import GdprLastAlerts from './GdprLastAlerts'
import GdprlastAlertTable from './GdprlastAlertTable'
import GdprReqbyAgentchart from './GdprReqbyAgentchart'
const GdprDashboard = forwardRef(function GdprDashboard(props, ref) {

    const dispatch = useDispatch()
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [agents, setAgents] = useState([])
    const [gdprrequirments, setGdprRequirments] = useState([]);
    const [gdprReqlabel, setGdprReqlabel] = useState('');
    const [lastalerts, setLastAlerts] = useState([])

    // //Loading states
    const [isloadAgent, setIsLoadAgent] = useState(false)
    const [loadRequirements, setLoadRequirements] = useState(false)
    const [loadlastalerts, setLoadLastalerts] = useState(false)
    //modal states
    const [modalStates, setModalStates] = useState({
        modalAgents: false,
        modallastalerts: false,
        modalreqbyAgent: false,

    });
    const handleModalToggle = (modalName) => {
        setModalStates((prevStates) => ({
            ...prevStates,
            [modalName]: !prevStates[modalName],
        }));
    };

    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex)
    }

    const getTopAgent = async (data) => {
        try {
            setIsLoadAgent(true)
            let Totalagents = await dbApi.postApi("SecurityCompliances/GDPR_Top_Agent", data)
            console.log(Totalagents)
            let Agents = []
            if (Object.keys(Totalagents.GDPR_Top_Agent).length !== 0) {
                Agents = Totalagents.GDPR_Top_Agent
                setAgents(Agents)
            }
            else {
                setAgents(Agents)
            }
            setIsLoadAgent(false)
        } catch (error) {
            console.log(error)
            setAgents([])
            setIsLoadAgent(false)
        }
    }
    const getGdprRequirements = async (data) => {
        try {
            setLoadRequirements(true)
            let res = await dbApi.postApi("SecurityCompliances/GDPR_Requirements", data);
            console.log(res)
            let gdprrequirement = []
            let label = "";
            if (Object.keys(res.GDPR_Requirements).length !== 0) {
                label = res.GDPR_Requirements.interval
                gdprrequirement = res.GDPR_Requirements.data.body.aggregations.rule_gdpr.buckets
                setGdprRequirments(gdprrequirement)
                setGdprReqlabel(label)
            }
            else {
                setGdprRequirments(gdprrequirement)
                setGdprReqlabel(label)
            }
            setLoadRequirements(false)
        } catch (error) {
            console.log(error)
            setGdprRequirments([])
            setGdprReqlabel('')
            setLoadRequirements(false)
        }
    }

    const getLastAlerts = async (data) => {
        try {
            setLoadLastalerts(true)
            let res = await dbApi.postApi("SecurityCompliances/GDPR_Last_Alerts", data);
            console.log(res)
            let alerts = [];
            if (Object.keys(res.GDPR_Last_Alerts).length !== 0) {
                alerts = res.GDPR_Last_Alerts
                setLastAlerts(alerts)
            }
            else {
                setLastAlerts(alerts)
            }
            setLoadLastalerts(false)
        }
        catch (error) {
            console.log(error)
            setLastAlerts([])
            setLoadLastalerts(false)
        }
    }

    const GdprDashboardApi = async (startDate, endDate, allFields) => {
        const Fields = allFields
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
        };
        try {
            setStartDate(startDate);
            setEndDate(endDate);
            console.log(data)
            getTopAgent(data);
            getGdprRequirements(data);
            getLastAlerts(data)

        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {

        if (props.dates.startDate != "") {
            let Dates = props.dates
            GdprDashboardApi(Dates.startDate, Dates.endDate, props.filterformdata)
        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata,]);


    const chartcolor = ['#519DE9', '#004B95', '#8BC1F7', '#06C', '#002F5D']

    const TopAgents = useMemo(() => {
        return (isloadAgent ?
            <Loader /> :
            agents.length == 0 ? <Emptystatefornodata /> :
                <EDonutChart seriesData={agents.slice(0, 10)} height="290px" color={chartcolor} filterFields={'agent.name'} />

        )
    }, [isloadAgent, agents])

    const LastAlerts = useMemo(() => {
        return (loadlastalerts ? <Loader /> :
            <GdprLastAlerts SeriesData={lastalerts.slice(0, 10)} filterFields={'rule.gdpr'} height={'300px'} />
        )
    }, [loadlastalerts, lastalerts])

    return (<>
        <Grid hasGutter className="pf-v5-u-mt-md">
            <GridItem md={8} sm={12} lg={4}>
                <Card isFullHeight>
                    <CardTitle >Top 10 Agents By Alerts Number</CardTitle>
                    <Divider />
                    <CardBody > {TopAgents} </CardBody>
                    {agents.length > 0 &&
                        <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalAgents'); }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`Top Agents By Alerts Number (${agents.length})`}
                                    isOpen={modalStates.modalAgents}
                                    onClose={() => handleModalToggle('modalAgents')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>
                                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                            <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>} >
                                                <TabContentBody className='pf-v5-u-p-xs'>
                                                    {isloadAgent ? <Loader /> : <TableViewForChart Tabledata={agents} columnData={["Agents", "Count"]} filterFields={'agent.name'} />}
                                                </TabContentBody>
                                            </Tab>
                                            <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                {activeTabKey == 1 && <TabContentBody className='pf-v5-u-p-xs'>
                                                    <EDonutChart seriesData={agents.slice(0, 20)} height="350px" color={['#519DE9', '#004B95', '#8BC1F7', '#06C', '#002F5D']} filterFields={'agent.name'} />
                                                </TabContentBody>}
                                            </Tab>
                                        </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>
                    }
                </Card>
            </GridItem>
            <GridItem md={4} sm={12} lg={8}>
                <Card isFullHeight>
                    <CardTitle >GDPR Requirements</CardTitle>
                    <Divider />
                    <CardBody >
                        {loadRequirements ?
                            <Loader /> : gdprrequirments.length == 0 ? <Emptystatefornodata /> :
                                <EScatterChart
                                    seriesData={gdprrequirments.slice(0,10)}
                                    label={gdprReqlabel}
                                    height={'290px'}
                                    StartDateTime={startDate}
                                    EndDateTime={endDate}
                                    filterFields={'rule.gdpr'}
                                />
                        }
                    </CardBody>
                </Card>
            </GridItem>
        </Grid>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={12} sm={12} lg={12}>
                    <Card isFullHeight>
                        <CardTitle > Top Requirements Over Time</CardTitle>
                        <Divider />
                        <CardBody >{loadRequirements ? <Loader /> :
                            <EAreaChart
                                alertlevelSeries={gdprrequirments.slice(0, 10)}
                                label={gdprReqlabel}
                                StartDateTime={startDate}
                                EndDateTime={endDate}
                                color={chartcolor}
                                height={'290px'}
                                filterFields={'rule.gdpr'}
                            />}</CardBody>
                    </Card>
                </GridItem>
            </Grid>
        </div>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={12} sm={12} lg={12}>
                    <Card isFullHeight>
                        <CardTitle >Last Alerts</CardTitle>
                        <Divider />
                        <CardBody >{LastAlerts}</CardBody>
                        {lastalerts.length > 0 &&
                            <CardFooter>
                                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                    <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modallastalerts'); }}>
                                        More
                                    </Button>
                                    <Modal
                                        variant={ModalVariant.large}
                                        title={`Last Alerts (${lastalerts.length})`}
                                        isOpen={modalStates.modallastalerts}
                                        onClose={() => handleModalToggle('modallastalerts')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                                <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                    <TabContentBody className='pf-v5-u-p-xs'>
                                                        {loadlastalerts ? <Loader /> : <GdprlastAlertTable Tabledata={lastalerts} columnData={["Requirement", "Agents", "Count"]} filterFields={'rule.gdpr'} />}
                                                    </TabContentBody>
                                                </Tab>
                                                <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                    {activeTabKey == 1 && <TabContentBody className='pf-v5-u-p-xs'>
                                                        <GdprLastAlerts SeriesData={lastalerts} filterFields={'rule.gdpr'} height={'450px'} />
                                                    </TabContentBody>}
                                                </Tab>
                                            </Tabs>
                                        </Card>
                                    </Modal>
                                </Flex>
                            </CardFooter>
                        }
                    </Card>
                </GridItem>
            </Grid>
        </div>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={12} sm={12} lg={12}>
                    <Card isFullHeight>
                        <CardTitle >Requirements By Agent </CardTitle>
                        <Divider />
                        <CardBody >  {loadlastalerts ? <Loader /> : lastalerts.length > 0 ?
                            <GdprReqbyAgentchart seriesData={lastalerts.slice(0, 10)} filterFields={'agent.name'} height={'230px'} /> :
                            <Emptystatefornodata />
                        }
                        </CardBody>
                        {lastalerts.length > 0 &&
                            <CardFooter>
                                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                    <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalreqbyAgent'); }}>
                                        More
                                    </Button>
                                    <Modal
                                        variant={ModalVariant.large}
                                        title={`Requirements By Agent (${lastalerts.length})`}
                                        isOpen={modalStates.modalreqbyAgent}
                                        onClose={() => handleModalToggle('modalreqbyAgent')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                                <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>} >
                                                    <TabContentBody className='pf-v5-u-p-xs'>
                                                        {loadlastalerts ? <Loader /> : <GdprlastAlertTable Tabledata={lastalerts} columnData={["Requirement", "Agents", "Count"]} filterFields={'rule.gdpr'} />}
                                                    </TabContentBody>
                                                </Tab>
                                                <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                    {activeTabKey == 1 && <TabContentBody className='pf-v5-u-p-xs'>
                                                        <GdprReqbyAgentchart seriesData={lastalerts.slice(0, 20)} filterFields={'agent.name'} height={'370px'} /> :
                                                    </TabContentBody>}
                                                </Tab>
                                            </Tabs>
                                        </Card>
                                    </Modal>
                                </Flex>
                            </CardFooter>
                        }
                    </Card>
                </GridItem>
            </Grid>
        </div>
    </>
    )
}
)
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} }
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(GdprDashboard)

