import React, { useState, useEffect, useRef } from 'react';
import {
  Select, SelectList, SelectOption, TextInputGroup,
  ChipGroup, Chip, Button, MenuToggle, TextInputGroupMain,
  TextInputGroupUtilities
} from '@patternfly/react-core';
import { TimesIcon } from '@patternfly/react-icons';
const MultiselectBox = ({
  initialOptions,
  onSelectChange,
  placeholderText,
  selectedOptions,
  updateValue,
  isDisabled,
  clearButtonAriaLabel,
  type,
  indexVal,
  validStatus,
  createOption
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState([]);
  const [selectedChip, setSelectedChip] = useState([]);
  const [selectOptions, setSelectOptions] = useState(initialOptions);
  const [optionList, setOptionList] = useState(initialOptions);
  const [focusedItemIndex, setFocusedItemIndex] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [onCreation, setOnCreation] = React.useState(false);
  const textInputRef = useRef();

  useEffect(() => {
    {
      setSelectOptions(initialOptions)
      setOptionList(initialOptions)
    }
  }, [initialOptions])

  useEffect(() => {
    let newSelectOptions = optionList;
    if (inputValue != "") {
      newSelectOptions = optionList.filter(
        (menuItem) =>
          String(menuItem.value)
            .toLowerCase()
            .includes(inputValue.toLowerCase())
      );
      if (createOption) {
        if (!newSelectOptions.length) {
          newSelectOptions = [
            {
              isDisabled: false,
              children: `Hit to add "${inputValue}" as a custom option`,
              value: 'create'
            }
          ];
        }
      }
      else {
        if (!newSelectOptions.length) {
          newSelectOptions = [
            {
              isDisabled: false,
              children: `Not available option "${inputValue}"`,
              value: 'no results'
            }
          ];
        }

      }
      if (!isOpen) {
        setIsOpen(true);
      }
    }

    setSelectOptions(newSelectOptions);
    setFocusedItemIndex(null);
    setActiveItem(null);
  }, [inputValue, onCreation]);

  useEffect(() => {
    setSelected(selectedOptions || []);
  }, [selectedOptions]);

  useEffect(() => {
    if (selected.length > 0) {
      let chip = [];
      selected.forEach(option => {
        let result = selectOptions.find(item => item.value === option);
        if (result) {
          // console.log("result.children",result.children)
          chip.push(result.children);
        }
      });
      setSelectedChip(chip);
    } else {
      setSelectedChip([])
    }
  }, [selected, onCreation, selectOptions]);


  const handleMenuArrowKeys = (key) => {
    let indexToFocus;
    if (isOpen) {
      if (key === 'ArrowUp') {
        if (focusedItemIndex === null || focusedItemIndex === 0) {
          indexToFocus = selectOptions.length - 1;
        } else {
          indexToFocus = focusedItemIndex - 1;
        }
      }
      if (key === 'ArrowDown') {
        if (
          focusedItemIndex === null ||
          focusedItemIndex === selectOptions.length - 1
        ) {
          indexToFocus = 0;
        } else {
          indexToFocus = focusedItemIndex + 1;
        }
      }
      setFocusedItemIndex(indexToFocus);
      const focusedItem = selectOptions.filter(
        (option) => !option.isDisabled
      )[indexToFocus];
      setActiveItem(`select-multi-typeahead-${focusedItem.value.replace(' ', '-')}`);
    }
  };

  const onInputKeyDown = (event) => {
    const enabledMenuItems = selectOptions.filter(
      (menuItem) => !menuItem.isDisabled
    );
    const [firstMenuItem] = enabledMenuItems;
    const focusedItem = focusedItemIndex
      ? enabledMenuItems[focusedItemIndex]
      : firstMenuItem;
    switch (event.key) {
      case 'Enter':
        if (!isOpen) {
          setIsOpen((prevIsOpen) => !prevIsOpen);
        } else if (isOpen && focusedItem.value !== 'no results') {
          onSelectOption(focusedItem.value);
        }
        break;
      case 'Tab':
      case 'Escape':
        setIsOpen(false);
        setActiveItem(null);
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        event.preventDefault();
        handleMenuArrowKeys(event.key);
        break;
      default:
        break;
    }
  };

  const onToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const onTextInputChange = (_event, value) => {
    setInputValue(value);
  };

  const onSelectOption = (value) => {
    console.log("value", value)
    if (value) {
      if (value && value !== 'no results') {
        setSelected((prevSelected) => {
          const updatedSelection = prevSelected.includes(value)
            ? prevSelected.filter((selection) => selection !== value)
            : [...prevSelected, value];
          onSelectChange(updatedSelection);
          updateValue(updatedSelection, type, indexVal);
          return updatedSelection;
        });
      }
    }
    textInputRef.current?.focus();
  };

  // const onSelectOption = (value) => {
  //   if (value) {
  //     setSelected((prevSelected) => {
  //       const updatedSelection = prevSelected.includes(value)
  //         ? prevSelected.filter((selection) => selection !== value) // Removing selection
  //         : [...prevSelected, value]; // Adding selection
  //       onSelectChange(updatedSelection); // Notify parent component
  //       updateValue(updatedSelection, type, indexVal); // Update the form
  //       return updatedSelection; // Return the updated selection
  //     });
  //   }
  // };
  const getChildren = value => selectOptions.find(option => option.value === value)?.children;

  const toggle = (toggleRef) => (
    <MenuToggle
      variant="typeahead"
      onClick={onToggleClick}
      innerRef={toggleRef}
      isExpanded={isOpen}
      isDisabled={isDisabled}
      isFullWidth
      status={validStatus}
    >
      <TextInputGroup isPlain>
        <TextInputGroupMain
          value={inputValue}
          onClick={onToggleClick}
          onChange={onTextInputChange}
          onKeyDown={onInputKeyDown}
          id="multi-typeahead-select-input"
          autoComplete="off"
          innerRef={textInputRef}
          placeholder={placeholderText || 'Select a state'}
          {...(activeItem && {
            'aria-activedescendant': activeItem
          })}
          role="combobox"
          isExpanded={isOpen}
          aria-controls="select-multi-typeahead-listbox"
        >
          <ChipGroup aria-label="Current selections">
            {selectedChip.map((selection, index) => (
        
               <Chip
                key={index}
                isReadOnly={selection === 'default' ? true : false}
                onClick={(ev) => {
                  ev.stopPropagation();
                  const value = selected[index]; 
                  console.log("value,",value)
                  onSelectOption(value);
                }}
              >
                {selection}
              </Chip>
            ))}
          </ChipGroup>
        </TextInputGroupMain>
        <TextInputGroupUtilities>
          {selected.length > 0 && (
            <Button
              variant="plain"
              onClick={() => {
                setInputValue('');
                setSelected([]);
                updateValue([], type, indexVal);
                textInputRef?.current?.focus();
              }}
              aria-label={clearButtonAriaLabel || 'Clear input value'}
            >
              <TimesIcon aria-hidden />
            </Button>
          )}
        </TextInputGroupUtilities>
      </TextInputGroup>
    </MenuToggle>
  );

  return (
    <Select
      id="multi-typeahead-select"
      isOpen={isOpen}
      selected={selected}
      onSelect={(ev, selection) => onSelectOption(selection)}
      onOpenChange={() => setIsOpen(false)}
      toggle={toggle}
      isScrollable
    >
      <SelectList isAriaMultiselectable id="select-multi-typeahead-listbox" style={{ width: '300px' }}>
        {selectOptions.slice(0, 1500).map((option, index) => (
          <SelectOption
            key={option.value + 'mulselection' + index}
            isFocused={focusedItemIndex === index}
            className={option.className}
            id={`select-multi-typeahead-${index}`}
            {...option}
            ref={null}
          />
        ))}
      </SelectList>
    </Select>
  );
};

MultiselectBox.defaultProps = {
  validStatus: ''
};
export default React.memo(MultiselectBox)
