import React, { useState, useRef, useEffect } from 'react';
import {
    PageSection,
    PageSectionVariants,
    Tabs,
    Tab,
    TabTitleText,
    TabContentBody,
} from '@patternfly/react-core';
import OverviewDashboard from './OverviewDashboard'
import { useParams } from 'react-router-dom';
import IncidentList from './IncidentList'
import IncidentFilter from './IncidentFilter';

const IncidentManagement = () => {
    const { tabname } = useParams();
    const [activeTabKey, setActiveTabKey] = useState(0);
    const handleTabClick = (_event, tabIndex) => {
        setActiveTabKey(tabIndex);
    };

    useEffect(() => {
        if (tabname == "incident") {
            setActiveTabKey(1);
        }
    }, [tabname])

    return (
        <div>
            <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }} >
                <Tabs variant='light300' activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs-individual" role="region" isOverflowHorizontal isBox width={40}>
                    <Tab eventKey={0} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Dashboard</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 0 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <IncidentFilter />
                                <OverviewDashboard />
                            </TabContentBody>}
                    </Tab>
                    <Tab eventKey={1} title={<TabTitleText className='pf-v5-u-font-weight-bold'>Incidents</TabTitleText>} aria-label="Horizontal overflow content users">
                        {activeTabKey == 1 &&
                            <TabContentBody className='pf-v5-u-p-xs'>
                                <IncidentList />
                            </TabContentBody>}
                    </Tab>
                </Tabs>
            </PageSection>
        </div>
    );
};

export default IncidentManagement;
