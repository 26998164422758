//2nd which is perfectly run and usable
import React, { useEffect, useState } from "react";
import {
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Table,
} from "@patternfly/react-table";
import { Emptystatefornodata, emptystatefornodata } from "../../util/EmptyStateCard";
import { Badge, Pagination, Toolbar, ToolbarContent, ToolbarItem } from "@patternfly/react-core";
import { connect, useDispatch } from "react-redux";
import { UpdateFilterData } from "../../util/FilterHelper";
import { FilterData } from "../../Redux/Action";
const TopThreat = (props) => {
    const dispatch = useDispatch();
    const defaultColumns = [
        "Category",
        "Threat",
        "Count",
    ];
    const [columns, setColumns] = useState(defaultColumns);
    const [defaultRows, setDefaultRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [selectedRowName, setSelectedRowName] = React.useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const handleSetPage = (_evt, newPage,) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage, newPage) => {
        setPage(newPage);
        setPerPage(newPerPage);
    };

    useEffect(() => {
        const newdefaultRows = props.ThreatData.map((log) => {
            return {

                threat: log.threat,
                count: log.count,
                category: log.category
            };
        });

        setDefaultRows(newdefaultRows)
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        const slicedRows = newdefaultRows.slice(startIndex, endIndex);
        setRows(slicedRows);
    }, [props.ThreatData, rows.length]);

    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }

    useEffect(() => {
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        const slicedRows = defaultRows.slice(startIndex, endIndex);
        // console.log("after slicing", "", startIndex, endIndex, slicedRows)
        setRows(slicedRows);
    }, [defaultRows, page, perPage]);

    const renderPagination = (variant) =>
        <Pagination
            itemCount={defaultRows.length}
            page={page}
            perPage={perPage}
            variant={variant}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
        />;
    return (

        <React.Fragment>
            {props.ThreatData.length === 0 ? (
                <Emptystatefornodata />
            ) : (<>
                <Toolbar>
                    <ToolbarContent>
                        <ToolbarItem variant="pagination">{props.Pagination !== false && renderPagination('top')}</ToolbarItem>
                    </ToolbarContent>
                </Toolbar>
                <Table variant="compact" aria-label="signlog" id="signlog-table" isStickyHeader>
                    <Thead>
                        <Tr>
                            {columns.map((column) => (
                                <Th key={column}>{column}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    {rows.map((row, rowIndex) => (
                        <Tbody key={rowIndex} >
                            <Tr key={rowIndex}
                                onRowClick={() => setSelectedRowName(rowIndex)}
                                isSelectable
                                isClickable
                                isRowSelected={selectedRowName === rowIndex}  >
                                <Td width={20}>
                                    {row?.category == 'C2/Malware' ? 
                                    <a className='pf-v5-u-color-100' onClick={() => { handleFilter('rule.group', 'tip') }}>{row?.category}</a>
                                    :
                                        <a className='pf-v5-u-color-100' onClick={() => { handleFilter('data.misp.category', row?.category) }}>{row?.category}</a>
                                    }

                                </Td>
                                <Td width={70} modifier="truncate">{row?.threat}</Td>
                                <Td width={10} ><a onClick={() => { handleFilter('rule.description', row?.threat) }}>{row?.count}</a></Td>
                            </Tr>
                        </Tbody>
                    ))}
                </Table>
                {props.Pagination !== false && <div className="pf-v5-u-mt-md">{renderPagination('bottom')}</div>}
            </>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    filterformdata: state.FilterData,
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(TopThreat)