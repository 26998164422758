import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { formatValue } from '../../util/ChartHelper';
import { UpdateFilterData } from '../../util/FilterHelper';
import { FilterData } from '../../Redux/Action';
import { connect, useDispatch } from 'react-redux';

const ReqByAgentChart = (props) => {
  const id = generateUniqueID();
  const dispatch = useDispatch();

  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  };

  useEffect(() => {
    if (props.seriesData && props.seriesData.length > 0) {
      const chartDom = document.getElementById(id + 'rqba');
      const myChart = echarts.init(chartDom);
      const textcolor = props.theme === 'dark-theme' ? '#fff' : '#000';
      const linecolor = props.theme === 'dark-theme' ? '#595757' : '#d9d9d9';

      const xAxisData = props.seriesData.map(item => item.key);

      // const agentsData = {};
      // props.seriesData.forEach(requirement => {
      //   requirement.Top_Agent.buckets.forEach(agent => {
      //     if (!agentsData[agent.key]) {
      //       agentsData[agent.key] = Array(xAxisData.length).fill(0);
      //     }
      //     const index = xAxisData.indexOf(requirement.key);
      //     agentsData[agent.key][index] = agent.doc_count;
      //   });
      // });
      const allAgents = props.seriesData.flatMap(requirement => requirement.Top_Agent.buckets);
      const agentTotals = allAgents.reduce((acc, agent) => {
        acc[agent.key] = (acc[agent.key] || 0) + agent.doc_count;
        return acc;
      }, {});
      const topAgents = Object.entries(agentTotals)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10)
      .map(([key]) => key);
      const agentsData = {};
      props.seriesData.forEach(requirement => {
        requirement.Top_Agent.buckets.forEach(agent => {
          if (topAgents.includes(agent.key)) {
            if (!agentsData[agent.key]) {
              agentsData[agent.key] = Array(xAxisData.length).fill(0);
            }
            const index = xAxisData.indexOf(requirement.key);
            agentsData[agent.key][index] = agent.doc_count;
          }
        });
      });
      const seriesData = Object.keys(agentsData).map(agent => ({
        name: agent,
        type: 'bar',
        stack: 'total',
        barWidth: '40',
        emphasis: {
          focus: 'series',
        },
        data: agentsData[agent],
      }));

      const legendData = Object.keys(agentsData);

      const option = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: "#000"
          }
        },
        title: {
          text: "Requirements",
          top: 'bottom',
          left: 'center',
          textStyle: {
            fontWeight: 450,
            fontSize: 14,
            color: textcolor,
            fontfamily: 'RedHatText, helvetica, arial, sans-serif',
          },
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 'right',
          top: 10,
          data: legendData,
          itemWidth: 9.5,
          itemHeight: 10,
          itemGap: 6.5,
          tooltip: {
            trigger: 'item',
            show: true,
            position: [60, 10],
            textStyle: {
              color: "#000"
            }
          },
          icon: "rect",
          pageIconSize: 12,
          textStyle: {
            width: 180,
            overflow: 'truncate',
            fontSize: 12,
            color: textcolor
          }
        },
        grid: {
          left: '0',
          right: '15%',
          bottom: '13%',
          top: '8%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              interval: 0,
              rotate: -50,
              width: 70,
              overflow: 'truncate',
              fontSize: 12,
              color: textcolor
            },
            data: xAxisData,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            axisLine: {
              show: true
            },
            splitLine: {
              lineStyle: {
                color: linecolor
              }
            },
            axisLabel: {
              formatter: formatValue,
              color: textcolor
            }
          }
        ],
        color: ['#0066cc', '#f4c145', '#4cb140', '#5752d1', '#ec7a08', '#40199A', '#C8EB79', '#C9190B', '#008BAD'],
        series: seriesData
      };

      option && myChart.setOption(option);
      myChart.on('legendselectchanged', function (param) {
        handleFilter(props.filterFields, param.name);
      });
//       myChart.on('click', 'series.bar', function (param) {
// console.log("chart",param.name)
//       })

      const resizeChart = () => {
        if (myChart) {
          myChart.resize();
        }
      };

      window.addEventListener('resize', resizeChart);
      return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
      };
    }
  }, [props.seriesData, props.theme, props.sidebarState,props.height ]);

  return (
    <div className="container">
      <div id={id + 'rqba'} style={{ width: '100%', height: props.height }}></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState: state.sidebarstate
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(ReqByAgentChart);
