//2nd which is perfectly run and usable
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  ExpandableRowContent,
  Table,
} from "@patternfly/react-table";
import {
  DescriptionList,
  DescriptionListDescription,
  DescriptionListGroup,
  DescriptionListTerm,
  Tab,
  Tabs,
  TabTitleText,
  TabContentBody,
  Toolbar,
  ToolbarItem,
  ToolbarContent,
  Button,
  Pagination,
  ToolbarGroup,
  Flex,
  FlexItem,
} from "@patternfly/react-core";
import dbApi from '../../services/DbApi'
import { formatTableTimestamp } from "../../util/Timeutils";
import { Emptystatefornodata } from "../../util/EmptyStateCard";
import { Link } from "react-router-dom";
// const { forwardRef, useRef, useImperativeHandle } = React;
export const Level12AlertsLog = forwardRef((props, ref) => {

  const [AlertLevelData, setAlertLevelData] = React.useState([])
  const [ScrollID, setScrollID] = React.useState('')
  useImperativeHandle(ref, () => ({
    setAlertLevelTable(data = [], scrollId = '') {
      setBeforescrolldata([])
      setAlertLevelData(data)
      setScrollID(scrollId)
    }
  }));

  const resetPagination = () => {
    setPage(1);
    setPerPage(10); // Set to your desired default perPage value
  };

  const columns = [
    "Time",
    "Agent Name",
    "Rule Level",
    "Rule Description",

  ];
  const [itemcount, setItemcount] = useState(0)
  const [defaultRows, setDefaultRows] = useState([]);
  const [scrolldata, setScrollData] = useState([]);
  const [beforescrolldata, setBeforescrolldata] = useState([]);
  const [rows, setRows] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [isloadnext, setIsloadNext] = useState(false)
  // const initialExpandedRowNames = [];
  // const [areAllExpanded, setAreAllExpanded] = useState(false);
  // const [collapseAllAriaLabel, setCollapseAllAriaLabel] = useState("Expand all");
  const [expandedRowNames, setExpandedRowNames] = useState([]);
  const [expandedRowData, setExpandedRowData] = useState({});
  const [selectedRowName, setSelectedRowName] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex);
  };


  //scroll Api for pagination
  const AlertLevelApi = async (ScrollID) => {
    const data = {
      scroll_Id: ScrollID
    }
    try {
      setIsloadNext(true)
      let Scrolldata = await dbApi.postApi("Opensearch/Latest_scroll", data)
      console.log(Scrolldata)
      const nextdata = Scrolldata.Latest_scroll
      let scrolldata = []
      if (nextdata.length > 0) {
        scrolldata = nextdata
      }
      setScrollData(scrolldata);
      setIsloadNext(false)
    }

    catch (error) {
      console.log(error)
      setIsloadNext(false)
    }
  }
  const onSetPage = (_event, newPage) => {
    setPage(newPage);
  };
  const onPerPageSelect = (_event, newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

  useEffect(() => {
    let afterScrolldata = []

    if (beforescrolldata.length === 0) {
      afterScrolldata = AlertLevelData
    } else {
      afterScrolldata = [...beforescrolldata, ...scrolldata]
    }
    resetPagination() // Reset pagination when new data is set
    setBeforescrolldata(afterScrolldata)

    const newdefaultRows = afterScrolldata.map((log) => {
      const { formattedTimeStamp } = formatTableTimestamp(log._source.timestamp);;
      return {
        id: log._id,
        timestamp: formattedTimeStamp,
        agentId: log._source.agent.id,
        agentName: log._source.agent.name,
        rulelevel: log._source.rule.level,
        ruleDescription: log._source.rule.description,
        details: log,
      };
    });

    setDefaultRows(newdefaultRows)
    setItemcount(newdefaultRows.length)
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);
  }, [AlertLevelData, scrolldata]);

  //Use Effect for pagination 
  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    // console.log("after slicing", "", startIndex, endIndex, slicedRows)
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);



  //row expansion function
  const setRowExpanded = (row, isExpanding) => {
    const otherExpandedRowNames = expandedRowNames.filter((r) => r !== row.id);
    setExpandedRowNames(
      isExpanding ? [row.id] : []
    );
    if (!expandedRowData[row.id]) {
      let agentName = typeof row?.details?._source?.agent?.name != "undefined" ? row?.details?._source?.agent?.name : "";
      let agentID = typeof row?.details?._source?.agent?.id != "undefined" ? row?.details?._source?.agent?.id : "";
      const keyifiedData = keyify(row?.details, "", agentName, agentID);
      setExpandedRowData({ [row.id]: keyifiedData, });
    }
    const updatedExpandedRowNames = isRowExpanded(row)
      ? otherExpandedRowNames : [row.id];
    setExpandedRowNames(updatedExpandedRowNames);

  };
  const isRowExpanded = (row) => {
    return expandedRowNames.includes(row.id);
  };


  const hyperlinkConditions = {
    'agent.id': (value) => value !== '000',
    'agent.name': (value) => value !== '000',
  };

  const keyify = (obj, prefix = "",agentName = '', agentID = '') => {
    const skipKeys = ["_score", "_version", "_index", "sort", "@timestamp"];
    const result = [];
    Object.keys(obj).forEach((el) => {
      const fullkey = prefix + el;
      if (skipKeys.includes(el)) {
        return;
      }
      else if (Array.isArray(obj[el])) {
        const value = obj[el].join(", ");
        result.push({ key: fullkey, value });
      } else if (typeof obj[el] === "object" && obj[el] !== null) {
        // console.log("${obj['agent.name']}",obj[el].agent.name)
        if (el === "_source") {
          result.push(...keyify(obj[el], prefix,agentName, agentID));
        }
        else {
          result.push(...keyify(obj[el], fullkey + ".",agentName, agentID));
        }
      }
      else {
        if (hyperlinkConditions[fullkey] && hyperlinkConditions[fullkey](obj[el])) {
          result.push({ key: fullkey, value: <Link to={`/individual-agent-dashboard/${agentName}/${agentID}`}>{obj[el]}</Link> });
        }
        else if (typeof obj[el] === 'boolean') {
          result.push({ key: fullkey, value: obj[el] ? 'true' : 'false' });
        }
        else { result.push({ key: fullkey, value: obj[el] }); }
      }
    });
    const sortedResult = result.sort((a, b) => {
      if (a.key < b.key) return -1;
      if (a.key > b.key) return 1;
      return 0
    });

    return sortedResult;
  };


  //pagination code
  const renderPagination = variant => <Pagination
    itemCount={itemcount}
    perPage={perPage}
    page={page}
    onSetPage={onSetPage}
    variant={variant}
    onPerPageSelect={onPerPageSelect}

  />


  return (
    <React.Fragment>
      <Toolbar inset={{
        default: 'insetNone',
        md: 'insetSm',
        xl: 'inset2xl',
        '2xl': 'insetLg'
      }}>
        <ToolbarContent>
          <ToolbarGroup align={{ default: 'alignRight' }}>
            {/* <ToolbarItem key={'next-btn'}> <Button onClick={() => AlertLevelApi(ScrollID)} size="sm" >Next</Button></ToolbarItem> */}
            <ToolbarItem key={'top-page'}>{renderPagination('top', false)}</ToolbarItem>
          </ToolbarGroup>
        </ToolbarContent>
      </Toolbar>
      <Table variant="compact"
        aria-label="AuthSuccess"
        id="AuthSuccess-log-table"
        isStickyHeader
        gridBreakPoint="grid-md"
      >
        <Thead>
          <Tr>
            <Th key={'expand'} aria-label="expand-event" />
            {columns.map((column, index) => (
              <Th key={index}>{column}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows.length > 0 ?
            rows && rows.map((row, rowIndex) => {

              return (
                <>
                  <Tr key={rowIndex}
                    onRowClick={() => setSelectedRowName(rowIndex)}
                    isSelectable
                    isClickable
                    isRowSelected={selectedRowName === rowIndex}  >
                    <Td
                      key={'expand-td' + rowIndex}
                      expand={
                        row.details
                          ? {
                            rowIndex: rowIndex,
                            isExpanded: isRowExpanded(row),
                            onToggle: () => { setRowExpanded(row, !isRowExpanded(row)); setActiveTabKey(0) }
                          }
                          : undefined
                      }
                    >
                      <ExpandableRowContent> </ExpandableRowContent>
                    </Td>
                    <Td width={20} key={'timestamp'}> {row?.timestamp}</Td>
                    <Td width={20} key={'agentname'}> <Link to={"/Individual-Agent-Dashboard/" + row?.agentName + '/' + row?.agentId}>{row?.agentId}: {row?.agentName}</Link></Td>
                    <Td width={10} key={'rulelevel'}>{row?.rulelevel}</Td>
                    <Td width={50} key={'desc'} modifier='truncate' >{row?.ruleDescription} </Td>
                  </Tr>
                  <Tr isExpanded={isRowExpanded(row)} key={'expansion-row'}>
                    <Td colSpan={5} noPadding>
                      <ExpandableRowContent>
                        <Tabs
                          activeKey={activeTabKey}
                          onSelect={handleTabClick}
                          style={{ marginTop: "0px" }}
                          aria-label="Tabs in the Security Alerts"
                          role="region"
                        >
                          <Tab key={0} eventKey={0} title={<TabTitleText>Table</TabTitleText>} aria-label="Table data" >
                            <TabContentBody hasPadding>
                              <DescriptionList isHorizontal horizontalTermWidthModifier={{ default: '30ch' }}>
                                {expandedRowData[row.id]?.map((item, index) => (
                                  <React.Fragment key={index + 'level'}>
                                    <DescriptionListGroup key={index + 'lvlgrp'}>
                                      <DescriptionListTerm key={index + 'lvltrm'}> {item.key}</DescriptionListTerm>
                                      <DescriptionListDescription key={index + 'lvldsc'}>{item.value}</DescriptionListDescription>
                                    </DescriptionListGroup>
                                  </React.Fragment>
                                ))}
                              </DescriptionList>
                            </TabContentBody>
                          </Tab>
                          <Tab key={1}
                            eventKey={1} title={<TabTitleText>JSON</TabTitleText>}>
                            <TabContentBody hasPadding >
                              <pre style={{ whiteSpace: 'pre-wrap' }}>
                                {JSON.stringify(row?.details, null, 2)}
                              </pre>
                            </TabContentBody>
                          </Tab>
                        </Tabs>
                      </ExpandableRowContent>
                    </Td>
                  </Tr>
                </>
              )
            })
            :
            <Tr key={'emptystate'}>
              <Td colSpan={10}>
                <Emptystatefornodata />
              </Td>
            </Tr>
          }
        </Tbody>
      </Table>
      <Flex justifyContent={{ default: 'justifyContentFlexEnd' }} spaceItems={{ default: 'spaceItemsNone' }}>
        <FlexItem alignSelf={{ default: 'alignSelfCenter' }} key={'next'}>
          <Button onClick={() => AlertLevelApi(ScrollID)} size="sm" isLoading={isloadnext}>Next</Button>
        </FlexItem>
        <FlexItem key={'page'}>
          {renderPagination('bottom', false)}
        </FlexItem>
      </Flex>
    </React.Fragment>
  );
});
