import { ActionGroup, Button, FormGroup, Grid, GridItem, TextArea, TextInput } from '@patternfly/react-core';
import { SaveIcon } from '@patternfly/react-icons';
import React, { useState, useEffect } from 'react';
import dbApi from 'src/services/DbApi';
import { useDispatch } from 'react-redux';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import CommonSelectBox from 'src/Components/CommonEventPages/CommonSelectBox';
import { alertBoxData } from 'src/Redux/Action';
import { emptyCheck, selectmenuoption } from 'src/util/Validate';
import { Loader } from 'src/util/Loadingstate';

const IncidentTtModal = ({ onclose, IncidentId, onModalClose }) => {
    const dispatch = useDispatch();
    const [tacticList, setTacticList] = useState([]);
    const [techniqueList, setTechniqueList] = useState([]);
    const [subTechniqueList, setSubTechniqueList] = useState([]);
    const [tactic, setTactic] = useState('');
    const [technique, setTechnique] = useState('');
    const [subTechnique, setSubTechnique] = useState('');
    const [ttpProcedure, setTtpProcedure] = useState('');
    const [validTtpProcedure, setValidTtpProcedure] = useState('');
    const [validTactic, setValidTactic] = useState('');
    const [validTechnique, setValidTechnique] = useState('');
    const [isLoader, setIsLoader] = useState(false);
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    });

    const handleFieldChange = (value, field) => {
        switch (field) {
            case 'ttpProcedure':
                setTtpProcedure(value);
                setValidTtpProcedure(emptyCheck(value));
                break;
            default:
                break;
        }

    };

    const handleUpdateTacticValue = (value) => {
        setTactic(value);
        setValidTactic(emptyCheck(value));
        fetchTechniqueList(value);
        setTechnique('');
        setSubTechnique('');
    };

    const handleUpdateTechniqueValue = (value) => {
        setTechnique(value);
        setValidTechnique(emptyCheck(value));
        fetchSubTechniqueList(value);
        setSubTechnique('');
    };

    const handleUpdateSubTechniqueValue = (value) => {
        setSubTechnique(value);
    };

    const fetchTechniqueList = async (tacticId) => {
        try {
            const res = await dbApi.postApi("incident_management/get_mitre_technique", { parentId: tacticId, level: '2' });
            setTechniqueList(res || []);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchSubTechniqueList = async (techniqueId) => {
        try {
            const res = await dbApi.postApi("incident_management/get_mitre_technique", { parentId: techniqueId, level: '3' });
            setSubTechniqueList(res || []);
        } catch (error) {
            console.log(error);
        }
    };

    const getTacticList = async () => {
        try {
            const res = await dbApi.postApi("incident_management/get_mitre_technique", { parentId: '', level: '1' });
            setTacticList(res || []);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getTacticList();
    }, []);

    const resetForm = () => {
        setTactic('');
        setTechnique('');
        setSubTechnique('');
    };

    const finalSubmit = async () => {
        setIsLoader(true);
        const params = {
            incidentId: IncidentId,
            tacticId: tactic,
            techniqueId: technique,
            subTechniqueId: subTechnique,
            ttpProcedure: ttpProcedure
        };

        try {
            let res = await dbApi.postApi('incident_management/add_ttp', params);
            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                resetForm();
                onclose();
                onModalClose(true);
            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
            }
        } catch (error) {
            console.error('API call failed:', error);
            onModalClose(false);
        }
        setIsLoader(false);
    };

    const formValidation = () => {
        let isValid = true;
        const ttpProcedureStatus = emptyCheck(ttpProcedure);
        setValidTtpProcedure(ttpProcedureStatus);
        if (ttpProcedureStatus === 'error') {
            isValid = false;
        }
        const tacticStatus = selectmenuoption(tactic);
        setValidTactic(tacticStatus);
        if (tacticStatus === 'error') {
            isValid = false;
        }
        const techniqueStatus = selectmenuoption(technique);
        setValidTechnique(techniqueStatus);
        if (techniqueStatus === 'error') {
            isValid = false;
        }
        
        return isValid;
    };

    const submitForm = () => {
        if (formValidation()) {
            setConfirmBox({
                show: true,
                msg: 'Are you sure?',
                funCall: finalSubmit,
                title: 'Add TTP.',
                position: 'default',
                iconvariant: 'info',
                submitbtnvariant: 'primary',
            });
        }
    };

    return (
        <div>
            {isLoader ? <Loader /> : (
                <>
                    <div className="pf-v5-u-mt-md">

                        <Grid className='pf-v5-u-mt-md'>
                            <GridItem lg={4}>Tactic</GridItem>
                            <GridItem lg={6} >
                                <CommonSelectBox
                                    options={tacticList.map(item => ({
                                        value: item.id,
                                        children: item.m_title
                                    }))}
                                    isDisabled={false}
                                    createOption={false}
                                    selectedOptions={tactic}
                                    placeholder="Tactic"
                                    updateValue={handleUpdateTacticValue}
                                    validStatus={validTactic}
                                    helperTextInvalid="Tactic is required"
                                />
                            </GridItem>
                        </Grid>
                        {tactic && (
                            <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={4} >Technique</GridItem>
                                <GridItem lg={6}>
                                    <CommonSelectBox
                                        options={techniqueList.map(item => ({
                                            value: item.id,
                                            children: item.m_title
                                        }))}
                                        isDisabled={false}
                                        createOption={false}
                                        selectedOptions={technique}
                                        placeholder="Technique"
                                        updateValue={handleUpdateTechniqueValue}
                                        validStatus={validTechnique}
                                        helperTextInvalid="Technique is required"
                                    />
                                </GridItem>
                            </Grid>
                        )}
                        {technique && (
                            <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={4}>Sub-Technique</GridItem>
                                <GridItem lg={6}>
                                    <CommonSelectBox
                                        options={subTechniqueList.map(item => ({
                                            value: item.id,
                                            children: item.m_title
                                        }))}
                                        isDisabled={false}
                                        createOption={false}
                                        selectedOptions={subTechnique}
                                        placeholder="Sub-Technique"
                                        updateValue={handleUpdateSubTechniqueValue}
                                        // validStatus={validSubTechnique}
                                        helperTextInvalid="Sub-Technique is required"
                                    />
                                </GridItem>
                            </Grid>
                        )}
                        <Grid className='pf-v5-u-mt-md' >
                            <GridItem lg={4}>TTP Procedure</GridItem>
                            <GridItem lg={6}>
                                <FormGroup
                                    isRequired
                                    fieldId="ttp-procedure"
                                    helperTextInvalid="TTP Procedure is required"
                                    validated={validTtpProcedure}
                                >
                                    <TextArea
                                        value={ttpProcedure}
                                        isRequired
                                        placeholder='TTP Procedure'
                                        type="text"
                                        id="ttp-procedure-input"
                                        aria-describedby="ttp-procedure-helper"
                                        name="ttp-procedure"
                                        onChange={(e, value) => handleFieldChange(value, 'ttpProcedure')}
                                        validated={validTtpProcedure}
                                    />
                                </FormGroup>
                            </GridItem>
                        </Grid>

                    </div>
                    <ActionGroup className="pf-v5-u-mt-md">
                        <Button variant="primary" className="pf-v5-u-mr-md" icon={<SaveIcon />} onClick={submitForm}>
                            Create
                        </Button>
                        <Button variant="secondary" onClick={onclose}>Cancel</Button>
                    </ActionGroup>
                    <ConfirmBox confirmModal={confirmModalData} />
                </>
            )}

        </div>
    );
};

export default IncidentTtModal;
