import React, { forwardRef, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Flex,
  FlexItem,
  Grid,
  GridItem,
  InputGroup,
  Text,
  TextContent,
  TextVariants,
  Divider,
  CardHeader,
  MenuToggle,
  Dropdown,
  DropdownList,
  DropdownItem,
  Tabs,
  Tab,
  TabTitleText,
  SearchInput,

} from '@patternfly/react-core';
import { RedoIcon, } from '@patternfly/react-icons/dist/esm/icons';
import AgentStatus from './AgentStatuschart';
import AgentEvolution from './AgentEvolutionChart';
import AgentTable from './Agenttable';
import dbApi from '../../services/DbApi';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { TimeHandler } from '../../util/Timeutils';
import { Link } from 'react-router-dom';
import { Loader, TextSkeleton } from '../../util/Loadingstate';
import { StartEndDate } from '../../Redux/Action';
import { connect, useDispatch } from 'react-redux';
import AgentTopOsChart from './AgentTopOsChart';

const Agents = forwardRef(function Agents(props, ref) {
  const dispatch = useDispatch();
  const [entire_startDate, setEntireStartDate] = useState();
  const [CurrentTime, setCurrentTime] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [StatusSeries, setStatusSeries] = useState([])
  const [active, setActive] = useState(0)
  const [disconnected, setDisconnected] = useState(0)
  const [pending, setPending] = useState(0)
  const [neverconnected, setNeverConnected] = useState(0)
  const [agentcoverage, setAgentCoverage] = useState(0)
  const [lastAgentname, setlastAgentName] = useState('');
  const [lastAgentID, setlastAgentID] = useState('')
  const [topOs, setTopOs] = useState([])
  const [evolutionlabel, setEvolutionLabel] = useState('')
  const [evolutionSeries, setEvolutionSeries] = useState([]);
  const [defaultTermSet, setDefaultTermSet] = useState(false);
  const [isevolutiontimeOpen, setIsEvolutionTimeOpen] = React.useState(false);
  const [selectedterm, setSelectedterm] = React.useState('Last 24 hour');
  const [searchValue, setSearchValue] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [filterStatus, setFilterStatus] = useState([])
  //Loading States
  const [isloadStatus, setIsLoadStatus] = useState(false);
  const [isLoadtopOs, setIsLoadTopOS] = useState(false);
  // const [isloadEvolution, setIsLoadEvolution] = useState(false)
  const [activeTabKey, setActiveTabKey] = useState(0);
  const handleTabClick = (_event, tabIndex) => {
    setActiveTabKey(tabIndex);
  };

  // const TimeApi = async () => {
  //   try {
  //     let currentDateTime = await dbApi.getApi("elasticsearch/getCurrentUtcDateTime");
  //     const CurrentTime = new Date(currentDateTime);
  //     setCurrentTime(CurrentTime)
  //     return CurrentTime;
  //   }
  //   catch (error) {
  //     console.log(error);
  //   }
  // }
  // elasticsearch/Entire_log
  //used in Evolution chart 
  // const entireLog = async () => {
  //   try {
  //     let entirelog = await dbApi.getApi("elasticsearch/Entire_log");
  //     const timestamp = entirelog.Entire_log.body.hits.hits[0]._source["timestamp"];
  //     const entireStartDate = new Date(timestamp);
  //     setEntireStartDate(entireStartDate)
  //     return entireStartDate;
  //   }
  //   catch (error) {
  //     console.log(error);
  //   }
  // }
  // const handleItemClick = async (term) => {
  //   try {
  //     const { startDate, endDate, } = await TimeHandler(term, TimeApi, entireLog,);
  //     setSelectedterm(term)
  //     setStartDate(startDate)
  //     setEndDate(endDate)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };
  // const getEvolutionData = async (startDate, endDate) => {
  //   try {
  //     setIsLoadEvolution(true)
  //     let evolutiondata = await dbApi.postApi("Opensearch/Agent_Evolution", { startDate, endDate, filter: JSON.stringify([{}]) })
  //     console.log(evolutiondata)
  //     let label = evolutiondata.Agent_Evolution.interval
  //     let Series = []
  //     let Label = ''
  //     let evolution_trend = evolutiondata.Agent_Evolution.data.body.aggregations.Agent_Evolution;
  //     if (evolution_trend.buckets.length > 0) {
  //       Series = evolution_trend.buckets
  //       Label = label
  //       setEvolutionSeries(Series)
  //       setEvolutionLabel(Label)
  //     }
  //     else {
  //       setEvolutionSeries(Series)
  //       setEvolutionLabel(Label)
  //     }

  //     setIsLoadEvolution(false)
  //   } catch (error) {
  //     console.log(error)
  //     setEvolutionSeries([])
  //     setEvolutionLabel('')
  //     setIsLoadEvolution(false)
  //   }
  // }

  const getSatus = async () => {
    try {
      setIsLoadStatus(true)
      let res = await dbApi.getApi("opensearch/get_total_agent_status_wise")
      console.log(res)
      let Active = 0
      let Disconnected = 0
      let Pending = 0
      let NeverConnected = 0
      let AgentCoverage = 0
      let LastAgentname = ''
      let lastAId = ''
      // lastRegisteredAgent
      if (res.data.active > 0) { Active = res.data.active }
      if (res.data.pending > 0) { Pending = res.data.pending }
      if (res.data.disconnected > 0) { Disconnected = res.data.disconnected }
      if (res.data.neverConnected > 0) { NeverConnected = res.data.neverConnected }
      if (res.data.coveragePercentage > 0) { AgentCoverage = res.data.coveragePercentage }
      if (res.data.lastRegisteredAgent.name !== undefined) { LastAgentname = res.data.lastRegisteredAgent.name }
      if (res.data.lastRegisteredAgent.id !== undefined) { lastAId = res.data.lastRegisteredAgent.id }
      setActive(Active)
      setStatusSeries(res.data)
      setDisconnected(Disconnected)
      setPending(Pending)
      setNeverConnected(NeverConnected)
      setAgentCoverage(AgentCoverage)
      setlastAgentName(LastAgentname)
      setlastAgentID(lastAId)
      setIsLoadStatus(false)
    }
    catch (error) {
      console.log(error)
      setActive(0)
      setDisconnected(0)
      setPending(0)
      setNeverConnected(0)
      setAgentCoverage(0)
      setlastAgentName('')
      setlastAgentID('')
      setIsLoadStatus(false)
    }

  }

  const getTopOs = async () => {
    try {
      setIsLoadTopOS(true)
      let res = await dbApi.getApi("Opensearch/Top_Os")
      console.log(res)
      let AllOs = []
      if (res.Top_Os.length > 0) {
        AllOs = res.Top_Os
        setTopOs(AllOs)
      }
      else {
        setTopOs(AllOs)
      }
      setIsLoadTopOS(false)
    } catch (error) {
      console.log(error)
      setIsLoadTopOS(false)
    }
  }


  // useEffect(() => {
  //   try {
  //     if (!defaultTermSet) { // Check if default term is not set yet
  //       handleItemClick("Last 1 hour"); // Set default term
  //       setDefaultTermSet(true); // Update flag to indicate default term is set
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [defaultTermSet]);

  useEffect(() => {
    getSatus()
    getTopOs()
    setActiveTabKey(0)
  }, [props?.selectedGroup])

  //For Evolution
  // useEffect(() => {
  //   try {
  //     if (startDate !== undefined) {
  //       getEvolutionData(startDate, endDate)
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [startDate, endDate, props?.selectedGroup]);

  const updateFilterStatus = () => {
    setFilterStatus([]);
  };
  //Dropdown code for times selection
  // const onSelect = (_event, value) => {
  //   setSelectedterm(value);
  //   setIsEvolutionTimeOpen(false);
  // };
  // const onToggleClick = () => {
  //   setIsEvolutionTimeOpen(!isevolutiontimeOpen);
  // };

  // const toggle = toggleRef =>
  //   <MenuToggle ref={toggleRef} onClick={onToggleClick}
  //     isExpanded={isevolutiontimeOpen}> {selectedterm}</MenuToggle>
  // const Timelist = [
  //   { term: "Today", key: "option1" },
  //   { term: "This week", key: "option2" },
  //   { term: "Last 15 minutes", key: "option3" },
  //   { term: "Last 30 minutes", key: "option4" },
  //   { term: "Last 1 hour", key: "option5" },
  //   { term: "Last 24 hours", key: "option6" },
  //   { term: "Last 7 days", key: "option7" },
  //   { term: "Last 30 days", key: "option8" },
  //   { term: "Last 90 days", key: "option9" },
  //   { term: "Last 1 year", key: "option10" },
  // ]

  // const timedropdown =
  //   <Dropdown
  //     isOpen={isevolutiontimeOpen}
  //     onSelect={onSelect}
  //     onOpenChange={isevolutiontimeOpen => setIsEvolutionTimeOpen(isevolutiontimeOpen)}
  //     toggle={toggle}
  //     ouiaId="BasicDropdown"
  //     shouldFocusToggleOnSelect isScrollable>
  //     {Timelist.map((item, index) => (
  //       <DropdownList key={index}>
  //         <DropdownItem key={item.key}
  //           value={item.term}
  //           onClick={() => { { handleItemClick(item.term) } }}
  //         >{item.term}</DropdownItem>
  //       </DropdownList>
  //     ))}
  //   </Dropdown>

  const [refreshTrigger, setRefreshTrigger] = useState('');

  const OnRefreshClick = () => {
    // getEvolutionData(startDate, endDate, props?.groupindex);
    getSatus()
    getTopOs()
    setRefreshTrigger(Date.now())
  }

  const onSearch = event => {
    if (event.key && event.key !== 'Enter') {
      return;
    }
    setSearchValue(inputValue)
  }
  return (<>
    <div className="pf-v5-u-p-sm">
      <Grid hasGutter>
        <GridItem md={5} sm={12} lg={3} >
          <Card isFullHeight>
            <CardTitle >Status</CardTitle>
            <Divider />
            {isloadStatus ? <Loader /> :
              active + disconnected + pending + neverconnected === 0 ? <Emptystatefornodata /> :
                <AgentStatus StatusData={StatusSeries} />
            }
          </Card>
        </GridItem>
        <GridItem md={7} sm={12} lg={6} >
          <Card isFullHeight>
            <CardTitle >Details</CardTitle>
            <Divider />
            <Grid >
              <GridItem md={4} sm={6} lg={2}>
                <Card isPlain>
                  <CardTitle align='center' className='title-height'>Active</CardTitle>
                  <CardBody >
                    {isloadStatus ? <TextSkeleton /> :
                      <TextContent>
                        <a><Text component={TextVariants.h1} className='pf-v5-u-success-color-100 pf-v5-u-text-align-center'
                          onClick={() => setFilterStatus(['1'])}
                        // onClick={()=>handleqValue("status=active") }
                        > {active} </Text></a>
                      </TextContent>
                    }
                  </CardBody>

                </Card>
              </GridItem>
              <GridItem md={4} sm={6} lg={3}>
                <Card isPlain>
                  <CardTitle align='center' className='title-height'>Disconnected</CardTitle>
                  <CardBody >
                    {isloadStatus ? <TextSkeleton /> :
                      <TextContent>
                        <a><Text component={TextVariants.h1} className='pf-v5-u-danger-color-100 pf-v5-u-text-align-center'
                          onClick={() => setFilterStatus(['0'])}
                        > {disconnected} </Text></a>
                      </TextContent>
                    }
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem md={4} sm={6} lg={2}>
                <Card isPlain>
                  <CardTitle align='center' className='title-height'> Pending</CardTitle>
                  <CardBody >
                    {isloadStatus ? <TextSkeleton /> :
                      <TextContent>
                        <a> <Text component={TextVariants.h1} className='pf-v5-u-warning-color-100 pf-v5-u-text-align-center'
                          onClick={() => setFilterStatus(['2'])}
                        > {pending} </Text></a>
                      </TextContent>
                    }
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem md={4} sm={6} lg={2}>
                <Card isPlain>
                  <CardTitle align='center' className='title-height'>Never Connected </CardTitle>
                  <CardBody >
                    {isloadStatus ? <TextSkeleton /> :
                      <TextContent>
                        <a> <Text component={TextVariants.h1} className='pf-v5-u-disabled-color-100 pf-v5-u-text-align-center'
                          onClick={() => setFilterStatus(['3'])}
                        > {neverconnected} </Text></a>
                      </TextContent>
                    }
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem md={4} sm={6} lg={3}>
                <Card isPlain>
                  <CardTitle align='center' className='title-height'>Agents Coverage </CardTitle>
                  <CardBody >
                    {isloadStatus ? <TextSkeleton /> :
                      <TextContent>
                        <Text component={TextVariants.h1} className='pf-v5-u-text-align-center'>{agentcoverage}%</Text>
                      </TextContent>
                    }
                  </CardBody>
                </Card>
              </GridItem>
            </Grid>
            {lastAgentname !== '' &&
              <CardFooter>
                <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }} >
                  <FlexItem >
                    <Card isPlain>
                      <CardTitle><small>Last registered agent</small></CardTitle>
                      <CardBody >
                        {isloadStatus ? <TextSkeleton /> :
                          <Link to={"/Individual-Agent-Dashboard/" + lastAgentname + '/' + lastAgentID}>{lastAgentname}</Link>
                        }
                      </CardBody>
                    </Card>
                  </FlexItem>
                </Flex>
              </CardFooter>
            }
          </Card>
        </GridItem>
        <GridItem md={12} sm={12} lg={3}>
          <Card isFullHeight>
            <CardTitle>Top 5 OS</CardTitle>
            {/* <CardHeader className="pf-v5-u-align-items-flex-start" actions={{ actions: timedropdown, hasNoOffset: false }}>
              <CardTitle>Evolution</CardTitle>
            </CardHeader> */}
            <Divider />
            {isLoadtopOs ? <Loader /> :
              <CardBody>
                {topOs.length > 0 ? <AgentTopOsChart seriesData={topOs.slice(0, 5)} />
                  :
                  <Emptystatefornodata />
                }
              </CardBody>
            }
          </Card>
        </GridItem>
      </Grid>
      <div className="pf-v5-u-mt-md">
        <Flex spaceItems={{ default: 'spaceItemsXs' }} display={{ default: 'inlineFlex' }} className='pf-v5-u-display-flex pf-v5-u-flex-nowrap' >
          <FlexItem className='pf-v5-u-flex-fill' >
            <InputGroup>
              {/* <AgentSearch AllFields={agentKeys} updateString={AccessupdateString}/> */}
              <SearchInput placeholder="Search..."
                value={inputValue}
                onChange={(_event, value) => setInputValue(value)}
                onSearch={onSearch}
                onClear={() => setInputValue('')} />

            </InputGroup>
          </FlexItem>
          <FlexItem className='pf-v5-u-flex-none'>
            <Button icon={<RedoIcon />}
              onClick={() => { OnRefreshClick() }}
              style={{ width: '100%', maxWidth: '100%' }} />
          </FlexItem>
        </Flex>
      </div>
      <div className="pf-v5-u-mt-sm">
        <Card>
          {/* <CardBody> */}
          <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs-individual" role="region">
            <Tab eventKey={0} title={<TabTitleText>Agents</TabTitleText>} aria-label="Agentlists">
              {activeTabKey == 0 &&
                <AgentTable searchValue={searchValue} FilterStatus={filterStatus} updateFilterStatus={updateFilterStatus} refreshCall={refreshTrigger} />
              }
            </Tab>
            {/* <Tab eventKey={1} title={<TabTitleText>Agent Less</TabTitleText>} aria-label="Agentlists-agentlesslists">
              {activeTabKey == 1 &&
                <AgentlessList searchValue={searchValue} />
              }
            </Tab>
            <Tab eventKey={2} title={<TabTitleText>O 365</TabTitleText>} aria-label="Agentlists-o365">
              O 365 data
            </Tab> */}
          </Tabs>
        </Card>
      </div>
    </div>

  </>

  )
})

const mapStateToProps = (state) => ({
  dates: {
    startDate: state.startdate,
    endDate: state.enddate,
    selectedcommontime: state.Term
  },
  groupindex: state.groupIndexName,
  selectedGroup: state.SelectedGroup,
});

const mapDispatchToProps = (dispatch) => {
  return {
    SetDates: (startDate, endDate) => { (StartEndDate(startDate, endDate)); },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(Agents)



// const handleqValue=(value)=>{
//   setSecondqValue(value)
// }

// const AgentDashboardApi = async (qValue, searchValue, firstIndex = '0', lastIndex = '10') => {
//   const pagelimit = {
//     offset: firstIndex,
//     limit: lastIndex,
//     q: qValue,
//     search: searchValue
//     // q:'',
//     // search:'Windows'
//   }

//   try {
//     getAgentList(pagelimit)
//   } catch (error) {
//     console.log(error)
//   }
// }
// const AccessupdateString = (qvalue, searchvalue) => {
//   setqValue(qvalue)
//   setSearchValue(searchvalue)
// }

// useEffect(() => {
//   AgentDashboardApi(qValue, searchValue)
// }, [qValue, searchValue])

// const getAgentList = async (pagelimit) => {
//   try {
//     // setIsLoadAgent(true)
//     let all_agents = await dbApi.postApi("Opensearch/Agents_List", pagelimit)
//     console.log(all_agents)
//     let allAgents = all_agents.Agent_List.agent
//     let AllKeys = all_agents.Agent_List.keys
//     let agentlist = [];
//     let agentKeys = []
//     let totalagent = 0
//     if (allAgents.length > 0) {
//       agentlist = allAgents
//       agentKeys = AllKeys
//       totalagent = all_agents.Agent_List.Total
//     }
//     // if(all_agents.statusCode!==200){emptystatefornodata()}
//     setAgentKeys(agentKeys)
//     setAgentlist(agentlist)
//     setTotalAgent(totalagent)
//     // setIsLoadAgent(false)
//   } catch (error) {
//     console.log(error)
//     // setIsLoadAgent(false)
//   }
// }
// const AgentPagination = async (firstIndex = '0', lastIndex = '10') => {
//   const pagelimit = {
//     offset: firstIndex,
//     limit: lastIndex,
//     q: qValue,
//     search: ''
//   };
//   // console.log("pages", firstIndex, lastIndex)
//   try {

//     await getAgentList(pagelimit);
//   }
//   catch (error) {
//     console.log(error)
//   }
// }