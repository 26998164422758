import { Icon, List, ListItem, ListVariant, Modal, ModalVariant, Pagination, TextArea, TextInput, Toolbar, ToolbarContent, ToolbarItem } from '@patternfly/react-core'
import { ExclamationCircleIcon, PlusCircleIcon, TrendDownIcon, TrendUpIcon } from '@patternfly/react-icons'
import { ActionsColumn, Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import React, { useEffect, useState } from 'react'
import dbApi from '../../services/DbApi'
import { formatTableTimestamp } from '../../util/Timeutils'
import { connect, useDispatch } from 'react-redux'
import { Emptystatefornodata } from 'src/util/EmptyStateCard'

const RankListTable = (props) => {
    const dispatch = useDispatch()
    const ranklistcolumn = {
        group_name: "Group Name",
        agent_name: "Agent Name",
        current_risk_score: "Risk Score",
        // rank: "Rank"
        // "Trend",
    };
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0)
    const [selectedRowName, setSelectedRowName] = useState('');
    const [activeSortIndex, setActiveSortIndex] = useState(2);
    const [activeSortDirection, setActiveSortDirection] = useState('desc');
    const [perPage, setPerPage] = useState(10);

    const excludeOrder = []
    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };
     // Pagination logic
     const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage) => {
        setPerPage(newPerPage);
    };
    const getRiskRank = async () => {
        let data = {
            limitStart: (perPage * page) - perPage,
            limitLength: perPage,
            search: '',
            sortBy: Object.keys(ranklistcolumn)[activeSortIndex],
            sortOrder: activeSortDirection,
            watchListId: ''
        }
        try {
            let res = await dbApi.postApi("Opensearch/agent_risk_list", data)
            console.log(res)
            let rankdata = []
            if (!res.error) {
                rankdata = res.data.data
                setRows(rankdata)
                setTotalItem(res.data.totalRecord)
            }
            else {
                setRows(rankdata)
                setTotalItem(0)
            }
        } catch (error) {
            console.log(error)
        }
    }
   
    useEffect(() => {
        getRiskRank()
    }, [page, perPage,activeSortIndex,activeSortDirection,])

    const renderPagination = (variant, isCompact) => (
        <Pagination
            isCompact={isCompact}
            itemCount={totalItem}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
            titles={{
                paginationAriaLabel: `${variant} pagination`
            }}
        />
    );

  
    return (
        <>
            <Toolbar>
                <ToolbarContent >
                    <ToolbarItem align={{ default: 'alignRight' }}>
                        <ToolbarItem> {renderPagination('top', false)}</ToolbarItem>
                    </ToolbarItem>
                </ToolbarContent>
            </Toolbar>
            <Table aria-label="Sortable Table"
                variant='compact'
                isStickyHeader
                gridBreakPoint="grid-md">
                <Thead>
                    <Tr>
                        {Object.values(ranklistcolumn).map((column, columnIndex) => {
                            const sortParams = {
                                sort: {
                                    sortBy: {
                                        index: activeSortIndex,
                                        direction: activeSortDirection
                                    },
                                    onSort,
                                    columnIndex
                                }
                            };
                            return !excludeOrder.includes(column) ? <Th key={columnIndex} {...sortParams} isStickyColumn >{column}</Th> : <Th key={columnIndex } isStickyColumn>{column}</Th>;
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {rows.length > 0 ? (rows.map((row, rowIndex) => {
                        return (<Tr key={rowIndex}
                            onRowClick={() => setSelectedRowName(rowIndex)}
                            isSelectable
                            isClickable
                            isRowSelected={selectedRowName === rowIndex}
                            className={row?.agent_name === props.AgentName ? "pf-v5-u-background-color-danger" : ""}
                        >
                            <Td width={20} dataLabel={ranklistcolumn[0]}>{row?.group_name} </Td>
                            <Td width={40} dataLabel={ranklistcolumn[1]}>{row?.agent_name} </Td>
                            <Td width={20} dataLabel={ranklistcolumn[2]}>{row?.current_risk_score}</Td>
                            {/* <Td width={20} dataLabel={ranklistcolumn[3]}>{row?.rank}</Td> */}
                            {/* <Td width={20} dataLabel={ranklistcolumn[3]}>{row?.difference > 0 ? <Icon status="danger"><TrendUpIcon /></Icon> : <Icon status="success"><TrendDownIcon /></Icon>}</Td> */}

                        </Tr>

                        )
                    }
                    ))
                        :
                        <Tr key='empty-row'>
                            <Td colSpan={3} key='empty-data'>
                                <Emptystatefornodata />
                            </Td>
                        </Tr>
                    }
                </Tbody>
            </Table>
            {renderPagination('bottom', false)}
        </>
    )
}

const mapStateToProps = (state) => ({
    groups: state.Allgroups
})
export default connect(mapStateToProps)(RankListTable) 