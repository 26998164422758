import React, { useEffect } from 'react'
import * as echarts from 'echarts';
import { connect } from 'react-redux'
import { calculateTimeDifference, formatTime, formatTimestamp } from 'src/util/Timeutils';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';

export const IncidentMttrChart = (props) => {
    const id = generateUniqueID()
    useEffect(() => {
        if (props.EventSeries && props.EventSeries.length > 0) {
            const chartDom = document.getElementById(id + 'mttrc');
            const myChart = echarts.init(chartDom);
            const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
            const linecolor = props.theme == 'dark-theme' ? '#595757' : '#d9d9d9'
            const Label = props.Label;
            const { resultInMinutes, resultInHours, resultInDays } = calculateTimeDifference(props.StartDateTime, props.EndDateTime);
            const uniqueDatesSet = new Set();
            const xAxisData = []

            for (let i = 0; i < props.EventSeries.length; i++) {
                const xvalue = props.EventSeries[i].time_bucket;
                uniqueDatesSet.add(xvalue);
            }
            const uniqueDatesArray = [...uniqueDatesSet];
            for (let k = 0; k < uniqueDatesArray.length; k++) {
                const { ConvertTimestamp, hours, minutes, seconds } = formatTimestamp(uniqueDatesArray[k]);
                const formattedTime = formatTime(resultInMinutes, resultInHours, resultInDays, hours, minutes, seconds, ConvertTimestamp);
                xAxisData.push({ x: formattedTime });
            }
            let interval = ''
            let lineheight = ''
            if (Label === '30 seconds') {
                interval = 4
                lineheight = 100
            }
            else if (Label === 'hour') {
                interval = 3
                lineheight = 95
            }
            else {
                interval = 0
                lineheight = 200
            }
            const option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b0}: {c0} hours',
                },
                grid: {
                    left: '13%',
                    right: 45,
                    bottom: '10%',
                    top: '5%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    data: xAxisData.map(item => item.x),
                    axisLabel: {
                        rotate: -60,
                        color: textcolor,
                        interval: interval,

                    },
                    name: `Timestamp per ${Label}`,
                    nameLocation: "middle",
                    nameTextStyle: {
                        align: "center",
                        lineHeight: lineheight,
                        fontSize: 14,
                        color: textcolor,
                    },
                },
                yAxis: {
                    type: 'value',
                    name: 'MTTR (Hours)',
                    nameLocation: "middle",
                    nameTextStyle: {
                        align: "center",
                        lineHeight: 110,
                        fontSize: 14,
                        color: textcolor,  // Adjusted line height to prevent overlap
                    },
                    nameGap: 20,  // Adds space between the Y-axis name and values
                    axisLine: {
                        show: true
                    },
                    splitLine: {
                        lineStyle: {
                            color: linecolor
                        }
                    },
                    axisLabel: {
                        color: textcolor
                    }
                },
                series: [
                    {
                        name: 'MTTR',
                        data: props.EventSeries.map(item => item.time_diff ?? 0),
                        type: 'bar',
                        smooth: true,
                        itemStyle: {
                            color: 'blue'
                        },
                        markLine: {
                            data: [
                                {
                                    type: 'average',
                                    name: 'Avg',
                                    lineStyle: {
                                        width: 2,
                                        type: "solid"
                                    },
                                    label: {
                                        color: textcolor
                                    }
                                },

                            ]
                        }
                    }
                ]
            };

            option && myChart.setOption(option);
            const resizeChart = () => {
                if (myChart) { // Check if chart exists before resizing
                    myChart.resize();
                }
            };

            window.addEventListener('resize', resizeChart);
            return () => {
                myChart.dispose();
                window.removeEventListener('resize', resizeChart);
            };
        }
    }, [props.Label, props.StartDateTime, props.EndDateTime, props.EventSeries, props.theme, props.sidebarState])
    return (
        <div className="container">
            <div id={id + 'mttrc'} style={{ width: '100%', height: '300px' }} ></div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(IncidentMttrChart)