import React, { useState, useEffect } from 'react';
import {
    Badge,
    Bullseye,
    Button,
    Card,
    EmptyState,
    EmptyStateHeader,
    EmptyStateIcon,
    EmptyStateBody,
    EmptyStateActions,
    EmptyStateFooter,
    Flex,
    FlexItem,
    Pagination,
    SearchInput,
    StackItem,
    Stack,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
} from '@patternfly/react-core';
import { Table, Thead, Tr, Th, Tbody, Td, InnerScrollContainer, } from '@patternfly/react-table';
import dbApi from '../../services/DbApi'
import { RedoIcon, SearchIcon,  } from '@patternfly/react-icons';
import { Link } from 'react-router-dom';
import { Loader } from '../../util/Loadingstate';
import { connect } from 'react-redux';
import PictorialBarChart from '../AllCharts/PictorialBarChart';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';

const AIenabled = (props) => {
    const uniqueId = generateUniqueID()
    const defaultColumns = {
        // group_name: 'Group',
        agent_id: "Agent",
        // rank: "Rank",
        asset_criticality: 'Asset Criticality',
        severity: 'Alert Severity',
        vulnerability: "Vulnerability",
        sca: "Benchmark",
        tip: "Threat Actors",
        current_risk_score: "Risk Score",
    };

    const [columns, setColumns] = useState(defaultColumns);
    const [isLoading, setIsLoading] = useState(false)
    const [rows, setRows] = useState([]);
    const [selectedRowName, setSelectedRowName] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [activeSortIndex, setActiveSortIndex] = useState(6);
    const [totalItem, setTotalItem] = useState(0)
    const [activeSortDirection, setActiveSortDirection] = useState('desc');
    const [searchValue, setSearchValue] = useState('');
    const excludeOrder = ['Riskometer']
    const infoheader = ['Alert Severity', 'Vulnerability', 'Benchmark', 'Threat Actors', 'Risk Score']
    const columnInfo = {
        'Alert Severity': <Stack>
            <StackItem>Score is less than or equal to 2 = Low </StackItem>
            <StackItem>Score is less than or equal to 3 = Medium </StackItem>
            <StackItem>Score is greater than or equal to 4= High </StackItem>
        </Stack>,
        'Vulnerability': <Stack>
            <StackItem> Vulnerability Score 1 = Low </StackItem>
            <StackItem>Vulnerability Score 2 = Medium </StackItem>
            <StackItem>Vulnerability Score 3 = High </StackItem>
        </Stack>,
        'Benchmark': <Stack>
            <StackItem> Benchmark Score 1 = Low </StackItem>
            <StackItem>Benchmark Score 2 = Medium </StackItem>
            <StackItem>Benchmark Score 3 = High </StackItem>
        </Stack>,
        'Threat Actors': <Stack>
            <StackItem> Threat Actors 1 = Low </StackItem>
            <StackItem>Threat Actors 2 = Medium </StackItem>
            <StackItem>Threat Actors 3 = High </StackItem>
        </Stack>,
        'Risk Score': <Stack>
            <StackItem>Score is less than or equal to 2 = Low </StackItem>
            <StackItem>Score is less than or equal to 3 = Medium </StackItem>
            <StackItem>Score is greater than or equal to 4= High </StackItem>
        </Stack>,
        // Add more column information here as needed
    };

    let orderCol = 'current_risk_score'
    if (activeSortIndex != 2) {
        orderCol = Object.keys(columns)[activeSortIndex]
        orderCol = orderCol
    }

    const getRiskRank = async () => {
        let data = {
            limitStart: (perPage * page) - perPage,
            limitLength: perPage,
            search: searchValue,
            sortBy: orderCol,
            sortOrder: activeSortDirection,
            watchListId: props.watchListId
        }
        try {
            setIsLoading(true)
            let res = await dbApi.postApi("Opensearch/agent_risk_list", data)
            console.log("riskrank->", res)
            let rankdata = []
            if (res.data.data.length > 0) {
                rankdata = res.data.data
            }
            setRows(rankdata)
            setTotalItem(res.data.totalRecord)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getRiskRank()
    }, [page, perPage, activeSortIndex, activeSortDirection, searchValue, props?.selectedGroup, props.Refresh])


    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalToggle = _event => {
        setIsModalOpen(!isModalOpen);
    };


    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage) => {
        setPerPage(newPerPage);
    };

    const renderPagination = (variant, isCompact) => (
        <Pagination
            isCompact={isCompact}
            itemCount={totalItem}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
            titles={{
                paginationAriaLabel: `${variant} pagination`
            }}
        />
    );

    const tableToolbar = <Toolbar>
        <ToolbarContent >
            <ToolbarItem style={{ width: '60%' }} variant="search-filter">
                <SearchInput placeholder="Search..."
                    value={searchValue}
                    onChange={(_event, value) => setSearchValue(value)}
                    // onSearch={(_event, value) => setSearchValue(value)}
                    onClear={() => setSearchValue('')} />
            </ToolbarItem>
            <ToolbarItem>
                <Button icon={<RedoIcon />}
                    onClick={() => { getRiskRank(); setPerPage(10); setPage(1) }}
                    style={{ width: '100%', maxWidth: '100%' }} />
            </ToolbarItem>
            <ToolbarItem align={{ default: 'alignRight' }}>
                <ToolbarItem> {renderPagination('top', false)}</ToolbarItem>
            </ToolbarItem>
        </ToolbarContent>
    </Toolbar>
    const emptyState = <EmptyState>
        <EmptyStateHeader headingLevel="h4" titleText="No results found" icon={<EmptyStateIcon icon={SearchIcon} />} />
        <EmptyStateBody>No results match the filter criteria. Clear all filters and try again.</EmptyStateBody>
        <EmptyStateFooter>
            <EmptyStateActions>
                <Button variant="link" onClick={() => { setSearchValue(''); }}>Clear all filters </Button>
            </EmptyStateActions>
        </EmptyStateFooter>
    </EmptyState>;

    const renderCriticality = (criticality) => {
        let badgeStyle = {};
        let badgeText = ''
        if (criticality == "1") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#0066CC" };
            badgeText = 'Low'
        }
        if (criticality == "3") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00" };
            badgeText = 'Medium'
        }
        if (criticality == "5") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" };
            badgeText = 'High'
        }
        return (
            <div><Badge style={badgeStyle}>{badgeText}</Badge></div>
        );
    };
    const starcolor = (label) => {
        let starColor;
        if (label <= 2) {
            starColor = '#0066CC';
        } else if (label <= 3) {
            starColor = '#f0ab00';
        }
        else {
            starColor = '#C9190B'
        }
        return starColor
    }
    const ratingColor = (label) => {
        let starColor;
        if (label <= 1) {
            starColor = '#0066CC';
        } else if (label <= 2) {
            starColor = '#f0ab00';
        }
        else {
            starColor = '#C9190B'
        }
        return starColor
    }
    return <React.Fragment>
        <> {tableToolbar}
            {isLoading ?
                <Card>
                    <Loader />
                </Card>
                :
                <>
                    <InnerScrollContainer key={'scroller'}>
                        <Table aria-label="Sortable Table"
                            variant='compact'
                            isStickyHeader
                            gridBreakPoint="grid-md"
                        >
                            <Thead key="thead">
                                <Tr key="theadrow">
                                    {Object.values(columns).map((column, columnIndex) => {
                                        const stickyColumnsIndex = [0, 1];
                                        const isSticky = stickyColumnsIndex.includes(columnIndex);
                                        const sortParams = {
                                            sort: {
                                                sortBy: {
                                                    index: activeSortIndex,
                                                    direction: activeSortDirection
                                                },
                                                onSort,
                                                columnIndex
                                            }
                                        };

                                        if (infoheader.includes(column)) {
                                            return (
                                                <Th key={columnIndex} {...sortParams} isStickyColumn={isSticky}
                                                    info={{
                                                        popover: (columnInfo[column]
                                                        ),
                                                        ariaLabel: column,
                                                        popoverProps: {
                                                            headerContent: column,
                                                        }
                                                    }}>
                                                    {column}</Th>
                                            )
                                        }
                                        return !excludeOrder.includes(column) ? <Th key={columnIndex} {...sortParams} isStickyColumn={isSticky} >{column}</Th> : <Th key={columnIndex} isStickyColumn={isSticky}>{column}</Th>;
                                    })}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {rows.length > 0 ? rows.map((row, rowIndex) => {

                                    return (
                                        <>
                                            <Tr key={rowIndex}
                                                onRowClick={() => setSelectedRowName(rowIndex)}
                                                isSelectable
                                                isClickable
                                                isRowSelected={selectedRowName === rowIndex}
                                            >
                                                <>
                                                    <Td width={20} dataLabel={columns.agent_id} key={rowIndex + 'agid'}>
                                                        {row?.agent_id.includes('AL') ? `${row?.agent_id} : ${row?.agent_name}` :
                                                            <Link to={"/Individual-Agent-Dashboard/" + row?.agent_name + '/' + row?.agent_id}>{row?.agent_id} : {row?.agent_name}</Link>
                                                        } </Td>
                                                    <Td width={15} dataLabel={columns.asset_criticality} key={rowIndex + 'assetcritical'}>{renderCriticality(row?.asset_criticality)}</Td>
                                                    <Td width={20} dataLabel={columns.severity} key={rowIndex + 'agseverity'}>
                                                        <Flex spaceItems={{ default: 'spaceItemsNone' }} display={{ default: 'inlineFlex' }} alignItems={{ default: 'alignItemsCenter' }}>
                                                            <FlexItem>
                                                                <PictorialBarChart MaxCount={5}
                                                                    Score={[row?.severity]}
                                                                    gridright={12}
                                                                    Color={starcolor(row?.severity)}
                                                                    UniqueId={row?.agent_id + uniqueId + 'severity'} />
                                                            </FlexItem>
                                                            <FlexItem><b>{row?.severity} </b></FlexItem>
                                                        </Flex>

                                                    </Td>
                                                    <Td width={10} dataLabel={columns.vulnerability} key={rowIndex + 'agvul'}>
                                                        <Flex spaceItems={{ default: 'spaceItemsNone' }} display={{ default: 'inlineFlex' }} alignItems={{ default: 'alignItemsCenter' }}>
                                                            <FlexItem>
                                                                <PictorialBarChart MaxCount={3}
                                                                    Score={[row?.vulnerability]}
                                                                    gridright={45}
                                                                    Color={ratingColor(row?.vulnerability)}
                                                                    UniqueId={row?.agent_id + uniqueId + 'vulnerable'} />
                                                            </FlexItem>
                                                            <FlexItem> <b>{row?.vulnerability}</b> </FlexItem>
                                                        </Flex>

                                                    </Td>
                                                    {/* <Td width={10} dataLabel={columns.sca} key={rowIndex + 'agsca'} className={row?.sca > 75 ? "pf-v5-u-success-color-100 pf-v5-u-font-weight-bold" : "pf-v5-u-warning-color-100 pf-v5-u-font-weight-bold"}>{row?.sca}%</Td> */}
                                                    <Td  dataLabel={columns.sca} key={rowIndex + 'agsca'}>
                                                        <Flex spaceItems={{ default: 'spaceItemsNone' }} display={{ default: 'inlineFlex' }} alignItems={{ default: 'alignItemsCenter' }}>
                                                            <FlexItem>
                                                                <PictorialBarChart MaxCount={3}
                                                                    Score={[row?.sca_score]}
                                                                    gridright={45}
                                                                    Color={ratingColor(row?.sca_score)}
                                                                    UniqueId={row?.agent_id + uniqueId + 'sca'} />
                                                            </FlexItem>
                                                            <FlexItem><b> {row?.sca_score}</b></FlexItem>
                                                        </Flex>
                                                    </Td>
                                                    <Td  dataLabel={columns.tip} key={rowIndex + 'agtip'}>
                                                        {/* {row?.tip} */}
                                                        <Flex spaceItems={{ default: 'spaceItemsNone' }} display={{ default: 'inlineFlex' }} alignItems={{ default: 'alignItemsCenter' }}>
                                                            <FlexItem>
                                                                <PictorialBarChart MaxCount={3}
                                                                    Score={[row?.tip]}
                                                                    gridright={45}
                                                                    Color={ratingColor(row?.tip)}
                                                                    UniqueId={row?.agent_id + uniqueId + 'tip'} />
                                                            </FlexItem>
                                                            <FlexItem><b> {row?.tip}</b> </FlexItem>
                                                        </Flex>
                                                    </Td>
                                                    <Td width={30} dataLabel={columns.current_risk_score} key={rowIndex + 'agriskscore'}>
                                                        <Flex spaceItems={{ default: 'spaceItemsNone' }} display={{ default: 'inlineFlex' }} alignItems={{ default: 'alignItemsCenter' }}>
                                                            <FlexItem>
                                                                <PictorialBarChart MaxCount={5}
                                                                    Score={[row?.current_risk_score]}
                                                                    gridright={12}
                                                                    Color={starcolor(row?.current_risk_score)}
                                                                    UniqueId={row?.agent_id + uniqueId + 'risk'} />
                                                            </FlexItem>
                                                            <FlexItem spacer={{default:'spacerXs'}}><b> {row?.current_risk_score}</b></FlexItem>
                                                            <FlexItem>{' '}({row?.risk_level})</FlexItem>
                                                        </Flex>
                                                    </Td>
                                                </>
                                            </Tr>
                                        </>
                                    )
                                }) :
                                    <Tr key={'rowIndex'}>
                                        <Td colSpan={10} key={'tdindex'}>
                                            <Bullseye>{emptyState}</Bullseye>
                                        </Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </InnerScrollContainer>
                </>
            }
            {renderPagination('bottom', false)}
        </>
    </React.Fragment >;
};
const mapStateToProps = (state) => ({
    selectedGroup: state.SelectedGroup,
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(AIenabled)