import React, { useEffect, useState, forwardRef, useRef, useMemo } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Divider,
    Flex,
    Grid,
    GridItem,
    Icon,
    Modal,
    Progress,
    Text,
    TextVariants,
    ModalVariant,
    ProgressSize,
    Tooltip,
    CardHeader,
    SelectList,
    SelectOption,
    MenuToggle,
    Select,
    Tabs,
    Tab,
    TabTitleText,
    Drawer,
    DrawerContent,
    DrawerContentBody,
    DrawerPanelContent,
    FlexItem,
    TabContentBody,
    Bullseye,
} from '@patternfly/react-core'
import {
    RunningIcon,
    TableIcon,
    ListIcon,
    CodeBranchIcon,
    DatabaseIcon,
    TrendUpIcon,
    TrendDownIcon,
} from '@patternfly/react-icons';
import dbApi from '../../services/DbApi'
import TopCVE from './TopCVE';
import VulnerabilityStatusTrend from './VulnerabilityStatusTrend';
import TopVulnerableGrpAssets from './TopAssetGrpVul';
import { connect, useDispatch } from 'react-redux';
import { FilterData } from '../../Redux/Action';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import TableViewForChart from '../AllCharts/TableViewForChart';
import { Loader, RoundSkeleton, TextSkeleton } from '../../util/Loadingstate';
import LatestVulnerabilitiesCve from './LatestVulnerabilities'
import { formatValue } from 'src/util/ChartHelper';
import VulnerableStatus from './VulnerableStatus';
import ExploitableCve from './ExploitableCve';
import { UpdateFilterData } from 'src/util/FilterHelper';
import VulnerableGroupTable from './VulnerableGroupTable';
import TopVulnerableAgentTable from './TopVulnerableAgentTable';

const VulnerabilitiesDashboard = forwardRef(function VulnerabilitiesDashboard(props, ref) {
    const dispatch = useDispatch();
    const childLatestCVELogsRef = useRef(null);
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [isOpen, setIsOpen] = useState(false);
    const [filterField, setFilterfield] = useState([])
    const [activeTabKey, setActiveTabKey] = useState(0);
    //Total Vulnerability
    const [total, setTotal] = useState(0)
    const [totalActive, setTotalActive] = useState(0)
    const [totalSolved, setTotalSolved] = useState(0)
    //Critical Vulnerability
    const [critical, setCritcal] = useState(0)
    const [activecritical, setActiveCritcal] = useState(0)
    const [solvedcritical, setSolvedCritcal] = useState(0)
    //High Vulnerability
    const [high, setHigh] = useState(0)
    const [activehigh, setActiveHigh] = useState(0)
    const [solvedhigh, setSolvedHigh] = useState(0)
    //Medium Vulnerability
    const [medium, setMedium] = useState(0)
    const [activemedium, setActiveMedium] = useState(0)
    const [solvedmedium, setSolvedMedium] = useState(0)
    //Low Vulnerability
    const [low, setLow] = useState(0)
    const [activelow, setActiveLow] = useState(0)
    const [solvedlow, setSolvedLow] = useState(0)
    //Progress bar detection and mitigation
    const [activestatus, setActiveStatus] = useState(0)
    const [solvedstatus, setSolvedStatus] = useState(0)
    //active solved trend     //summary history trend
    const [statusTrend, setStatusTrend] = useState([]);
    const [statusTrendlabel, setStatusTrendlabel] = useState('');
    // vulnerability groups by agent 
    const [groupseries, setGroupSeries] = useState([]);
    const [moregroupseries, setMoreGroupSeries] = useState([]);
    //vulnerability by agent 
    const [agentseries, setAgentSeries] = useState([]);
    const [moreagentseries, setMoreAgentSeries] = useState([]);
    const [cveagent, setCveAgent] = useState([])
    //latest cve table
    const [latestCve, setlatestCve] = useState([]);
    const [cvescrollId, setCVElogScrollID] = useState('');

    //poccount
    const [totalpoc, setTotalPoc] = useState(0)
    //Cve pie chart
    const [cveseries, setCVESeries] = useState([]);
    const [morecveseries, setMorecveSeries] = useState([]);
    const [cvepackage, setCvePackage] = useState([])//package wise in latest cve more modal
    const [cveField, setCveField] = useState('')
    const [selected, setSelected] = useState('Name');//dropdown cve
    //Loading states
    const [isloadTrend, setIsLoadTrend] = useState(false)
    const [isloadSummary, setIsLoadSummary] = useState(false)
    const [isloadgroup, setIsLoadgroup] = useState(false)
    const [isloadAgent, setIsLoadAgent] = useState(false)
    const [isloadCve, setIsLoadCve] = useState(false)
    const [isloadTopcve, setIsLoadTopcve] = useState(false)
    const [isloadseverity, setisloadSeverity] = useState(false)
    const [isloadpackage, setIsloadPackage] = useState(false)
    //Modal states
    const [modalStates, setModalStates] = useState({
        modalTopCVE: false,
        modalVulBranch: false,
        modalVulAsset: false,
        modalVulCVE: false,
        modalVulSummary: false,
        modalAlltimeVul: false,
        modalpoc: false

    });
    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex)
    }
    const handleModalToggle = (modalName) => {
        setModalStates((prevStates) => ({
            ...prevStates,
            [modalName]: !prevStates[modalName],
        }));
    };

    const updatePoCcount = (value) => {
        setTotalPoc(value)
    }
    const getvulnerabilityBySeverity = async (data) => {
        try {
            setisloadSeverity(true)
            let res = await dbApi.postApi("opensearch/get_total_vulnerability", data);
            console.log(res);
            let Total = '0'
            let TotalActive = '0'
            let TotalSolved = '0'
            let Critical = '0'
            let High = '0'
            let Medium = '0'
            let Low = '0'
            let ActiveCritical = '0'
            let SolvedCritical = '0'
            let ActiveHigh = '0'
            let SolvedHigh = '0'
            let ActiveMedium = '0'
            let SolvedMedium = '0'
            let ActiveLow = '0'
            let SolvedLow = '0'
            let Detection = '0'
            let Mitigation = '0'

            if (Object.keys(res.data).length > 0) {
                //Total Vulnerability
                Total = res.data.total
                TotalActive = res.data.active
                TotalSolved = res.data.solve
                //Critical Vulnerability
                Critical = res.data.critical_total
                ActiveCritical = res.data.critical_active
                SolvedCritical = res.data.critical_solve
                //High Vulnerability
                High = res.data.high_total
                ActiveHigh = res.data.high_active
                SolvedHigh = res.data.high_solve
                //Medium Vulnerability
                Medium = res.data.medium_total
                ActiveMedium = res.data.medium_active
                SolvedMedium = res.data.medium_solve
                //Low Vulnerability
                Low = res.data.low_total
                ActiveLow = res.data.low_active
                SolvedLow = res.data.low_solve
                //progress of detection and remediation
                Detection = res.data.detection
                Mitigation = res.data.mitigation

                setTotal(Total);
                setCritcal(Critical);
                setHigh(High)
                setMedium(Medium)
                setLow(Low)
                setTotalActive(TotalActive)
                setTotalSolved(TotalSolved)
                setActiveCritcal(ActiveCritical)
                setActiveHigh(ActiveHigh)
                setActiveLow(ActiveLow)
                setActiveMedium(ActiveMedium)
                setSolvedCritcal(SolvedCritical)
                setSolvedHigh(SolvedHigh)
                setSolvedLow(SolvedLow)
                setSolvedMedium(SolvedMedium)
                setActiveStatus(Detection);
                setSolvedStatus(Mitigation);
            }
            else {
                setTotal(Total);
                setCritcal(Critical);
                setHigh(High)
                setMedium(Medium)
                setLow(Low)
                setTotalActive(TotalActive)
                setTotalSolved(TotalSolved)
                setActiveCritcal(ActiveCritical)
                setActiveHigh(ActiveHigh)
                setActiveLow(ActiveLow)
                setActiveMedium(ActiveMedium)
                setSolvedCritcal(SolvedCritical)
                setSolvedHigh(SolvedHigh)
                setSolvedLow(SolvedLow)
                setSolvedMedium(SolvedMedium)
                setActiveStatus(Detection);
                setSolvedStatus(Mitigation);
            }
            setisloadSeverity(false)
        }
        catch (error) {
            console.log(error);
            setTotal(0);
            setCritcal(0);
            setHigh(0)
            setMedium(0)
            setLow(0)
            setTotalActive(0)
            setTotalSolved(0)
            setActiveCritcal(0)
            setActiveHigh(0)
            setActiveLow(0)
            setActiveMedium(0)
            setSolvedCritcal(0)
            setSolvedHigh(0)
            setSolvedLow(0)
            setSolvedMedium(0)
            setActiveStatus(0);
            setSolvedStatus(0);
            setisloadSeverity(false)
        }
    }
    const getVulnerabilityTrend = async (data) => {
        try {
            setIsLoadTrend(true)
            let res = await dbApi.postApi("opensearch/get_vulnerability_graph_data", data)
            console.log(res)
            let StatusTrend = [];
            let StatusTrendlabel = ""

            if (Object.keys(res.data).length > 0) {
                StatusTrend = res.data.vulnerability
                StatusTrendlabel = res.data.interval
                setStatusTrend(StatusTrend)
                setStatusTrendlabel(StatusTrendlabel)
            }
            else {
                setStatusTrend(StatusTrend)
                setStatusTrendlabel(StatusTrendlabel)
            }
            setIsLoadTrend(false)
        } catch (error) {
            console.log(error)
            setStatusTrend([])
            setStatusTrendlabel('')
            setIsLoadTrend(false)
        }
    }
    const getVulnerabilityTopCVE = async (data, selectedCvetype) => {
        try {
            let param = {
                ...data,
                cveType: selectedCvetype.toLowerCase()
            }
            setIsLoadTopcve(true)
            let res = await dbApi.postApi("opensearch/get_top_vulnerability_cve", param)
            console.log(res)
            let CveSeries = [];
            let MoreCveSeries = [];
            let CveField = ''
            if (selectedCvetype === 'CVE') {
                CveField = 'data.vulnerability.cve'
            }
            else if (selectedCvetype === 'Version') {
                CveField = 'data.vulnerability.package.version'
            }
            else if (selectedCvetype === 'Cvss2') {
                CveField = 'data.vulnerability.cvss.cvss2.base_score'
            }
            else if (selectedCvetype === 'Cvss3') {
                CveField = 'data.vulnerability.cvss.cvss3.base_score'
            }
            else {
                CveField = 'data.vulnerability.package.name'
            }
            if (res.data.length > 0) {
                CveSeries = res.data.slice(0, 5);
                MoreCveSeries = res.data;
                setCVESeries(CveSeries)
                setCveField(CveField)
                setMorecveSeries(MoreCveSeries)
            }
            else {
                setCVESeries(CveSeries)
                setCveField(CveField)
                setMorecveSeries(MoreCveSeries)
            }
            setIsLoadTopcve(false)
        } catch (error) {
            console.log("error", error)
            setCVESeries([])
            setCveField('')
            setMorecveSeries([])
            setIsLoadTopcve(false)
        }
    }
    const getPackageWisedata = async (data) => {
        try {
            setIsloadPackage(true)
            let res = await dbApi.postApi("opensearch/get_top_vulnerability_cve", { ...data, cveType: 'name' })
            console.log(res)
            let CvePackage = []
            if (!res.error && res.data.length > 0) {
                CvePackage = res.data.map((item) => ({
                    key: item.cve_title,
                    count: item.total
                }))
                setCvePackage(CvePackage)
            }
            else {
                setCvePackage(CvePackage)
            }
            setIsloadPackage(false)

        } catch (error) {
            console.log(error)
            setCvePackage([])
            setIsloadPackage(false)
        }
    }

    const getTopVulnerableGroups = async (data) => {
        try {
            setIsLoadgroup(true)
            let res = await dbApi.postApi("opensearch/get_top_vulnerability_groups", data)
            console.log(res)
            let groups = res.data
            let Groupseries = []
            let MoreGroupSeries = []
            if (!res.error && groups.length > 0) {
                Groupseries = groups.slice(0, 5)
                MoreGroupSeries = groups
                setGroupSeries(Groupseries)
                setMoreGroupSeries(MoreGroupSeries)
            }
            else {
                setGroupSeries(Groupseries)
                setMoreGroupSeries(MoreGroupSeries)
            }
            setIsLoadgroup(false)
        } catch (error) {
            console.log(error)
            setGroupSeries([])
            setMoreGroupSeries([])
            setIsLoadgroup(false)
        }
    }
    const setGroupData = () => {
        let groupdata = moregroupseries
        setMoreGroupSeries([])
        setTimeout(function () { setMoreGroupSeries(groupdata) }, 100)
    }
    const getTopVulnerableAssets = async (data) => {
        try {
            setIsLoadAgent(true)
            let vulnerable_agents = await dbApi.postApi("opensearch/get_top_vulnerability_agents", data)
            console.log(vulnerable_agents);
            let Agentseries = [];
            let MoreAgentseries = [];
            let CveAgent = []
            if (Object.keys(vulnerable_agents.data).length > 0) {
                Agentseries = vulnerable_agents.data.slice(0, 5);
                MoreAgentseries = vulnerable_agents.data
                CveAgent = vulnerable_agents.data.map((item) =>
                ({
                    key: item.agent_name,
                    count: item.total
                }))
                setAgentSeries(Agentseries)
                setCveAgent(CveAgent)
                setMoreAgentSeries(MoreAgentseries)
            }
            else {
                setAgentSeries(Agentseries)
                setMoreAgentSeries(MoreAgentseries)
                setCveAgent(CveAgent)
            }
            setIsLoadAgent(false)
        }
        catch (error) {
            console.log(error)
            setAgentSeries([])
            setMoreAgentSeries([])
            setCveAgent([])
            setIsLoadAgent(false)
        }

    }



    useEffect(() => {
        if (childLatestCVELogsRef.current) {
            childLatestCVELogsRef.current.setLatestCVELog(latestCve, cvescrollId, modalStates.modalVulCVE);
        }
    }, [latestCve, cvescrollId, modalStates.modalVulCVE]);

    const setLatestCVEData = () => {
        let data = latestCve
        setlatestCve([])
        setTimeout(function () { setlatestCve(data) }, 100);
    }


    const VulnerableDashboardApi = async (startDate, endDate, allFields) => {
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(allFields),
        };
        // console.log(data)
        try {
            setStartDate(startDate);
            setEndDate(endDate)
            setFilterfield(allFields)
            getvulnerabilityBySeverity(data);
            getVulnerabilityTrend(data)
            getVulnerabilityTopCVE(data, 'Name');
            getPackageWisedata(data)
            getTopVulnerableGroups(data);
            if (!props?.hideCard) {
                getTopVulnerableAssets(data);
            }

        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (props.dates.startDate != "") {
            let Dates = props.dates
            VulnerableDashboardApi(Dates.startDate, Dates.endDate, props.filterformdata)
        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata]);

    const setCveData = () => {
        let data = morecveseries
        setMorecveSeries([])
        setTimeout(function () { setMorecveSeries(data) }, 100);
    }
    const setAssetData = () => {
        let assetdata = moreagentseries
        setMoreAgentSeries([])
        setTimeout(function () { setMoreAgentSeries(assetdata) }, 100)
    }



    const onSelect = (_event, value) => {
        setSelected(value);
        setIsOpen(false);
        // const Fields = [{}]
        // const Fields = filterField
        const data = {
            startDate: startDate,
            endDate: endDate,
            // filter: JSON.stringify(Fields),
            filter: filterField.length > 0 ? JSON.stringify(filterField) : "[]",
        };
        getVulnerabilityTopCVE(data, value)
    };
    const onToggleClick = () => {
        setIsOpen(!isOpen);
    };
    const toggle = toggleRef =>
        <MenuToggle ref={toggleRef} onClick={onToggleClick}
            isExpanded={isOpen}> {selected}</MenuToggle>

    const selectItems = <SelectList>
        <SelectOption key="option1" value="Name" > Name </SelectOption>
        <SelectOption key="option2" value="CVE"> CVE </SelectOption>
        <SelectOption key="option3" value="Version"> Version </SelectOption>
        <SelectOption key="option4" value="Cvss2"> Cvss2</SelectOption>
        <SelectOption key="option5" value="Cvss3"> Cvss3</SelectOption>
    </SelectList>;
    const headerActions =
        <Select id="single-select" isOpen={isOpen}
            selected={selected}
            onSelect={onSelect}
            onOpenChange={isOpen =>
                setIsOpen(!isOpen)
            }
            toggle={toggle} shouldFocusToggleOnSelect
        >
            {selectItems}
        </Select>
    //Vulnerabilities Active/Solved Status Trend
    const ActiveSolvedTrend = useMemo(() => {
        return (isloadTrend ? <Loader /> : statusTrend.length > 0 ?
            <VulnerabilityStatusTrend
                statusTrend={statusTrend}
                label={statusTrendlabel}
                StartDateTime={startDate}
                EndDateTime={endDate}
                filterFormData={props?.filterformdata}
            />
            :
            <Emptystatefornodata />)
    }, [statusTrend, statusTrendlabel, startDate, endDate, isloadTrend, props?.filterformdata])


    return (<>
        <Grid hasGutter>
            <GridItem md={2} lg={2} sm={4}>
                <Card isRounded isFullHeight>
                    <CardTitle align="center" className='vul-title-height'> Severity Status</CardTitle>
                    {isloadseverity ? <div style={{ marginLeft: '30px' }}><RoundSkeleton width={'50px'} /></div> : <VulnerableStatus Critical={critical} High={high} Medium={medium} Low={low} TotalActiveVul={totalActive} />}
                </Card>
            </GridItem>
            <GridItem md={7} lg={8} sm={8}>
                <Card isFullHeight isRounded>
                    <CardTitle align="center" className='title-height'>Vulnerability Posture</CardTitle>
                    <CardBody>
                        {isloadseverity ? <TextSkeleton /> :
                            // pf-v5-u-justify-content-center pf-m-column-on-md
                            <Flex className='pf-v5-u-justify-content-center' spaceItems={{ default: 'spaceItemsXs', lg: 'spaceItemsXl' }}>
                                <FlexItem>
                                    <Text component={TextVariants.h1} className=' pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>

                                        <span className='pf-v5-u-font-size-4xl'>{<Tooltip content={total}><div>{formatValue(total)}</div></Tooltip> ?? 0}</span>
                                        <small className=' pf-v5-u-font-size-md'>{totalActive ?? 0}
                                            <Tooltip
                                                content={<div>Active </div>}>
                                                <Icon status="danger"> <TrendUpIcon /></Icon>
                                            </Tooltip> |
                                            <Tooltip
                                                content={<div>Solved </div>} >
                                                <Icon status="success"> <TrendDownIcon /></Icon>
                                            </Tooltip> {totalSolved ?? 0}</small>
                                    </Text>
                                    <Text component={TextVariants.small} className='pf-v5-u-color-100 grid-text-center'>Total</Text>
                                </FlexItem>
                                <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                <FlexItem >
                                    <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                        {/* <Icon size='lg'><BugIcon /></Icon>  pf-v5-u-color-light-100*/}
                                        <a className='pf-v5-u-font-size-4xl pf-v5-u-danger-color-200' onClick={() => dispatch(FilterData(UpdateFilterData(props.filterformdata, 'data.vulnerability.severity', 'is', 'Critical')))} >
                                            {<Tooltip content={critical}><div>{formatValue(critical)}</div></Tooltip> ?? 0}</a>
                                        <small className='pf-v5-u-font-size-md'>{activecritical ?? 0} <Tooltip
                                            content={<div>Active </div>} >
                                            <Icon status="danger"> <TrendUpIcon /></Icon>
                                        </Tooltip> |
                                            <Tooltip
                                                content={<div>Solved </div>} >
                                                <Icon status="success"> <TrendDownIcon /></Icon>
                                            </Tooltip> {solvedcritical ?? 0}</small>
                                    </Text>
                                    <Text component={TextVariants.small} className='pf-v5-u-danger-color-200 grid-text-center'>Critical</Text>
                                </FlexItem>
                                <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                <FlexItem >
                                    <Text component={TextVariants.h1} className=' pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                        {/* <Icon size='lg'><BugIcon /></Icon> */}
                                        <a className=' pf-v5-u-font-size-4xl pf-v5-u-danger-color-100' onClick={() => dispatch(FilterData(UpdateFilterData(props.filterformdata, 'data.vulnerability.severity', 'is', 'High')))}>
                                            {<Tooltip content={high}><div>{formatValue(high)}</div></Tooltip> ?? 0}</a>
                                        <small className=' pf-v5-u-font-size-md '>{activehigh ?? 0} <Tooltip
                                            content={<div>Active </div>} >
                                            <Icon status='danger'> <TrendUpIcon /></Icon>
                                        </Tooltip> |
                                            <Tooltip
                                                content={<div>Solved </div>} >
                                                <Icon status="success"> <TrendDownIcon /></Icon>
                                            </Tooltip> {solvedhigh ?? 0}</small></Text>
                                    <Text component={TextVariants.small} className='pf-v5-u-danger-color-100 grid-text-center'>High</Text>
                                </FlexItem>
                                <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                <FlexItem >
                                    <Text component={TextVariants.h1} className=' pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                        {/* <Icon size='lg'><BugIcon /></Icon>  */}
                                        <a className=' pf-v5-u-font-size-4xl pf-v5-u-warning-color-100' onClick={() => dispatch(FilterData(UpdateFilterData(props.filterformdata, 'data.vulnerability.severity', 'is', 'Medium')))}>
                                            {<Tooltip content={medium}><div>{formatValue(medium)}</div></Tooltip> ?? 0}</a>
                                        <small className=' pf-v5-u-font-size-md'>{activemedium ?? 0} <Tooltip
                                            content={<div>Active </div>} >
                                            <Icon status="danger"> <TrendUpIcon /></Icon>
                                        </Tooltip> |
                                            <Tooltip
                                                content={<div>Solved </div>} >
                                                <Icon status="success"> <TrendDownIcon /></Icon>
                                            </Tooltip> {solvedmedium ?? 0}</small> </Text>
                                    <Text component={TextVariants.small} className='pf-v5-u-warning-color-100 grid-text-center'>Medium</Text>
                                </FlexItem>
                                <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                <FlexItem >
                                    <Text component={TextVariants.h1} className=' pf-v5-u-text-align-center pf-v5-u-font-weight-bold'>
                                        {/* <Icon size='lg'><BugIcon /></Icon> */}
                                        <a className=' pf-v5-u-font-size-4xl pf-v5-u-info-color-100' onClick={() => dispatch(FilterData(UpdateFilterData(props.filterformdata, 'data.vulnerability.severity', 'is', 'Low')))}>
                                            {<Tooltip content={low}><div>{formatValue(low)}</div></Tooltip> ?? 0} </a>
                                        <small className=' pf-v5-u-font-size-md'>{activelow ?? 0} <Tooltip
                                            content={<div>Active </div>} >
                                            <Icon status="danger"> <TrendUpIcon /></Icon>
                                        </Tooltip> |
                                            <Tooltip
                                                content={<div>Solved </div>} >
                                                <Icon status="success"> <TrendDownIcon /></Icon>
                                            </Tooltip> {solvedlow ?? 0}</small>
                                    </Text>
                                    <Text component={TextVariants.small} className='pf-v5-u-info-color-100 grid-text-center'>Low</Text>
                                </FlexItem>
                            </Flex>
                        }
                    </CardBody>

                </Card>
            </GridItem>

            <GridItem md={3} lg={2} sm={12}>
                <Card isRounded isFullHeight >
                    <CardTitle align="center" className='vul-title-height'>Detection & Mitigation Progress</CardTitle>
                    {isloadseverity ?
                        <TextSkeleton /> :
                        <CardBody className='pf-v5-u-mt-lg'>
                           
                                <Flex direction={{ default: 'column' }}>
                                    <FlexItem>
                                        <Progress aria-label="active"
                                            value={activestatus}
                                            size={ProgressSize.sm}
                                            style={{ '--pf-v5-c-progress__bar--before--BackgroundColor': '#C9190B' }}
                                            label={`${activestatus}%`} />
                                    </FlexItem>
                                    <FlexItem>
                                        <Progress aria-label="solved"
                                            value={parseFloat(solvedstatus)}
                                            style={{ '--pf-v5-c-progress__bar--before--BackgroundColor': '#3E8635' }}
                                            size={ProgressSize.sm}
                                            label={`${solvedstatus} %`} />
                                    </FlexItem>
                                </Flex>
                        </CardBody>
                    }
                </Card>
            </GridItem>
        </Grid>
        <Grid hasGutter className='pf-v5-u-mt-md'>
            <GridItem md={12} sm={12} lg={6}>
                <Card isFullHeight >
                    <CardTitle > <DatabaseIcon /> Vulnerabilities Active/Solved Status Trend</CardTitle>
                    <Divider />
                    <CardBody >
                        {ActiveSolvedTrend}
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem md={12} sm={12} lg={6}>
                <Card isFullHeight>
                    <CardTitle > <ListIcon /> CVE With Exploitable POC ({totalpoc})</CardTitle>
                    <Divider />
                    <ExploitableCve InModal={false} TotalPoC={updatePoCcount} />
                    {totalpoc > 0 && <CardFooter>
                        <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                            <Button variant="link" onClick={() => { handleModalToggle('modalpoc') }}> More </Button>
                            <Modal
                                variant={ModalVariant.large}
                                title={`CVE With Exploitable POC (${totalpoc})`}
                                isOpen={modalStates.modalpoc}
                                onClose={() => handleModalToggle('modalpoc')}
                                description={<div><Flex spaceItems={{ default: 'spaceItemsXl' }} display={{ default: 'inlineFlex' }}>
                                    <FlexItem ><Text style={{ color: '#A30000' }}>Critical</Text></FlexItem>
                                    <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                    <FlexItem ><Text className='pf-v5-u-danger-color-100'>High </Text></FlexItem>
                                    <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                    <FlexItem ><Text className='pf-v5-u-warning-color-100'>Medium </Text></FlexItem>
                                    <Divider orientation={{ default: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                    <FlexItem ><Text className='pf-v5-u-info-color-100'>Low </Text></FlexItem>
                                </Flex>
                                </div>}
                            >
                                <Divider />
                                <Card>
                                    <ExploitableCve InModal={true} TotalPoC={updatePoCcount} />
                                </Card>
                            </Modal>
                        </Flex>
                    </CardFooter>}
                </Card>
            </GridItem>
        </Grid>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={12} sm={12} lg={props?.hideCard ? 7 : 4}>
                    <Card isFullHeight >
                        <CardTitle > <RunningIcon /> Top Vulnerable Groups</CardTitle>
                        <Divider />
                        <CardBody >
                            {isloadgroup ? <Loader /> : groupseries.length > 0 ?
                                <TopVulnerableGrpAssets SeriesData={groupseries} height={250} ismodal={false} YAxisData={groupseries.map((entry) => ({ y: entry.group_name }))} />
                                : <Emptystatefornodata />
                            }

                        </CardBody>
                        {moregroupseries.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { handleModalToggle('modalVulBranch'); setGroupData(); setActiveTabKey(0) }}> More </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`Top Vulnerable Groups (${moregroupseries.length})`}
                                    isOpen={modalStates.modalVulBranch}
                                    onClose={() => handleModalToggle('modalVulBranch')}
                                >
                                    <Divider />
                                    <Card>
                                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                            <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                {isloadgroup ? <Loader /> :
                                                    <VulnerableGroupTable Tabledata={moregroupseries} columnData={["Group Name", 'C', 'H', 'M', "L", "Count",]} />}
                                            </Tab>
                                            <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                {activeTabKey == '1' &&
                                                    <TabContentBody className='pf-v5-u-p-xs'>
                                                        {isloadgroup ? <Loader /> : moregroupseries.length > 0 ?
                                                            <TopVulnerableGrpAssets SeriesData={moregroupseries} height={600} ismodal={true} YAxisData={moregroupseries.map((entry) => ({ y: entry.group_name }))} />
                                                            :
                                                            <Emptystatefornodata />}
                                                    </TabContentBody>
                                                }
                                            </Tab>

                                        </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
                {props?.hideCard ?
                    ""
                    :
                    <GridItem md={12} sm={12} lg={4}>
                        <Card isFullHeight  >
                            <CardTitle > <RunningIcon /> Top  Vulnerable Agents</CardTitle>
                            <Divider />
                            <CardBody >
                                {isloadAgent ? <Loader /> : agentseries.length > 0 ?
                                    <TopVulnerableGrpAssets SeriesData={agentseries} height={250} ismodal={false} YAxisData={agentseries.map((entry) => ({ y: entry.agent_name }))} />
                                    :
                                    <Emptystatefornodata />
                                }

                            </CardBody>
                            {agentseries.length > 0 && <CardFooter>
                                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                    <Button variant="link" onClick={() => { handleModalToggle('modalVulAsset'); setAssetData(); setActiveTabKey(0) }}> More </Button>
                                    <Modal
                                        variant={ModalVariant.large}
                                        title={`Top  Vulnerable Agents (${moreagentseries.length})`}
                                        isOpen={modalStates.modalVulAsset}
                                        onClose={() => handleModalToggle('modalVulAsset')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                                <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                    {isloadAgent ? <Loader /> :
                                                        <TopVulnerableAgentTable Tabledata={moreagentseries} columnData={["Agent Name", 'C', 'H', 'M', "L", "Count",]} filterFields={'agent.name'} />}
                                                </Tab>
                                                <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                    {activeTabKey == '1' &&
                                                        <TabContentBody className='pf-v5-u-p-xs'>
                                                            {isloadAgent ? <Loader /> : moreagentseries.length > 0 ?
                                                                <TopVulnerableGrpAssets SeriesData={moreagentseries.slice(0, 15)} height={600} ismodal={true} YAxisData={moreagentseries.slice(0, 15).map((entry) => ({ y: entry.agent_name }))} />
                                                                :
                                                                <Emptystatefornodata />}
                                                        </TabContentBody>
                                                    }
                                                </Tab>
                                            </Tabs>
                                        </Card>
                                    </Modal>
                                </Flex>
                            </CardFooter>}
                        </Card>
                    </GridItem>
                }
                <GridItem md={12} sm={12} lg={props?.hideCard ? 5 : 4}>
                    <Card isFullHeight>
                        <CardHeader className="pf-v5-u-align-items-flex-start" actions={{ actions: headerActions, hasNoOffset: false }}>
                            <CardTitle > <CodeBranchIcon /> Top CVE</CardTitle>
                        </CardHeader>
                        <Divider />
                        <CardBody >
                            {isloadTopcve ? <Loader /> : cveseries.length > 0 ?
                                <TopCVE cveData={cveseries} SelectedItem={selected} height={250} filterFields={cveField} /> :
                                <Emptystatefornodata />
                            }
                        </CardBody>
                        {cveseries.length > 0 && <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { handleModalToggle('modalTopCVE'); setCveData(); setActiveTabKey(0) }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`Top CVE (${morecveseries.length})`}
                                    help={headerActions}
                                    isOpen={modalStates.modalTopCVE}
                                    onClose={() => handleModalToggle('modalTopCVE')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>
                                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                            <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                {isloadTopcve ? <Loader /> : <TableViewForChart
                                                    Tabledata={morecveseries.map((item) =>
                                                    ({
                                                        key: item.cve_title,
                                                        count: item.total
                                                    }))}
                                                    columnData={[selected, "Count"]} filterFields={cveField} />}
                                            </Tab>
                                            <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                {activeTabKey == '1' &&

                                                    isloadTopcve ? <Loader /> :
                                                    morecveseries.length > 0 ?
                                                        <TopCVE cveData={morecveseries.slice(0, 20)} SelectedItem={selected} height="400px" filterFields={cveField} />
                                                        : <Emptystatefornodata />
                                                }
                                            </Tab>
                                        </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>}
                    </Card>
                </GridItem>
            </Grid>
        </div>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={12} sm={12} lg={12}>
                    <Card isFullHeight isRounded>
                        <CardTitle > <TableIcon /> Latest Vulnerabilities(CVE)</CardTitle>
                        <Divider />
                        <CardBody >
                            {isloadCve ? <Loader /> :
                                <LatestVulnerabilitiesCve IsModal={false} />
                            }
                        </CardBody>
                        <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => {
                                    setActiveTabKey(0);
                                    handleModalToggle('modalVulCVE');
                                    setLatestCVEData();
                                }}> More </Button>
                                <Modal
                                    height="50%"
                                    // variant={ModalVariant}
                                    title='Latest Vulnerabilities(CVE)'
                                    isOpen={modalStates.modalVulCVE}
                                    onClose={() => handleModalToggle('modalVulCVE')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>
                                        <Drawer isStatic>
                                            <DrawerContent panelContent={
                                                <DrawerPanelContent isResizable defaultSize={'80%'} minSize={'500px'}>
                                                    {isloadCve ? <Loader /> :
                                                        // <LatestVulnerabilitiesTable ref={childLatestCVELogsRef} />
                                                        <LatestVulnerabilitiesCve IsModal={true} />}
                                                </DrawerPanelContent>

                                            }>
                                                <DrawerContentBody >
                                                    <Card isFullHeight>
                                                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                                            <Tab eventKey={0} title={<TabTitleText>By Agent</TabTitleText>} aria-label="graph">
                                                                {isloadAgent ? <Loader /> : <TableViewForChart Tabledata={cveagent} columnData={["Agent Name", "Count"]} filterFields={'agent.name'} vulPerPage={10} />}
                                                            </Tab>
                                                            <Tab eventKey={1} title={<TabTitleText>By Package Name </TabTitleText>}>
                                                                {isloadpackage ? <Loader /> : <TableViewForChart Tabledata={cvepackage} columnData={["Package Name", "Count"]} filterFields={"data.vulnerability.package.name"} vulPerPage={10} />}
                                                            </Tab>
                                                        </Tabs>
                                                    </Card>
                                                </DrawerContentBody>
                                            </DrawerContent>
                                        </Drawer>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>
                    </Card>
                </GridItem>

            </Grid>
        </div>
    </>
    )
})
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate, selectedtime: state.Term },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
});

const mapDispatchToProps = (dispatch) => { return {} }

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(VulnerabilitiesDashboard)