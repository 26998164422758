//2nd which is perfectly run and usable
import React, { useEffect, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  ExpandableRowContent,
  Table,
} from "@patternfly/react-table";
import {
  DescriptionList,
  DescriptionListDescription,
  DescriptionListGroup,
  DescriptionListTerm,
  Tab,
  Tabs,
  TabTitleText,
  TabContentBody,
  Toolbar,
  ToolbarItem,
  SearchInput,
  ToolbarContent,
  EmptyState,
  EmptyStateIcon,
  EmptyStateBody,
  EmptyStateHeader,
  Bullseye,
  EmptyStateFooter,
  EmptyStateActions,
  Button,
  Pagination,
  ToolbarGroup,
  Flex,
  FlexItem,

} from "@patternfly/react-core";
import { SearchIcon } from "@patternfly/react-icons";
import dbApi from '../../services/DbApi'
import { formatTableTimestamp } from "../../util/Timeutils";
import { Emptystatefornodata, emptystatefornodata } from "../../util/EmptyStateCard";
import { Link } from "react-router-dom";

const { forwardRef, useRef, useImperativeHandle } = React;

export const ThreatDetectionTable = forwardRef((props, ref) => {

  const [ThreatDetectionData, setThreatDetectionData] = React.useState([])
  const [ScrollID, setScrollID] = React.useState('')

  useImperativeHandle(ref, () => ({
    setThreatDetectionTable(data = [], scrollId = '') {
      setBeforescrolldata([])
      setThreatDetectionData(data)
      setScrollID(scrollId)

      // console.log("ThreatDetectionData2", data)

    }
  }));

  // useEffect(()=>{
  //   console.log("ThreatDetectionData1", ThreatDetectionData)

  //   if (props.IsModal) {
  //     console.log("ThreatDetectionData", ThreatDetectionData)
  //   }
  
  // },[props.IsModal,ThreatDetectionData])

  const columns = [
    "Time",
    "Agent Name",
    "Rule Description",
  ];
  const [itemcount, setItemcount] = useState(0)
  const [defaultRows, setDefaultRows] = useState([]);
  const [scrolldata, setScrollData] = useState([]);
  const [beforescrolldata, setBeforescrolldata] = useState([]);
  const [rows, setRows] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [expandedRowNames, setExpandedRowNames] = useState([]);
  const [expandedRowData, setExpandedRowData] = useState({});
  const [selectedRowName, setSelectedRowName] = useState('');
  const [page, setPage] = useState(1);
  const [isloadnext, setIsloadNext] = useState(false)
  const [perPage, setPerPage] = useState(props?.IsModal ? 20 : 8);
  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex);
  };

  //scroll Api for pagination
  const TDPaginationApi = async (ScrollID) => {
    const data = {
      scroll_Id: ScrollID
    }
    try {
      setIsloadNext(true)
      let Scrolldata = await dbApi.postApi("Opensearch/Latest_scroll", data)
      console.log(Scrolldata)
      const nextdata = Scrolldata.Latest_scroll
      let scrolldata = []
      if (nextdata.length > 0) {
        scrolldata = nextdata
      }
      setScrollData(scrolldata);
      setIsloadNext(false)
    }
    catch (error) {
      console.log(error)
      setIsloadNext(false)
    }
  }

  const onSetPage = (_event, newPage) => {
    setPage(newPage);
  };
  const onPerPageSelect = (_event, newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

  const resetPagination = () => {
    setPage(page);
    setPerPage(perPage); // Set to your desired default perPage value
  };
  useEffect(() => {

    let afterScrolldata = []

    if (beforescrolldata.length === 0) {
      afterScrolldata = ThreatDetectionData
    } else {
      afterScrolldata = [...beforescrolldata, ...scrolldata]
    }
    resetPagination() // Reset pagination when new data is set
    setBeforescrolldata(afterScrolldata)


    const newdefaultRows = afterScrolldata.map((log) => {
      const { formattedTimeStamp } = formatTableTimestamp(log._source.timestamp);
      return {
        id: log._id,
        timestamp: formattedTimeStamp,
        agentId: log._source.agent.id,
        agentName: log._source.agent.name,
        ruleDescription: log._source.rule.description,
        details: log,
      };
    });

    setDefaultRows(newdefaultRows)
    setItemcount(newdefaultRows.length)
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);

  }, [ThreatDetectionData, scrolldata]);

  //Use Effect for pagination 
  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    // console.log("after slicing", "", startIndex, endIndex, slicedRows)
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);



  //row expansion function
  const setRowExpanded = (row, isExpanding) => {
    const otherExpandedRowNames = expandedRowNames.filter((r) => r !== row.id);
    setExpandedRowNames(
      isExpanding ? [row.id] : []
    );
    if (!expandedRowData[row.id]) {
      let agentName = typeof row?.details?._source?.agent?.name != "undefined" ? row?.details?._source?.agent?.name : "";
      let agentID = typeof row?.details?._source?.agent?.id != "undefined" ? row?.details?._source?.agent?.id : "";
      const keyifiedData = keyify(row?.details, "", agentName, agentID);
      setExpandedRowData({ [row.id]: keyifiedData, });
      // const keyifiedData = keyify(row?.details);
      // setExpandedRowData({ [row.id]: keyifiedData, });
    }
    const updatedExpandedRowNames = isRowExpanded(row)
      ? otherExpandedRowNames : [row.id];
    setExpandedRowNames(updatedExpandedRowNames);

  };
  const isRowExpanded = (row) => {
    return expandedRowNames.includes(row.id);
  };



  const hyperlinkConditions = {
   'agent.id': (value) => value !== '000',
    'agent.name': (value) => value !== '000',
  };

  const keyify = (obj, prefix = "",agentName = '', agentID = '') => {
    const skipKeys = ["_score", "_version", "_index", "sort", "@timestamp"];
    const result = [];
    Object.keys(obj).forEach((el) => {
      const fullkey = prefix + el;
      if (skipKeys.includes(el)) {
        return;
      }
      else if (Array.isArray(obj[el])) {
        const value = obj[el].join(", ");
        result.push({ key: fullkey, value });
      } else if (typeof obj[el] === "object" && obj[el] !== null) {
        if (el === "_source") {
          result.push(...keyify(obj[el], prefix, agentName, agentID));
        }
        else {
          result.push(...keyify(obj[el], fullkey + ".", agentName, agentID));
        }
      }
      else {
        if (hyperlinkConditions[fullkey] && hyperlinkConditions[fullkey](obj[el])) {
          result.push({ key: fullkey, value: <Link to={`/individual-agent-dashboard/${agentName}/${agentID}`}>{obj[el]}</Link> });
        }
        else if (typeof obj[el] === 'boolean') {
          result.push({ key: fullkey, value: obj[el] ? 'true' : 'false' });
        }
        else { result.push({ key: fullkey, value: obj[el] }); }
      }
    });
    const sortedResult = result.sort((a, b) => {
      if (a.key < b.key) return -1;
      if (a.key > b.key) return 1;
      return 0
    });

    return sortedResult;
  };


  //pagination code
  const renderPagination = (variant) => <Pagination
    itemCount={itemcount}
    perPage={perPage}
    page={page}
    variant={variant}
    onSetPage={onSetPage}
    onPerPageSelect={onPerPageSelect}
  />

  return (
    <React.Fragment>
      {props?.IsModal && <Toolbar inset={{
        default: 'insetNone',
        md: 'insetSm',
        xl: 'inset2xl',
        '2xl': 'insetLg'
      }}>
        <ToolbarContent>
          <ToolbarGroup align={{ default: 'alignRight' }}>
            <ToolbarItem> <Button onClick={() => TDPaginationApi(ScrollID)} size="sm" isLoading={isloadnext} >Next</Button></ToolbarItem>
            <ToolbarItem>{renderPagination('top')}</ToolbarItem>
          </ToolbarGroup>
        </ToolbarContent>
      </Toolbar>}
      <Table variant="compact"
        aria-label="securityalertsLogTable"
        id="securityalerts-log-table"
        isStickyHeader
        gridBreakPoint="grid-md" >
        <Thead>
          <Tr>
            <Th key={'expand'} aria-label="expand-event" />
            {columns.map((column, index) => (
              <Th key={index}>{column}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows.length > 0 ?
            rows && rows.map((row, rowIndex) => {
              return (
                <>
                  <Tr key={rowIndex}
                    onRowClick={() => setSelectedRowName(rowIndex)}
                    isSelectable
                    isClickable
                    isRowSelected={selectedRowName === rowIndex}  >
                    <Td
                      expand={
                        row.details
                          ? {
                            rowIndex: rowIndex,
                            isExpanded: isRowExpanded(row),
                            onToggle: () =>
                              setRowExpanded(row, !isRowExpanded(row)),
                          }
                          : undefined
                      }
                    >
                      <ExpandableRowContent>
                        {/* <pre>{JSON.stringify(row?.details, null, 2)}</pre> */}
                      </ExpandableRowContent>
                    </Td>

                    <Td width={20}> {row?.timestamp}</Td>

                    <Td width={20} > <Link to={"/Individual-Agent-Dashboard/" + row?.agentName + '/' + row?.agentId}>{row?.agentId}: {row?.agentName}</Link></Td>

                    <Td width={60} modifier="truncate" >{row?.ruleDescription} </Td>
                  </Tr>
                  <Tr isExpanded={isRowExpanded(row)}>

                    <Td colSpan={10} noPadding>
                      <ExpandableRowContent>
                        <Tabs
                          activeKey={activeTabKey}
                          onSelect={handleTabClick}
                          style={{ marginTop: "0px" }}
                          aria-label="Tabs in the Security Alerts"
                          role="region"
                        >
                          <Tab
                            eventKey={0}
                            title={<TabTitleText>Table</TabTitleText>}
                            aria-label="Table data"
                          >
                            <TabContentBody hasPadding>

                              <DescriptionList isHorizontal horizontalTermWidthModifier={{ default: '30ch' }}>
                                {expandedRowData[row.id]?.map((item, index) => (
                                  <React.Fragment key={index + "tdp"}>
                                    <DescriptionListGroup key={index + 'tdgrp'}>
                                      <DescriptionListTerm key={index + 'tdterm'}> {item.key}</DescriptionListTerm>
                                      <DescriptionListDescription key={index + 'tddesc'}>{item.value}</DescriptionListDescription>
                                    </DescriptionListGroup>
                                  </React.Fragment>
                                ))}
                              </DescriptionList>
                            </TabContentBody>
                          </Tab>
                          <Tab eventKey={1} title={<TabTitleText>JSON</TabTitleText>}>
                            <TabContentBody hasPadding>
                              <pre style={{ whiteSpace: 'pre-wrap' }}>
                                {JSON.stringify(row?.details, null, 2)}
                              </pre>
                            </TabContentBody>
                          </Tab>
                        </Tabs>
                      </ExpandableRowContent>
                    </Td>
                  </Tr>
                </>
              )
            })
            :
            <Tr>
              <Td colSpan={10}>
                <Emptystatefornodata />
              </Td>
            </Tr>

          }
        </Tbody>
      </Table>
      {props?.IsModal && <div className="pf-v5-u-mb-sm">
        <Flex justifyContent={{ default: 'justifyContentFlexEnd' }} spaceItems={{ default: 'spaceItemsNone' }} className="pf-v5-u-mt-sm">
          <FlexItem alignSelf={{ default: 'alignSelfCenter' }}>
            <Button onClick={() => TDPaginationApi(ScrollID)} size="sm" isLoading={isloadnext}>Next</Button>
          </FlexItem>
          <FlexItem>
            {renderPagination('false')}
          </FlexItem>
        </Flex>
      </div>}
    </React.Fragment>
  );
});
