import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { formatValue } from '../../util/ChartHelper';
import { connect, useDispatch } from 'react-redux';
import { UpdateFilterData } from 'src/util/FilterHelper';
import { FilterData } from 'src/Redux/Action';
const TopVulnerableGrpAssets = (props) => {
  const id = generateUniqueID()
  const dispatch = useDispatch();
  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }
  useEffect(() => {

    if (props.SeriesData && props.SeriesData.length > 0) {
      // console.log("props.SeriesData",props.SeriesData)
      const chartDom = document.getElementById(id + 'vulagegrp');
      const myChart = echarts.init(chartDom);
      const textcolor = props.theme == 'dark-props.theme' ? '#fff' : '#000'
      const linecolor = props.theme == 'dark-props.theme' ? '#595757' : '#d9d9d9'

      // const yAxisData = props.SeriesData.map((entry) => ({ y: entry.group_name }))
      const yAxisData = props.YAxisData

      const option = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: "#000"
          }
        },
        title: {
          text: 'Count',
          top: 'bottom',
          left: 'center',
          textStyle: {
            fontWeight: 450,
            fontSize: 14,
            color: textcolor,
            fontfamily: 'RedHatText, helvetica, arial, sans-serif',
          },
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 'right',
          top: 0,
          itemWidth: 9.5,
          itemHeight: 10,
          itemGap: 6,
          icon: "rect",
          textStyle: {
            // width: 50,
            overflow: 'truncate',
            color: textcolor
          },
          data: ['Critical', 'High', 'Medium', 'Low']
        },
        grid: {
          left: 0,
          right: '24%',
          bottom: '15%',
          top: 10,
          containLabel: true
        },
        xAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: linecolor
            }
          },
          axisLabel: {
            formatter: formatValue,
            color: textcolor,
            rotate: -50
          },
          axisLine: {
            show: true
          },
        },
        yAxis: {
          type: 'category',
          inverse: true,
          axisLabel: {
            interval: 0,
            rotate: 20,
            width: 70,
            overflow: 'truncate',
            fontSize: 12,
            color: textcolor
          },
          boundaryGap: true,
          data: yAxisData.map(item => item.y),
          axisTick: {
            alignWithLabel: true
          },
        },
        series: [
          {
            name: 'Critical',
            type: 'bar',
            label: {
              show: props.ismodal ? true : false
            },
            stack: 'total',
            barWidth: props.ismodal ? 30 : 10,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              color: '#A30000', // Red for active, Green for solved
            },
            data: props.SeriesData.map(item => item.critical_total)
          },
          {
            name: 'High',
            type: 'bar',
            label: {
              show: props.ismodal ? true : false
            },
            stack: 'total',
            barWidth: props.ismodal ? 30 : 10,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              color: '#C9190B', // Red for active, Green for solved
            },
            data: props.SeriesData.map(item => item.high_total)
          },
          {
            name: 'Medium',
            type: 'bar',
            label: {
              show: props.ismodal ? true : false
            },
            stack: 'total',
            barWidth: props.ismodal ? 30 : 10,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              color: '#f0ab00', // Red for active, Green for solved
            },
            data: props.SeriesData.map(item => item.medium_total)
          }, {
            name: 'Low',
            type: 'bar',
            label: {
              show: props.ismodal ? true : false
            },
            stack: 'total',
            barWidth: props.ismodal ? 30 : 10,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              color: '#2B9AF3', // Red for active, Green for solved
            },
            data: props.SeriesData.map(item => item.low_total)
          },
        ]
      };
      option && myChart.setOption(option);
      myChart.on('legendselectchanged', function (param) {
        handleFilter('data.vulnerability.severity', param.name)
      });
      const resizeChart = () => {
        if (myChart) { // Check if chart exists before resizing
          myChart.resize();
        }
      };

      window.addEventListener('resize', resizeChart);
      return () => {
        myChart.dispose();
        window.removeEventListener('resize', resizeChart);
      };

    }
  }, [props.SeriesData, props.height, props.theme, props.sidebarState, props.ismodal, props.YAxisData])
  return <div className="container">
    <div id={id + 'vulagegrp'} style={{ width: '100%', height: props.height }} ></div>
  </div>;
}

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  theme: state.theme,
  sidebarState: state.sidebarstate
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(TopVulnerableGrpAssets)