import { Grid, GridItem, PageSection } from '@patternfly/react-core'
import React, { useState } from 'react';
import ElinesGlChart from '../AllCharts/ElinesGlChart';

const Testing = () => {


    return (<>
        <PageSection>
            <Grid hasGutter>
                <GridItem md={12}>
                    <ElinesGlChart />
                </GridItem>
                {/* <GridItem md={12}>
                    <PictorialBarChart MaxCount={5} SymbolSize={[30, 30]} SymbolMargin='20%' Score={[3.6]} height={'150px'} />
                </GridItem> */}
            </Grid>


        </PageSection>
    </>
    )
}

export default Testing