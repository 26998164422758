import { utils, writeFile } from 'xlsx'; // Import xlsx
export const UpdateFilterData = (filterformdata, fields,condition, value) => {
    let obj = {
        fields: fields,
        condition: condition,
        value: value,
    };
    let isDuplicate = filterformdata.some(item =>
        item.fields === obj.fields &&
        item.condition === obj.condition &&
        item.value === obj.value
    );
    let updatedFields = [];
    if (!isDuplicate) {
        // console.log("notduplicate")
        updatedFields = [...filterformdata, obj];
    } else {
        updatedFields = [...filterformdata];
    }
    return updatedFields; // Return the updatedFields instead of dispatching
};

//for individual dashboard if agent.name field is already exists then replace its value of agent name 
export const UpdateFilterDataIndDash = (filterformdata, fields,condition, value) => {
  let obj = {
      fields: fields,
      condition: condition,
      value: value,
  };
  let isDuplicate = filterformdata.some(item =>
      item.fields === obj.fields 
  );
  let updatedFields = [];
  if (!isDuplicate) {
      // console.log("notduplicate")
      updatedFields = [...filterformdata, obj];
  } else {
      // updatedFields = [...filterformdata];
      updatedFields = filterformdata.map(item =>
        item.fields === obj.fields ? { ...item, value: obj.value } : item
      );
  }
  console.log("updatedFields",updatedFields)
  return updatedFields; // Return the updatedFields instead of dispatching
};


export const getBorderColor = (severity) => {
    switch (severity) {
      case 'High':
        return '10px solid #C9190B'; // Red
      case 'Critical':
        return '10px solid #A30000'; // Green
      case 'Medium':
        return '10px solid #F0AB00'; // Yellow
      case 'Low':
        return '10px solid #2B9AF3'; // Blue
      default:
        return 'none'; // No border
    }
  };


export const exportToExcel = (tableData, columns, fileName) => {
  // Create a new array of objects where keys match the column names

  // console.log('sdfgh', tableData)
  const formattedData = tableData.map(row => {
    const formattedRow = {};
    columns.forEach(column => {
      formattedRow[column] = row[column];
    });
    // console.log('sderetrdutfgh', formattedRow)
    return formattedRow;
  });

  const worksheet = utils.json_to_sheet(formattedData);
  const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  writeFile(workbook, fileName);
};

export const handleCopy = (textToCopy) => {
  console.log('v', textToCopy)
  var textField = document.createElement('textarea')
  textField.innerText = textToCopy
  document.body.appendChild(textField)
  console.log(textField)
  textField.select()
  document.execCommand('copy')
  // setCopytip && setCopytip('Copied!');
  textField.remove()
  setTimeout(() => textField.remove(), 100);
};


//   export  const exportToExcel = (tabledata,columns,fileName) => {
//     const tableData = defaultRows.map(row => ({
//       GroupName: row.key,
//       Count: row.count,
//       Critical:row.critical,
//       High: row.high,
//       Medium: row.medium,
//       Low: row.low,
//     }));
//     const worksheet = utils.json_to_sheet(tableData);
//     const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
//     writeFile(workbook, 'table-data.xlsx');
//   };