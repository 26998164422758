import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Divider,
    Grid,
    GridItem,
    Icon,
} from '@patternfly/react-core'
import React, { useEffect, useMemo, useState } from 'react'
import dbApi from 'src/services/DbApi';
import scaicon from '../Assets/homeimages/scaicon.svg'
import policyIcon from '../Assets/homeimages/policymonitoricon.svg'
import pcidssicon from '../Assets/homeimages/pcidssicon.svg'
import gdpricon from '../Assets/homeimages/gdpricon.svg'
import tscicon from '../Assets/homeimages/tscicon.svg'
import nisticon from '../Assets/homeimages/nist800-53icon.svg'
import integritymonitoricon from '../Assets/homeimages/integritymonitoricon.svg'
import mitreicon from '../Assets/homeimages/mitreicon.svg'
import seceventicon from '../Assets/homeimages/seceventicon.svg'
import vulnerabilityicon from '../Assets/homeimages/vulnerabilityicon.svg'
import tipicon from '../Assets/homeimages/tipicon.svg'
import osqueryicon from '../Assets/homeimages/osqueryicon.svg'
import systemauditicon from '../Assets/homeimages/systemauditicon.svg'
import office365icon from '../Assets/homeimages/office365icon.svg'
import googlecloudicon from '../Assets/homeimages/googlecloudicon.svg'
import awsicon from '../Assets/homeimages/awsicon.svg'
import githubicon from '../Assets/homeimages/githubicon.svg'
import hipaaicon from '../Assets/homeimages/hipaaicon.svg'
import dockericon from '../Assets/homeimages/dockericon.svg'
import AgentStatuschart from 'src/Components/Agent/AgentStatuschart';

import { Emptystatefornodata } from 'src/util/EmptyStateCard';
import { Loader } from 'src/util/Loadingstate';
import { useNavigate } from 'react-router-dom';
import { StartEndDate } from 'src/Redux/Action';
import { connect, useDispatch } from 'react-redux';
import { TimeHandler } from 'src/util/Timeutils';
import Homebarchart from './Homebarchart';

const AppHome = (props) => {
    const id1 = 'clickable-selectable-card-input-1';
    const dispatch = useDispatch()
    const [StatusSeries, setStatusSeries] = useState([])
    const [active, setActive] = useState(0)
    const [disconnected, setDisconnected] = useState(0)
    const [pending, setPending] = useState(0)
    const [neverconnected, setNeverConnected] = useState(0)
    const [totalagent, setTotalAgent] = useState(0)
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [chartlabel, setChartLabel] = useState('')
    const [chartseries, setChartSeries] = useState([])
    const [TotalEvents, setTotalEvents] = useState("0");
    //loading state
    const [isloadStatus, setIsLoadStatus] = useState(false);
    const [isloadevent, setIsloadEvent] = useState(false)

    const TimeApi = async () => {
        try {
            let currentDateTime = await dbApi.getApi("elasticsearch/getCurrentUtcDateTime");
            const CurrentTime = new Date(currentDateTime);
            // setCurrentTime(CurrentTime)
            return CurrentTime;
        }
        catch (error) {
            console.log(error);
        }
    }
    const handleGetTime = async () => {
        let term = "Last 24 hours"
        // let term = "Last 24 hours"
        const {
            startDate,
            endDate,
            selectedTime,
            selectedUnit
        } = await TimeHandler(term, TimeApi, '');
        setStartDate(startDate);
        setEndDate(endDate);
        dispatch(StartEndDate(startDate, endDate, term));
    }
    const getEventonFrame = async (data) => {
        try {
            setIsloadEvent(true)
            let all_eventlog = await dbApi.postApi("elasticsearch/EventLog", data);
            console.log('event log data home', all_eventlog);
            if (Object.keys(all_eventlog.event).length !== 0) {
                let label = all_eventlog.event.interval
                let events = all_eventlog.event.result.body.hits
                let ChartData = all_eventlog.event.result.body.aggregations.date_histogram
                let chartlabel = ''
                let chartseries = []
                let TotalEvents = '0'
                if (events.total.value > 0) {
                    TotalEvents = events.total.value;
                }
                if (ChartData.buckets.length > 0) {
                    chartseries = ChartData.buckets;
                    chartlabel = label
                }
                setChartLabel(chartlabel)
                setChartSeries(chartseries)
                setTotalEvents(TotalEvents)
            }
            else {
                setChartLabel('')
                setChartSeries([])
                setTotalEvents(0)
            }
            setIsloadEvent(false)
        }
        catch (error) {
            console.log(error)
            setIsloadEvent(false)
        }
    }
    const getAgentSummary = async () => {
        try {
            setIsLoadStatus(true)
            let res = await dbApi.getApi("opensearch/get_total_agent_status_wise")
            console.log(res)
            let Active = 0
            let Disconnected = 0
            let Pending = 0
            let NeverConnected = 0
            let AgentCoverage = 0
            let LastAgentname = ''
            let lastAId = ''
            let totalAgent = 0
            // lastRegisteredAgent
            if (res.data.active > 0) { Active = res.data.active }
            if (res.data.pending > 0) { Pending = res.data.pending }
            if (res.data.disconnected > 0) { Disconnected = res.data.disconnected }
            if (res.data.neverConnected > 0) { NeverConnected = res.data.neverConnected }
            if (res.data.coveragePercentage > 0) { AgentCoverage = res.data.coveragePercentage }
            if (res.data.lastRegisteredAgent.name !== undefined) { LastAgentname = res.data.lastRegisteredAgent.name }
            if (res.data.lastRegisteredAgent.id !== undefined) { lastAId = res.data.lastRegisteredAgent.id }
            if (res.data.totalAgent > 0) { totalAgent = res.data.totalAgent }
            setActive(Active)
            setStatusSeries(res.data)
            setDisconnected(Disconnected)
            setPending(Pending)
            setNeverConnected(NeverConnected)
            setTotalAgent(totalAgent)
            setIsLoadStatus(false)
        }
        catch (error) {
            console.log(error)
            setActive(0)
            setDisconnected(0)
            setPending(0)
            setNeverConnected(0)
            setTotalAgent(0)
            setIsLoadStatus(false)
        }
    }

    useEffect(() => {
        handleGetTime()
    }, [])

    const homeApi = async (startDate, endDate) => {
        let staticfield = [{
            "fields": 'rule.groups',
            'condition': 'is_not',
            'value': 'zeek'
        }]
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify([...staticfield]),
        };

        try {

            setStartDate(startDate)
            setEndDate(endDate)
            getEventonFrame(data)
            getAgentSummary()
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (startDate !== '') {
            homeApi(startDate, endDate)
        }
    }, [startDate, endDate, props?.selectedGroup])
    const EventOnFrame = useMemo(() => {
        return (
            isloadevent ? <Loader /> :
                TotalEvents == 0 ? <Emptystatefornodata /> :
                    <Homebarchart
                        EventSeries={chartseries}
                        EventLabel={chartlabel}
                        StartDateTime={startDate}
                        EndDateTime={endDate}
                        TotalEvents={TotalEvents}

                    />
        )
    }, [chartseries, chartlabel, startDate, endDate, TotalEvents, isloadevent])

    const Agentsummary = useMemo(() => {
        return (
            isloadStatus ? <Loader /> :
                active + disconnected + pending + neverconnected === 0 ? <Emptystatefornodata /> :
                    <AgentStatuschart StatusData={StatusSeries} height={'180px'} />
        )
    }, [isloadStatus, active, disconnected, pending, neverconnected, StatusSeries])

    const navigate = useNavigate()
    const secid = 'secevents-card';
    const vulid = 'vul-card'
    const mitreid = 'mitre-card'
    const osqueryid = 'osquery-card'
    const tipid = 'tip-card'
    const scaid = 'sca-card'
    const pmid = 'pmmd-card'
    const fimid = 'fim-card'
    const said = 'sa-card'
    const pcidssid = 'pcidss-card'
    const gdprid = 'gdpr-card'
    const hipaaid = 'hipaa-card'
    const gpg13id = 'gpg13-card'
    const nistid = 'nist-card'
    const tscid = 'tsc-card'

    const iconClass = props.theme == 'light-theme' ? '' : 'dark-theme'
    return (<div className="pf-v5-u-p-sm">
        <Grid hasGutter>
            <GridItem md={5} sm={12} lg={3}>
                <Card isFullHeight>
                    <CardTitle>AGENTS SUMMARY ({totalagent}) </CardTitle>
                    <Divider />
                    <CardBody> {Agentsummary} </CardBody>
                </Card>
            </GridItem>
            <GridItem md={7} sm={12} lg={9} >
                <Card isFullHeight>
                    <CardTitle >LAST 24 HOURS ALERTS</CardTitle>
                    <Divider />
                    <CardBody>{EventOnFrame}</CardBody>
                </Card>
            </GridItem>
        </Grid>
        <Grid className="pf-v5-u-mt-sm" hasGutter>
            <GridItem md={6} sm={12} lg={6}>
                <Card isFullHeight>
                    <CardTitle>THREAT INTELLIGENCE</CardTitle>
                    <Divider />
                    <CardBody>
                        <Grid hasGutter>
                            <GridItem md={6} sm={12} lg={6}>
                                <Card isFlat isFullHeight isRounded isClickable >
                                    <CardHeader
                                        selectableActions={{
                                            onClickAction: () => navigate('/security-events'),
                                            selectableActionId: secid,
                                            selectableActionAriaLabelledby: 'secevents',
                                            name: 'secevents-card'
                                        }}
                                    >
                                        <CardTitle className='home-card-title'>  <img src={seceventicon} alt='' className={`home-icon-style ${iconClass}`} /> Security Events</CardTitle>
                                    </CardHeader>
                                    <CardBody>Browse through your security alerts, identifying issues and threats in your environment.</CardBody>
                                </Card>
                            </GridItem>
                            <GridItem md={6} sm={12} lg={6}>
                                <Card isFlat isFullHeight isRounded isClickable >
                                    <CardHeader
                                        selectableActions={{
                                            onClickAction: () => navigate('/vulnerabilities'),
                                            selectableActionId: vulid,
                                            selectableActionAriaLabelledby: 'vulnerability',
                                            name: 'vulnerability-card'
                                        }}
                                    >
                                        <CardTitle className='home-card-title'> <img src={vulnerabilityicon} alt='' className={`home-icon-style ${iconClass}`} /> Vulnerability Detection</CardTitle>
                                    </CardHeader>
                                    <CardBody>Discover what applications in your environment are affected by well-known vulnerabilities.</CardBody>
                                </Card>
                            </GridItem>
                            <GridItem md={6} sm={12} lg={6}>
                                <Card isFlat isFullHeight isRounded isClickable >
                                    <CardHeader
                                        selectableActions={{
                                            onClickAction: () => navigate('/mitre-attack'),
                                            selectableActionId: mitreid,
                                            selectableActionAriaLabelledby: 'mitre-attack',
                                            name: 'mitre-attack-card'
                                        }}
                                    >
                                        <CardTitle className='home-card-title'> <img src={mitreicon} alt='' className={`home-icon-style ${iconClass}`} /> MITRE ATT&CK</CardTitle>
                                    </CardHeader>
                                    <CardBody>Security events from the knowledge base of adversary tactics and techniques based on real-world observations.</CardBody>
                                </Card>
                            </GridItem>
                            <GridItem md={6} sm={12} lg={6}>
                                <Card isFlat isFullHeight isRounded isClickable>
                                    <CardHeader
                                        selectableActions={{
                                            onClickAction: () => navigate('/osquery'),
                                            selectableActionId: osqueryid,
                                            selectableActionAriaLabelledby: 'osquery',
                                            name: 'osquery-card'
                                        }}
                                    >
                                        {/* max-width: 32px;
  height: 32px;
  margin-right: 8px;
  filter: var(--icon-filter); */}
                                        <CardTitle className='home-card-title'><img src={osqueryicon} alt='' style={{ maxWidth: '28px', height: '28px', marginRight: '8px', filter: 'var(--icon-filter)' }} className={iconClass} /> OsQuery</CardTitle>
                                    </CardHeader>
                                    <CardBody>Expose an operating system as a high-performance relational database.</CardBody>
                                </Card>
                            </GridItem>
                            <GridItem md={6} sm={12} lg={6}>
                                <Card isFlat isFullHeight isRounded isClickable>
                                    <CardHeader
                                        selectableActions={{
                                            onClickAction: () => navigate('/threat-intel-platform-engine'),
                                            selectableActionId: tipid,
                                            selectableActionAriaLabelledby: 'threat-intel-platform-engine',
                                            name: 'threat-intel-platform-engine-card'
                                        }}
                                    >
                                        <CardTitle className='home-card-title'><img src={tipicon} alt='' className={`home-icon-style ${iconClass}`} /> TIP<sup>+</sup></CardTitle>
                                    </CardHeader>
                                    <CardBody>Threat intelligence and indicators of compromise (IOCs).</CardBody>
                                </Card>
                            </GridItem>
                        </Grid>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem md={6} sm={12} lg={6}>
                <Card isFullHeight  >
                    <CardTitle>ENDPOINT SECURITY </CardTitle>
                    <Divider />
                    <CardBody>
                        <Grid hasGutter>
                            <GridItem md={6} sm={12} lg={6}>
                                <Card isFlat isFullHeight isRounded isClickable >
                                    <CardHeader
                                        selectableActions={{
                                            onClickAction: () => navigate('/security-configuration-assessment'),
                                            selectableActionId: scaid,
                                            selectableActionAriaLabelledby: 'sca-card',
                                            name: 'sca-card'
                                        }}
                                    >
                                        <CardTitle className='home-card-title'> <img src={scaicon} alt='' className={`home-icon-style ${iconClass}`} />  Security Configuration Assessment (CIS)</CardTitle>
                                    </CardHeader>
                                    <CardBody> Scan your assets as part of a configuration assessment audit.</CardBody>
                                </Card>
                            </GridItem>
                            <GridItem md={6} sm={12} lg={6}>
                                <Card isFlat isFullHeight isRounded isClickable>
                                    <CardHeader
                                        selectableActions={{
                                            onClickAction: () => navigate('/policy-monitoring'),
                                            selectableActionId: pmid,
                                            selectableActionAriaLabelledby: 'pmmd-card',
                                            name: 'pmmd-card'
                                        }}
                                    >

                                        <CardTitle className='home-card-title'><img src={policyIcon} alt='' className={`home-icon-style ${iconClass}`} /> Policy Monitoring & Malware Detection</CardTitle>
                                    </CardHeader>
                                    <CardBody>Verify that your systems are configured according to your security policies baseline.</CardBody>
                                </Card>
                            </GridItem>
                            <GridItem md={6} sm={12} lg={6}>
                                <Card isFlat isFullHeight isRounded isClickable>
                                    <CardHeader
                                        selectableActions={{
                                            onClickAction: () => navigate('/integrity-monitoring'),
                                            selectableActionId: fimid,
                                            selectableActionAriaLabelledby: 'fim-card',
                                            name: 'fim-card'
                                        }}
                                    >
                                        <CardTitle className='home-card-title'><img src={integritymonitoricon} alt='' className={`home-icon-style ${iconClass}`} /> File Integrity Monitoring</CardTitle>
                                    </CardHeader>
                                    <CardBody>Alerts related to file changes, including permissions, content, ownership, and attributes.</CardBody>
                                </Card>
                            </GridItem>
                            <GridItem md={6} sm={12} lg={6}>
                                <Card isFlat isFullHeight isRounded isClickable>
                                    <CardHeader
                                        selectableActions={{
                                            onClickAction: () => navigate('/system-auditing'),
                                            selectableActionId: said,
                                            selectableActionAriaLabelledby: 'said-card',
                                            name: 'said-card'
                                        }}
                                    >
                                        <CardTitle className='home-card-title'><img src={systemauditicon} alt='' className={`home-icon-style ${iconClass}`} /> System Auditing</CardTitle>
                                    </CardHeader>
                                    <CardBody>Alert related to write access, read access, execute access, attribute change, or system calls.</CardBody>
                                </Card>
                            </GridItem>
                        </Grid>
                    </CardBody>
                </Card>
            </GridItem>
        </Grid>
        <Grid className="pf-v5-u-mt-sm" hasGutter>
            <GridItem md={6} sm={12} lg={6}>
                <Card isFullHeight>
                    <CardTitle>SECURITY COMPLIANCES</CardTitle>
                    <Divider />
                    <CardBody>  <Grid hasGutter>
                        <GridItem md={6} sm={12} lg={6}>
                            <Card isFlat isFullHeight isRounded isClickable>
                                <CardHeader
                                    selectableActions={{
                                        onClickAction: () => navigate('/pci-dss'),
                                        selectableActionId: pcidssid,
                                        selectableActionAriaLabelledby: 'pcidss-card',
                                        name: 'pcidss-card'
                                    }}
                                >
                                    <CardTitle className='home-card-title'><img src={pcidssicon} alt='' className={`home-icon-style ${iconClass}`} /> PCI DSS</CardTitle>
                                </CardHeader>
                                <CardBody>Global security standard for entities that process, store, or transmit payment cardholder data.</CardBody>
                            </Card>
                        </GridItem>
                        <GridItem md={6} sm={12} lg={6}>
                            <Card isFlat isFullHeight isRounded isClickable>
                                <CardHeader
                                    selectableActions={{
                                        onClickAction: () => navigate('/gdpr'),
                                        selectableActionId: gdprid,
                                        selectableActionAriaLabelledby: 'gdpr-card',
                                        name: 'gdpr-card'
                                    }}
                                >
                                    <CardTitle className='home-card-title'><img src={gdpricon} alt='' className={`home-icon-style ${iconClass}`} />  GDPR</CardTitle>
                                </CardHeader>
                                <CardBody>General Data Protection Regulation (GDPR) sets guidelines for processing of personal data.</CardBody>
                            </Card>
                        </GridItem>
                        <GridItem md={6} sm={12} lg={6}>
                            <Card isFlat isFullHeight isRounded isClickable>
                                <CardHeader
                                    selectableActions={{
                                        onClickAction: () => navigate('/hipaa'),
                                        selectableActionId: hipaaid,
                                        selectableActionAriaLabelledby: 'hipaa-card',
                                        name: 'hipaa-card'
                                    }}
                                >
                                    <CardTitle className='home-card-title'><img src={hipaaicon} alt='' className={`home-icon-style ${iconClass}`} />  HIPAA</CardTitle>
                                </CardHeader>
                                <CardBody>Health Insurance Portability and Accountability Act of 1996 (HIPAA) provides data privacy and security provisions for safeguarding medical information.</CardBody>
                            </Card>
                        </GridItem>
                        <GridItem md={6} sm={12} lg={6}>
                            <Card isFlat isFullHeight isRounded isClickable>
                                <CardHeader
                                    selectableActions={{
                                        onClickAction: () => navigate('/nist_800_53'),
                                        selectableActionId: nistid,
                                        selectableActionAriaLabelledby: 'nist_800_53-card',
                                        name: 'nist_800_53-card'
                                    }}
                                >
                                    <CardTitle className='home-card-title'><img src={nisticon} alt='' className={`home-icon-style ${iconClass}`} />  NIST 800-53</CardTitle>
                                </CardHeader>
                                <CardBody>National Institute of Standards and Technology Special Publication 800-53 (NIST 800-53) sets guidelines for federal information systems.</CardBody>
                            </Card>
                        </GridItem>
                        <GridItem md={6} sm={12} lg={6}>
                            <Card isFlat isFullHeight isRounded isClickable>
                                <CardHeader
                                    selectableActions={{
                                        onClickAction: () => navigate('/tsc'),
                                        selectableActionId: tscid,
                                        selectableActionAriaLabelledby: 'tsc-card',
                                        name: 'tsc-card'
                                    }}
                                >
                                    <CardTitle className='home-card-title'><img src={tscicon} alt='' className={`home-icon-style ${iconClass}`} />  TSC</CardTitle>
                                </CardHeader>
                                <CardBody>Trust Services Criteria for Security, Availability, Processing Integrity, Confidentiality, and Privacy.</CardBody>
                            </Card>
                        </GridItem>
                        <GridItem md={6} sm={12} lg={6}>
                            <Card isFlat isFullHeight isRounded isClickable>
                                <CardHeader
                                    selectableActions={{
                                        onClickAction: () => navigate('/gpg_13'),
                                        selectableActionId: gpg13id,
                                        selectableActionAriaLabelledby: 'gpg_13-card',
                                        name: 'gpg_13-card'
                                    }}
                                >
                                    <CardTitle className='home-card-title'><img src={tscicon} alt='' className={`home-icon-style ${iconClass}`} /> GPG13</CardTitle>
                                </CardHeader>
                                <CardBody>Good practice guide 13 to protect your data from unauthorized access, tampering, or destruction.</CardBody>
                            </Card>
                        </GridItem>
                    </Grid></CardBody>
                </Card>
            </GridItem>
            <GridItem md={6} sm={12} lg={6}>
                <Card isFullHeight>
                    <CardTitle>CLOUD SECURITY
                    </CardTitle>
                    <Divider />
                    <CardBody>  <Grid hasGutter>
                        <GridItem md={6} sm={12} lg={6}>
                            <Card isFlat isFullHeight isRounded isClickable id="clickable-card-example-2" >
                                <CardHeader selectableActions={{
                                    to: '',
                                    selectableActionId: id1,
                                    selectableActionAriaLabelledby: 'clickable-card-example-2',
                                    name: 'clickable-card-example'
                                }}>
                                    <CardTitle className='home-card-title'><img src={dockericon} alt='' className={`home-icon-style ${iconClass}`} /> Docker</CardTitle>
                                </CardHeader>
                                <CardBody>Monitor and collect the activity from Docker containers such as creation, running, starting, stopping or pausing events.</CardBody>
                            </Card>
                        </GridItem>
                        <GridItem md={6} sm={12} lg={6}>
                            <Card isFlat isFullHeight isRounded isClickable id="clickable-card-example-2" >
                                <CardHeader selectableActions={{
                                    to: '',
                                    selectableActionId: id1,
                                    selectableActionAriaLabelledby: 'clickable-card-example-2',
                                    name: 'clickable-card-example'
                                }}>
                                    <CardTitle className='home-card-title'><img src={awsicon} alt='' className={`home-icon-style ${iconClass}`} />  Amazon Web Services</CardTitle>
                                </CardHeader>
                                <CardBody>Security events related to your Amazon AWS services, collected directly via AWS API.</CardBody>
                            </Card>
                        </GridItem>
                        <GridItem md={6} sm={12} lg={6}>
                            <Card isFlat isFullHeight isRounded isClickable id="clickable-card-example-2" >
                                <CardHeader selectableActions={{
                                    to: '',
                                    selectableActionId: id1,
                                    selectableActionAriaLabelledby: 'clickable-card-example-2',
                                    name: 'clickable-card-example'
                                }}>
                                    <CardTitle className='home-card-title'><img src={googlecloudicon} alt='' className={`home-icon-style ${iconClass}`} /> Google Cloud</CardTitle>
                                </CardHeader>
                                <CardBody>Security events related to your Google Cloud Platform services, collected directly via GCP API.</CardBody>
                            </Card>
                        </GridItem>
                        <GridItem md={6} sm={12} lg={6}>
                            <Card isFlat isFullHeight isRounded isClickable id="clickable-card-example-2" >
                                <CardHeader selectableActions={{
                                    to: '',
                                    selectableActionId: id1,
                                    selectableActionAriaLabelledby: 'clickable-card-example-2',
                                    name: 'clickable-card-example'
                                }}>
                                    <CardTitle className='home-card-title'><img src={githubicon} alt='' className={`home-icon-style ${iconClass}`} />  GitHub</CardTitle>
                                </CardHeader>
                                <CardBody>Monitoring events from audit logs of your GitHub organizations.</CardBody>
                            </Card>
                        </GridItem>
                        <GridItem md={6} sm={12} lg={6}>
                            <Card isFlat isFullHeight isRounded isClickable id="clickable-card-example-2" >
                                <CardHeader selectableActions={{
                                    to: '',
                                    selectableActionId: id1,
                                    selectableActionAriaLabelledby: 'clickable-card-example-2',
                                    name: 'clickable-card-example'
                                }}>
                                    <CardTitle className='home-card-title'><img src={office365icon} alt='' className={`home-icon-style ${iconClass}`} /> Office 365</CardTitle>
                                </CardHeader>
                                <CardBody>Security events related to your Office 365 services.</CardBody>
                            </Card>
                        </GridItem>
                    </Grid></CardBody>
                </Card>
            </GridItem>

        </Grid>
    </div>
    )
}

const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    theme: state.theme
    // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {}; };

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(AppHome)