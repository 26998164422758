import React, { useEffect, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
} from "@patternfly/react-table";
import { Emptystatefornodata } from "../../util/EmptyStateCard";
import { Label, LabelGroup, Pagination, Toolbar, ToolbarContent, ToolbarItem } from "@patternfly/react-core";
import { connect, useDispatch } from "react-redux";
import { FilterData } from "../../Redux/Action";
import { UpdateFilterData } from "../../util/FilterHelper";

const GdprlastAlertTable = (props) => {

  const dispatch = useDispatch();
  const [columns, setColumns] = useState(props.columnData);
  const [defaultRows, setDefaultRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);//props.vulPerPage??20
  const [selectedRowName, setSelectedRowName] = useState('');
  const handleSetPage = (_evt, newPage, perPage, startIdx, endIdx) => {
    // setPaginatedRows(rows.slice(startIdx, endIdx));
    setPage(newPage);
  };
  const handlePerPageSelect = (_evt, newPerPage, newPage) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };
  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }

  useEffect(() => {
    const newdefaultRows = props.Tabledata.sort((a, b) => b.doc_count - a.doc_count).map((log) => {
      return {

        key: log.key,
        count: log.doc_count, 
        reqbyagent:log.Top_Agent
      };
    });
    setDefaultRows(newdefaultRows)
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    // if (defaultRows.length !== rows.length) {
    //   const slicedRows = defaultRows;
    setRows(slicedRows);
    // }
  }, [props.Tabledata]);

  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    // console.log("after slicing", "", startIndex, endIndex, slicedRows)
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);


  const renderPagination = (variant) =>
    <Pagination
      itemCount={defaultRows.length}
      page={page}
      perPage={perPage}
      onSetPage={handleSetPage}
      onPerPageSelect={handlePerPageSelect}
      variant={variant}
    />;
  return (
    <React.Fragment>
      {props.Tabledata.length === 0 ? (
        <Emptystatefornodata />
      ) : (
        <>
          <Toolbar>
            <ToolbarContent>
              <ToolbarItem variant="pagination">{props.Pagination !== false && renderPagination('top')}</ToolbarItem>
            </ToolbarContent>
          </Toolbar>
          <Table variant="compact" aria-label="IPlog" id="IPlog-table" isStickyHeader gridBreakPoint="">
            <Thead >
              <Tr key={'cahrt-table-header'}>
                {columns.map((column, index) => (
                  <Th key={column + index}>{column}</Th>
                ))}
              </Tr>
            </Thead>
            {rows.length > 0 && (rows.map((row, rowIndex) => {
              const effectiveIndex = (page - 1) * perPage + rowIndex;
              const BackgroundColor = page === 1 && effectiveIndex < 10;
              const Fields = props.filterFields ? props.filterFields : row?.typeofkey
              const filterKey = props.filterFields === 'rule.id' ? row?.ruleid : row?.key
              return (
                <Tbody key={rowIndex + 'tablebody'}>
                  <Tr key={rowIndex + 'row'}
                    onRowClick={() => setSelectedRowName(rowIndex)}
                    isSelectable
                    isClickable
                    isRowSelected={selectedRowName === rowIndex}
                    className={BackgroundColor ? "pf-v5-u-background-color-danger" : ""} >
                    <Td width={30}  key={rowIndex + 'requirement'}>{row?.key}
                    </Td>
                    <Td width={60} key={rowIndex + 'reqofagent'}>
                      {row?.reqbyagent !== undefined && row?.reqbyagent !== null ?
                        (<LabelGroup numLabels={5} >
                          {row?.reqbyagent.map((item, index) => (
                            <Label isCompact key={"label" + index} onClick={() => { handleFilter('agent.name', item?.key) }} color='blue'>{item.key}</Label>
                          ))}
                        </LabelGroup>) : null}
                    </Td>
                    
                    <Td width={10} key={rowIndex + 'rulecount'}><a onClick={() => { handleFilter(Fields, filterKey) }}>{row?.count}</a></Td>

                  </Tr>
                </Tbody>
              )
            }
            ))}
          </Table>
          {props.Pagination !== false && renderPagination('bottom')}
        </>
      )}
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  // mapStateToProps content remains unchanged if you have it
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(GdprlastAlertTable)
