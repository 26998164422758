import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Divider,
    Flex,
    Grid,
    GridItem,
    Icon,
    Modal,
    ModalVariant,
    Tabs,
    Tab,
    TabTitleText,
    TabContentBody,
} from '@patternfly/react-core'
import dbApi from '../../services/DbApi'
import { connect } from 'react-redux'
import EDonutChart from '../AllCharts/EDonutChart'
import { Loader } from 'src/util/Loadingstate'
import TableViewForChart from '../AllCharts/TableViewForChart'
import { Emptystatefornodata } from 'src/util/EmptyStateCard'
import ReqByAgentChart from './ReqByAgentChart'
import PciDsslastalertsTable from './PciDsslastalertsTable'
import ReqbyAgentDetailedTable from './ReqbyAgentDetailedTable'
import EHeatmapChart from '../AllCharts/EHeatmapChart'
import EScatterChart from '../AllCharts/EScatterChart'
const PciDssDashboard = forwardRef(function PciDssDashboard(props, ref) {
    const [actionseries, setActionseries] = useState([]);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [pciRequirement, setPciRequirement] = useState([])
    const [pciReqlabel, setPciReqlabel] = useState('');
    const [agent, setAgent] = useState([])
    const [agentbyreq, setAgentbyReq] = useState([])
    const [lastalerts, setLastAlerts] = useState([])
    // //Loading states
    const [isloadAgent, setIsLoadAgent] = useState(false)
    const [isloadPci, setIsLoadPci] = useState(false)
    const [isloadreqbyAgent, setIsloadreqbyAgent] = useState(false)
    const [loadlastalerts, setLoadLastalerts] = useState(false)

    //modal states
    const [modalStates, setModalStates] = useState({
        modalAgents: false,
        modalreqbyAgent: false,
        modallastalerts: false,
    });
    const handleModalToggle = (modalName) => {
        setModalStates((prevStates) => ({
            ...prevStates,
            [modalName]: !prevStates[modalName],
        }));
    };

    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex)
    }


    const getPciTopRequirement = async (data) => {
        try {
            setIsLoadPci(true)
            let TotalPciData = await dbApi.postApi("/SecurityCompliances/Security_Pci_Idss_Requirements", data)
            console.log('pci data', TotalPciData)
            let PciRequirement = []
            let label = "";
            let pci_requirement = TotalPciData.Security_Compliances_Pci_Idss_Requirements
            if (Object.keys(pci_requirement).length !== 0) {
                label = pci_requirement.interval
                PciRequirement = pci_requirement.data.body.aggregations.Rule_pci.buckets
                setPciRequirement(PciRequirement)
                setPciReqlabel(label)
            }
            else {
                setPciRequirement(PciRequirement)
                setPciReqlabel(label)
            }
            setIsLoadPci(false)
        } catch (error) {
            console.log(error)
            setPciRequirement([])
            setIsLoadPci(false)
        }
    }
    const getAgentsByalerts = async (data) => {
        try {
            setIsLoadAgent(true)
            let res = await dbApi.postApi("SecurityCompliances/Security_Compliances_Agent_By_Alerts_Number ", data);
            console.log(res)
            let agents = [];
            let allagents = res.Security_Compliances_Agent_By_Alerts_Number;
            if (Object.keys(allagents).length !== 0) {
                agents = allagents.body.aggregations.Top_Agents.buckets
                setAgent(agents)
            }
            else {
                setAgent(agents)
            }
            setIsLoadAgent(false)
        } catch (error) {
            console.log(error)
            setAgent([])
            setIsLoadAgent(false)
        }
    }

    // SecurityCompliances/Security_Compliances_Last_Alerts
    const getLastAlerts = async (data) => {
        try {
            setLoadLastalerts(true)
            let res = await dbApi.postApi("SecurityCompliances/Security_Compliances_Last_Alerts", data);
            console.log(res)
            let alerts = [];
            if (Object.keys(res.Security_Compliances_Last_Alerts).length !== 0) {
                alerts = res.Security_Compliances_Last_Alerts.body.aggregations.rule_pci.buckets
                setLastAlerts(alerts)
            }
            else {
                setLastAlerts(alerts)
            }
            setLoadLastalerts(false)
        }
        catch (error) {
            console.log(error)
            setLastAlerts([])
            setLoadLastalerts(false)
        }
    }
    // SecurityCompliances/Security_Compliances_Requirements_By_Agent 
    const getRequirementByAgent = async (data) => {
        try {
            setIsloadreqbyAgent(true)
            let res = await dbApi.postApi("SecurityCompliances/Security_Compliances_Requirements_By_Agent", data);
            console.log(res)
            let agentsreq = [];
            if (Object.keys(res.Security_Compliances_Requirements_By_Agent).length !== 0) {
                let agentsreq = res.Security_Compliances_Requirements_By_Agent.body.aggregations.Top_rule_agents.buckets
                // Security_Compliances_Requirements_By_Agent.body.aggregations.Top_rule_agents.buckets[0].Top_Agent.buckets
                setAgentbyReq(agentsreq)
            }
            else {
                setAgentbyReq(agentsreq)
            }
            setIsloadreqbyAgent(false)
        }
        catch (error) {
            console.log(error)
            setAgentbyReq([])
            setIsloadreqbyAgent(false)
        }
    }

    const PciDssDashboardApi = async (startDate, endDate, allFields) => {
        const Fields = allFields
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
        };
        console.log(data)
        try {
            setStartDate(startDate);
            setEndDate(endDate);
            getPciTopRequirement(data);
            getAgentsByalerts(data);
            getRequirementByAgent(data);
            getLastAlerts(data)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (props.dates.startDate != "") {
            let Dates = props.dates
            PciDssDashboardApi(Dates.startDate, Dates.endDate, props.filterformdata)
        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata]);

    const TopAgents = useMemo(() => {
        return (isloadAgent ?
            <Loader /> :
            agent.length == 0 ? <Emptystatefornodata /> :
                <EDonutChart seriesData={agent.slice(0, 10)} height={"310px"} color={['#519DE9', '#004B95', '#8BC1F7', '#06C', '#002F5D']} filterFields={'agent.name'} />
        )
    }, [isloadAgent, agent])

    const LastAlerts = useMemo(() => {
        return (loadlastalerts ? <Loader /> :
            <EHeatmapChart SeriesData={lastalerts.slice(0, 10)} filterFields={'rule.pci_dss'} height={'280px'} />
        )
    }, [loadlastalerts, lastalerts])

    return (<>
        <Grid hasGutter className="pf-v5-u-mt-md">
            <GridItem md={8} sm={12} lg={8}>
                <Card isFullHeight>
                    <CardTitle > Top 10 PCI DSS Requirements</CardTitle>
                    <Divider />
                    <CardBody >
                        {isloadPci ?
                            <Loader /> : pciRequirement.length == 0 ? <Emptystatefornodata /> :
                                <EScatterChart
                                    seriesData={pciRequirement.slice(0, 10)}
                                    label={pciReqlabel}
                                    StartDateTime={startDate}
                                    EndDateTime={endDate}
                                    height={'310px'}
                                    filterFields={'rule.pci_dss'}
                                />
                        }
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem md={4} sm={12} lg={4}>
                <Card isFullHeight>
                    <CardTitle > Top 10 Agents By Alerts Number</CardTitle>
                    <Divider />
                    <CardBody > {TopAgents} </CardBody>
                    {agent.length > 0 &&
                        <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalAgents'); }}>
                                    More
                                </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    title={`Agents By Alerts Number (${agent.length})`}
                                    isOpen={modalStates.modalAgents}
                                    onClose={() => handleModalToggle('modalAgents')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>
                                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                            <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>} >
                                                <TabContentBody className='pf-v5-u-p-xs'>
                                                    {isloadAgent ? <Loader /> : <TableViewForChart Tabledata={agent} columnData={["Agents", "Count"]} filterFields={'agent.name'} />}
                                                </TabContentBody>
                                            </Tab>
                                            <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                                                    <EDonutChart seriesData={agent.slice(0, 20)} height="350px" color={['#519DE9', '#004B95', '#8BC1F7', '#06C', '#002F5D']} filterFields={'agent.name'} />
                                                </TabContentBody>}
                                            </Tab>
                                        </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>
                    }
                </Card>
            </GridItem>
        </Grid>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={12} sm={12} lg={12}>
                    <Card isFullHeight>
                        <CardTitle > Last Alerts</CardTitle>
                        <Divider />
                        <CardBody >{LastAlerts}</CardBody>
                        {lastalerts.length > 0 &&
                            <CardFooter>
                                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                    <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modallastalerts'); }}>
                                        More
                                    </Button>
                                    <Modal
                                        variant={ModalVariant.large}
                                        title={`Last Alerts (${lastalerts.length})`}
                                        isOpen={modalStates.modallastalerts}
                                        onClose={() => handleModalToggle('modallastalerts')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                                <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>} >
                                                    <TabContentBody className='pf-v5-u-p-xs'>
                                                        {loadlastalerts ? <Loader /> : <PciDsslastalertsTable Tabledata={lastalerts} columnData={["Agents", "Requirements", "Count"]} filterFields={'rule.pci_dss'} />}
                                                    </TabContentBody>
                                                </Tab>
                                                <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                    {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                                                        <EHeatmapChart SeriesData={lastalerts.slice(0, 20)} filterFields={'rule.pci_dss'} height={'470px'} />:
                                                    </TabContentBody>}
                                                </Tab>
                                            </Tabs>
                                        </Card>
                                    </Modal>
                                </Flex>
                            </CardFooter>
                        }
                    </Card>
                </GridItem>
            </Grid>
        </div>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={12} sm={12} lg={12}>
                    <Card isFullHeight>
                        <CardTitle >Requirements By Agent</CardTitle>
                        <Divider />
                        <CardBody>
                            {isloadreqbyAgent ? <Loader /> : agentbyreq.length > 0 ?
                                <ReqByAgentChart seriesData={agentbyreq.slice(0, 10)} filterFields={'agent.name'} height={'250px'} /> :
                                <Emptystatefornodata />
                            }
                        </CardBody>
                        {agentbyreq.length > 0 &&
                            <CardFooter>
                                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                    <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalreqbyAgent'); }}>
                                        More
                                    </Button>
                                    <Modal
                                        variant={ModalVariant.large}
                                        title={`Requirements By Agent (${agentbyreq.length})`}
                                        isOpen={modalStates.modalreqbyAgent}
                                        onClose={() => handleModalToggle('modalreqbyAgent')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                                <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>} >
                                                    <TabContentBody className='pf-v5-u-p-xs'>
                                                        {isloadreqbyAgent ? <Loader /> : <ReqbyAgentDetailedTable Tabledata={agentbyreq} columnData={["Requirement", "Agents", "Count"]} filterFields={'rule.pci_dss'} />}
                                                    </TabContentBody>
                                                </Tab>
                                                <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                    {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                                                        <ReqByAgentChart seriesData={agentbyreq.slice(0, 20)} filterFields={'agent.name'} height={'370px'} /> :
                                                    </TabContentBody>}
                                                </Tab>
                                            </Tabs>
                                        </Card>
                                    </Modal>
                                </Flex>
                            </CardFooter>
                        }
                    </Card>
                </GridItem>
            </Grid>
        </div>
    </>
    )
}
)
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} }
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(PciDssDashboard)

