import IntegrityMonitoring from '../Components/IntegrityMonitoring/IntegrityMonitoring'
import PolicyMonitoring from '../Components/PolicyMonitoring/PolicyMonitoring'
import MitreAttack from '../Components/MitreAttacks/MitreAttack'
import Agents from '../Components/Agent/Agent'
import SecurityConfiguration from '../Components/SecurityConfiguration/SecurityConfiguration'
import SystemAuditing from '../Components/SystemAuditing/SystemAuditing'
import Osquery from '../Components/OSQuery/Osquery'
import UBMDashboard from '../Components/UserBehaviour/UBMDashboard'
import NetworkInsight from '../Components/NetworkInsight/NetworkInsight'
import ThreatIntel from '../Components/ThreatIntelPlatform/ThreatIntel'
import UserList from '../Components/UserManagement/UserList'
import UserProfile from '../Components/UserManagement/UserProfile'
import AgentHome from '../Components/IndividualDashes/AgentHome'
import Vulnerabilities from '../Components/Vulnerabilities/Vulnerabilities'
import WatchList from '../Components/WatchList/Watchlist'
import SecurityDashEvents from '../Components/SecurityEvents/SEScreen'
import Testing from '../Components/TestingExample/Testing'
import GroupAgent from '../Components/GroupManagement/GroupAgent'
import RealTimeDashboard from '../Components/RealTimeDash/RealTimeDashboard'
import OnBoardingMainpage from 'src/Components/OnBoarding/OnBoardingMainpage'
import OrganizationList from 'src/Components/OnBoarding/OrganizationList'
import GroupConfigEditor from 'src/Components/GroupManagement/GroupConfigEditor'
import ManageGroupAgent from 'src/Components/GroupManagement/ManageGroupAgent'
import CdbLists from 'src/Components/CDBLists/CdbLists'
import RuleList from 'src/Components/ManageRules/RulesList'
import PciDss from 'src/Components/PciDss/PcidssMain'
import GdprMain from 'src/Components/Gdpr/GdprMain'
import HipaaMain from 'src/Components/Hipaa/HipaaMain'
import NistMain from 'src/Components/Nist800-53/NistMain'
import TscMain from 'src/Components/Tsc/TscMain'
import GpgMain from 'src/Components/Gpg13/GpgMain'
import Groups from 'src/Components/GroupManagement/Groups'
import IncidentManagement from 'src/Components/IncidentManagement/IncidentManagement'
import IncidentAlertMain from 'src/Components/IncidentManagement/IncidentAlert/IndicentAlertMain'
import IncidentTaskMain from 'src/Components/IncidentManagement/IncidentTasks/IncidentTaskMain'
import IncidentDetailMain from 'src/Components/IncidentManagement/IncidentDetail/IncidentDetailMain'
import AppHome from 'src/Layout/AppHome'
import IndexManagementMain from 'src/Components/IndexManagement/IndexManagementMain'
import RbiMain from 'src/Components/RbiComponent/RbiMain'

const routes = [
  // AppHome
  { path: '/', name: 'AppHome', roles: ['Admin', 'User', 'Investigator'], element: AppHome },
  { path: '/home', name: 'AppHome', roles: ['Admin', 'User', 'Investigator'], element: AppHome },
  { path: '/security-events', name: 'SecurityDashEvents', roles: ['Admin', 'User', 'Investigator'], element: SecurityDashEvents },
  { path: '/vulnerabilities', name: 'Vulnerabilities', roles: ['Admin', 'User', 'Investigator'], element: Vulnerabilities },
  { path: '/mitre-attack', name: 'MitreAttack', roles: ['Admin', 'User', 'Investigator'], element: MitreAttack },
  { path: '/osquery', name: 'Osquery', roles: ['Admin', 'User', 'Investigator'], element: Osquery },
  { path: '/threat-intel-platform-engine', name: 'ThreatIntel', roles: ['Admin', 'User', 'Investigator'], element: ThreatIntel },
  { path: '/security-configuration-assessment', name: 'SecurityConfiguration', roles: ['Admin', 'User', 'Investigator'], element: SecurityConfiguration },
  { path: '/integrity-monitoring', name: 'IntegrityMonitoring', roles: ['Admin', 'User', 'Investigator'], element: IntegrityMonitoring },
  { path: '/policy-monitoring', name: 'PolicyMonitoring', roles: ['Admin', 'User', 'Investigator'], element: PolicyMonitoring },
  { path: '/system-auditing', name: 'SystemAuditing', roles: ['Admin', 'User', 'Investigator'], element: SystemAuditing },
  { path: '/pci-dss', name: 'PciDss', roles: ['Admin', 'User', 'Investigator'], element: PciDss },
  { path: '/rbi', name: 'RBI', roles: ['Admin', 'User', 'Investigator'], element: RbiMain },
  { path: '/gdpr', name: 'GdprMain', roles: ['Admin', 'User', 'Investigator'], element: GdprMain },
  { path: '/hipaa', name: 'HipaaMain', roles: ['Admin', 'User', 'Investigator'], element: HipaaMain },
  { path: '/nist_800_53', name: 'NistMain', roles: ['Admin', 'User', 'Investigator'], element: NistMain },
  { path: '/tsc', name: 'TscMain', roles: ['Admin', 'User', 'Investigator'], element: TscMain },
  { path: '/gpg_13', name: 'GpgMain', roles: ['Admin', 'User', 'Investigator'], element: GpgMain },
  { path: '/network-insights', name: 'NetworkInsight', roles: ['Admin', 'User', 'Investigator'], element: NetworkInsight },
  { path: '/user-behaviour-monitoring', name: 'UBMDashboard', roles: ['Admin', 'User', 'Investigator'], element: UBMDashboard },
  { path: '/individual-agent-dashboard/:agentname/:agentId', name: 'AgentHome', roles: ['Admin', 'User', 'Investigator'], element: AgentHome },
  //userprofile management
  { path: '/user-profile/:userid/:username', name: 'UserProfile', roles: ['Admin', 'User', 'Investigator', 'Analyst'], element: UserProfile },
  { path: '/user-profile', name: 'UserProfile', roles: ['Admin', 'User', 'Investigator'], element: UserProfile },

  //only Admin roles routing 
  { path: '/agents', name: 'Agents', roles: ['Admin'], element: Agents },
  { path: '/management/groups', name: 'Groups', roles: ['Admin'], element: Groups },
  { path: '/management/groups-agent/:groupname', name: 'GroupAgent', roles: ['Admin'], element: GroupAgent },
  { path: '/management/groups-config/:groupname/:filename/:id', name: ' GroupConfigEditor', roles: ['Admin'], element: GroupConfigEditor },
  { path: '/management/manage-group/:groupname', name: 'ManageGroupAgent', roles: ['Admin'], element: ManageGroupAgent },
  { path: '/cdb-list', name: 'CdbLists', roles: ['Admin'], element: CdbLists },
  { path: '/rules-list', name: 'RuleList', roles: ['Admin'], element: RuleList },
  { path: '/index-management', name: 'IndexManagement', roles: ['Admin'], element: IndexManagementMain },
  { path: '/onboardingform', name: 'OnBoardingMainpage', roles: ['Admin'], element: OnBoardingMainpage },
  { path: '/organizations-management', name: 'OrganizationList', roles: ['Admin'], element: OrganizationList },
  { path: '/user-management', name: 'UserList', roles: ['Admin'], element: UserList },
  { path: '/testing', name: 'Testing', roles: ['Admin'], element: Testing },

  //comment for bank build
  { path: '/incident-management', name: 'IncidentManagement', roles: ['Admin', 'User', 'Investigator', 'Analyst'], element: IncidentManagement },
  { path: '/incident-management/:tabname', name: 'IncidentManagement', roles: ['Admin', 'User', 'Investigator', 'Analyst'], element: IncidentManagement },
  { path: '/incident-detail/:id', name: 'IncidentManagement', roles: ['Admin', 'User', 'Investigator', 'Analyst'], element: IncidentDetailMain },
  { path: '/incident-detail/:id/alert/:alertid', name: 'IncidentManagement', roles: ['Admin', 'User', 'Investigator', 'Analyst'], element: IncidentDetailMain },
  { path: '/incident-detail/:id/task/:taskid', name: 'IncidentManagement', roles: ['Admin', 'User', 'Investigator', 'Analyst'], element: IncidentDetailMain },
  { path: '/alerts', name: 'IncidentAlertMain', roles: ['Admin', 'User', 'Investigator', 'Analyst'], element: IncidentAlertMain },
  { path: '/tasks', name: 'IncidentTaskMain', roles: ['Admin', 'User', 'Investigator', 'Analyst'], element: IncidentTaskMain },

  // Real-Time-Dashboard
  { path: '/real-time-dashboard', name: 'Realtimedash', roles: ['Admin', 'User'], element: RealTimeDashboard },
  { path: '/watchlist', name: 'WatchList', roles: ['Admin', 'User',], element: WatchList },

]

export default routes