import React, { useEffect, useState } from 'react'
import {
    Card,
    Drawer,
    DrawerContent,
    DrawerContentBody,
    DrawerPanelContent,
    FlexItem,
    Tabs,
    Tab,
    TabTitleText,

} from '@patternfly/react-core'
import dbApi from '../../services/DbApi'
import LatestVulnerabilitiesCve from './LatestVulnerabilities'
import { connect } from 'react-redux'
import { Loader } from 'src/util/Loadingstate'
import TableViewForChart from '../AllCharts/TableViewForChart'


const Vulnerabilitytablepage = (props) => {
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [cveagent, setCveAgent] = useState([])
    const [cvepackage, setCvePackage] = useState([])
    const [isloadCve, setIsLoadCve] = useState(false)
    const [isloadpackage, setIsloadPackage] = useState(false)
    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex)
    }
    const getTopVulnerableAssets = async (data) => {
        try {
            setIsLoadCve(true)
            let vulnerable_agents = await dbApi.postApi("opensearch/get_top_vulnerability_agents", data)
            console.log(vulnerable_agents);
            let CveAgent = []
            if (Object.keys(vulnerable_agents.data).length > 0) {
                CveAgent = vulnerable_agents.data.map((item) =>
                ({
                    key: item.agent_name,
                    count: item.total
                }))
               
                setCveAgent(CveAgent)
            }
            else {
                setCveAgent(CveAgent)
            }
            setIsLoadCve(false)
        }
        catch (error) {
            console.log(error)
           
            setCveAgent([])
            setIsLoadCve(false)
        }
    }
    const getPackageWisedata = async (data) => {
        try {
            setIsloadPackage(true)
            let res = await dbApi.postApi("opensearch/get_top_vulnerability_cve", { ...data, cveType: 'name' })
            console.log(res)
            let CvePackage = []
            if (!res.error && res.data.length > 0) {
                CvePackage = res.data.map((item) => ({
                    key: item.cve_title,
                    count: item.total,
                }))
                setCvePackage(CvePackage)
            }
            else {
                setCvePackage(CvePackage)
            }
            setIsloadPackage(false)

        } catch (error) {
            console.log(error)
            setCvePackage([])
            setIsloadPackage(false)
        }
    }

    const latestCveTableApi = async (startDate, endDate, allFields) => {
        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(allFields),
        };
        // console.log(data)
        try {
            setStartDate(startDate);
            setEndDate(endDate)
            getPackageWisedata(data)
            getTopVulnerableAssets(data);

        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (props.dates.startDate != "") {
            let Dates = props.dates
            latestCveTableApi(Dates.startDate, Dates.endDate, props.filterformdata)
        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata]);

    return (
        <div>
            <Card isFlat isRounded>
                <Drawer isStatic>
                    <DrawerContent panelContent={
                        <DrawerPanelContent isResizable defaultSize={'80%'} minSize={'600px'}>
                            <LatestVulnerabilitiesCve IsModal={true} />
                        </DrawerPanelContent>

                    }>
                        <DrawerContentBody >
                            <Card isFullHeight>
                                <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                    <Tab eventKey={0} title={<TabTitleText>By Agent </TabTitleText>} aria-label="graph">
                                        {isloadCve ? <Loader /> : <TableViewForChart Tabledata={cveagent} columnData={["Agent Name", "Count"]} filterFields={'agent.name'} vulPerPage={10} />}
                                    </Tab>
                                    <Tab eventKey={1} title={<TabTitleText>By Package Name  </TabTitleText>}>
                                        {isloadpackage ? <Loader /> : <TableViewForChart Tabledata={cvepackage} columnData={["Package Name", "Count"]} filterFields={"data.vulnerability.package.name"} vulPerPage={10} />}
                                    </Tab>
                                </Tabs>
                            </Card>
                        </DrawerContentBody>
                    </DrawerContent>
                </Drawer>
            </Card>
        </div>
    )
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate, selectedtime: state.Term },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,

    // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} }

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(Vulnerabilitytablepage)