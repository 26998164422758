import React, { useState, useRef, useEffect } from 'react';
import {Flex,Card, CardBody, Grid, GridItem, Title, Badge} from '@patternfly/react-core';
import { Loader } from 'src/util/Loadingstate';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import { formatTableTimestamp } from 'src/util/Timeutils';
import AlertLogModal from '../IncidentAlert/AlertLogModal';

const IncidentDetailAlert = (props) => {
    const childAlertModelRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [alertData, setAlertData] = useState({});
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
    });
   
    useEffect(() => {
        if (typeof props.alertData != "undefined") {
            setAlertData(props.alertData)
        }
    }, [props.alertData])

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }}>
                        <Flex spaceItems={{ default: 'spaceItemsXs' }}>
                        </Flex>
                    </Flex>

                    {isLoading ? (
                        <Loader />
                    ) : (<> <Grid className='pf-v5-u-mt-md'>
                        <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                            <Title headingLevel="h6">Doc Id:</Title>
                        </GridItem>
                        <GridItem lg={6}>{alertData?.doc_id}</GridItem>
                    </Grid>

                        <Grid className='pf-v5-u-mt-md'>
                            <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                <Title headingLevel="h6">Agent Id:</Title>
                            </GridItem>
                            <GridItem lg={6}>{alertData?.agent_id}</GridItem>
                        </Grid>

                        <Grid className='pf-v5-u-mt-md'>
                            <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                <Title headingLevel="h6">Agent Name:</Title>
                            </GridItem>
                            <GridItem lg={6}>{alertData?.agent_name}</GridItem>
                        </Grid>

                        <Grid className='pf-v5-u-mt-md'>
                            <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                <Title headingLevel="h6">Agent Label Group:</Title>
                            </GridItem>
                            <GridItem lg={6}>{alertData?.agent_label_group}</GridItem>
                        </Grid>

                        <Grid className='pf-v5-u-mt-md'>
                            <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                <Title headingLevel="h6">Rule Level:</Title>
                            </GridItem>
                            <GridItem lg={6}>{alertData?.rule_level}</GridItem>
                        </Grid>

                        <Grid className='pf-v5-u-mt-md'>
                            <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                <Title headingLevel="h6">Rule Description:</Title>
                            </GridItem>
                            <GridItem lg={6}>{alertData?.rule_description}</GridItem>
                        </Grid>

                        {
                            alertData?.event_target_user_name != null && <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                    <Title headingLevel="h6">Event Target User Name:</Title>
                                </GridItem>
                                <GridItem lg={6}>{alertData?.event_target_user_name}</GridItem>
                            </Grid>
                        }

                        {
                            alertData?.event_ip_address != null && <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                    <Title headingLevel="h6">Event IP Address:</Title>
                                </GridItem>
                                <GridItem lg={6}>{alertData?.event_ip_address}</GridItem>
                            </Grid>
                        }

                        {
                            alertData?.event_logon_process_name != null && <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                    <Title headingLevel="h6">Event LogOn Process Name:</Title>
                                </GridItem>
                                <GridItem lg={6}>{alertData?.event_logon_process_name}</GridItem>
                            </Grid>
                        }

                        {
                            alertData?.url != null && <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                    <Title headingLevel="h6">URL:</Title>
                                </GridItem>
                                <GridItem lg={6}>{alertData?.url}</GridItem>
                            </Grid>
                        }

                        {
                            alertData?.source_ip != null && <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                    <Title headingLevel="h6">Source IP:</Title>
                                </GridItem>
                                <GridItem lg={6}>{alertData?.source_ip}</GridItem>
                            </Grid>
                        }

                        {
                            alertData?.event_destination_ip != null && <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                    <Title headingLevel="h6">Event Destination IP:</Title>
                                </GridItem>
                                <GridItem lg={6}>{alertData?.event_destination_ip}</GridItem>
                            </Grid>
                        }

                        {
                            alertData?.event_source_ip != null && <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                    <Title headingLevel="h6">Event Source IP:</Title>
                                </GridItem>
                                <GridItem lg={6}>{alertData?.event_source_ip}</GridItem>
                            </Grid>
                        }

                        {
                            alertData?.destination_user != null && <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                    <Title headingLevel="h6">Destination IP:</Title>
                                </GridItem>
                                <GridItem lg={6}>{alertData?.destination_user}</GridItem>
                            </Grid>
                        }

                        {
                            alertData?.event_parameter4 != null && <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                    <Title headingLevel="h6">Event Parameter 4:</Title>
                                </GridItem>
                                <GridItem lg={6}>{alertData?.event_parameter4}</GridItem>
                            </Grid>
                        }

                        {
                            alertData?.query != null && <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                    <Title headingLevel="h6">Query:</Title>
                                </GridItem>
                                <GridItem lg={6}>{alertData?.query}</GridItem>
                            </Grid>
                        }

                        {
                            alertData?.log_arrive_datetime != null && <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                    <Title headingLevel="h6">Timestamp:</Title>
                                </GridItem>
                                <GridItem lg={6}>{((arriveDatetime) => {
                                    const { formattedTimeStamp: createDatetime } = formatTableTimestamp(arriveDatetime);
                                    return createDatetime;
                                })(alertData.log_arrive_datetime)}</GridItem>
                            </Grid>
                        }

                        {
                            alertData?.log_arrive_datetime != null && <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                    <Title headingLevel="h6">Read Status:</Title>
                                </GridItem>
                                <GridItem lg={6}>{
                                    <Badge
                                        isRead={alertData.read_status === 1}
                                        style={{ backgroundColor: alertData.read_status === 1 ? '#38812F' : '#C9190B', color: 'white' }}
                                    >
                                        {alertData.read_status === 1 ? 'Read' : 'Unread'}
                                    </Badge>
                                }</GridItem>
                            </Grid>
                        }

                        {
                            <Grid className='pf-v5-u-mt-md'>
                                <GridItem lg={3} className="pf-v5-u-font-weight-bold">
                                    Alert Detail:
                                </GridItem>
                                <GridItem lg={6}>
                                    <a href="javascript:void(0);" onClick={() => { childAlertModelRef.current.getAlertDetail(alertData.unique_id) }}> More... </a>
                                    {<AlertLogModal ref={childAlertModelRef} />}
                                </GridItem>
                            </Grid>
                        }
                    </>)}
                </CardBody>
            </Card>
            <ConfirmBox confirmModal={confirmModalData} />
        </React.Fragment>
    );
};

export default IncidentDetailAlert;
