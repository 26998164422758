//2nd which is perfectly run and usable
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  ExpandableRowContent,
  Table,
} from "@patternfly/react-table";
import {
  DescriptionList,
  DescriptionListDescription,
  DescriptionListGroup,
  DescriptionListTerm,
  Tab,
  Tabs,
  TabTitleText,
  TabContentBody,
  Toolbar,
  ToolbarItem,
  ToolbarContent,
  Bullseye,
  EmptyState,
  EmptyStateHeader,
  EmptyStateIcon,
  EmptyStateBody,
  EmptyStateFooter,
  EmptyStateActions,
  Button,
  Pagination,
  ToolbarGroup,
  Flex,
  FlexItem,
} from "@patternfly/react-core";
import { SearchIcon, } from "@patternfly/react-icons";
import dbApi from '../../services/DbApi'
import { formatTableTimestamp } from "../../util/Timeutils";
import { Link } from "react-router-dom";
// const { forwardRef, useRef, useImperativeHandle } = React;
export const AuthenticationFailureLog = forwardRef((props, ref) => {

  const [AuthFailuredata, setAuthFailuredata] = React.useState([])
  const [ScrollID, setScrollID] = React.useState('')
  useImperativeHandle(ref, () => ({
    setAuthFailureTable(data = [], scrollId = '') {
      setBeforescrolldata([])
      setAuthFailuredata(data)
      setScrollID(scrollId)
    }
  }));

  const resetPagination = () => {
    setPage(1);
    setPerPage(10); // Set to your desired default perPage value
  };

  const defaultColumns = [
    "Time",
    "Agent Name",
    "Rule Description",
  ];
  const [itemcount, setItemcount] = useState(0)
  const [defaultRows, setDefaultRows] = useState([]);
  const [scrolldata, setScrollData] = useState([]);
  const [beforescrolldata, setBeforescrolldata] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const [rows, setRows] = useState([]);
  const [activeTabKey, setActiveTabKey] = React.useState(0);
  const initialExpandedRowNames = [];
  const [areAllExpanded, setAreAllExpanded] = React.useState(false);
  const [collapseAllAriaLabel, setCollapseAllAriaLabel] = React.useState("Expand all");
  const [expandedRowNames, setExpandedRowNames] = React.useState(initialExpandedRowNames);
  const [expandedRowData, setExpandedRowData] = useState({});
  const [selectedRowName, setSelectedRowName] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('')
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(20);
  const [isloadnext, setIsloadNext] = useState(false)
  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex);
  };

  //scroll Api for pagination
  const AuthFailureApi = async (ScrollID) => {
    const data = {
      scroll_Id: ScrollID
    }
    try {
      setIsloadNext(true)
      let Scrolldata = await dbApi.postApi("Opensearch/Latest_scroll", data)
      console.log(Scrolldata)
      const nextdata = Scrolldata.Latest_scroll
      let scrolldata = []
      if (nextdata.length > 0) {
        scrolldata = nextdata
      }
      setScrollData(scrolldata);
      setIsloadNext(false)
    }
    catch (error) {
      console.log(error)
      setIsloadNext(false)
    }
  }
  const onSetPage = (_event, newPage) => {
    setPage(newPage);
  };
  const onPerPageSelect = (_event, newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

  useEffect(() => {
    let afterScrolldata = []

    if (beforescrolldata.length === 0) {
      afterScrolldata = AuthFailuredata
    } else {
      afterScrolldata = [...beforescrolldata, ...scrolldata]
    }
    resetPagination() // Reset pagination when new data is set
    setBeforescrolldata(afterScrolldata)

    const newdefaultRows = afterScrolldata.map((log) => {
      const { formattedTimeStamp } = formatTableTimestamp(log._source.timestamp);;
      return {
        id: log._id,
        timestamp: formattedTimeStamp,
        agentId: log._source.agent.id,
        agentName: log._source.agent.name,
        ruleDescription: log._source.rule.description,
        details: log,
      };
    });

    setDefaultRows(newdefaultRows)
    // console.log(newdefaultRows)
    // console.log(page, perPage)
    setItemcount(newdefaultRows.length)
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    // console.log("after initial slicing", "", startIndex, endIndex, slicedRows)
    setRows(slicedRows);
    // console.log("rows with all data", newdefaultRows)

    const allExpanded = expandedRowNames.length === rows.length;
    setAreAllExpanded(allExpanded);
    setCollapseAllAriaLabel(allExpanded ? "Collapse all" : "Expand all");
    // setIsLoading(true);
  }, [AuthFailuredata, scrolldata]);

  //Use Effect for pagination 
  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    // console.log("after slicing", "", startIndex, endIndex, slicedRows)
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);


  //row searching function
  const onSearchChange = value => {
    setSearchValue(value);
  };
  const onFilter = repo => {
    if (searchValue === '') {
      return true;
    }
    let input;
    try {
      input = new RegExp(searchValue, 'i');
    } catch (err) {
      input = new RegExp(searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
    }
    return repo.agentName.search(input) >= 0;
  };

  const filteredRows = rows.filter(onFilter);

  //row expansion function
  const setRowExpanded = (row, isExpanding) => {
    const otherExpandedRowNames = expandedRowNames.filter((r) => r !== row.id);
    setExpandedRowNames(isExpanding ? [row.id] : []);

    if (!expandedRowData[row.id]) {
      let agentName = typeof row?.details?._source?.agent?.name != "undefined" ? row?.details?._source?.agent?.name : "";
      let agentID = typeof row?.details?._source?.agent?.id != "undefined" ? row?.details?._source?.agent?.id : "";
      const keyifiedData = keyify(row?.details, "", agentName, agentID);
      setExpandedRowData({ [row.id]: keyifiedData, });
     
    }
    const updatedExpandedRowNames = isRowExpanded(row)
      ? otherExpandedRowNames : [row.id];
    setExpandedRowNames(updatedExpandedRowNames);

  };
  const isRowExpanded = (row) => {
    return expandedRowNames.includes(row.id);
  };

  const hyperlinkConditions = {
    'agent.id': (value) => value !== '000',
    'agent.name': (value) => value !== '000',
  };

  const keyify = (obj, prefix = "",agentName = '', agentID = '') => {
    const skipKeys = ["_score", "_version", "_index", "sort", "@timestamp"];
    const result = [];
    Object.keys(obj).forEach((el) => {
      const fullkey = prefix + el;
      if (skipKeys.includes(el)) {
        return;
      }
      else if (Array.isArray(obj[el])) {
        const value = obj[el].join(", ");
        result.push({ key: fullkey, value });
      } else if (typeof obj[el] === "object" && obj[el] !== null) {
        if (el === "_source") {
          result.push(...keyify(obj[el], prefix,agentName, agentID));
        }
        else {
          result.push(...keyify(obj[el], fullkey + ".",agentName, agentID));
        }
      }
      else {
        if (hyperlinkConditions[fullkey] && hyperlinkConditions[fullkey](obj[el])) {
          result.push({ key: fullkey, value: <Link to={`/individual-agent-dashboard/${agentName}/${agentID}`}>{obj[el]}</Link> });
        }
        else if (typeof obj[el] === 'boolean') {
          result.push({ key: fullkey, value: obj[el] ? 'true' : 'false' });
        }
        else { result.push({ key: fullkey, value: obj[el] }); }
      }
    });
    const sortedResult = result.sort((a, b) => {
      if (a.key < b.key) return -1;
      if (a.key > b.key) return 1;
      return 0
    });

    return sortedResult;
  };

  //pagination code
  const renderPagination = (variant) => <Pagination
    itemCount={itemcount}
    perPage={perPage}
    page={page}
    onSetPage={onSetPage}
    variant={variant}
    onPerPageSelect={onPerPageSelect}
  />

  const emptyState = <EmptyState>
    <EmptyStateHeader headingLevel="h4" titleText="No results found" icon={<EmptyStateIcon icon={SearchIcon} />} />
    <EmptyStateBody>No results match the filter criteria. Clear all filters and try again.</EmptyStateBody>
    <EmptyStateFooter>
      <EmptyStateActions>
        <Button variant="link" onClick={() => { setSearchValue(''); }}>Clear all filters</Button>
      </EmptyStateActions>
    </EmptyStateFooter>
  </EmptyState>;

  return (
    <React.Fragment>
      <Toolbar inset={{
        default: 'insetNone',
        md: 'insetSm',
        xl: 'inset2xl',
        '2xl': 'insetLg'
      }}>
        <ToolbarContent>
          {/* <ToolbarItem variant="search-filter" style={{ width: '50%' }}>
              <SearchInput
                placeholder="Search..."
                value={searchValue}
                onChange={(_event, value) => onSearchChange(value)}
                onClear={() => onSearchChange('')} />
            </ToolbarItem> */}
          <ToolbarGroup align={{ default: 'alignRight' }}>
            {/* <ToolbarItem> <Button onClick={() => AuthFailureApi(ScrollID)} size="sm" >Next</Button></ToolbarItem> */}
            <ToolbarItem>{renderPagination('top')}</ToolbarItem>
          </ToolbarGroup>
        </ToolbarContent>
      </Toolbar>
      <Table variant="compact"
        aria-label="AuthSuccess"
        id="AuthSuccess-log-table"
        isStickyHeader
        gridBreakPoint="grid-md"
      >
        <Thead>
          <Tr>
            <Th key={'expand'} aria-label="expand-event" screenReaderText="expand" />
            {columns.map((column, index) => (
              <Th key={index}>{column}</Th>
            ))}
          </Tr>
        </Thead>
        {filteredRows.length > 0 && filteredRows.map((row, rowIndex) => (
          <Tbody key={rowIndex} isExpanded={isRowExpanded(row)}>
            <Tr key={rowIndex}
              onRowClick={() => setSelectedRowName(rowIndex)}
              isSelectable
              isClickable
              isRowSelected={selectedRowName === rowIndex}  >
              <Td
                expand={
                  row.details
                    ? {
                      rowIndex: rowIndex,
                      isExpanded: isRowExpanded(row),
                      onToggle: () => setRowExpanded(row, !isRowExpanded(row)),
                    }
                    : undefined
                }
              >
                <ExpandableRowContent>
                  {/* <pre>{JSON.stringify(row?.details, null, 2)}</pre> */}
                </ExpandableRowContent>
              </Td>

              <Td width={20}> {row?.timestamp}</Td>
              <Td width={20} > <Link to={"/Individual-Agent-Dashboard/" + row?.agentName + '/' + row?.agentId}>{row?.agentId}: {row?.agentName}</Link></Td>

              <Td modifier="truncate" width={60} >{row?.ruleDescription} </Td>
            </Tr>
            <Tr isExpanded={isRowExpanded(row)}>

              <Td colSpan={10} noPadding>
                <ExpandableRowContent>
                  <Tabs
                    activeKey={activeTabKey}
                    onSelect={handleTabClick}
                    style={{ marginTop: "0px" }}
                    aria-label="Tabs in the Security Alerts"
                    role="region"
                  >
                    <Tab
                      eventKey={0}
                      title={<TabTitleText>Table</TabTitleText>}
                      aria-label="Table data"
                    >
                      <TabContentBody hasPadding>

                        <DescriptionList isHorizontal horizontalTermWidthModifier={{ default: '30ch' }}>
                          {expandedRowData[row.id]?.map((item, index) => (
                            <React.Fragment key={index + 'af'}>
                              <DescriptionListGroup key={index + 'afgrp'}>
                                <DescriptionListTerm key={index + 'aftrm'}> {item.key}</DescriptionListTerm>
                                <DescriptionListDescription key={index + 'afdsc'}>{item.value}</DescriptionListDescription>
                              </DescriptionListGroup>
                            </React.Fragment>
                          ))}
                        </DescriptionList>
                      </TabContentBody>
                    </Tab>
                    <Tab eventKey={1} title={<TabTitleText>JSON</TabTitleText>}>
                      <TabContentBody hasPadding>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                          {JSON.stringify(row?.details, null, 2)}
                        </pre>
                      </TabContentBody>
                    </Tab>
                  </Tabs>
                </ExpandableRowContent>
              </Td>
            </Tr>
          </Tbody>
        ))}
        <Tbody>
          {filteredRows.length === 0 &&
            (<Tr>
              <Td colSpan={10}>
                <Bullseye>{emptyState}</Bullseye>
              </Td>
            </Tr>)}
        </Tbody>
      </Table>
      <Flex justifyContent={{ default: 'justifyContentFlexEnd' }} spaceItems={{ default: 'spaceItemsNone' }}>
        <FlexItem alignSelf={{ default: 'alignSelfCenter' }}>
          <Button onClick={() => AuthFailureApi(ScrollID)} size="sm" isLoading={isloadnext}>Next</Button>
        </FlexItem>
        <FlexItem >
          <div className="pf-v5-u-ml-sm">{renderPagination('bottom')}</div>
        </FlexItem>
      </Flex>
    </React.Fragment>
  );
});
