import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MenuToggle, Toolbar, ToolbarContent, ToolbarItem, Pagination, Button, Select, SelectList, SelectOption, SelectGroup, SearchInput, Tooltip, Card, CardBody, Icon, ModalVariant, Switch, Badge, OverflowMenu, OverflowMenuGroup, OverflowMenuItem, OverflowMenuDropdownItem, Dropdown, DropdownList, DataList, DataListItem, DataListItemRow, DataListCheck, DataListItemCells, DataListCell, Modal, TextContent, ToolbarFilter, ToolbarToggleGroup, ToolbarGroup, DescriptionList } from '@patternfly/react-core';
import { Table, Thead, Tr, Th, Tbody, Td, InnerScrollContainer } from '@patternfly/react-table';
import { EllipsisVIcon, CircleIcon, PlusCircleIcon, FilterIcon, SkullIcon, } from '@patternfly/react-icons';
import { useParams } from 'react-router-dom';
import dbApi from 'src/services/DbApi';
import { EmptystateforIncident } from 'src/util/EmptyStateCard';
import { Loader } from 'src/util/Loadingstate';
import { useDispatch } from 'react-redux';
import { alertBoxData } from 'src/Redux/Action';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import IncidentObservableModal from './IncidentObservableModal';
import ModalCommon from 'src/Components/CommonEventPages/ModalCommon';
import { formatTableTimestamp } from 'src/util/Timeutils';

const orderColumns = [
    {
        label: 'Flag',
        col: 'flag'
    },
    {
        label: 'Type',
        col: 'observable_type'
    },
    {
        label: 'Value',
        col: 'value'
    },
    {
        label: 'Create Datetime',
        col: 'create_at'
    },
    {
        label: 'TLP',
        col: 'tlp'
    },
    {
        label: 'Description',
        col: 'description'
    }
]

const IncidentObservableList = () => {
    const dispatch = useDispatch();
    const columns = {
        flag: 'Flag',
        observable_type: 'Type',
        value: 'Value',
        tlp: 'TLP',
        description: 'Description',
        create_at: 'Create Datetime'
    };
    const { id } = useParams();
    const userRole = localStorage.getItem("role");
    const defaultColumns = ['Flag', 'Type', 'Value', 'TLP', 'Description', 'Create Datetime'];
    const defaultRows = [];
    const excludeOrder = ['Flag', 'Description',]
    const excludeWrapCol = ['Created_At']
    const childTaskDetailModalref = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [activeSortIndex, setActiveSortIndex] = useState(5);
    const [activeSortDirection, setActiveSortDirection] = useState('desc');
    const [totalItem, setTotalItem] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [isKebabDropdownOpen, setIsKebabDropdownOpen] = useState(false);
    const [paginatedRows, setPaginatedRows] = useState([]);
    const [filters, setFilters] = useState([]);
    const [filteredColumns, setFilteredColumns] = useState(defaultColumns);
    const [filteredRows, setFilteredRows] = useState(defaultRows);
    const [managedColumns, setManagedColumns] = useState(defaultColumns);
    const [managedRows, setManagedRows] = useState(defaultRows);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState('Name')
    const [selectedRowName, setSelectedRowName] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false)
    const [iocFlags, setIocFlags] = useState({}); // State for is_ioc flags
    const [sightedFlags, setSightedFlags] = useState({}); // State for has_been_sighted flags
    const [checkedState, setCheckedState] = useState(Array(defaultColumns.length).fill(true));
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    });
    const tlpColorMap = {
        WHITE: { backgroundColor: '#00c0ef', color: '#000' },
        AMBER: { backgroundColor: '#ffbf00', color: '#000' },
        GREEN: { backgroundColor: '#008000', color: '#fff' },
        RED: { backgroundColor: '#C9190B', color: '#fff' }
    };
    const [dataFilters, setDataFilters] = useState({
        name: [],
        tlp: [],
        tlpChip: []
    })

    useEffect(() => {
        const initialIocFlags = {};
        const initialSightedFlags = {};

        paginatedRows.forEach(item => {
            initialIocFlags[item.unique_id] = item.is_ioc;
            initialSightedFlags[item.unique_id] = item.has_been_sighted;
        });

        setIocFlags(initialIocFlags);
        setSightedFlags(initialSightedFlags);
    }, [paginatedRows]);

    const toggleIoc = (uniqueId) => {
        setConfirmBox({
            show: true,
            title: 'Update Observable?',
            msg: (<span>Are you sure you want to toggle <strong>IOC</strong>?</span>
            ),

            funCall: () => {
                setIocFlags(prevFlags => {
                    const newFlags = {
                        ...prevFlags,
                        [uniqueId]: !prevFlags[uniqueId] // Toggle the specific flag for this uniqueId
                    };
                    updateObservable(uniqueId, newFlags[uniqueId], sightedFlags[uniqueId]);
                    return newFlags;
                });
            },
            funParam: {},
            position: 'default',
            iconvariant: 'info',
            submitbtnvariant: 'primary',
        });
    };
    const toggleSighted = (uniqueId) => {
        setConfirmBox({
            show: true,
            title: 'Update Observable?',
            msg: (<span> Are you sure you want to toggle <strong>Has been sighted</strong>? </span>
            ),
            funCall: () => {

                setSightedFlags(prevFlags => {
                    const newFlags = {
                        ...prevFlags,
                        [uniqueId]: !prevFlags[uniqueId]
                    };

                    updateObservable(uniqueId, iocFlags[uniqueId], newFlags[uniqueId]);
                    return newFlags;
                });
            },
            funParam: {},
            position: 'default',
            iconvariant: 'info',
            submitbtnvariant: 'primary',
        });
    };

    const renderIcon = (condition, IconComponent, tooltipContent, ariaLabel, trueColor, falseColor, toggleFunc) => (
        <Tooltip content={tooltipContent}>
            <Button
                variant="plain"
                aria-label={ariaLabel}
                className='pf-v5-u-mr-sm'
                onClick={toggleFunc}
                style={{ marginLeft: '-15px' }}
            >
                <IconComponent color={condition ? trueColor : falseColor} />
            </Button>
        </Tooltip>
    );

    const getObservableList = async () => {
        let orderCol = 'create_at';
        if (activeSortIndex > 0) {
            const orderResult = orderColumns.find((obj) => obj.label == filteredColumns[activeSortIndex])
            orderCol = orderResult.col;
        }
        const data = {
            limitStart: (perPage * page) - perPage,
            limitLength: perPage,
            sortByCol: orderCol,
            sortOrder: activeSortDirection,
            search: dataFilters.name.length > 0 ? dataFilters.name[0] : '',
            incidentId: id,
        };

        try {
            setIsLoading(true);
            const res = await dbApi.postApi('incident_management/observable_list', data);
            console.log('observable_list', res);
            if (res && res.data) {
                setRows(res.data || []);
                setTotalItem(res.totalRecord);
                setManagedRows(res.data);
                setPaginatedRows(res.data);
            } else {
                setRows([]);
                setTotalItem(0);
                setManagedRows([]);
                setPaginatedRows([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching incidents:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getObservableList();
    }, [page, perPage, activeSortIndex, activeSortDirection, searchValue, dataFilters]);

    const updateObservable = async (uniqueId, isIoc, hasBeenSighted) => {
        try {
            setIsLoading(true);
            const param = {
                uniqueId: uniqueId,
                incidentId: id,
                isIoc: isIoc ? '1' : '0',          // Use toggled value
                hasBeenSighted: hasBeenSighted ? '1' : '0' // Use toggled value
            }
            const ret = await dbApi.putApi('incident_management/update_observable', param);
            console.log('update_observable', ret);
            let type = 'danger';
            if (!ret.error) {
                type = 'success';
                getObservableList();
            }
            dispatch(alertBoxData([{ title: ret.message, variant: type }]));
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const onInputChange = newValue => {
        setInputValue(newValue)
    };

    const onNameInput = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        let temp = dataFilters
        temp.name[0] = inputValue
        setDataFilters(prevDataFilters => ({
            ...prevDataFilters,
            temp
        }));
    };
    const onCategorySelect = event => {
        setCurrentCategory(event.target.innerText)
        setIsCategoryDropdownOpen(false)
        setIsFilterDropdownOpen(false)
    };

    const onCategoryToggle = () => {
        setIsCategoryDropdownOpen(!isCategoryDropdownOpen)
    };

    const onDelete = (type = '', id = '') => {
        if (type) {
            let temp = dataFilters
            temp[type].splice(0, 1);
            if (type == "tlp") {
                temp['tlpChip'].splice(0, 1);
            }
            setDataFilters(prevDataFilters => ({
                ...prevDataFilters,
                temp
            }));
        } else {
            setDataFilters({ name: [], tlp: [], tlpChip: [] })
        }
    };
    const [selectedRows, setSelectedRows] = useState([]);
    const isRowSelected = row => selectedRows.includes(row.unique_id);
    const setRowSelected = (row, isSelecting) => {
        setSelectedRows(prevSelected => {
            const otherSelectedRows = prevSelected.filter(r => r !== row.unique_id);
            const newSelectedRows = isSelecting ? [...otherSelectedRows, row.unique_id] : otherSelectedRows;
            console.log('Selected Row IDs:', newSelectedRows); // Log the selected row IDs
            return newSelectedRows;
        });
    };
    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };

    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };

    const handlePerPageSelect = (_evt, newPerPage, newPage) => {
        setPerPage(newPerPage);
        if (newPage !== page) { setPage(newPage); }
    };

    const renderPagination = (variant) => (
        <Pagination
            itemCount={totalItem}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
        />
    );

    const matchCheckboxNameToColumn = (name) => {
        switch (name) {
            case 'check1':
                return 'Flag';
            case 'check2':
                return 'Type';
            case 'check3':
                return 'Value';
            case 'check4':
                return 'TLP';
            case 'check5':
                return 'Create Datetime';
            case 'check6':
                return 'Description';
        }
    };
    const matchSelectedColumnNameToAttr = (name) => {
        switch (name) {
            case 'Flag':
                return 'ignore_similarity';
            case 'Type':
                return 'type';
            case 'Value':
                return 'value';
            case 'TLP':
                return 'tlp';
            case 'Create Datetime':
                return 'create_at';
            case 'Description':
                return 'description';
        }
    };

    const selectAllColumns = () => {
        unfilterAllData();
        setCheckedState(Array(defaultColumns.length).fill(true));
    };

    const handleModalToggle = (_event) => {
        setIsModalOpen(!isModalOpen);
    };

    const onSave = () => {
        setManagedColumns(filteredColumns);
        console.log("filteredColumns")
        console.log(filteredColumns)
        setPaginatedRows(filteredRows);
        setIsModalOpen(!isModalOpen);
    };


    const renderModal = () => {
        return (
            <Modal
                title="Manage columns"
                isOpen={isModalOpen}
                variant="small"
                description={
                    <TextContent>
                        <Button isInline onClick={selectAllColumns} variant="link">
                            Select all
                        </Button>
                    </TextContent>
                }
                onClose={handleModalToggle}
                actions={[
                    <Button key="save" variant="primary" onClick={onSave}>
                        Save
                    </Button>,
                    <Button key="cancel" variant="secondary" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                ]}
            >
                <DataList aria-label="Table column management" id="table-column-management" isCompact>
                    {
                        defaultColumns.map((obj, index) => {
                            return <DataListItem key={index} aria-labelledby={"col-mgmt-item-" + index}>
                                <DataListItemRow>
                                    <DataListCheck
                                        aria-labelledby={"col-mgmt-item-" + index}
                                        checked={checkedState[index]}
                                        name={"check" + (index + 1)}
                                        id={"check" + index}
                                        onChange={handleChange}
                                    />
                                    <DataListItemCells
                                        dataListCells={[
                                            <DataListCell id={"col-mgmt-item-" + index} key={"col-mgmt-item-" + index}>
                                                <label htmlFor={"check" + index}>{obj}</label>
                                            </DataListCell>
                                        ]}
                                    />
                                </DataListItemRow>
                            </DataListItem>
                        })
                    }
                </DataList>
            </Modal>
        );
    };

    const removePropFromObject = (object, keys) =>
        keys.reduce((obj, prop) => {
            const { [prop]: _, ...keep } = obj;
            return keep;
        }, object);

    const filterData = (checked, name) => {
        const selectedColumn = matchSelectedColumnNameToAttr(name);
        const filteredRows = [];
        if (checked) {
            const updatedFilters = filters.filter((item) => item !== selectedColumn);
            const filteredColumns = defaultColumns.filter(
                (column) => !updatedFilters.includes(matchSelectedColumnNameToAttr(column))
            );
            managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));
            setFilters(updatedFilters);
            setFilteredColumns(filteredColumns);
            setFilteredRows(filteredRows);
        } else {
            let updatedFilters = filters;
            if (typeof selectedColumn != 'undefined') {
                updatedFilters.push(selectedColumn);
            }
            const filteredColumns = managedColumns.filter(
                (column) => !filters.includes(matchSelectedColumnNameToAttr(column))
            );
            managedRows.forEach((item) => filteredRows.push(removePropFromObject(item, updatedFilters)));
            setFilters(updatedFilters);
            setFilteredColumns(filteredColumns);
            setFilteredRows(filteredRows);
        }
    };

    const unfilterAllData = () => {
        setFilters([]);
        setFilteredColumns(defaultColumns);
        setFilteredRows(managedRows);
    };

    const handleChange = (event, checked) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        filterData(checked, matchCheckboxNameToColumn(target.name));
        const checkedIndex = defaultColumns.findIndex((element) => element === matchCheckboxNameToColumn(target.name));

        const updatedCheckedState = [...checkedState];
        updatedCheckedState[checkedIndex] = value;

        setCheckedState(updatedCheckedState);
    };
    const onFilterToggle = () => {
        setIsFilterDropdownOpen(!isFilterDropdownOpen)
    };


    const finalRemoveTask = async () => {
        try {
            setIsLoading(true);
            console.log({ taskUniqueId: selectedRows });
            const ret = await dbApi.deleteApi('incident_management/delete_observable', { uniqueId: selectedRows });
            let type = 'danger';
            if (!ret.error) {
                type = 'success';
                getObservableList();
            }
            dispatch(alertBoxData([{ title: ret.message, variant: type }]));
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }
    const onFilterSelect = (event, selection, type, chipType) => {
        const checked = event.target.checked;
        let text = event.target.innerText
        setIsFilterDropdownOpen(false)
        let temp = dataFilters
        temp[type][0] = selection
        temp[chipType][0] = text
        setDataFilters(prevDataFilters => ({
            ...prevDataFilters,
            temp
        }));
    };

    const removeTask = () => {
        setConfirmBox({
            show: true,
            title: 'Permanently delete Observable ?',
            msg: "Are you sure you want to delete this Observable ?",
            funCall: finalRemoveTask,
            funParam: {},
            position: 'default',
            iconvariant: 'warning',
            submitbtnvariant: 'danger',
        })
    }

    const tableActionItems = [
        <OverflowMenuDropdownItem key="1" onClick={() => handleModalToggle()} > Manage Columns</OverflowMenuDropdownItem>,
        ...(userRole !== 'Analyst' ? [<OverflowMenuDropdownItem key="2" onClick={() => removeTask()} isDisabled={selectedRows.length == 0}>  Remove</OverflowMenuDropdownItem>] : [])
    ];

    const tableAction =
        <Dropdown onSelect={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} onOpenChange={isKebabDropdownOpen => setIsKebabDropdownOpen(isKebabDropdownOpen)} toggle={toggleRef => <MenuToggle ref={toggleRef} aria-label="overflow menu" variant="plain" onClick={() => setIsKebabDropdownOpen(!isKebabDropdownOpen)} isExpanded={false}>
            <EllipsisVIcon />
        </MenuToggle>} isOpen={isKebabDropdownOpen}>
            <DropdownList >{tableActionItems}</DropdownList>
        </Dropdown>


    function buildFilterDropdown() {
        const tlpMenuItems = [
            <SelectOption key="1" value="WHITE" isSelected={dataFilters.tlp.includes("WHITE")}>WHITE</SelectOption>,
            <SelectOption key="2" value="AMBER" isSelected={dataFilters.tlp.includes("AMBER")}>AMBER</SelectOption>,
            <SelectOption key="3" value="RED" isSelected={dataFilters.tlp.includes("RED")}>RED</SelectOption>,
            <SelectOption key="4" value="GREEN" isSelected={dataFilters.tlp.includes("GREEN")}>GREEN</SelectOption>

        ];

        return <React.Fragment>

            <ToolbarFilter chips={dataFilters.name} deleteChip={onDelete} categoryName="name" showToolbarItem={currentCategory === 'Name'}>
                <SearchInput aria-label="name filter" placeholder="Filter by name..." onChange={(_event, value) => onInputChange(value)} value={inputValue} onClear={() => {
                    onInputChange('');
                }} onSearch={onNameInput} />
            </ToolbarFilter>

            <ToolbarFilter chips={dataFilters.tlpChip} deleteChip={onDelete} categoryName="TLP" showToolbarItem={currentCategory === 'TLP'}>
                <Select aria-label="TLP" isOpen={isFilterDropdownOpen} onSelect={(event, selection) => onFilterSelect(event, selection, 'tlp', 'tlpChip')} selected={dataFilters.status} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onFilterToggle} isExpanded={isFilterDropdownOpen} style={{
                    width: '100%',
                    verticalAlign: 'text-bottom'
                }}>
                    Filter by TLP
                </MenuToggle>}>
                    {tlpMenuItems}
                </Select>
            </ToolbarFilter>


        </React.Fragment>;
    }

    function buildCategoryDropdown() {
        const categoryMenuItems = [
            <SelectOption key="1" value="Name">Name</SelectOption>,
            <SelectOption key="2" value="tlp">TLP</SelectOption>

        ];
        return <ToolbarItem>
            <Select onSelect={onCategorySelect} selected={currentCategory} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={onCategoryToggle} isExpanded={isCategoryDropdownOpen} icon={<FilterIcon />} style={{
                width: '100%',
                verticalAlign: 'text-bottom'
            }}>
                {currentCategory}
            </MenuToggle>} isOpen={isCategoryDropdownOpen}>
                {categoryMenuItems}
            </Select>
        </ToolbarItem>;
    }

    const renderToolbar = <Toolbar id="toolbar-with-chip-groups" clearAllFilters={onDelete} collapseListedFiltersBreakpoint="xl">
        <ToolbarContent>
            <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
                <ToolbarGroup variant="filter-group" style={{
                    lineHeight: '22px',
                    alignItems: 'center'
                }}>
                    {buildCategoryDropdown()}
                    {buildFilterDropdown()}
                </ToolbarGroup>
            </ToolbarToggleGroup>
            <ToolbarItem variant="overflow-menu">
                <OverflowMenu breakpoint="md">
                    <OverflowMenuGroup groupType="button" isPersistent>
                        <OverflowMenuItem isPersistent>
                            {tableAction}
                        </OverflowMenuItem>
                        {userRole !== 'Analyst' && <OverflowMenuItem>
                            <Button variant="primary" icon={<PlusCircleIcon />} onClick={() => childTaskDetailModalref.current.setModalBox(true)}> Add Observable</Button>
                        </OverflowMenuItem>}
                    </OverflowMenuGroup>
                </OverflowMenu>
            </ToolbarItem>
            <ToolbarItem variant="pagination">{renderPagination('top')}</ToolbarItem>
        </ToolbarContent>
    </Toolbar>;

    const handleModalClose = async (isSuccess) => {
        if (isSuccess) {
            await getObservableList();
        }
        setIsLoading(false);
    };

    const tableHeader = useMemo(() => {
        return <Thead>
            <Tr>
                <Th key={'chk'} screenReaderText='key' />
                {managedColumns.map((column, columnIndex) => {
                    const sortParams = {
                        sort: {
                            sortBy: {
                                index: activeSortIndex,
                                direction: activeSortDirection
                            },
                            onSort,
                            columnIndex
                        }
                    };
                    return !excludeOrder.includes(column) ? <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} {...sortParams} >{column}</Th> : <Th modifier={!excludeWrapCol.includes(column) && 'wrap'} key={columnIndex} >{column}</Th>;
                })}
            </Tr>
        </Thead>
    }, [activeSortIndex, activeSortDirection, managedColumns])

    return (
        <>
            {renderToolbar}
            {isLoading ? <Loader /> : (
                <InnerScrollContainer key="scroller">
                    <Table aria-label="Incident Observable Table" variant="compact">
                        {tableHeader}
                        <Tbody>
                            {rows && rows.length > 0 ? (
                                paginatedRows
                                    .filter(row => {
                                        if (dataFilters.tlpChip && dataFilters.tlpChip.length > 0) {
                                            return dataFilters.tlpChip.includes(row.tlp);
                                        }
                                        return true;
                                    })
                                    .map((row, rowIndex) => {
                                        const { formattedTimeStamp: createDatetime } = formatTableTimestamp(row.create_at);
                                        return (
                                            <Tr key={rowIndex + row.incident_id}
                                                isSelectable
                                                isClickable
                                                onRowClick={() => setSelectedRowName(row.unique_id)}
                                                isRowSelected={selectedRowName === row.unique_id}>
                                                <Td
                                                    key={"chk-" + rowIndex}
                                                    select={{
                                                        rowIndex,
                                                        onSelect: (_event, isSelecting) => setRowSelected(row, isSelecting),
                                                        isSelected: isRowSelected(row),
                                                        isDisabled: row?.process_status == 1,
                                                    }}
                                                />
                                                {
                                                    <Td width={20} dataLabel="Flags">
                                                        <span >
                                                            {renderIcon(
                                                                iocFlags[row.unique_id],   // Use the separate state for is_ioc
                                                                SkullIcon,
                                                                "Is an IoC",
                                                                'is-ioc-icon',
                                                                '#C9190B',  // Active color (true)
                                                                'gray',
                                                                userRole !== 'Analyst' ? () => toggleIoc(row.unique_id) : undefined
                                                            )}
                                                            {renderIcon(
                                                                sightedFlags[row.unique_id],
                                                                CircleIcon,
                                                                "Has been sighted",
                                                                'has-been-sighted-icon',
                                                                '#06c',                   // Active color (true)
                                                                'gray',                   // Inactive color (false)
                                                                userRole !== 'Analyst' ? () => toggleSighted(row.unique_id) : undefined
                                                            )}
                                                        </span>
                                                        {row.tip_status != null && <Tooltip content={"TLP"}>
                                                            <Badge style={tlpColorMap[row.tip_status] || { backgroundColor: '#d3d3d3', color: '#000' }} > {row.tip_status} </Badge>
                                                        </Tooltip>
                                                        }
                                                    </Td>
                                                }
                                                {
                                                    typeof row?.type != 'undefined' && (
                                                        <Td width={20} dataLabel={columns.type}>
                                                            {row.observable_type}
                                                        </Td>
                                                    )}
                                                {
                                                    typeof row?.value != 'undefined' && (
                                                        <Td dataLabel={columns.value}>
                                                            {row.value}
                                                        </Td>
                                                    )}
                                                {
                                                    typeof row?.tlp != 'undefined' && (
                                                        <Td width={20}>
                                                            <Badge
                                                                style={tlpColorMap[row.tlp] || { backgroundColor: '#d3d3d3', color: '#000' }}>
                                                                {row.tlp}
                                                            </Badge>
                                                        </Td>
                                                    )}
                                                {
                                                    typeof row?.description != 'undefined' && (
                                                        <Td width={20} dataLabel={columns.description}>
                                                            {row.description}
                                                        </Td>
                                                    )}
                                                {
                                                    typeof row?.create_at != 'undefined' && (
                                                        <Td width={20} dataLabel={columns.create_at}>
                                                            {createDatetime}
                                                        </Td>
                                                    )}
                                            </Tr>
                                        );
                                    })
                            ) : (
                                <Tr>
                                    <Td colSpan={Object.keys(columns).length} key="empty-data">
                                        <EmptystateforIncident />
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </InnerScrollContainer>
            )}
            {renderPagination('bottom')}
            {renderModal()}
            <ModalCommon
                title="Create new observable(s)"
                ref={childTaskDetailModalref}
                variant={ModalVariant.large}
                onclose={() => childTaskDetailModalref.current.setModalBox(false)}
            >
                <IncidentObservableModal
                    onTaskAdded={getObservableList}
                    IncidentId={id}
                    onclose={() => childTaskDetailModalref.current.setModalBox(false)}
                    onModalClose={handleModalClose}
                />
            </ModalCommon>
            <ConfirmBox confirmModal={confirmModalData} />
        </>
    );
};

export default IncidentObservableList;
