import { SquareIcon } from '@patternfly/react-icons';
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as echarts from 'echarts';

const PictorialBarChart = (props) => {

    useEffect(() => {
        var maxData = props.MaxCount;
        const chartDom = document.getElementById(props.UniqueId+'pbc');
        const myChart = echarts.init(chartDom);

        const option = {
            // tooltip: {},
            xAxis: {

                max:maxData,
                interval: 0,  
                type: 'value',
                splitLine: { show: false },
                axisTick: { show: false },
                axisLine: {show: false},
                axisLabel: {show: false,}
            },
            yAxis: {
                data: ['riskscore'],
                inverse: true,
                type: 'category',
                axisTick: { show: false },
                axisLine: { show: false },
                axisLabel: {show: false}
            },
            grid: {
                top: 'center',
                height: 40, // Adjust height according to requirement
                left: 0,
                right: props.gridright
            },
            series: [
                {
                    // current data
                    type: 'pictorialBar',
                    symbol: 'path://M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z',
                    symbolRepeat: 'fixed',
                    symbolMargin:'23%',
                    symbolClip: true,
                    symbolSize:[15,15],
                    
                    itemStyle: {
                        color: props.Color // Color for filled portion
                    },
                    // symbolBoundingData: maxData,
                    data: props.Score,
                    z: 10
                },
                {
                    // full data
                    type: 'pictorialBar',
                    itemStyle: {
                        opacity: 0.3,
                        color: '#6A6E73', 
                    },
                    symbolRepeat: 'fixed',
                    symbol: 'path://M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z',
                    symbolMargin:'23%',
                    symbolClip: true,
                    symbolSize:[15,15],
                    data: [maxData],
                    z: 5
                }
            ]
        };
        option && myChart.setOption(option);
        return () => {
            myChart.dispose();
        };
    }, [props.MaxCount,props.Score,props.height,props.UniqueId,props.Color,,props.gridright])
    return (
        <div className="container">
            <div id={props.UniqueId+"pbc"} style={{ width: '100%', height: '15px'}} ></div>
        </div>
    )
}
const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PictorialBarChart)
