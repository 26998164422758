
import React, { forwardRef, useState, useEffect, useMemo } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Divider,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  Tabs,
  Tab,
  TabTitleText,
  TabContentBody,
} from '@patternfly/react-core'
import AttacksbyTechnique from './AttacksbyTechnique'
import TopTactics from './TopTactics';
import dbApi from '../../services/DbApi'
import ELineStackChart from '../AllCharts/Elinestackchart';
import EBarChart from './EBarChart';
import { connect, useDispatch } from 'react-redux';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { Loader } from '../../util/Loadingstate';
import TableViewforChart from '../AllCharts/TableViewForChart';
import Radarchart from '../AllCharts/Radarchart';
import EWordCloud from '../AllCharts/EWordCloud';
import TacticByAgenttable from './TacticByAgenttable';

const MitreAttacksDashboard = forwardRef(function ThreatIntelDashboard(props, ref) {
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [alertevolutionseries, setAlertevolutionseries] = useState([]);
  const [evolutionlabel, setEvolutionlabel] = useState();
  const [tacticsseries, setTacticsSeries] = useState([]);
  const [techniquebyagent, setTechniquebyagent] = useState([]);
  const [agentTacticsseries, setAgentTacticsseries] = useState([]);
  const [attackbyTechnique, setAttackbyTechnique] = useState([]);
  const [techniqueseries, setTechniqueseries] = useState([])
  //Loading states
  const [isloadAlert, setIsLoadAlert] = useState(false)
  const [isloadTopTactic, setIsLoadTopTactic] = useState(false)
  const [isloadTech, setIsLoadTech] = useState(false)
  const [isloadTacbyagent, setIsLoadTacbyagent] = useState(false)
  const [isloadTechbyagent, setIsLoadTechbyagent] = useState(false)
  const [isloadAttacks, setIsLoadAttacks] = useState(false)
  //modal states
  const [modalStates, setModalStates] = useState({
    modalTactics: false,
    modaltechniques: false,
    modaltacticbyagent: false,
    modaltechbyagent: false,
    modalattackbytech: false
  });
  const handleModalToggle = (modalName) => {
    setModalStates((prevStates) => ({
      ...prevStates,
      [modalName]: !prevStates[modalName],
    }));
  };
  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex)
  }
  const getallTactics = async (data) => {
    try {
      setIsLoadTopTactic(true)
      let all_tactics = await dbApi.postApi("Opensearch/Mitre_Dashboard_Attack_Top_Tactics", data);
      console.log(all_tactics)
      let TacticSeries = [];
      let tactics = all_tactics.Mitre_Dashboard_Attack_Top_Tactics
      if (Object.keys(tactics).length !== 0) {
        TacticSeries = tactics.body.aggregations.Top_Tactics.buckets
        setTacticsSeries(TacticSeries)
      }
      else {
        setTacticsSeries(TacticSeries)
      }
      setIsLoadTopTactic(false)
    }
    catch (error) {
      console.log(error)
      setTacticsSeries([])
      setIsLoadTopTactic(false)
    }
  }
  const getAlertEvolution = async (data) => {
    try {
      setIsLoadAlert(true)
      let all_alerts = await dbApi.postApi("Opensearch/Mitre_Dashboard_Attack_Alerts_Evolution_Over_Time", data);
      console.log(all_alerts)
      let alertevolutionseries = [];
      let evolutionlabel = ""
      let res = all_alerts.Mitre_Dashboard_Attack_Alerts_Evolution_Over_Time
      if (Object.keys(res).length !== 0) {

        alertevolutionseries = res.data.body.aggregations.Alerts_evolution_over_time.buckets.slice(0, 5)
        evolutionlabel = res.interval

        setAlertevolutionseries(alertevolutionseries)
        setEvolutionlabel(evolutionlabel)
      } else {
        setAlertevolutionseries(alertevolutionseries)
        setEvolutionlabel(evolutionlabel)
      }
      setIsLoadAlert(false)
    } catch (error) {
      setAlertevolutionseries([])
      setEvolutionlabel('')
      console.log(error)
      setIsLoadAlert(false)
    }
  }
  const gettechniquesbyagent = async (data) => {
    try {
      setIsLoadTechbyagent(true)
      let all_techniques = await dbApi.postApi("Opensearch/Mitre_Dashboard_Attack_Mitre_Techniques_By_Agent", data);
      console.log(all_techniques)
      let TechniqueByAgent = [];
      if (Object.keys(all_techniques.Mitre_Dashboard_Attack_Mitre_Techniques_By_Agent).length !== 0) {
        let techniques = all_techniques.Mitre_Dashboard_Attack_Mitre_Techniques_By_Agent.body.aggregations.Top_technique;
        TechniqueByAgent = techniques.buckets
        setTechniquebyagent(TechniqueByAgent)
      }
      else {
        setTechniquebyagent(TechniqueByAgent)
      }
      setIsLoadTechbyagent(false)
    }
    catch (error) {
      console.log(error)
      setTechniquebyagent([])
      setIsLoadTechbyagent(false)
    }
  }
  const gettacticssbyagent = async (data) => {
    try {
      setIsLoadTacbyagent(true)
      let tacticsbyagent = await dbApi.postApi("Opensearch/Mitre_Dashboard_Attack_Top_Tactics_By_Agent", data);
      console.log(tacticsbyagent)
      let AgentTacticSeries = [];
      if (Object.keys(tacticsbyagent.Mitre_Dashboard_Attack_Top_Tactics_By_Agent).length !== 0) {
        let tactics_byagent = tacticsbyagent.Mitre_Dashboard_Attack_Top_Tactics_By_Agent.body.aggregations.Top_tactics
        AgentTacticSeries = tactics_byagent.buckets;
        setAgentTacticsseries(AgentTacticSeries)
      }
      else {
        setAgentTacticsseries(AgentTacticSeries)
      }
      setIsLoadTacbyagent(false)
    }
    catch (error) {
      console.log(error)
      setAgentTacticsseries([])
      setIsLoadTacbyagent(false)
    }
  }

  const getattackbytechnique = async (data) => {
    try {
      setIsLoadAttacks(true)
      let attackbytechnique = await dbApi.postApi("Opensearch/Mitre_Dashboard_Attack_Attacks_By_Technique", data);
      console.log(attackbytechnique)
      let AttackByTechnique = [];
      let tech = attackbytechnique.Mitre_Dashboard_Attack_Attacks_By_Technique
      if (Object.keys(tech).length !== 0) {

        AttackByTechnique = tech.body.aggregations.Top_technique.buckets
        setAttackbyTechnique(AttackByTechnique)
      }
      else {
        setAttackbyTechnique(AttackByTechnique)
      }
      setIsLoadAttacks(false)
    }
    catch (error) {
      console.log(error)
      setIsLoadAttacks(false)
    }
  }

  //get techniques
  const getTopTechniques = async (data) => {
    try {
      setIsLoadTech(true)
      let All_mitreattack = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Top_Mitre_Attacks', data)
      console.log(All_mitreattack);
      let TechniqueSeries = [];
      if (Object.keys(All_mitreattack.Security_Dasbhoard_Top_Mitre_Attacks).length !== 0) {
        let security_mitreattack = All_mitreattack.Security_Dasbhoard_Top_Mitre_Attacks.body.aggregations.Top_MITRE_ATTACKS;
        TechniqueSeries = security_mitreattack.buckets
        setTechniqueseries(TechniqueSeries)
      }
      else {
        setTechniqueseries(TechniqueSeries)
      }
      setIsLoadTech(false)
    }
    catch (error) {
      console.log(error)
      setTechniqueseries([])
      setIsLoadTech(false)
    }
  }

  const MitredashboardApi = async (startDate, endDate, allFields) => {
    const Fields = allFields
    const data = {
      startDate: startDate,
      endDate: endDate,
      filter: JSON.stringify(Fields),
    };
    try {
      console.log(data)
      setStartDate(startDate);
      setEndDate(endDate);
      getAlertEvolution(data);
      getallTactics(data);
      getTopTechniques(data)
      gettechniquesbyagent(data);
      gettacticssbyagent(data);
      getattackbytechnique(data);
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (props.dates.startDate != "") {
      let Dates = props.dates
      MitredashboardApi(Dates.startDate, Dates.endDate, props.filterformdata)
    }
  }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata,]);

  const colorforevent = ['#0066cc', '#f4c145', '#4cb140', '#5752d1', '#ec7a08', '#40199A', '#C8EB79', '#C9190B', '#008BAD']
  const TechniqueSeries = useMemo(() => {
    return (
      isloadTech ?
        <Loader /> :
        techniqueseries.length > 0 ?
          <EWordCloud cloudseries={techniqueseries.slice(0, 20)} height={300} filterFields={'rule.mitre.technique'} />
          : <Emptystatefornodata />
    )
  }, [isloadTech, techniqueseries])
  const TopTactic = useMemo(() => {
    return (
      isloadTopTactic ? <Loader /> : tacticsseries.length > 0 ?
        <Radarchart radarseries={tacticsseries} height={230} /> :
        <Emptystatefornodata />
    )
  }, [tacticsseries, isloadTopTactic])

  return (
    <>
      <Grid hasGutter>
        <GridItem md={12} sm={12} lg={5}>
          <Card isFullHeight>
            <CardTitle>Alerts Evolution Over Time</CardTitle>
            <Divider />
            {isloadAlert ? <Loader /> :
              <ELineStackChart
                lineseries={alertevolutionseries}
                label={evolutionlabel}
                StartDateTime={startDate}
                EndDateTime={endDate}
                color={colorforevent}
                legendwidth={65}
                filterFields={'rule.mitre.technique'}
              />
            }
          </Card>
        </GridItem>
        <GridItem md={6} sm={12} lg={3} >
          <Card isFullHeight>
            <CardTitle> Top MITRE ATT&CK Techniques</CardTitle>
            <Divider />
            <CardBody>   {TechniqueSeries}</CardBody>
            {techniqueseries.length > 0 && <CardFooter>
              <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalTopMitre') }}> More </Button>
                <Modal
                  tabIndex={0}
                  variant={ModalVariant.large}
                  title={`Top MITRE ATT&CK Techniques (${techniqueseries.length})`}
                  isOpen={modalStates.modalTopMitre}
                  onClose={() => handleModalToggle('modalTopMitre')}
                >
                  <Divider className='modal-Divider' />
                  <Card>
                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                      <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                        {isloadTech ?
                          <Loader /> : <TableViewforChart Tabledata={techniqueseries} columnData={["Mitre Att&CK Technique", "Count"]} filterFields={'rule.mitre.technique'} />
                        }
                      </Tab>
                      <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                        {isloadTech ? <Loader /> :
                          techniqueseries.length > 0 ?
                            (<EWordCloud cloudseries={techniqueseries.slice(0, 100)} height={500} filterFields={'rule.mitre.technique'} />) : (<Emptystatefornodata />)
                        }
                      </Tab>
                    </Tabs>
                  </Card>
                </Modal>
              </Flex>
            </CardFooter>}
          </Card>
        </GridItem>
        <GridItem md={6} sm={12} lg={4}  >
          <Card isFullHeight >
            <CardTitle>Top Tactics</CardTitle>
            <Divider />
            <CardBody>
              {TopTactic}
            </CardBody>
            {tacticsseries.length > 0 && <CardFooter>
              <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                <Button variant="link" onClick={() => { handleModalToggle('modalTactics'); setActiveTabKey(0) }}>
                  More
                </Button>
                <Modal
                  variant={ModalVariant.large}
                  title='Top Tactics'
                  isOpen={modalStates.modalTactics}
                  onClose={() => handleModalToggle('modalTactics')}
                >
                  <Divider className='modal-Divider' />
                  <Card>
                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                      <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                        {isloadTopTactic ? <Loader /> :
                          <TableViewforChart Tabledata={tacticsseries} columnData={["Mitre Tactics", "Count"]} filterFields={'rule.mitre.tactic'} />}
                      </Tab>
                      <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                        {activeTabKey == '1' &&
                          <TabContentBody className='pf-v5-u-p-xs'>
                            {isloadTopTactic ? <Loader /> :
                              tacticsseries.length > 0 ?
                                <Radarchart radarseries={tacticsseries} height={500} /> :
                                <Emptystatefornodata />}
                          </TabContentBody>
                        }
                      </Tab>
                    </Tabs>
                  </Card>
                </Modal>
              </Flex>
            </CardFooter>}
          </Card>
        </GridItem>
      </Grid>
      {props?.hideCard ? '' :
        <div className="pf-v5-u-mt-md">
          <Grid hasGutter>
            <GridItem md={12} sm={12} lg={12}>
              <Card isExpanded isFullHeight>
                <CardTitle>Top Tactics By Agents</CardTitle>
                <Divider />
                <CardBody>
                  {isloadTacbyagent ? <Loader /> :
                    agentTacticsseries.length > 0 ?
                      <EBarChart seriesData={agentTacticsseries.slice(0, 5)} filterFields={'rule.mitre.tactic'} height={'250px'} /> :
                      <Emptystatefornodata />
                  }
                </CardBody>
                {agentTacticsseries.length > 0 && <CardFooter>
                  <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                    <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modaltacticbyagent'); }}>
                      More
                    </Button>
                    <Modal
                      variant={ModalVariant.large}
                      title={`Tactics By Agents (${agentTacticsseries.length})`}
                      isOpen={modalStates.modaltacticbyagent}
                      onClose={() => handleModalToggle('modaltacticbyagent')}
                    >
                      <Divider className='modal-Divider' />
                      <Card>
                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                          <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                            <TabContentBody className='pf-v5-u-p-xs'>
                              {isloadTacbyagent ? <Loader /> : <TacticByAgenttable Tabledata={agentTacticsseries} columnData={["Tactic", "Agents", "Count"]} filterFields={'rule.mitre.tactic'} />}
                            </TabContentBody>
                          </Tab>
                          <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                            {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                              {isloadTacbyagent ? <Loader /> :
                                agentTacticsseries.length > 0 ?
                                  <EBarChart seriesData={agentTacticsseries.slice(0, 15)} filterFields={'rule.mitre.tactic'} height={'500px'} /> :
                                  <Emptystatefornodata />}
                            </TabContentBody>}
                          </Tab>
                        </Tabs>
                      </Card>
                    </Modal>
                  </Flex>

                </CardFooter>}
              </Card>

            </GridItem>
          </Grid>
        </div>
      }
      {props?.hideCard ? '' :
        <div className="pf-v5-u-mt-md">
          <Grid>
            <GridItem md={12} sm={12} lg={12}>
              <Card isExpanded isFullHeight>
                <CardTitle>MITRE Techniques By Agent</CardTitle>
                <Divider />
                <CardBody>
                  {isloadTechbyagent ? <Loader /> :
                    techniquebyagent.length > 0 ?
                      <EBarChart seriesData={techniquebyagent.slice(0, 5)} filterFields={'rule.mitre.technique'} height={'250px'} /> :
                      <Emptystatefornodata />
                  }
                </CardBody>
                {techniquebyagent.length > 0 && <CardFooter>
                  <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                    <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modaltechbyagent'); }}>
                      More
                    </Button>
                    <Modal
                      variant={ModalVariant.large}
                      title={`Techniques By Agents (${techniquebyagent.length})`}
                      isOpen={modalStates.modaltechbyagent}
                      onClose={() => handleModalToggle('modaltechbyagent')}
                    >
                      <Divider className='modal-Divider' />
                      <Card>
                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                          <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                            <TabContentBody className='pf-v5-u-p-xs'>
                              {isloadTechbyagent ? <Loader /> : <TacticByAgenttable Tabledata={techniquebyagent} columnData={["Technique", "Agents", "Count"]} filterFields={'rule.mitre.technique'} />}
                            </TabContentBody>
                          </Tab>
                          <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                            {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                              {isloadTechbyagent ? <Loader /> :
                                techniquebyagent.length > 0 ?
                                  <EBarChart seriesData={techniquebyagent.slice(0, 10)} filterFields={'rule.mitre.technique'} height={'500px'} /> :
                                  <Emptystatefornodata />}
                            </TabContentBody>}
                          </Tab>
                        </Tabs>
                      </Card>
                    </Modal>
                  </Flex>

                </CardFooter>}
              </Card>
            </GridItem>
          </Grid>
        </div>
      }
      <div className="pf-v5-u-mt-md">
        <Grid hasGutter>
          <GridItem md={12} sm={12} lg={12}>
            <Card isExpanded isFullHeight>
              <CardTitle>Attacks By Techniques</CardTitle>
              <Divider />
              <CardBody>
                {isloadAttacks ? <Loader /> : attackbyTechnique.length > 0 ?
                  <AttacksbyTechnique seriesData={attackbyTechnique.slice(0, 5)} filterFields={'rule.mitre.technique'} height={'250px'} /> :
                  <Emptystatefornodata />
                }
              </CardBody>
              {attackbyTechnique.length > 0 && <CardFooter>
                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                  <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalattackbytech'); }}>
                    More
                  </Button>
                  <Modal
                    variant={ModalVariant.large}
                    title={`Attacks By Techniques (${attackbyTechnique.length})`}
                    isOpen={modalStates.modalattackbytech}
                    onClose={() => handleModalToggle('modalattackbytech')}
                  >
                    <Divider className='modal-Divider' />
                    <Card>
                      <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                        <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                          <TabContentBody className='pf-v5-u-p-xs'>
                            {isloadAttacks ? <Loader /> : <TacticByAgenttable Tabledata={attackbyTechnique} columnData={["Technique", "Tactics", "Count"]} filterFields={'rule.mitre.technique'} />}
                          </TabContentBody>
                        </Tab>
                        <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                          {activeTabKey == '1' && <TabContentBody className='pf-v5-u-p-xs'>
                            {isloadAttacks ? <Loader /> :
                              attackbyTechnique.length > 0 ?
                                <AttacksbyTechnique seriesData={attackbyTechnique.slice(0, 10)} filterFields={'rule.mitre.technique'} height={'500px'} /> :
                                <Emptystatefornodata />}
                          </TabContentBody>}
                        </Tab>
                      </Tabs>
                    </Card>
                  </Modal>
                </Flex>

              </CardFooter>}
            </Card>
          </GridItem>
        </Grid>
      </div>
    </>
  )
})

const mapStateToProps = (state) => ({
  dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  selectedGroup: state.SelectedGroup,
  // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(MitreAttacksDashboard)


