import React, { useEffect, useState } from "react";
import {
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Table,
    ExpandableRowContent,
} from "@patternfly/react-table";
import {
    EmptyState,
    EmptyStateBody,
    EmptyStateHeader,
    EmptyStateIcon,
    Pagination,
    Select,
    SelectOption,
    SelectList,
    SelectGroup,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    MenuToggle,
    SearchInput,

} from "@patternfly/react-core";
import { connect } from "react-redux";
import { SearchIcon, SortAmountDownIcon } from "@patternfly/react-icons"
import { Loader } from "src/util/Loadingstate";
import { formatTableTimestamp } from "src/util/Timeutils";
import dbApi from '../../services/DbApi'
import ChildIndexTable from "./ChildIndexTable";
import MigrateDataForm from './MigrateDataForm';

const IndexTable = (props) => {
    const defaultColumns = {
        index_name: "Index Name",
        organization_name: "Organization Name",
        size: "Consumed Size",
        last_datetime: "Timestamp",
    };
    const [columns, setColumns] = useState(defaultColumns);
    const [rows, setRows] = useState([]);
    const initialExpandedRowNames = [];
    const [expandedRowNames, setExpandedRowNames] = useState(initialExpandedRowNames);
    const [expandedRowData, setExpandedRowData] = useState({});
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalindices, setTotalIndices] = useState(0);
    const [activeSortIndex, setActiveSortIndex] = useState(2);
    const [activeSortDirection, setActiveSortDirection] = useState('desc');
    const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
    const [selectedRowName, setSelectedRowName] = useState('');
    const [loadindices, setLoadIndices] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [searchValue, setSearchValue] = useState('');
    const handleSetPage = (_evt, newPage) => {
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage, newPage) => {
        setPage(newPage);
        setPerPage(newPerPage);
    };

    const getIndices = async () => {
        try {
            let params = {
                limitStart: (perPage * page) - perPage,
                limitLength: perPage,
                search: searchValue,
                sortBy: Object.keys(columns)[activeSortIndex],
                sortOrder: activeSortDirection,
                id: '',
            }
            setLoadIndices(true)
            // props.isload(true)
            let res = await dbApi.postApi("elasticsearch/index_List", params)
            console.log(res)
            let total = 0
            let totaldata = []
            if (!res.index_List.error) {
                totaldata = res.index_List.data.data
                total = res.index_List.data.totalRecord
                let size = res.index_List.data.Overall_Consumed_Size
                setRows(totaldata)
                setTotalIndices(total)
                props.Totalindex(total, size)

            }
            else {
                setRows(totaldata)
                setTotalIndices(total)
            }
            setLoadIndices(false)
            // props.isload(false)
        } catch (error) {
            console.log(error)
            setRows([])
            setTotalIndices(0)
            setLoadIndices(false)
            // props.isload(false)
        }
    }
    useEffect(() => {
        if (!props.LoadIndices) { getIndices() }
    }, [page,
        perPage,
        activeSortIndex,
        activeSortDirection,
        searchValue,
        props.LoadIndices
    ])

    const onSort = (event, index, direction) => {
        setActiveSortIndex(index);
        setActiveSortDirection(direction);
    };


    //code for row  expansion 
    const setRowExpanded = (row, isExpanding) => {
        const otherExpandedRowNames = expandedRowNames.filter((r) => r !== row.id);
        setExpandedRowNames(
            isExpanding ? [row.id] : []
        );
        if (!expandedRowData[row.id]) {
            // const keyifiedData = <ChildIndexTable ParentId={row?.id} />;
            setExpandedRowData([row.id]);
        }
        const updatedExpandedRowNames = isRowExpanded(row)
            ? otherExpandedRowNames
            : [row.id];
        setExpandedRowNames(updatedExpandedRowNames);

    };
    const isRowExpanded = (row) => {
        return expandedRowNames.includes(row.id);
    };


    const renderPagination = (variant) =>
        <Pagination
            itemCount={totalindices}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
            variant={variant}
        />;

    const onSearch = event => {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        setSearchValue(inputValue)
        setPage(1)
    }
    const tableToolbar = <Toolbar id="sortable-toolbar">
        <ToolbarContent>
            <ToolbarItem visibility={{ md: 'hidden' }}>
                <Select isOpen={isSortDropdownOpen} selected={[activeSortDirection, activeSortIndex]} onOpenChange={isOpen => setIsSortDropdownOpen(isOpen)} onSelect={(event, value) => {
                    if (value === 'asc' || value === 'desc') {
                        onSort(event, activeSortIndex, value);
                    } else {
                        onSort(event, value, activeSortDirection !== 'none' ? activeSortDirection : 'desc');
                    }
                }} toggle={toggleRef => <MenuToggle ref={toggleRef} onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)} isExpanded={isSortDropdownOpen} variant="plain">
                    <SortAmountDownIcon />
                </MenuToggle>}>
                    <SelectGroup label="Sort column">
                        <SelectList>
                            {Object.keys(columns).map((column, columnIndex) => <SelectOption key={column} value={columnIndex} isSelected={activeSortIndex === columnIndex}>
                                {column}
                            </SelectOption>)}
                        </SelectList>
                    </SelectGroup>
                    <SelectGroup label="Sort direction">
                        <SelectList>
                            <SelectOption isSelected={activeSortDirection === 'asc'} value="asc" key="ascending">
                                Ascending
                            </SelectOption>
                            <SelectOption isSelected={activeSortDirection === 'desc'} value="desc" key="descending">
                                Descending
                            </SelectOption>
                        </SelectList>
                    </SelectGroup>
                </Select>
            </ToolbarItem>
            <ToolbarItem key={'search-input'} variant="search-filter" style={{ width: '45%' }}>
                <SearchInput placeholder="Search..."
                    value={inputValue}
                    onChange={(_event, value) => setInputValue(value)}
                    onSearch={onSearch}
                    onClear={() => { setSearchValue(''); setInputValue('') }} />
            </ToolbarItem>
            <ToolbarItem align={{ default: 'alignRight' }}><MigrateDataForm /></ToolbarItem>
            <ToolbarItem >{renderPagination('top')}</ToolbarItem>
        </ToolbarContent>
    </Toolbar>;
    return (
        <React.Fragment>
            {loadindices || props.LoadIndices ?
                <Loader /> :
                <>
                    {tableToolbar}
                    <Table variant="compact" aria-label="indextable" id="indextable-table" isStickyHeader gridBreakPoint="">
                        <Thead >
                            <Tr key={'cahrt-table-header'}>
                                <Th key={'expand'} aria-label='expandall' screenReaderText="expandtable" />
                                {Object.values(columns).map((column, columnIndex) => {
                                    const sortParams = {
                                        sort: {
                                            sortBy: {
                                                index: activeSortIndex,
                                                direction: activeSortDirection
                                            },
                                            onSort,
                                            columnIndex
                                        }
                                    };
                                    return <Th key={column} {...sortParams} isStickyColumn modifier="wrap">{column}</Th>;
                                })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {rows && rows.length > 0 ?
                                rows.map((row, rowIndex) => {
                                    const { formattedTimeStamp } = formatTableTimestamp(row?.last_datetime);
                                    return (
                                        <>
                                            <Tr key={rowIndex + 'row'}
                                                onRowClick={() => setSelectedRowName(rowIndex)}
                                                isSelectable
                                                isClickable
                                                isRowSelected={selectedRowName === rowIndex} >
                                                <Td
                                                    expand={{
                                                        rowIndex: rowIndex,
                                                        isExpanded: isRowExpanded(row),
                                                        onToggle: () => setRowExpanded(row, !isRowExpanded(row)),

                                                    }}
                                                    key={"exp-" + rowIndex}
                                                />
                                                <Td width={20} dataLabel="Index Name" key={rowIndex + 'indexname'}>{row?.index_name}</Td>
                                                <Td width={30} dataLabel="Organization Name" key={rowIndex + 'orgname'}>{row?.organization_name}</Td>
                                                <Td width={20} key={rowIndex + 'sizeused'} dataLabel="Consumed Size">{row?.size}</Td>
                                                <Td width={30} key={rowIndex + 'time'} dataLabel="Timestamp">{formattedTimeStamp}</Td>
                                            </Tr>
                                            <Tr isExpanded={isRowExpanded(row)}>
                                                <Td colSpan={Object.keys(columns).length + 1}>
                                                    <ExpandableRowContent>
                                                        {isRowExpanded(row) && (
                                                            <ChildIndexTable ParentId={row?.id} />
                                                        )}
                                                    </ExpandableRowContent>
                                                </Td>
                                            </Tr>
                                        </>
                                    )
                                })
                                :
                                <Tr>
                                    <Td colSpan={10} key='empty-data'>
                                        <EmptyState>
                                            <EmptyStateHeader titleText="No results found" headingLevel="h4" icon={<EmptyStateIcon icon={SearchIcon} />} />
                                            <EmptyStateBody>No results match the filter criteria. Clear all filters and try again.</EmptyStateBody>

                                        </EmptyState>
                                    </Td>
                                </Tr>
                            }
                        </Tbody>
                    </Table>
                        {renderPagination('bottom')}
                </>
            }

        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(IndexTable)
