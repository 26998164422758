import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from 'src/util/UniqueIdGenerator';
import { formatValue } from 'src/util/ChartHelper';
import { connect, useDispatch } from 'react-redux';
const IncidentBarChart = (props) => {
    const id = generateUniqueID()
    useEffect(() => {
        if (props.seriesData && props.seriesData.length > 0) {
            const chartDom = document.getElementById(id + 'ibc');
            const myChart = echarts.init(chartDom);
            const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
            const linecolor = props.theme == 'dark-theme' ? '#595757' : '#d9d9d9'

            const uniqueUsers = [...new Set(props.seriesData
                .filter(item => item.create_by_user !== null) // Ignore null users
                .map(item => item.create_by_user))];
            
            const option = {
                animation: false,
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        color: "#000"
                    },
                },
                grid: {
                    left: '2%',
                    right: 0,
                    bottom: '10%',
                    top: '5%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLabel: { interval: 0, color: textcolor, rotate: 45 },
                    data: uniqueUsers,
                    axisTick: {
                        alignWithLabel: true
                    },
                    name: 'Investigators Name',
                    nameLocation: "middle",
                    nameTextStyle: {
                        align: "center",
                        lineHeight: 110,
                        fontSize: 14,
                        color: textcolor,
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: true
                    },
                    splitLine: {
                        lineStyle: {
                            color: linecolor
                        }
                    },
                    axisLabel: {
                        formatter: (value) => {
                            return Math.floor(value); // Ensures only whole numbers are displayed
                        },
                        color: textcolor
                    }
                },
                color: ['#0066cc'],
                series: [
                    {
                        name: 'count',
                        data: uniqueUsers.map(user => {
                            return props.seriesData
                                .filter(item => item.create_by_user === user)
                                .reduce((sum, current) => sum + parseInt(current.total), 0);
                        }),
                        type: 'bar',
                        barWidth: 20,
                    }
                ]
            };
            option && myChart.setOption(option);
            const resizeChart = () => {
                if (myChart) { // Check if chart exists before resizing
                    myChart.resize();
                }
            };

            window.addEventListener('resize', resizeChart);
            return () => {
                myChart.dispose();
                window.removeEventListener('resize', resizeChart);
            };
        }
    }, [props.seriesData, props.theme, props.sidebarState])

    return <> <div className="container">
        <div id={id + 'ibc'} style={{ width: '100%', height: '230px' }} > </div>
    </div>
    </>
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(IncidentBarChart)