
import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { connect, useDispatch } from 'react-redux';
import { FilterData } from '../../Redux/Action';
import { UpdateFilterData } from '../../util/FilterHelper';

const EWordCloud = (props) => {
    const id = generateUniqueID()
    const dispatch = useDispatch();
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }
    useEffect(() => {
        const chartDom = document.getElementById(id + 'ewcc');
        const myChart = echarts.init(chartDom);
        const textcolor = props?.theme == 'dark-theme' ? '#fff' : '#000'
        const option = {
            layoutAnimation: false,
            title: {
                text: props?.title ? props?.title : null,
                textstyle: {
                    color: textcolor,
                }
            },
            tooltip: {
                trigger: 'item',
                position: [20, 20],
                formatter: function (params) {
                    if (typeof params.data.nestedkey1 != 'undefined') { return params.data.name + ' : ' + params.value + `(RiskScore->${params.data.nestedkey1})` }
                    else {
                        return params.data.name + ' : ' + params.value
                    }
                },
            },
            series: [
                {
                    textStyle: {
                        color: function () {
                            if (props?.theme == 'dark-theme') {
                                return 'rgb(' + [
                                    Math.round(100 + Math.random() * 150),
                                    Math.round(100 + Math.random() * 150),
                                    Math.round(100 + Math.random() * 150)
                                ].join(',') + ')';
                            }
                            else {
                                return 'rgb(' + [
                                    Math.round(Math.random() * 160),
                                    Math.round(Math.random() * 160),
                                    Math.round(Math.random() * 160)
                                ].join(',') + ')';
                            }
                        }
                    },
                    emphasis: {
                        focus: 'self',

                        textStyle: {
                            textShadowBlur: 5,
                            textShadowColor: '#333'
                        }
                    },
                    type: 'wordCloud',
                    gridSize: 2,
                    keepAspect: false,
                    sizeRange: [12, 60],
                    shape: 'Star',
                    rotationRange: [-90, 90],
                    left: 'center',
                    top: 'center',
                    width: '100%',
                    right: null,
                    bottom: null,
                    drawOutOfBound: false,
                    shrinkToFit: true,
                    data: props.cloudseries.map((bucket) => {
                        const mappedData = {
                            value: bucket.doc_count,
                            name: bucket.key,
                            nestedkey: bucket.type,
                            nestedkey1: bucket.score
                        };
                        return mappedData;
                    }
                    ),
                },
            ],
        };
        option.animationDuration = 0;
        option && myChart.setOption(option);
        if (props.filterFields !== 'health') {
            myChart.on('click', 'series.wordCloud', function (param) {
                const Fields = props.filterFields ? props.filterFields : param.data.nestedkey
                handleFilter(Fields, param.data.name)
                if (props?.reDirect) {
                    window.location.href = "#/security-events";
                }
            });
        }
        const resizeChart = () => { if (myChart) { myChart.resize(); } };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, [props.cloudseries, props.filterFields, props.theme, props.title, props.sidebarState]);


    return <div className="container">
        {props.cloudseries.length == 0 ?
            (<>
                <div id={id + 'ewcc'} style={{ width: '1px', height: '1px' }}> </div>
                < Emptystatefornodata />
            </>)
            : (
                <div id={id + 'ewcc'} style={{ width: '100%', height: props.height }} > </div>
            )
        }
    </div>
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState: state.sidebarstate
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(EWordCloud)


