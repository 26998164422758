import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Divider,
    Flex,
    FlexItem,
    Grid,
    GridItem,
    Icon,
    Text,
    TextContent,
    TextVariants,
    Tooltip,
    Modal,
    ModalVariant,
    CardHeader,
    Tabs,
    Tab,
    TabTitleText,
    Bullseye,
    List,
    ListItem,
    ListVariant,
    ToggleGroup,
    ToggleGroupItem,

} from '@patternfly/react-core';
import { ChartAreaIcon, PlusIcon, } from '@patternfly/react-icons';
import dbApi from '../../services/DbApi'
import { Level12AlertsLog } from './HighLevelalerts';
import { AuthenticationSuccessLog } from './AuthenticationSuccessTable';
import { AuthenticationFailureLog } from './AuthenticationFailureTable';
import { ThreatDetectionTable } from './ThreatDetectionTable';
import { ActiveResponseTable } from './ActiveResponseTable';
import TableViewForChart from '../AllCharts/TableViewForChart';
import EWordCloud from '../AllCharts/EWordCloud';
import DashboardSecurityAlerts from './SecurityAlerts';
import EBarStackChart from '../AllCharts/EBarStackChart';
import { connect, useDispatch, useSelector } from 'react-redux';
import SeverityStatus from './SeverityStatus';
import { Emptystatefornodata } from '../../util/EmptyStateCard';
import { Loader, RoundSkeleton, TextSkeleton } from '../../util/Loadingstate';
import Elinestackchart from '../AllCharts/Elinestackchart';
import { FilterData, alertBoxData } from 'src/Redux/Action';
import RuleClassificationtable from './RuleClassificationtable';
import SecurityAlertDesc from './SecurityAlertsDescription'
import AlertBox from '../AlertBox/AlertBox';
import NetworkUserIP from './NetworkUserIP';
import NetworkUserIPTable from './TopIPTable'
import { formatValue } from 'src/util/ChartHelper';

const SecurityDashboard = forwardRef(function SecurityDashboard(props, ref) {
    const dispatch = useDispatch();
    const childSecurityAlertsRef = useRef(null);
    const childThreatDetectionRef = useRef(null);
    const childActiveResponseRef = useRef(null);
    const childAuthSuccessRef = useRef(null);
    const childAuthFailureRef = useRef(null);
    const childAlertLevelRef = useRef(null);
    const [alertdesc, setAlertDesc] = useState([])
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [AuthenticationSuccess, setAuthenticationSuccess] = useState('0');
    const [TotalLog, setTotalLog] = useState('0');
    const [level, setLevel] = useState('0');
    const [levelabove12, setLevelabove12] = useState([])
    const [criticalRules, setCriticalRules] = useState([])
    const [Vulnerabilities, setVulnerabilities] = useState('0');
    const [AuthenticationFailure, setAuthenticationFailure] = useState('0');
    const [SAscrollId, setSAscrollId] = useState('');
    const [TDscrollId, setTDscrollId] = useState('');
    const [ARscrollId, setARscrollId] = useState('');
    const [AFscrollId, setAFscrollId] = useState('');
    const [ASscrollId, setASscrollId] = useState('');
    const [levelscrollId, setLevelscrollId] = useState('');
    const [TDdata, setTDdata] = useState('0');
    const [TDlog, setTDlog] = useState([]);
    const [ARlog, setARlog] = useState([]);
    const [ASLog, setASLog] = useState([]);
    const [AFLog, setAFLog] = useState([]);
    const [levelLog, setLevelLog] = useState([]);
    const [ARdata, setARdata] = useState('0');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [severityStatus, setSeverityStatus] = useState([]);
    const [mitreSeries, setMitreSeries] = useState([]);
    const [rulegroup, setRuleGroup] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [netIP, setNetIP] = useState([])
    const [alertlevelseries, setAlertLevelSeries] = useState([]);
    const [alertevolutionLabel, setAlertEvolutionLabel] = useState('');
    const [alertevolutionTopseries, setAlertEvolutionTopSeries] = useState([]);
    const [alertevolutionTopLabel, setAlertEvolutionTopLabel] = useState('');
    const [securityAlertsLog, setSecurityAlertsLog] = useState([]);
    const [users, setUsers] = useState([]);
    //Loading States
    const [loadstatus, setLoadStatus] = useState(true)
    const [islogloading, setIslogLoading] = useState(true)
    const [isloadlevel, setIsLoadLevel] = useState(true)
    const [isloadAs, setIsLoadAs] = useState(true)
    const [isloadAf, setIsLoadAf] = useState(true)
    const [isloadTd, setIsLoadTd] = useState(true)
    const [isloadAr, setIsLoadAr] = useState(true)
    const [isloadVul, setIsLoadVul] = useState(true)
    const [isloadAlert, setIsLoadAlert] = useState(true)
    const [isloadAgent, setIsLoadAgent] = useState(true)
    const [isloadMitre, setIsLoadMitre] = useState(true)
    const [isloadUsers, setIsLoadUsers] = useState(true)
    const [isloadTopAlert, setIsLoadTopAlert] = useState(true)
    const [isloadRule, setIsLoadRule] = useState(true)
    const [isloadSecAlert, setIsLoadSecAlert] = useState(true)
    const [isloadalertdesc, setIsLoadAlertDesc] = useState(false)
    const [isloadUserIP, setIsLoadUserIP] = useState(false)
    const [selectediptype, setSelectedIptype] = useState('all');


    const [modalStates, setModalStates] = useState({
        modalAgent: false,
        modalTopMitre: false,
        modalTopAgent: false,
        modalAlertLevel: false,
        modalTDData: false,
        modalARData: false,
        modalRuleGroup: false,
        modalalerts: false,
        modalIPs: false,
        modalAS: false,
        modalAF: false,
        modalLevel: false,
        modalUser: false,
        modalRuleClass: false,

    });

    const handleTabClick = (event, tabIndex) => {
        setActiveTabKey(tabIndex)
    }

    const handleModalToggle = (modalName) => {
        setModalStates((prevState) => ({
            ...prevState,
            [modalName]: !prevState[modalName],
        }));
    };
    const handleIptoggle = (event, isSelected) => {
        const id = event.currentTarget.id;
        setSelectedIptype(id);
    };

    //Severity Status
    const getSeverity = async (data) => {
        let SeverityStatus = [];
        try {
            setLoadStatus(true)
            let severity = await dbApi.postApi("elasticsearch/Security_Dasbhoard_Severity_Status", data);
            console.log(severity)
            if (Object.keys(severity.Security_Dasbhoard_Severity_Status).length !== 0) {
                let severity_status = severity.Security_Dasbhoard_Severity_Status.body.aggregations.level_ranges;
                SeverityStatus = severity_status.buckets;
                setSeverityStatus(SeverityStatus);
            }
            else {
                setSeverityStatus(SeverityStatus);
            }
            setLoadStatus(false)
        } catch (error) {
            console.error('Error in getSeverity:', error);
            setSeverityStatus(SeverityStatus);
            setLoadStatus(false)
        }
    };
    //get total logs
    const getTotalLog = async (data) => {
        try {
            setIslogLoading(true)
            let Total_Log = await dbApi.postApi('elasticsearch/Secuirty_Dashboard_Total', data);
            console.log(Total_Log)
            let totallog = '0';
            if (Object.keys(Total_Log.Secuirty_Dashboard_Total).length !== 0) {
                let Dashboard_Total = Total_Log.Secuirty_Dashboard_Total.body.hits;
                totallog = Dashboard_Total.total.value;
                setTotalLog(totallog);
            }
            else {
                setTotalLog(totallog);
            }
            setIslogLoading(false)
        } catch (error) {
            console.error('Error in getTotalLog:', error);
            setTotalLog(0);
            setIslogLoading(false)
        }
    };
    //get level 12 and above alerts
    const getLevel = async (data) => {
        try {
            setIsLoadLevel(true)
            let Total_Level = await dbApi.postApi('elasticsearch/Security_Dashboard_Level', data);
            console.log(Total_Level);
            let Level = '0';
            let LevelLog = []
            let LevelScrollId = ''
            if (Object.keys(Total_Level.Security_Dasbhoard_Level).length !== 0) {
                let Dashboard_level = Total_Level.Security_Dasbhoard_Level.body.hits
                Level = Dashboard_level.total.value
                LevelLog = Dashboard_level.hits;
                LevelScrollId = Total_Level.Security_Dasbhoard_Level.body._scroll_id;
                setLevel(Level)
                setLevelLog(LevelLog)
                setLevelscrollId(LevelScrollId)
            }
            else {
                setLevel(Level)
                setLevelLog(LevelLog)
                setLevelscrollId(LevelScrollId)
            }
            setIsLoadLevel(false)
        }
        catch (error) {
            console.log(error)
            setLevel(0)
            setLevelLog([])
            setLevelscrollId('')
            setIsLoadLevel(false)
        }
    }
    const getCriticalRules = async (data) => {
        try {
            let allcritical = await dbApi.postApi("elasticsearch/Security_Dasbhoard_Rule_Description", data)
            console.log(allcritical)
            let critical_rules = allcritical.Security_Dasbhoard_Rule_Description
            let CriticalRules = []
            if (critical_rules.length > 0) {
                CriticalRules = critical_rules
                setCriticalRules(CriticalRules)
            } else {
                setCriticalRules(CriticalRules)
            }

        } catch (error) {
            console.log(error)
            setCriticalRules([])
        }
    }

    useEffect(() => {
        if (childAlertLevelRef.current) {
            childAlertLevelRef.current.setAlertLevelTable(levelLog, levelscrollId);
        }
    }, [levelLog, levelscrollId]);

    const setLevelData = () => {
        let leveldata = levelLog
        setLevelLog([])
        setTimeout(function () { setLevelLog(leveldata) }, 300)
    }

    //get authentication success
    const getAuthSuccess = async (data) => {
        try {
            setIsLoadAs(true)
            let Total_AuthSuccess = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Authentication_Success', data);
            console.log(Total_AuthSuccess);
            let authenticationSuccess = '0'
            let asLog = []
            let ScrollId = ''
            if (Object.keys(Total_AuthSuccess.Security_Dasbhoard_Authentication_Success).length !== 0) {
                let Dashboard_Auth_Success = Total_AuthSuccess.Security_Dasbhoard_Authentication_Success.body.hits
                authenticationSuccess = Dashboard_Auth_Success.total.value

                asLog = Dashboard_Auth_Success.hits;
                ScrollId = Total_AuthSuccess.Security_Dasbhoard_Authentication_Success.body._scroll_id

                setAuthenticationSuccess(authenticationSuccess)
                setASLog(asLog)
                setASscrollId(ScrollId)
            }
            else {
                setAuthenticationSuccess(authenticationSuccess)
                setASLog(asLog)
                setASscrollId(ScrollId)
            }
            setIsLoadAs(false)

        }
        catch (error) {
            console.log(error)
            setAuthenticationSuccess(0)
            setASLog([])
            setASscrollId('')
            setIsLoadAs(false)
        }
    }
    useEffect(() => {
        if (childAuthSuccessRef.current) {
            childAuthSuccessRef.current.setAuthSuccessTable(ASLog, ASscrollId);
        }
    }, [ASLog, ASscrollId]);
    const setAuthSuccessData = () => {
        let ASdata = ASLog
        setASLog([])
        setTimeout(function () { setASLog(ASdata) }, 0)
    }
    //get authentication failure
    const getAuthFailure = async (data) => {
        try {
            setIsLoadAf(true)
            let Total_AuthFailure = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Authentication_failure', data);
            console.log(Total_AuthFailure);
            let authenticationFailure = '0'
            let AFLog = []
            let AFscrollId = ''
            if (Object.keys(Total_AuthFailure.Security_Dasbhoard_Authentication_failure).length !== 0) {
                let Dashboard_Auth_Failure = Total_AuthFailure.Security_Dasbhoard_Authentication_failure.body.hits
                authenticationFailure = Dashboard_Auth_Failure.total.value
                AFLog = Dashboard_Auth_Failure.hits;
                AFscrollId = Total_AuthFailure.Security_Dasbhoard_Authentication_failure.body._scroll_id

                setAuthenticationFailure(authenticationFailure)
                setAFLog(AFLog)
                setAFscrollId(AFscrollId)
            }
            else {
                setAuthenticationFailure(0)
                setAFLog([])
                setAFscrollId('')
            }
            setIsLoadAf(false)
        }
        catch (error) {
            console.log(error)
            setAuthenticationFailure(0)
            setAFLog([])
            setAFscrollId('')
            setIsLoadAf(false)
        }
    }
    useEffect(() => {
        if (childAuthFailureRef.current) {
            childAuthFailureRef.current.setAuthFailureTable(AFLog, AFscrollId);
        }
    }, [AFLog, AFscrollId]);
    const setAuthFailureData = () => {
        let AFdata = AFLog
        setAFLog([])
        setTimeout(function () { setAFLog(AFdata) }, 5)
    }
    //Threat Detection
    const getThreatDetection = async (data) => {
        try {
            setIsLoadTd(true)
            let Total_TD = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Tip_Threat_Detection', data);
            console.log(Total_TD);
            let tdlog = [];
            let scrollId = ''
            let tddata = 0
            let MispCount = 0
            let tipCount = 0
            if (Object.keys(Total_TD.Security_Dasbhoard_Tip_Threat_Detection).length !== 0) {
                let misp_count = Total_TD.Security_Dasbhoard_Tip_Threat_Detection.Security_Dasbhoard_Tip.body.aggregations.Threat_Detection_Misp.doc_count
                if (misp_count > 0) { MispCount = misp_count };
                let tip_count = Total_TD.Security_Dasbhoard_Tip_Threat_Detection.Security_Dasbhoard_Tip.body.aggregations.Threat_Detection_Tip.doc_count
                if (tip_count > 0) { tipCount = tip_count };

                tdlog = Total_TD.Security_Dasbhoard_Tip_Threat_Detection.Security_Dasbhoard_Tip.body.hits.hits
                scrollId = Total_TD.Security_Dasbhoard_Tip_Threat_Detection.Security_Dasbhoard_Tip.body._scroll_id

                setTDdata(MispCount + tipCount)
                setTDlog(tdlog)
                setTDscrollId(scrollId)
            }
            else {
                setTDdata(tddata)
                setTDlog(tdlog)
                setTDscrollId(scrollId)
            }
            setIsLoadTd(false)
        } catch (error) {
            console.log(error)
            setTDdata(0)
            setTDlog([])
            setTDscrollId('')
            setIsLoadTd(false)
        }

    }
    useEffect(() => {
        if (childThreatDetectionRef.current) {
            childThreatDetectionRef.current.setThreatDetectionTable(TDlog, TDscrollId);
        }
    }, [TDlog, TDscrollId]);
    const setThreatDetectData = () => {
        let TDdata = TDlog
        setTDlog([])
        setTimeout(function () { setTDlog(TDdata) }, 5)
    }
    //Active Response
    const getActiveResponse = async (data) => {
        try {
            setIsLoadAr(true)
            let Total_AR = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Active_Response', data);
            console.log(Total_AR);
            let ardata = '0'
            let arLog = [];
            let scrollId = ''
            let ActiveResponse = Total_AR.Security_Dasbhoard_Active_Response
            if (Object.keys(ActiveResponse).length !== 0) {
                let TIP_AR = ActiveResponse.Security_Dasbhoard_Active.body.aggregations
                let ActiveResponse_log = ActiveResponse.Security_Dasbhoard_Active.body.hits
                scrollId = ActiveResponse.Security_Dasbhoard_Active.body._scroll_id
                arLog = ActiveResponse_log.hits;
                ardata = TIP_AR.Active_response.doc_count;
                setARdata(ardata)
                setARlog(arLog)
                setARscrollId(scrollId)
            }
            else {
                setARdata(ardata)
                setARlog(arLog)
                setARscrollId(scrollId)
            }
            setIsLoadAr(false)
        } catch (error) {
            console.log(error)
            setARdata(0)
            setARlog([])
            setARscrollId('')
            setIsLoadAr(false)
        }

    }
    useEffect(() => {
        if (childActiveResponseRef.current) {
            childActiveResponseRef.current.setActiveResponseTable(ARlog, ARscrollId);
        }
    }, [ARlog, ARscrollId]);
    const setActiveResponseData = () => {
        let ARdata = ARlog
        setARlog([])
        setTimeout(function () { setARlog(ARdata) }, 5)
    }

    //Vulnerabilities
    const getVulnerabilities = async (data) => {
        try {
            setIsLoadVul(true)
            // let Total_vulnerabilities = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Total_Vulnerabilities', data)
            let Total_vulnerabilities = await dbApi.postApi("opensearch/get_total_vulnerability", data);
            console.log(Total_vulnerabilities);
            let vulnerabilities = '0'
            if (!Total_vulnerabilities.error) {
                vulnerabilities = Total_vulnerabilities.data.active
                setVulnerabilities(vulnerabilities)
                setIsLoadVul(false)
            }
            else {
                setVulnerabilities(vulnerabilities)
                setIsLoadVul(false)
            }
        }
        catch (error) {
            console.log(error)
            setVulnerabilities(0)
            setIsLoadVul(false)
        }
    }
    //alert evolution 1st grid chart
    const getAlertEvolution = async (data) => {
        try {
            setIsLoadAlert(true)
            let All_alertlevel = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Alert_level_evolution', data)
            console.log(All_alertlevel)
            let AlertLevelSeries = [];
            let Label = '';
            let AlertEvolutionData = All_alertlevel.Security_Dasbhoard_Alert_level_evolution
            if (Object.keys(AlertEvolutionData).length !== 0) {
                let all_alert_evolution = AlertEvolutionData.data.body.aggregations.Alert_level_evolution;
                AlertLevelSeries = all_alert_evolution.buckets;
                Label = AlertEvolutionData.interval
                setAlertLevelSeries(AlertLevelSeries)
                setAlertEvolutionLabel(Label)
            }
            else {
                setAlertLevelSeries(AlertLevelSeries)
                setAlertEvolutionLabel(Label)
            }
            setIsLoadAlert(false)
        }
        catch (error) {
            console.log(error)
            setAlertLevelSeries([])
            setAlertEvolutionLabel('')
            setIsLoadAlert(false)
        }
    }
    //count of particular rule level
    const getrulelevelcount = async (data) => {
        try {
            let rulelevelCount = await dbApi.postApi("elasticsearch/Security_Dasbhoard_Rule_Level", data)
            console.log(rulelevelCount)
            let level12count = []
            if (Object.keys(rulelevelCount.Security_Dasbhoard_Rule_Level).length !== 0) {
                let allcount = rulelevelCount.Security_Dasbhoard_Rule_Level.body.aggregations.Rule_level
                level12count = allcount.buckets
                setLevelabove12(level12count)
            }
            else {
                setLevelabove12(level12count)
            }
        } catch (error) {
            console.log(error)
            setLevelabove12([])
        }
    }
    //get top alertevolution top 5 agent 2nd grid
    const getTopAlertEvolution = async (data) => {
        try {
            setIsLoadAgent(true)
            let Top_Alerts = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Alerts_evolution_Top_5_agents', data)
            console.log(Top_Alerts)
            let AlertevolutionTopseries = [];
            let AlertevolutionTopLabel = '';
            let Top5 = Top_Alerts.Security_Dasbhoard_Alerts_evolution_Top_5_agents
            if (Object.keys(Top5).length !== 0) {
                AlertevolutionTopseries = Top5.data.body.aggregations.Top_5_agents.buckets;
                AlertevolutionTopLabel = Top5.interval
                setAlertEvolutionTopSeries(AlertevolutionTopseries)
                setAlertEvolutionTopLabel(AlertevolutionTopLabel)
            }
            else {
                setAlertEvolutionTopSeries(AlertevolutionTopseries)
                setAlertEvolutionTopLabel(AlertevolutionTopLabel)
            }
            setIsLoadAgent(false)
        } catch (error) {
            console.log(error)
            setIsLoadAgent(false)
            setAlertEvolutionTopSeries([])
            setAlertEvolutionTopLabel('')
        }

    }
    //Mitre attack cloud
    const getTopMitreAttack = async (data) => {
        try {
            setIsLoadMitre(true)
            let All_mitreattack = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Top_Mitre_Attacks', data)
            console.log(All_mitreattack);
            let MitreSeries = [];
            let security_mitreattack = All_mitreattack.Security_Dasbhoard_Top_Mitre_Attacks
            if (Object.keys(security_mitreattack).length !== 0) {
                MitreSeries = security_mitreattack.body.aggregations.Top_MITRE_ATTACKS.buckets

                setMitreSeries(MitreSeries)
            }
            else {
                setMitreSeries(MitreSeries)
            }
            setIsLoadMitre(false)
        }
        catch (error) {
            console.log(error)
            setMitreSeries([])
            setIsLoadMitre(false)
        }
    }
    //Top users cloud
    const getTopUsers = async (data) => {
        try {
            setIsLoadUsers(true)
            let allusers = await dbApi.postApi("elasticsearch/User_Behaviour_Monitoring_Top_Dstuser_Srcuser", data);
            console.log(allusers);
            let all_users = allusers.User_Behaviour_Monitoring_Top_Dstuser_Srcuser;
            let Users = []
            if (Object.keys(all_users).length > 0) {
                Users = all_users.filter(item => !item.key.includes('$'))
                setUsers(Users)
            }
            else {
                setUsers(Users)
            }
            setIsLoadUsers(false)
        }
        catch (error) {
            console.log(error)
            setUsers([])
            setIsLoadUsers(false)
        }
    }
    //Top Ips 
    const getUserIPs = async (data) => {
        try {
            setIsLoadUserIP(true)
            let res = await dbApi.postApi("elasticsearch/Network_Ip", data)
            console.log("res", res)
            let All_IP = []
            if (res.Network_Ip.length > 0) {
                All_IP = res.Network_Ip
                setNetIP(All_IP)
            }
            else {
                setNetIP(All_IP)
            }
            setIsLoadUserIP(false)
        } catch (error) {
            console.log(error)
            setNetIP([])
            setIsLoadUserIP(false)
        }
    }
    // Top alerts according rule description elasticsearch/Security_Dasbhoard_Top_Rule_Id_Description 
    // const getTopAlerts = async (data) => {
    //     try {
    //         setIsLoadTopAlert(true)
    //         let allalerts = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Top_Rule_Id_Description', data)
    //         console.log(allalerts);
    //         let all_alerts = allalerts.Security_Dasbhoard_Top_Rule_Id_Description;//Security_Dasbhoard_Top_Rule_Id_Description
    //         let Alerts = [];
    //         if (all_alerts.length > 0) {
    //             const FlatLog = all_alerts.map((logname) => {
    //                 return {
    //                     key: logname.Description[0].key,
    //                     doc_count: logname.Description[0].doc_count,
    //                     Critical_levels: logname.Description[0].Critical_levels,
    //                     ruleId:logname.key
    //                 };
    //             });
    //             // console.log("FlatLog",FlatLog)
    //             Alerts = FlatLog
    //             // Alerts = all_alerts
    //             setAlerts(Alerts)
    //         }
    //         else {
    //             setAlerts(Alerts)
    //         }

    //         setIsLoadTopAlert(false)
    //     }
    //     catch (error) {
    //         console.log(error)
    //         setAlerts([])
    //         setIsLoadTopAlert(false)
    //     }
    // }
    //Top rule groups
    const getRuleGroups = async (data) => {
        try {
            setIsLoadRule(true)
            let rulegroups = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Top_5_Rule_Groups', data)
            console.log(rulegroups);
            let Rulegroup = [];
            let rule_groups = rulegroups.Security_Dasbhoard_Top_5_Rule_Groups
            if (Object.keys(rule_groups).length !== 0) {
                Rulegroup = rule_groups.body.aggregations.Top_Groups.buckets
                setRuleGroup(Rulegroup)
            }
            else {
                setRuleGroup(Rulegroup)
            }
            setIsLoadRule(false)
        }
        catch (error) {
            console.log(error)
            setRuleGroup([])
            setIsLoadRule(false)
        }
    }
    //Security alerts table log last grid
    const getSecurityalerts = async (data) => {
        try {
            setIsLoadSecAlert(true)
            let all_security_Alerts = await dbApi.postApi('elasticsearch/Security_Dasbhoard_Security_Alerts', data)
            console.log(all_security_Alerts)
            let SecurityAlertsLog = [];
            let ScrollID = ''
            if (Object.keys(all_security_Alerts.Security_Dasbhoard_Security_Alerts).length !== 0) {
                let security_alerts = all_security_Alerts.Security_Dasbhoard_Security_Alerts.Security_Dasbhoard_Security_Alerts.body
                SecurityAlertsLog = security_alerts.hits.hits;
                ScrollID = security_alerts._scroll_id
                setSecurityAlertsLog(SecurityAlertsLog)
                setSAscrollId(ScrollID)

            } else {
                setSecurityAlertsLog(SecurityAlertsLog)
                setSAscrollId(ScrollID)
            }
            setIsLoadSecAlert(false)
        } catch (error) {
            console.log(error)
            setIsLoadSecAlert(false)
        }
    }
    useEffect(() => {
        if (childSecurityAlertsRef.current) {
            childSecurityAlertsRef.current.setSecurityAlertsTable(securityAlertsLog, SAscrollId);
        }
    }, [securityAlertsLog, SAscrollId]);

    //SecurityAlerts Description
    const getSecurityAlertDesc = async (data) => {
        try {
            setIsLoadAlertDesc(true)
            let res = await dbApi.postApi("elasticsearch/Security_Dasbhoard_Alerts_Description", data)
            console.log(res)
            let totalLog = []
            // if (Object.keys(res.Security_Dasbhoard_Alerts_Description).length !== 0) {
            if (res.Security_Dasbhoard_Alerts_Description.length > 0) {
                totalLog = res.Security_Dasbhoard_Alerts_Description
                setAlertDesc(totalLog)
            }
            else {
                setAlertDesc(totalLog)
            }
            setIsLoadAlertDesc(false)
        } catch (error) {
            console.log(error)
            setAlertDesc([])
            // dispatch(alertBoxData([{ title: 'Api Failed', variant: 'danger' }]));
            setIsLoadAlertDesc(false)
        }
    }


    const [intervalId, setIntervalId] = useState(null);

    const dashboardApi = async (startDate, endDate, allFields) => {
        // GroupId
        let staticfield = []
        if (!props?.ZeekAlerts) {
            staticfield = [{
                "fields": 'rule.groups',
                'condition': 'is_not',
                'value': 'zeek'
            }]
        }
        else {
            staticfield = staticfield
        }
        const Fields = [...allFields, ...staticfield]

        const data = {
            startDate: startDate,
            endDate: endDate,
            filter: JSON.stringify(Fields),
            // signal
            // groupId:GroupId
        };
        console.log(data)
        try {
            setStartDate(startDate)
            setEndDate(endDate)
            getSeverity(data);
            getTotalLog(data)
            getLevel(data);
            getrulelevelcount(data);
            getCriticalRules(data)
            getAuthSuccess(data);
            getAuthFailure(data);
            getThreatDetection(data);
            getActiveResponse(data);
            getVulnerabilities(data);
            getAlertEvolution(data);
            getTopAlertEvolution(data);
            getTopMitreAttack(data);
            getTopUsers(data)
            getUserIPs(data)
            getRuleGroups(data);
            getSecurityAlertDesc(data)


        } catch (error) {
            console.log(error)
        }
    }
    const Rulelevels = <List variant={ListVariant.inline}>
        {levelabove12.map((item, index) => (
            <ListItem key={item.key + "+" + index}>{item.key}{`->`}{item.doc_count}</ListItem>
        ))}
    </List>


    useEffect(() => {
        if (props?.dates.startDate != "") {
            let Dates = props.dates
            // abortControllerRef.current.abort()
            dashboardApi(Dates.startDate, Dates.endDate, props.filterformdata)

            // const newIntervalId = setInterval(() => {
            //     // console.log("props?.GlobalInterval",props?.GlobalInterval)
            //     dashboardApi(Dates.startDate, Dates.endDate, props.filterformdata)
            // }, 5000);

            // setIntervalId(newIntervalId);

            // return () => clearInterval(newIntervalId);

        }
    }, [props?.dates.startDate, props?.dates.endDate, props?.filterformdata, props?.ZeekAlerts]);

    useEffect(() => {
        let ipfield = [{
            "fields": 'rule.groups',
            'condition': 'is',
            'value': 'tip'
        }]
        let staticfield = []
        if (!props?.ZeekAlerts) {
            staticfield = [{
                "fields": 'rule.groups',
                'condition': 'is_not',
                'value': 'zeek'
            }]
        }
        else {
            staticfield = staticfield
        }
        if (props?.dates.startDate != "") {
            if (selectediptype == 'malicious') {
                // alert(1)
                const Fields = [...props?.filterformdata, ...staticfield, ...ipfield]
                const data = {
                    startDate: props?.dates.startDate,
                    endDate: props?.dates.endDate,
                    filter: JSON.stringify(Fields),
                };
                getUserIPs(data)
            }
            else {
                const Fields = [...props?.filterformdata, ...staticfield]
                const data = {
                    startDate: props?.dates.startDate,
                    endDate: props?.dates.endDate,
                    filter: JSON.stringify(Fields)
                };
                getUserIPs(data)
            }
        }

    }, [selectediptype, props?.ZeekAlerts, props?.dates.startDate, props?.dates.endDate])
    const colorforalerts = ['#0066cc', '#f4c145', '#4cb140', '#5752d1', '#ec7a08', '#40199A', '#C8EB79', '#C9190B', '#008BAD']


    const TopIpToggle = <ToggleGroup aria-label="Default with single selectable ip" isCompact>
        <ToggleGroupItem
            text="All IPs"
            buttonId="all"
            isSelected={selectediptype == 'all'}
            onChange={handleIptoggle}
        />
        <ToggleGroupItem
            text="Malicious IPs"
            buttonId="malicious"
            isSelected={selectediptype == 'malicious'}
            onChange={handleIptoggle}
        />
    </ToggleGroup>
    const AlertLevelEvolution = useMemo(() => {
        return (isloadAlert ?
            <Loader />
            :
            <Elinestackchart
                lineseries={alertlevelseries}
                label={alertevolutionLabel}
                StartDateTime={startDate}
                EndDateTime={endDate}
                color={colorforalerts}
                legendwidth={100}
                filterFields={'rule.level'}
            /> )
    }, [alertlevelseries, alertevolutionLabel, startDate, endDate, colorforalerts])

    //Alert Evolution Agent Chart
    const AlertEvolutionAgents = useMemo(() => {
        return (isloadAgent ? <Loader /> :
            <EBarStackChart
                label={alertevolutionTopLabel}
                Stackseries={alertevolutionTopseries.slice(0, 5)}
                StartDateTime={startDate}
                EndDateTime={endDate}
                filterFields={'agent.name'}
            />)
    }, [alertevolutionTopLabel, alertevolutionTopseries, startDate, endDate, isloadAgent])


    //Mitre Attack component
    const MitreAttack = useMemo(() => {
        return (isloadMitre ? <Loader /> :
            mitreSeries.length > 0 ?
                <EWordCloud cloudseries={mitreSeries.slice(0, 40)}
                    height={300}
                    filterFields={'rule.mitre.technique'} />
                : <Emptystatefornodata />)
    }, [mitreSeries, isloadMitre])

    //Top Users
    const TopUsers = useMemo(() => {
        return (isloadUsers ?
            <Loader /> :
            users.length > 0 ?
                <EWordCloud cloudseries={users.slice(0, 50)} height={300} />
                :
                <Emptystatefornodata />)
    }, [users, isloadUsers])

    //Top Alerts Component
    // const TopAlerts = useMemo(() => {
    //     return (isloadTopAlert ?
    //         <Loader />
    //         : alerts.length > 0 ?
    //             <EWordCloud cloudseries={alerts.slice(0, 30)} height={300} filterFields={'rule.description'} />
    //             : <Emptystatefornodata />)
    // }, [alerts, isloadTopAlert])

    const UserIP = useMemo(() => {
        return (isloadUserIP ?
            <Loader />
            : netIP.length > 0 ?
                <NetworkUserIP cloudseries={netIP.slice(0, 30)} height={300} />
                : <Emptystatefornodata />)
    }, [isloadUserIP, netIP])
    //Rule groups component
    const ruleGroup = useMemo(() => {
        return (isloadRule ?
            <Loader />
            :
            rulegroup.length > 0 ? (
                <EWordCloud cloudseries={rulegroup.slice(0, 40)} height={300} filterFields={'rule.groups'} />
            ) : (
                <Emptystatefornodata />
            ))
    }, [isloadRule, rulegroup])

    //For SecurityAlerts
    const SecurityAlerts = useMemo(() => {
        return (
            isloadSecAlert ?
                <Loader /> :
                securityAlertsLog.length == 0 ?
                    <Emptystatefornodata />
                    :
                    <>
                        <DashboardSecurityAlerts ref={childSecurityAlertsRef} searchbar={true} Pagination={true} />
                    </>
        )
    }, [securityAlertsLog, isloadSecAlert, childSecurityAlertsRef])

    const headerActions = useMemo(() => {
        return (
            <>
                <Button variant="link" isInline onClick={() => { handleModalToggle('modalRuleClass') }}>Rule level info </Button>
                <Modal
                    tabIndex={0}
                    variant={ModalVariant.large}
                    title='Rule level info'
                    isOpen={modalStates.modalRuleClass}
                    onClose={() => handleModalToggle('modalRuleClass')}
                >
                    <Divider className='modal-Divider' />
                    <Card> <RuleClassificationtable /></Card>
                </Modal>
            </>
        )
    }, [modalStates.modalRuleClass])

    return (<>
        <AlertBox />
        <Grid hasGutter>
            <GridItem md={4} lg={2} sm={4}>
                <Card isFullHeight isRounded>
                    <CardTitle align="center" className='title-height' style={{ marginBottom: '-10px' }}>Severity Status</CardTitle>
                    {/* {loadstatus ?
                        <Bullseye> <RoundSkeleton /></Bullseye>
                        : */}
                    {/* {sumOfValues !== 0 ? */}
                    <CardBody style={{ margin: '-15px' }} className='pf-v5-u-mt-sm'>
                        {loadstatus ?
                            <Bullseye> <RoundSkeleton width='45%' /></Bullseye> : <SeverityStatus severityData={severityStatus} height='120px' />
                        }
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem md={4} lg={2} sm={4} >
                <Card isFullHeight isRounded  >
                    <CardTitle align="center" className='title-height'>Total</CardTitle>
                    <CardBody >
                        <TextContent >
                            <Text component={TextVariants.h1} className='pf-v5-u-text-align-center pf-v5-u-font-size-4xl'>
                                {islogloading ?
                                    <Bullseye>
                                        <TextSkeleton />
                                    </Bullseye> : <><Tooltip content={TotalLog}><div>{formatValue(TotalLog)}</div></Tooltip></> ?? 0}

                            </Text>
                        </TextContent>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem md={4} lg={2} sm={4}>
                <Card isFullHeight isRounded  >
                    <CardTitle align="center" className='title-height' >{`Critical Alerts (>=12)`}</CardTitle>
                    <CardBody >
                        <TextContent>
                            <a onClick={() => { handleModalToggle('modalLevel'); setLevelData(); setActiveTabKey(0) }}>
                                <Text component={TextVariants.h1} className='pf-v5-u-danger-color-100 pf-v5-u-text-align-center pf-v5-u-font-size-4xl'>
                                    {isloadlevel ? <Bullseye> <TextSkeleton width='70%' /> </Bullseye> : <Tooltip content={level}><div>{formatValue(level)}</div></Tooltip> ?? 0}
                                </Text> </a >
                            <Modal
                                variant={ModalVariant.large}
                                title={<Flex spaceItems={{ default: 'spaceItemsmd' }}>
                                    {/* <FlexItem>Critical Alerts(Above level 12 :-{level})</FlexItem> */}
                                    <FlexItem style={{ borderRight: '1px solid #000', paddingRight: '10px' }}>
                                        <TextContent>
                                            <Text component={TextVariants.h1}>{level}</Text>
                                            <small className='pf-v5-u-color-100'>Total</small>
                                        </TextContent>
                                    </FlexItem>
                                    {levelabove12.sort((a, b) => a.key - b.key).map((item, index) => (
                                        <FlexItem key={item.key + index} style={{ borderRight: '1px solid #000', paddingRight: '10px' }}>
                                            <TextContent>
                                                <a> <Text component={TextVariants.h1}
                                                    onClick={() => dispatch(FilterData([{ fields: 'rule.level', condition: 'is', value: item.key }]))}
                                                    className='pf-v5-u-danger-color-100'>{item.doc_count}</Text></a>
                                                <small className='pf-v5-u-color-100'>{`L-${item.key}`}</small>
                                            </TextContent>
                                        </FlexItem>
                                    ))}

                                </Flex>
                                }
                                isOpen={modalStates.modalLevel}
                                onClose={() => handleModalToggle('modalLevel')}
                            >
                                <Divider className='modal-Divider' />
                                <Card>
                                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs for rule level alerts" role="region">
                                        <Tab eventKey={0} title={<TabTitleText>Critical Rules</TabTitleText>}>
                                            {islogloading ? <Loader /> : <TableViewForChart Tabledata={criticalRules} columnData={["Rule", "Count"]} filterFields={'rule.description'} />}
                                        </Tab>
                                        <Tab eventKey={1} title={<TabTitleText>Critical Alerts</TabTitleText>} aria-label="rule-log-table">
                                            {levelLog.length > 0 ? <Level12AlertsLog ref={childAlertLevelRef} /> : <Emptystatefornodata />}
                                        </Tab>
                                    </Tabs>
                                </Card>
                            </Modal>
                        </TextContent>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem md={4} lg={2} sm={4}>
                <Card isFullHeight isRounded  >
                    <CardTitle align="center" className='title-height'>Authentication</CardTitle>
                    {isloadAf && isloadAs ?
                        <div style={{ marginLeft: '40px' }}> <TextSkeleton height='50px' width='70%' /></div>
                        :
                        <CardBody >
                            <Flex className='pf-v5-u-justify-content-center'
                                spaceItems={{ default: 'spaceItemsXs' }}
                            // direction={{ default: 'row', md: 'column' }}
                            >
                                <FlexItem>
                                    <Tooltip content={`Authentication Success: ${AuthenticationSuccess??0}`}>
                                        <React.Fragment>
                                            <Text className='pf-v5-u-font-family-heading pf-v5-u-font-size-3xl pf-v5-u-text-align-center'>
                                                <a onClick={() => { handleModalToggle('modalAS'); setAuthSuccessData() }} className='pf-v5-u-success-color-100'>  {formatValue(AuthenticationSuccess) ?? 0}</a>
                                            </Text>
                                            <Text component={TextVariants.small} className='pf-v5-u-success-color-100 grid-text-center'>success</Text>
                                        </React.Fragment>
                                    </Tooltip>
                                    <Modal
                                        variant={ModalVariant.large}
                                        title={`Authentication Success (${AuthenticationSuccess??0})`}
                                        isOpen={modalStates.modalAS}
                                        onClose={() => handleModalToggle('modalAS')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            {isloadAs ? <Loader /> :
                                                AuthenticationSuccess > 0 ?
                                                    <AuthenticationSuccessLog ref={childAuthSuccessRef} />
                                                    :
                                                    <Emptystatefornodata />
                                            }
                                        </Card>
                                    </Modal>
                                </FlexItem>
                                <Divider orientation={{ default: 'vertical', sm: 'horizontal', md: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                <FlexItem>
                                    <Tooltip content={`Authentication Failure : ${AuthenticationFailure??0}`}>
                                        <React.Fragment>
                                            <Text className='pf-v5-u-font-family-heading pf-v5-u-font-size-3xl pf-v5-u-text-align-center'>
                                                <a onClick={() => { handleModalToggle('modalAF'); setAuthFailureData() }} className='pf-v5-u-danger-color-100' > {formatValue(AuthenticationFailure) ?? 0} </a>
                                            </Text>
                                            <Text component={TextVariants.small} className='pf-v5-u-danger-color-100 grid-text-center'>failure</Text>
                                        </React.Fragment>
                                    </Tooltip>
                                    <Modal
                                        variant={ModalVariant.large}
                                        title={`Authentication Failure (${AuthenticationFailure??0})`}
                                        isOpen={modalStates.modalAF}
                                        onClose={() => handleModalToggle('modalAF')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            {isloadAf ? <Loader /> :
                                                AuthenticationFailure > 0 ?
                                                    <AuthenticationFailureLog ref={childAuthFailureRef} />
                                                    : <Emptystatefornodata />}
                                        </Card>
                                    </Modal>
                                </FlexItem>
                            </Flex>
                        </CardBody>
                    }
                </Card>
            </GridItem>
            <GridItem md={4} lg={2} sm={4} >
                <Card isFullHeight isRounded  >
                    <CardTitle align="center" className='title-height'>
                        <Tooltip content={"Threat Intel Platform Engine"}>
                            <Text component={TextVariants.h1}>TIP<sup><Icon size="sm"> <PlusIcon /></Icon></sup></Text>
                        </Tooltip>
                    </CardTitle>
                    {isloadTd && isloadAr ? <div style={{ marginLeft: '40px' }}> <TextSkeleton height='50px' width='70%' /></div> :
                        <CardBody  >
                            <Flex className='pf-v5-u-justify-content-center' spaceItems={{ default: 'spaceItemsXs' }} >
                                <FlexItem>
                                    <Tooltip content={`Threat Detection:${TDdata}`}>
                                        <React.Fragment>
                                            <Text className=' pf-v5-u-font-family-heading pf-v5-u-font-size-3xl pf-v5-u-text-align-center'>
                                                <a onClick={() => { handleModalToggle('modalTDData'); setThreatDetectData() }} className='pf-v5-u-danger-color-100'> {formatValue(TDdata) ?? 0}   </a>
                                            </Text>
                                            <Text component={TextVariants.small} className='pf-v5-u-danger-color-100 grid-text-center'>detection</Text>
                                        </React.Fragment>
                                    </Tooltip>
                                    <Modal
                                        variant={ModalVariant.large}
                                        title={`Threat Detection (TIP) (${TDdata}) `}
                                        isOpen={modalStates.modalTDData}
                                        onClose={() => handleModalToggle('modalTDData')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            {TDdata > 0 ? <ThreatDetectionTable ref={childThreatDetectionRef} IsModal={true} /> : <Emptystatefornodata />}
                                        </Card>
                                    </Modal>
                                </FlexItem>
                                <Divider orientation={{ default: 'vertical', sm: 'horizontal', md: 'vertical' }} style={{ '--pf-v5-c-divider--BackgroundColor': '#8A8D90' }} />
                                <FlexItem>
                                    <Tooltip content={`Active Response: ${ARdata}`}>
                                        <React.Fragment>
                                            <Text className='pf-v5-u-font-family-heading  pf-v5-u-font-size-3xl pf-v5-u-text-align-center'>
                                                <a onClick={() => { handleModalToggle('modalARData'); setActiveResponseData() }} className='pf-v5-u-success-color-100'>   {formatValue(ARdata) ?? 0}</a>
                                            </Text>
                                            <Text component={TextVariants.small} className='pf-v5-u-success-color-100 grid-text-center'>response</Text>
                                        </React.Fragment>
                                    </Tooltip>
                                    <Modal
                                        variant={ModalVariant.large}
                                        title={`Active Response (TIP) (${ARdata})`}
                                        isOpen={modalStates.modalARData}
                                        onClose={() => handleModalToggle('modalARData')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            {ARdata > 0 ? <ActiveResponseTable ref={childActiveResponseRef} IsModal={true} /> : <Emptystatefornodata />}
                                        </Card>
                                    </Modal>
                                </FlexItem>
                            </Flex>
                            {/* </TextContent> */}
                        </CardBody>
                    }
                </Card>
            </GridItem>
            <GridItem md={4} lg={2} sm={4}>
                <Card isFullHeight isRounded>
                    <CardTitle align="center" className='title-height'>Total Active Vulnerabilities</CardTitle>
                    <CardBody >
                        <TextContent>
                            <a href='#/Vulnerabilities'
                                onClick={() => { dispatch(FilterData([{ fields: 'data.vulnerability.status', condition: 'is', value: 'Active' }])) }}>
                                <Text component={TextVariants.h1} className='pf-v5-u-danger-color-100 pf-v5-u-text-align-center pf-v5-u-font-size-4xl' >
                                    {isloadVul ? <Bullseye> <TextSkeleton /></Bullseye> : <Tooltip content={Vulnerabilities??0}><div>{formatValue(Vulnerabilities??0)}</div></Tooltip> ?? 0}
                                </Text>
                            </a>
                        </TextContent>
                    </CardBody>
                </Card>
            </GridItem>
        </Grid>
        <Grid hasGutter className='pf-v5-u-mt-md'>
            <GridItem md={12} sm={12} lg={6} >
                <Card isFullHeight >
                    <CardHeader actions={{ actions: headerActions, hasNoOffset: true }}  >
                        {/* <ChartAreaIcon /> */}
                        <CardTitle>Alert Level Evolution {`(>=3)`} </CardTitle>
                    </CardHeader>
                    <Divider />
                    {AlertLevelEvolution}
                </Card>
            </GridItem>
            <GridItem md={12} sm={12} lg={6}>
                <Card isExpanded isFullHeight>
                    <CardTitle>Alerts Evolution-Top 5 Agents</CardTitle>
                    <Divider />
                    {AlertEvolutionAgents}
                    {alertevolutionTopseries.length > 0 &&
                        <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { handleModalToggle('modalAgent') }}> More </Button>
                                <Modal
                                    tabIndex={0}
                                    variant={ModalVariant.large}
                                    title={`All Agents (${alertevolutionTopseries.length})`}
                                    isOpen={modalStates.modalAgent}
                                    onClose={() => handleModalToggle('modalAgent')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>
                                        {isloadAgent ? <Loader /> : <TableViewForChart Tabledata={alertevolutionTopseries} columnData={["Agent", "Count"]} filterFields={'agent.name'} />
                                        }
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>
                    }
                </Card>
            </GridItem>
        </Grid>
        <div className="pf-v5-u-mt-md">
            <Grid hasGutter>
                <GridItem md={6} sm={12} lg={3} >
                    <Card isFullHeight>
                        <CardTitle> Top MITRE ATT&CK Techniques</CardTitle>
                        <Divider />
                        {MitreAttack}
                        {mitreSeries.length > 0 &&
                            <CardFooter>
                                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                    <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalTopMitre') }}> More </Button>
                                    <Modal
                                        tabIndex={0}
                                        variant={ModalVariant.large}
                                        title={`Top MITRE ATT&CK Techniques (${mitreSeries.length})`}
                                        isOpen={modalStates.modalTopMitre}
                                        onClose={() => handleModalToggle('modalTopMitre')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">
                                                <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                    {isloadMitre ?
                                                        <Loader /> : <TableViewForChart Tabledata={mitreSeries} columnData={["Mitre Att&CK Technique", "Count"]} filterFields={'rule.mitre.technique'} />
                                                    }
                                                </Tab>
                                                <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                    {isloadMitre ? <Loader /> :
                                                        mitreSeries.length > 0 ?
                                                            (<EWordCloud cloudseries={mitreSeries.slice(0, 100)} height={500} filterFields={'rule.mitre.technique'} />) : (<Emptystatefornodata />)
                                                    }
                                                </Tab>
                                            </Tabs>
                                        </Card>
                                    </Modal>
                                </Flex>
                            </CardFooter>
                        }
                    </Card>
                </GridItem>
                <GridItem md={6} sm={12} lg={3} >
                    <Card isFullHeight>
                        <CardTitle> Top Users</CardTitle>
                        <Divider />
                        {TopUsers}
                        {users.length > 0 &&
                            <CardFooter>
                                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                    <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalUser') }}> More </Button>
                                    <Modal
                                        tabIndex={0}
                                        variant={ModalVariant.large}
                                        title={`Top Users (${users.length})`}
                                        isOpen={modalStates.modalUser}
                                        onClose={() => handleModalToggle('modalUser')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">

                                                <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                    {isloadUsers ? <Loader /> : <TableViewForChart Tabledata={users} columnData={["User", "Count"]} />}
                                                </Tab>
                                                <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                    {isloadUsers ? <Loader /> :
                                                        users.length > 0 ?
                                                            <EWordCloud cloudseries={users.slice(0, 100)} height={500} />
                                                            :
                                                            <Emptystatefornodata />
                                                    }
                                                </Tab>
                                            </Tabs>
                                        </Card>
                                    </Modal>
                                </Flex>
                            </CardFooter>
                        }
                    </Card>
                </GridItem>
                <GridItem md={6} sm={12} lg={3}>
                    <Card isFullHeight>
                        <CardHeader actions={{
                            hasNoOffset: true,
                            actions: (TopIpToggle)
                        }}>
                            <CardTitle>Top IPs</CardTitle>
                        </CardHeader>
                        <Divider />
                        {UserIP}
                        <CardFooter>
                            <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalIPs') }}> More </Button>
                                <Modal
                                    variant={ModalVariant.large}
                                    // title={<Flex display={{default:'inlineFlex'}} alignItems={{default:'alignItemsCenter'}} >
                                    //     <FlexItem>Top IPs ({netIP.length})</FlexItem>
                                    //     <FlexItem align={{default:'alignRight'}}>{TopIpToggle}</FlexItem>
                                    // </Flex>}
                                    title={`Top IPs (${netIP.length})`}
                                    isOpen={modalStates.modalIPs}
                                    help={TopIpToggle}
                                    onClose={() => handleModalToggle('modalIPs')}
                                >
                                    <Divider className='modal-Divider' />
                                    <Card>
                                        <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">

                                            <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                {isloadUserIP ? <Loader /> : <NetworkUserIPTable Tabledata={netIP} columnData={["IP", 'City Name(s)', 'Country Name(s)', "Category", "Count"]} />}
                                            </Tab>
                                            <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                {isloadUserIP ? <Loader /> :
                                                    netIP.length > 0 ?
                                                        <NetworkUserIP cloudseries={netIP.slice(0, 50)} height={500} />
                                                        : <Emptystatefornodata />
                                                }
                                            </Tab>
                                        </Tabs>
                                    </Card>
                                </Modal>
                            </Flex>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem md={6} sm={12} lg={3}>
                    <Card isFullHeight>
                        <CardTitle>Top Rule Groups </CardTitle>
                        <Divider />
                        {ruleGroup}
                        {rulegroup.length > 0 &&
                            <CardFooter>
                                <Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
                                    <Button variant="link" onClick={() => { setActiveTabKey(0); handleModalToggle('modalRuleGroup') }}> More </Button>
                                    <Modal
                                        variant={ModalVariant.large}
                                        title={`Top Rule Groups (${rulegroup.length})`}
                                        isOpen={modalStates.modalRuleGroup}
                                        onClose={() => handleModalToggle('modalRuleGroup')}
                                    >
                                        <Divider className='modal-Divider' />
                                        <Card>
                                            <Tabs activeKey={activeTabKey} onSelect={handleTabClick} aria-label="Tabs in the default example" role="region">

                                                <Tab eventKey={0} title={<TabTitleText>Table View</TabTitleText>}>
                                                    {isloadRule ?
                                                        <Loader /> : <TableViewForChart Tabledata={rulegroup} columnData={["Rule", "Count"]} filterFields={'rule.groups'} />}
                                                </Tab>
                                                <Tab eventKey={1} title={<TabTitleText>Graph View</TabTitleText>} aria-label="graph">
                                                    {isloadRule ?
                                                        <Loader /> : rulegroup.length > 0 ? (
                                                            <EWordCloud cloudseries={rulegroup.slice(0, 100)} height={500} filterFields={'rule.groups'} />) : (
                                                            <Emptystatefornodata />
                                                        )}
                                                </Tab>
                                            </Tabs>
                                        </Card>
                                    </Modal>
                                </Flex>
                            </CardFooter>
                        }
                    </Card>
                </GridItem>
            </Grid>
        </div>
        <div className="pf-v5-u-mt-md">
            <Card>
                {/* <CardTitle></CardTitle> */}
                <CardBody style={{ margin: '-15px' }}>
                    {isloadalertdesc ? <Loader /> :
                        alertdesc.length > 0 ?
                            <SecurityAlertDesc Tabledata={alertdesc} />
                            :
                            <Emptystatefornodata />

                    }
                </CardBody>
            </Card>
        </div>
    </>)

})
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    selectedGroup: state.SelectedGroup,
    GlobalInterval: state.globalIntervalDuration,
    ZeekAlerts: state.ZeekAlerts,
    theme: state.theme
    // groupindex: state.groupIndexName
});

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(SecurityDashboard)