import React, { useEffect, useState } from 'react';
import { Form, FormGroup, TextInput, Modal, Button, ActionGroup, Spinner, Radio, FlexItem, Flex } from '@patternfly/react-core';
import { SaveIcon } from '@patternfly/react-icons';
import dbApi from 'src/services/DbApi';
import { useParams } from 'react-router-dom';
import { emptyCheck } from 'src/util/Validate';
import ConfirmBox from 'src/Components/ConfirmBox/ConfirmBox';
import { useDispatch } from 'react-redux';
import { alertBoxData } from 'src/Redux/Action';

const IncidentAlertModal = ({ incidentId, onclose, onModalClose }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [alertText, setAlertText] = useState('');
    const [validalertText, setValidAlertText] = useState('');
    const [isLoader, setIsLoader] = useState(false);
    const [incidentType, setIncidentType] = useState('new'); // State to track radio button selection

    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    });


    useEffect(() => {
        if (incidentId) {
            console.log('incident_id alertttt', incidentId)
            handleSave()
        }
    }, [incidentId])

    const handleAlertInputChange = (event, field) => {
        const value = event.target.value; // Extract value from the event object
        switch (field) {
            case 'alertText':
                setAlertText(value);
                setValidAlertText(emptyCheck(value));
                break;
            default:
                break;
        }
    };

    const resetForm = () => {
        setAlertText('');
    };

    const handleSave = async () => {
        setIsLoader(true);
        const params = {
            incidentId: id,
            docId: alertText,
        };
        console.log('alert text', alertText)
        try {
            let res = await dbApi.postApi('incident_management/add_incident_alert', params);
            console.log('alert_add', res);

            if (!res.error) {
                dispatch(alertBoxData([{ title: res.message, variant: 'success' }]));
                resetForm();
                onclose();
                onModalClose(true);
            } else {
                dispatch(alertBoxData([{ title: res.message, variant: 'danger' }]));
                console.error(res.message);
                onModalClose(false);
            }
        } catch (error) {
            console.error('API call failed:', error);
            onModalClose(false);
        }
        setIsLoader(false);
    };

    const formValidation = () => {
        let isValid = true;
        const alertTextStatus = emptyCheck(alertText);
        setValidAlertText(alertTextStatus);
        if (alertTextStatus === 'error') {
            isValid = false;
        }

        return isValid;
    };

    const submitForm = () => {
        if (formValidation()) {
            setConfirmBox({
                show: true,
                msg: 'Are you sure?',
                funCall: handleSave,
                title: 'Add Alert.',
                position: 'default',
                iconvariant: 'info',
                submitbtnvariant: 'primary',
            });
        }
    };

    return (
        <>
            {isLoader ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <Form>
                    {/* Input Field */}
                    <FormGroup fieldId="alert-input" isRequired helperTextInvalid="Document Id is required" validated={validalertText}>
                        <TextInput
                            value={alertText}
                            onChange={(value) => handleAlertInputChange(value, 'alertText')}
                            id="alert-input"
                            name="alertInput"
                            type="text"
                            placeholder="Enter Document Id"
                            isRequired
                            validated={validalertText}
                        />
                    </FormGroup>
                    <ActionGroup>
                        <Button variant="primary" icon={<SaveIcon />} onClick={submitForm}>Create</Button>
                        <Button variant="secondary" onClick={onclose}>Cancel</Button>
                    </ActionGroup>
                </Form>
            )}
            <ConfirmBox confirmModal={confirmModalData} />
        </>
    );
};

export default IncidentAlertModal;
