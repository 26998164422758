import React, { useEffect, useState } from "react";
import {
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Table,
} from "@patternfly/react-table";
import { Emptystatefornodata } from "../../util/EmptyStateCard";
import { Badge, Button, Pagination, Toolbar,
     ToolbarContent, ToolbarItem, } from "@patternfly/react-core";
import { connect, useDispatch } from "react-redux";
import { FilterData } from "../../Redux/Action";
import { UpdateFilterData } from "../../util/FilterHelper";
import {exportToExcel} from '../../util/FilterHelper'
import { DownloadIcon } from "@patternfly/react-icons";


const TopVulnerableAgentTable = (props) => {

    const dispatch = useDispatch();
    const [columns, setColumns] = useState(props.columnData);
    const [defaultRows, setDefaultRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);//props.vulPerPage??20
    const [selectedRowName, setSelectedRowName] = useState('');
    const handleSetPage = (_evt, newPage, perPage, startIdx, endIdx) => {
        // setPaginatedRows(rows.slice(startIdx, endIdx));
        setPage(newPage);
    };
    const handlePerPageSelect = (_evt, newPerPage, newPage) => {
        setPage(newPage);
        setPerPage(newPerPage);
    };
    const handleFilter = (fields, value) => {
        const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
        dispatch(FilterData(updatedFields));
    }

    useEffect(() => {
        const newdefaultRows = props.Tabledata.sort((a, b) => b.total - a.total).map((log) => {
            return {

                key: log.agent_name,
                count: log.total,
                high: log.high_total,
                critical: log.critical_total,
                medium: log.medium_total,
                low: log.low_total,
            };
        });
        setDefaultRows(newdefaultRows)
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        const slicedRows = newdefaultRows.slice(startIndex, endIndex);
        setRows(slicedRows);
    }, [props.Tabledata]);

    useEffect(() => {
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        const slicedRows = defaultRows.slice(startIndex, endIndex);
        setRows(slicedRows);
    }, [defaultRows, page, perPage]);


    const renderPagination = () =>
        <Pagination
            itemCount={defaultRows.length}
            page={page}
            perPage={perPage}
            onSetPage={handleSetPage}
            onPerPageSelect={handlePerPageSelect}
        />;
    const renderseverity = (severity, count) => {
        let badgeStyle = {};
        if (severity == "High") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#C9190B" };
        }
        else if (severity == "Critical") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000 " };
        }
        else if (severity == "Medium") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00 " };
        }
        else if (severity == "Low") {
            badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#2B9AF3 " };
        }
        return (
            <div>
                <Badge style={badgeStyle}>{count} </Badge>
            </div>
        );
    };
    const tableData = defaultRows.map(row => ({
        'Agent Name': row.key,
        'Critical': row.critical,
        'High': row.high,
        'Medium': row.medium,
        'Low': row.low,
        'Count': row.count,
    }));
    return (
        <React.Fragment>
            {props.Tabledata.length === 0 ? (
                <Emptystatefornodata />
            ) : (
                <>
                    <Toolbar>
                        <ToolbarContent>
                            <ToolbarItem>
                                <Button onClick={() => exportToExcel(tableData, Object.keys(tableData[0]), 'vulnerableagent.xlsx')} icon={<DownloadIcon/>} isInline>Download Excel</Button>
                            </ToolbarItem>
                            <ToolbarItem variant="pagination">{props.Pagination !== false && renderPagination()}</ToolbarItem>
                        </ToolbarContent>
                    </Toolbar>
                    <Table variant="compact" aria-label="vulagent" id="vulagent-table" isStickyHeader gridBreakPoint="">
                        <Thead >
                            <Tr key={'cahrt-table-header'}>
                                {columns.map((column, index) => (
                                    <Th key={column + index}>{column}</Th>
                                ))}
                            </Tr>
                        </Thead>
                        {rows.length > 0 && (rows.map((row, rowIndex) => {
                            const effectiveIndex = (page - 1) * perPage + rowIndex;
                            const BackgroundColor = page === 1 && effectiveIndex < 10;
                            return (
                                <Tbody key={rowIndex + 'tablebody'}>
                                    <Tr key={rowIndex + 'row'}
                                        onRowClick={() => setSelectedRowName(rowIndex)}
                                        isSelectable
                                        isClickable
                                        isRowSelected={selectedRowName === rowIndex}
                                        className={BackgroundColor ? "pf-v5-u-background-color-danger" : ""} >
                                        <Td width={40} key={rowIndex + 'group'}>{row?.key}</Td>
                                        <Td width={10} key={rowIndex + 'critical'}>{renderseverity('Critical', row?.critical)}</Td>
                                        <Td width={10} key={rowIndex + 'high'}>{renderseverity('High', row?.high)}</Td>
                                        <Td width={10} key={rowIndex + 'medium'}>{renderseverity('Medium', row?.medium)}</Td>
                                        <Td width={10} key={rowIndex + 'low'}>{renderseverity('Low', row?.low)}</Td>
                                        <Td width={20} key={rowIndex + 'rulecount'}><a onClick={() => { handleFilter(props.filterFields, row?.key) }}>{row?.count}</a></Td>
                                      
                                    </Tr>
                                </Tbody>
                            )
                        }
                        ))}
                    </Table>
                    {props.Pagination !== false && <div className="pf-v5-u-mt-md">{renderPagination()}</div>}
                </>
            )}
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(TopVulnerableAgentTable)
