import React, { useRef } from 'react'
import {
  Button,
  PageSection,
  PageSectionVariants,
  Tooltip,
  Text,
  TextContent,
  Flex,
  FlexItem,
  Card,
  ModalVariant,
  CardBody,
  CardTitle
} from '@patternfly/react-core';
import IncidentAlertList from './IncidentAlertList';

const IncidentAlertMain = () => {
  const [count, setCount] = React.useState(0);

  const setAlertTotalCount = (totalCount) => {
    setCount(totalCount);
  }

  return (
    <div>
      <PageSection padding={{ default: 'noPadding' }} className='pf-v5-u-p-sm'>
        <Card>
          <CardTitle> My Alerts ({count})</CardTitle>
          <CardBody>
              <IncidentAlertList setTaskCount={setAlertTotalCount} />
          </CardBody>
        </Card>

      </PageSection>
    </div>
  )
}

export default IncidentAlertMain;