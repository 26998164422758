//2nd which is perfectly run and usable
import React, { useEffect, useState } from "react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
} from "@patternfly/react-table";
import { Emptystatefornodata } from "../../util/EmptyStateCard";
import { Badge, Pagination, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem } from "@patternfly/react-core";
import { UpdateFilterData } from "src/util/FilterHelper";
import { FilterData } from "src/Redux/Action";
import { connect, useDispatch } from "react-redux";
 const TopSignatures = (props) => {
  const dispatch=useDispatch()
  const defaultColumns = [
    "Signatures",
    "Severity",
    "Count",
  ];
  const [columns, setColumns] = useState(defaultColumns);
  const [rows, setRows] = useState([]);
  const [selectedRowName, setSelectedRowName] = React.useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [itemcount, setItemcount] = useState(0)
  const [defaultRows, setDefaultRows] = useState([]);
  useEffect(() => {
    const newdefaultRows = props.SignatureData.map((log) => {
          return {

            signatures: log.signatures,
            count: log.count,
            severity:log.severity
          };
    });

    setDefaultRows(newdefaultRows)
    setItemcount(newdefaultRows.length)
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = newdefaultRows.slice(startIndex, endIndex);
    setRows(slicedRows);
  }, [props.SignatureData, rows.length]);


  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedRows = defaultRows.slice(startIndex, endIndex);
    // console.log("after slicing", "", startIndex, endIndex, slicedRows)
    setRows(slicedRows);
  }, [defaultRows, page, perPage]);

  const handleSetPage = (_evt, newPage) => {
    setPage(newPage);
  };
  const handlePerPageSelect = (_evt, newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

  const renderPagination = (variant, isCompact) => (
    <Pagination
      isCompact={isCompact}
      itemCount={itemcount}
      page={page}
      perPage={perPage}
      onSetPage={handleSetPage}
      onPerPageSelect={handlePerPageSelect}
      variant={variant}
      titles={{
        paginationAriaLabel: `${variant} pagination`
      }}
    />
  );
  const renderSeveritycolumn = (severity) => {
    let badgeStyle = {};
    if (severity === '1') {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#A30000" };
      return (<div><Badge style={badgeStyle}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.alert.severity', severity) }}>High</a></Badge></div>)
    }
    else if (severity === '2') {
      badgeStyle = { "--pf-v5-c-badge--m-unread--BackgroundColor": "#F0AB00 " };
      return (<div><Badge style={badgeStyle}><a className='pf-v5-u-color-light-100' onClick={() => { handleFilter('data.alert.severity', severity) }}>Medium</a></Badge></div>)
    }
    else {
      return ' ';
    }
  };

  const handleFilter = (fields, value) => {
    const updatedFields = UpdateFilterData(props.filterformdata, fields, 'is', value);
    dispatch(FilterData(updatedFields));
  }
  return (
    <React.Fragment>
      {props.SignatureData.length === 0 ? (
       <Emptystatefornodata/>
      ) : (
        <>
        {props?.IsModal && <Toolbar>
          <ToolbarContent>
            <ToolbarGroup align={{ default: 'alignRight' }}>
              <ToolbarItem>{renderPagination('top')}</ToolbarItem>
            </ToolbarGroup>
          </ToolbarContent>
        </Toolbar>}
        <Table  variant="compact" aria-label="signlog" id="signlog-table" isStickyHeader>
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column}>{column}</Th>
              ))}
            </Tr>
          </Thead>
          {rows.map((row, rowIndex) => (
            <Tbody key={rowIndex} >
              <Tr key={rowIndex}
                onRowClick={() => setSelectedRowName(rowIndex)}
                isSelectable
                isClickable
                isRowSelected={selectedRowName === rowIndex}  >
                <Td dataLabel='Signature' width={80} modifier="truncate">{row?.signatures}</Td>
                <Td dataLabel='Severity' width={10}  >{renderSeveritycolumn(row?.severity)}</Td>
                <Td dataLabel='Count' width={10} ><a className="pf-v5-u-color-100" onClick={() => { handleFilter('data.alert.signature', row?.signatures) }}>{row?.count}</a></Td>
              </Tr>
            </Tbody>
          ))}
        </Table>
        </>
      )}
      {props?.IsModal && renderPagination('bottom')}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  // dates: { startDate: state.startdate, endDate: state.enddate },
  filterformdata: state.FilterData,
  // selectedGroup: state.SelectedGroup,
  // allgroups: state.Allgroups,
  // mapStateToProps content remains unchanged if you have it
});

const mapDispatchToProps = (dispatch) => { return {} };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(TopSignatures)