import React, { useState, useEffect, useRef } from 'react';
import {
    Form,
    Grid,
    GridItem,
    TextInput,
    InputGroup,
    InputGroupItem,
    FormGroup,
    ToggleGroup,
    ToggleGroupItem,
    TextArea,
    ActionGroup,
    Button,
    Badge,
    Tooltip
} from '@patternfly/react-core';
import SaveIcon from '@patternfly/react-icons/dist/js/icons/save-icon';
import IndexInputGroup from '../OnBoarding/IndexInputGroup';
import CommonSelectBox from '../CommonEventPages/CommonSelectBox';
import dbApi from 'src/services/DbApi';
import ConfirmBox from '../ConfirmBox/ConfirmBox';
import { alertBoxData } from 'src/Redux/Action';
import { useDispatch } from 'react-redux';
import SingleDateTimePicker from '../CommonEventPages/SingleDateTimePicker';
import { HelpIcon, InfoAltIcon, InfoCircleIcon } from '@patternfly/react-icons';
import { emptyCheck, selectmenuoption } from 'src/util/Validate';
import { Loader } from 'src/util/Loadingstate';

const IncidentModal = ({ incidentFormData, onModalClose, onclose }) => {
    const dispatch = useDispatch()
    const initialFormset = {
        title: '',
        startDatetime: '',
        severity: '',
        tlpAmber: '',
        papAmber: '',
        tags: '',
        docId: '1',
        description: '',
        organizationUniqueId: '',
    }
    const [isLoader, setIsLoader] = useState(false);
    const [caseName, setCaseName] = useState(''); // Set title as default value
    const [selectedOrgValue, setSelectedOrgValue] = useState('');
    const [caseValidName, setCaseValidName] = useState('');
    const [formData, setFormData] = useState([initialFormset]);
    const [severity, setSeverity] = useState('L');
    const [tlp, setTlp] = useState('AMBER');
    const [pap, setPap] = useState('AMBER');
    const [startDateTime, setStartDateTime] = useState();
    const [date, setDateTime] = useState();
    const [description, setDescription] = useState('');
    const [docId, setDocId] = useState('');
    const [validdescription, setValidDescription] = useState('');
    const [investigatorValid, setInvestigatorValid] = useState('');
    const [selectedInvestigatorValue, setSelectedInvestigatorValue] = useState('');
    const [chips, setChips] = useState([]);
    const [indexNameValid, setIndexNameValid] = useState('');
    const [organizationValid, setOrganizationValid] = useState('');
    const [orgList, setOrgList] = useState([]);
    const [confirmModalData, setConfirmBox] = useState({
        show: false,
        msg: '',
        funCall: '',
        funParam: {},
        title: '',
        position: '',
        iconvariant: '',
        submitbtnvariant: '',
    });

    useEffect(() => {
        const today = new Date();
        setDateTime(today);
    }, []);


    useEffect(() => {
        if (typeof incidentFormData != "undefined" && incidentFormData != null) {
            console.log("incidentFormData")
            console.log(incidentFormData)
            console.log('title----', incidentFormData.title)
            setCaseName(incidentFormData.title)
            setDocId(incidentFormData.docId)
        }
        if (incidentFormData && orgList.length > 0) {
            console.log('organization----', orgList)
            const selectedOrg = orgList.find(org => org.unique_id === incidentFormData.orgUniqueId);
            console.log('incidentFormData orgUniqueId', incidentFormData.orgUniqueId)
            if (selectedOrg) {
                console.log('select org', selectedOrg)
                console.log('unique id---', selectedOrg.unique_id)
                setSelectedOrgValue(selectedOrg.unique_id); 
            }
        }
    }, [incidentFormData, orgList]);


    const handleFieldChange = (value, field) => {
        switch (field) {
            case 'caseName':
                setCaseName(value);
                setCaseValidName(emptyCheck(value));
                break;
            case 'description':
                setDescription(value);
                break;
            case 'docId':
                setDocId(value);
                break;
            default:
                break;
        }
    };

    const formValidation = () => {
        let isValid = true;
        const caseNameStatus = emptyCheck(caseName);
        setCaseValidName(caseNameStatus);
        if (caseNameStatus === 'error') {
            isValid = false;
        }
       
        const organizationStatus = selectmenuoption(selectedOrgValue);
        setOrganizationValid(organizationStatus);
        if (organizationStatus === 'error') {
            isValid = false;
        }
        return isValid;
    };

    const handleUpdateOrgValue = (value) => {
        console.log(value)
        setSelectedOrgValue(value);
        setOrganizationValid(emptyCheck(value));
    };
    
    const handleSetChips = (newChips) => {
        setChips(newChips);
    };

    const handleSeverityToggle = (event, _isSelected) => {
        const id = event.currentTarget.id;
        setSeverity(id);
    };

    const handleTlpToggle = (event, _isSelected) => {
        const id = event.currentTarget.id;
        setTlp(id);
    };

    const handlePapToggle = (event, _isSelected) => {
        const id = event.currentTarget.id;
        setPap(id);
    };

    const handleDateTimeChange = (newDate) => {
        setStartDateTime(newDate)
    };
    useEffect(() => {
        getOrganizationList();
    }, []);

    const getOrganizationList = async () => {
        let data = { status: '1' };
        try {
            let res = await dbApi.postApi("opensearch/get_organization_list", data);
            console.log(res);
            let allOrg = [];
            if (res.data.length > 0) {
                allOrg = res.data;
                setOrgList(allOrg);
                console.log('orglist', orgList);
            } else {
                setOrgList([]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const resetForm = () => {
        setCaseName('');
        setCaseValidName('');
        setSeverity('L');
        setTlp('AMBER');
        setPap('AMBER');
        setDescription('');
        setValidDescription('');
        setSelectedInvestigatorValue('');
        setInvestigatorValid('');
        setChips([]);
        setDocId('')
        setOrganizationValid('');
        setSelectedOrgValue('');
    };


    const finalSubmit = async () => {
        if (!startDateTime) {
            console.error('Date or Time is missing');
            return;
        }
        setIsLoader(true)
        const params = {
            title: caseName,
            description: description,
            severity: severity,
            tlpAmber: tlp,
            papAmber: pap,
            startDatetime: startDateTime,
            tags: chips,
            docId: docId,
            organizationUniqueId: selectedOrgValue,
        };

        try {
            const res = await dbApi.postApi("incident_management/add_incident", params);
            if (!res.error) {
                resetForm();
                onclose();
                onModalClose(true);
            }
            dispatch(alertBoxData([{ title: res.message, variant: !res.error ? 'success' : 'danger' }]));
        } catch (error) {
            console.error('API call failed:', error.response || error.message || error);
            onclose(false);
        }
        setIsLoader(false);
    };

   
    const submitForm = () => {
        if (formValidation(formData)) {
            setConfirmBox({
                show: true,
                msg: 'Are you sure?',
                funCall: finalSubmit,
                title: 'Add Incident.',
                position: 'default',
                iconvariant: 'info',
                submitbtnvariant: 'primary',
            });
        }
    };

    const tlpColorMap = {
        WHITE: { backgroundColor: '#00c0ef', color: '#000' },
        AMBER: { backgroundColor: '#ffbf00', color: '#000' },
        GREEN: { backgroundColor: '#008000', color: '#fff' },
        RED: { backgroundColor: '#C9190B', color: '#fff' }
    };

    const extendedTlpColorMap = {
        ...tlpColorMap, // Existing color mappings
        L: { backgroundColor: '#00c0ef', color: '#fff' }, // Blue
        M: { backgroundColor: '#ffbf00', color: '#000' }, // Yellow
        H: { backgroundColor: '#C9190B', color: '#fff' }, // Red
        C: { backgroundColor: '#8B0000', color: '#fff' },// Dark Red
        Open: { backgroundColor: '#00c0ef', color: '#fff' },  // Blue
        Close: { backgroundColor: '#C9190B', color: '#fff' }, // Red
        Waiting: { backgroundColor: '#ffbf00', color: '#000' } // Yellow
    };


    return (<>
        {isLoader ? <Loader /> :
            <Form>
                <Grid hasGutter>
                    <GridItem lg={8}>
                        <FormGroup
                            isRequired
                            fieldId="case-name"
                            helperTextInvalid="Case name is required"
                            validated={caseValidName}
                        >
                            <TextInput
                                value={caseName}
                                isRequired
                                placeholder='Title'
                                type="text"
                                id="horizontal-form-name"
                                aria-describedby="horizontal-form-name-helper"
                                name="horizontal-form-name"
                                onChange={(e, value) => handleFieldChange(value, 'caseName')}
                                validated={caseValidName}
                            />
                        </FormGroup>
                    </GridItem>
                    <GridItem lg={4}>
                        <InputGroup>
                            <InputGroupItem>
                                <SingleDateTimePicker
                                    dateTime={date}
                                    //  time={time}
                                    onDateChange={handleDateTimeChange}
                                // onTimeChange={handleDateTimeChange}
                                />
                            </InputGroupItem>
                        </InputGroup>
                    </GridItem>
                </Grid>
                <Grid  md={4} hasGutter>
                    {[
                        {
                            label: 'Severity', fieldId: 'severity', helperText: 'Severity is required', popoverHeader: 'Severity', popoverBody: 'Severity', value: severity, onChange: handleSeverityToggle, items: [
                                { text: 'L', color: '#FFFF00' }, { text: 'M', color: '#ADD8E6' }, { text: 'H', color: '#FF6347' }, { text: 'C', color: '#8B0000' }
                            ]
                        },
                        {
                            label: 'TLP', fieldId: 'simple-form-group-05', helperText: 'TLP is required', popoverHeader: 'TLP', popoverBody: 'Traffic Light Protocol', value: tlp, onChange: handleTlpToggle, items: [
                                { text: 'WHITE' }, { text: 'GREEN' }, { text: 'AMBER' }, { text: 'RED' }
                            ]
                        },
                        {
                            label: 'PAP', fieldId: 'simple-form-group-09', helperText: 'PAP is required', popoverHeader: 'PAP', popoverBody: 'Policy Administration Point', value: pap, onChange: handlePapToggle, items: [
                                { text: 'WHITE' }, { text: 'GREEN' }, { text: 'AMBER' }, { text: 'RED' }
                            ]
                        }
                    ].map(({ label, fieldId, helperText, popoverHeader, popoverBody, value, onChange, items }, index) => (
                        <GridItem key={index} >
                            <FormGroup
                                label={label}
                                isRequired
                                fieldId={fieldId}
                                helperTextInvalid={helperText}
                                labelIcon={
                                    <Tooltip content={<div>{popoverBody}</div>}>
                                        <InfoAltIcon />
                                    </Tooltip>
                                }
                            >
                              
                                <ToggleGroup aria-label={label}>
                                    {items.map(({ text, color }, i) => (
                                        <ToggleGroupItem
                                            key={i}
                                            className='custom-toggle-group'
                                            buttonId={text}
                                            isSelected={value === text}
                                            onChange={onChange}
                                            text={
                                                <div
                                                    style={{
                                                        backgroundColor: value === text ? extendedTlpColorMap[text]?.backgroundColor : '#fff',
                                                        color: value === text ? extendedTlpColorMap[text]?.color : '#000',
                                                        padding: '4px 14px',
                                                        borderRadius: '4px',
                                                    }}
                                                >
                                                    {text}
                                                </div>
                                            }
                                        >
                                            <Badge
                                                style={{
                                                    backgroundColor: value === text ? extendedTlpColorMap[text]?.backgroundColor : '#f0f0f0',
                                                    color: value === text ? extendedTlpColorMap[text]?.color : '#000',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {text}
                                            </Badge>
                                        </ToggleGroupItem>
                                    ))}
                                </ToggleGroup>

                            </FormGroup>
                        </GridItem>
                    ))}
                </Grid>
                <IndexInputGroup
                    placeholder='Enter Tags Name'
                    currentChips={chips}
                    setCurrentChips={handleSetChips}
                    indexNameValid={indexNameValid}
                />
                <FormGroup fieldId='description'>
                    <TextArea
                        isRequired
                        value={description}
                        placeholder='Description'
                        onChange={(e, value) => handleFieldChange(value, 'description')}
                        id="description"
                        name="description"
                        validated={validdescription}
                    />
                </FormGroup>
                <CommonSelectBox
                    options={orgList.map((item) => ({
                        value: item.unique_id,
                        children: item.name
                    }))}
                    isDisabled={false}
                    createOption={false}
                    selectedOptions={selectedOrgValue}
                    placeholder="Organization"
                    updateValue={handleUpdateOrgValue}
                    validStatus={organizationValid}
                />

                <FormGroup
                    isRequired
                    fieldId="doc-id"
                >
                    <TextInput
                        value={docId}
                        isRequired
                        placeholder='Document ID'
                        type="text"
                        id="doc_id"
                        aria-describedby="horizontal-form-name-helper"
                        name="doc_id"
                        onChange={(e, value) => handleFieldChange(value, 'docId')}
                    />
                </FormGroup>

                <ActionGroup>
                    <Button variant="primary" icon={<SaveIcon />} onClick={() => submitForm()} >Create</Button>
                    <Button variant="secondary" onClick={onclose}>Cancel</Button>
                </ActionGroup>
            </Form>
        }
        <ConfirmBox confirmModal={confirmModalData} />
    </>
    );
};

export default IncidentModal;
