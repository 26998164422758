
import * as echarts from 'echarts';
import React, { useEffect } from 'react';
import { generateUniqueID } from '../../util/UniqueIdGenerator';
import { connect, useDispatch } from 'react-redux';
const AgentTopOsChart = (props) => {
    const id = generateUniqueID()
    useEffect(() => {
        const chartDom = document.getElementById(id + 'atos');
        const myChart = echarts.init(chartDom);
        const textcolor = props.theme == 'dark-theme' ? '#fff' : '#000'
        const bordercolor = props.theme == 'dark-theme' ? '#262525' : '#fff'
        const option = {
            tooltip: {
                trigger: 'item',
                textStyle: {
                    color: "#000"
                },
                position: ['50%', '50%'],
            },

            legend: {
                top: 'bottom',
                itemWidth: 9.5,
                itemHeight: 10,
                itemGap: 6.5,
                icon: "rect",
                tooltip: {
                    trigger: 'item',
                    show: true,
                    position: [60, 40],
                    textStyle: {
                        color: "#000",
                        fontSize: 12,
                    }
                },
                textStyle: {
                    width: 200,
                    overflow: 'truncate',
                    fontSize: 12,
                    color: textcolor
                }
            },
            color: ['#6753AC', '#009596', '#6EC664', '#C9190B', '#00B9E4'],
            series: [
                {
                    type: 'pie',
                    top:5,
                    radius: ['50%', '70%'],
                    center: ['50%', '35%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 0,
                        borderColor: bordercolor,
                        borderWidth: 1.5
                    },
                    label: {
                        show: false,
                        position: 'center',
                    },
                    emphasis: {
                        label: { show: false, }
                    },
                    labelLine: {
                        show: false
                    },
                    data: props.seriesData.map((bucket) => ({
                        value: bucket.doc_count, // Use log scale for word size
                        name: bucket.key,

                    })
                    )
                }
            ]
        };

        option && myChart.setOption(option);
        const resizeChart = () => {
            if (myChart) { // Check if chart exists before resizing
              myChart.resize();
            }
          };

        window.addEventListener('resize', resizeChart);
        return () => {
            myChart.dispose();
            window.removeEventListener('resize', resizeChart);
        };

    }, [props.seriesData,  props.theme,props.sidebarState])

    return <div className="container">
        <div id={id + 'atos'} style={{ width: '100%', height:'230px' }} ></div>
    </div>;
}
const mapStateToProps = (state) => ({
    dates: { startDate: state.startdate, endDate: state.enddate },
    filterformdata: state.FilterData,
    theme: state.theme,
    sidebarState:state.sidebarstate
    // mapStateToProps content remains unchanged if you have it
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true }
)(AgentTopOsChart)

